// import Constants from "expo-constants";
import appCache from "../services/appCache";
import i18n from "./i18n";
import { navigateTo } from "../navigation/navigationHelpers";
import { Platform } from "react-native";
import config from "../services/config";

export type ErrorResponse = {
  [key: string]: string[];
};

// const {
//   API_URL = "",
//   TOKEN_URL = "",
//   REGISTRATION_URL = "",
//   TOKEN_KEY = "",
//   REFRESH_TOKEN_KEY = "",
// } = Constants.manifest.extra;

const {
  API_URL = "",
  TOKEN_URL = "",
  REGISTRATION_URL = "",
  TOKEN_KEY = "",
  REFRESH_TOKEN_KEY = "",
} = config;

let isTokenFetching = false;
const tokenSubscribers: Function[] = [];

const getNormalizedUrl = (uri: string) => {
  if (/^http/.test(uri)) {
    return uri;
  }

  const normalizedUrl = API_URL + uri;
  return normalizedUrl;
};

export const fileRequest = async (dataUri: string): Promise<Blob> => {
  let headers: { [KEY: string]: string } = {};
  let extraConfig: { [KEY: string]: string } = {};

  if (dataUri.includes(API_URL) || dataUri.includes("https://alt.")) {
    headers = await getAuthHeaders();
  }

  if (!dataUri.includes("/documents_archive/")) {
    extraConfig.cache = "no-store";
  }

  return fetch(dataUri, {
    ...extraConfig,
    headers,
  }).then((res) => res.blob());
};

export const refreshTokenRequest = async () => {
  isTokenFetching = true;

  const res = await fetch(getNormalizedUrl(TOKEN_URL || ""), {
    method: "POST",
    body: JSON.stringify({
      refresh_token: await appCache.getValue(REFRESH_TOKEN_KEY),
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!res.ok) {
    await appCache.setValue(TOKEN_KEY, "");
    await appCache.setValue(REFRESH_TOKEN_KEY, "");

    isTokenFetching = false;
    if (Platform.OS === "web" && window.location.href.includes("AUTH")) {
      return;
    } else {
      return navigateTo("AUTH.SIGN_IN");
    }
  }

  const data = await res.json();

  await appCache.setValue(TOKEN_KEY, data.access_token);
  await appCache.setValue(REFRESH_TOKEN_KEY, data.refresh_token);

  isTokenFetching = false;

  return data;
};

export const isAuthHeadersNeeded = (url: string) => {
  if (
    url.indexOf(REGISTRATION_URL) !== -1 &&
    url.indexOf("https://alt.") !== -1
  ) {
    return false;
  }

  return (
    (url.indexOf(API_URL) !== -1 &&
      url.indexOf(TOKEN_URL) === -1 &&
      url.indexOf(REGISTRATION_URL) === -1) ||
    url.indexOf("https://alt.") !== -1 ||
    !/^http/.test(url)
  );
};

export const getAuthHeaders = async () => {
  const token = await appCache.getValue(TOKEN_KEY);

  const headers: { [key: string]: string } = {};

  if (!token) {
    return headers;
  }

  headers.Authorization = `Bearer ${token}`;

  return headers;
};

const getNormalizedConfig = async (
  config: RequestInit,
  shouldAddAuthHeaders: boolean
): Promise<RequestInit> => {
  config.headers = {
    ...config.headers,
    "accept-language": i18n.language,
  };

  // skip adding auth headers if getting new token or creating account
  if (!shouldAddAuthHeaders) {
    return config;
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      ...(await getAuthHeaders()),
    },
  };
};

const handleAuthError = async <T>(webRequest: () => Promise<T>) => {
  let rejectHandler: (args: any) => void;

  if (!isTokenFetching) {
    refreshTokenRequest()
      .then(() => {
        tokenSubscribers.forEach((callback) => callback());
        tokenSubscribers.length = 0;
      })
      .catch((err) => {
        isTokenFetching = false;
        rejectHandler(err);
      });
  }

  return new Promise((resolve, reject) => {
    rejectHandler = reject;
    tokenSubscribers.push(() => {
      resolve(webRequest());
    });
  });
};

const handleGenericError = async (err: any) => {
  let error = err;

  if (err && typeof err === "string") {
    return { ["non_field_error"]: err };
  }

  if ("json" in err && typeof err.json === "function") {
    try {
      error = await err.json();
    } catch (err) {
      error = { ["non_field_error"]: "response error" };
    }
  }

  throw error;
};

const handleResponse = (authFallbackRequest?: () => Promise<any>) => async (
  res: Response
) => {
  if (!res.ok) {
    if (authFallbackRequest && (res.status === 401 || res.status === 403)) {
      return handleAuthError(authFallbackRequest);
    }
    throw res;
  }

  try {
    const data = await res.json();
    return data;
  } catch (err) {
    return true;
  }
};

const getDecoratedFetch = async <T>(
  url: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  extraConfig: RequestInit = { headers: {} },
  body?: object | FormData
): Promise<T> => {
  const normalizedUrl = getNormalizedUrl(url);

  let normalizedConfig = await getNormalizedConfig(
    extraConfig,
    isAuthHeadersNeeded(url)
  );

  normalizedConfig = {
    ...normalizedConfig,
    method,
  };

  if (!(body instanceof FormData)) {
    normalizedConfig.headers = {
      ...normalizedConfig.headers,
      "Content-Type": "application/json",
    };
  }

  if (body instanceof FormData && Platform.OS !== "web") {
    normalizedConfig.headers = {
      ...normalizedConfig.headers,
      "Content-Type": "multipart/form-data",
    };
  }

  if (method !== "GET") {
    normalizedConfig = {
      ...normalizedConfig,
      body: body instanceof FormData ? body : JSON.stringify(body),
      headers: {
        ...normalizedConfig.headers,
      },
    };
  }

  const repeatedRequest = async () => {
    const repeatedConfig = await getNormalizedConfig(normalizedConfig, true);

    return fetch(normalizedUrl, repeatedConfig)
      .then(handleResponse())
      .catch(handleGenericError);
  };

  const normalizedRequest = fetch(normalizedUrl, normalizedConfig)
    .then(handleResponse(repeatedRequest))
    .catch(handleGenericError) as Promise<T>;

  return normalizedRequest;
};

const wpRequest = {
  get: <T>(url: string) => getDecoratedFetch<T>(url, "GET"),
  post: <T>(url: string, body: object | FormData, extraConfig?: RequestInit) =>
    getDecoratedFetch<T>(url, "POST", extraConfig, body),

  put: <T>(url: string, body: object | FormData, extraConfig?: RequestInit) =>
    getDecoratedFetch<T>(url, "PUT", extraConfig, body),

  delete: <T>(url: string) => getDecoratedFetch<T>(url, "DELETE"),
};

export default wpRequest;

export const getBaseUrl = () => API_URL;
