import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const RefreshIcon: React.FC<IconProps> = (props) => {
  let height = 20;
  let width = 20;
  const ratio = width / height;
  const color = props.color || "#637381";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M16.032 6.129c.392 0 .716.291.768.67l.007.104v4.835a4.197 4.197 0 01-3.988 4.192l-.21.005H8.613l.742.743c.275.275.3.705.075 1.008l-.075.087a.774.774 0 01-1.008.075l-.087-.075-2.064-2.064a.774.774 0 01-.075-1.008l.075-.087 2.064-2.065a.775.775 0 011.17 1.008l-.075.087-.743.743h3.999a2.648 2.648 0 002.643-2.481l.005-.168V6.903c0-.427.347-.774.774-.774zm-4.571-3.977l.086.075 2.065 2.064c.275.275.3.705.075 1.008l-.075.087-2.065 2.065a.774.774 0 01-1.169-1.008l.075-.087.741-.743H7.197a2.648 2.648 0 00-2.643 2.481l-.006.168v4.835a.774.774 0 01-1.541.104L3 13.098V8.262A4.197 4.197 0 016.988 4.07l.21-.005h3.997l-.742-.743a.774.774 0 01-.075-1.008l.075-.087a.774.774 0 011.008-.075z"
      />
    </Svg>
  );
};

RefreshIcon.displayName = "RefreshIcon";

export default RefreshIcon;
