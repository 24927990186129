import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, ScrollView, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import Svg, { Path, Mask, G } from "../../../../components/Icon/SVG";
import ReviewNode from "../../../../components/misc/ReviewNode";
import WPAvatar from "../../../../components/misc/WPAvatar";
import WPSeparator from "../../../../components/misc/WPSeparator";
import WPText from "../../../../components/Text/WPText";
import { useRatingHook } from "../../../../services/hooks/authHooks";
import { getEmployeeDisplayName } from "../../../../services/store/reducers/employee/employeeReducer";
import { RootState } from "../../../../services/store/reducers/mainReducer";
import theme, { isMobileView } from "../../../../utils/theme";

function getStarType(starIdx: number, rating: number) {
  if (starIdx > rating) {
    return "empty";
  }

  if (starIdx < rating && starIdx === Math.floor(rating)) {
    return "half";
  }

  return "full";
}

export function RatingStar(props: {
  type: "empty" | "half" | "full";
  dense?: boolean;
}) {
  const size = props.dense ? 16 : 20;
  if (props.type === "half") {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
        {...props}
      >
        <Path
          stroke="#FF8200"
          strokeWidth={1.5}
          d="M8.12 11.679l-.349-.184-.349.184-3.491 1.836.667-3.888.066-.389-.282-.275-2.825-2.754 3.904-.567.39-.056.175-.354L7.77 1.695l1.746 3.537.175.354.39.056 3.903.567-2.824 2.754-.283.275.067.389.667 3.888-3.492-1.836z"
        />
        <Mask
          id="prefix__a"
          width={16}
          height={15}
          x={0}
          y={0}
          maskUnits="userSpaceOnUse"
        >
          <Path
            fill="#fff"
            stroke="#fff"
            strokeWidth={1.5}
            d="M8.12 11.679l-.349-.184-.349.184-3.491 1.836.667-3.888.066-.389-.282-.275-2.825-2.754 3.904-.567.39-.056.175-.354L7.77 1.695l1.746 3.537.175.354.39.056 3.903.567-2.824 2.754-.283.275.067.389.667 3.888-3.492-1.836z"
          />
        </Mask>
        <G mask="url(#prefix__a)">
          <Path fill="#FF8200" d="M.571 1.029h7.2V14.4h-7.2z" />
        </G>
      </Svg>
    );
  }

  return (
    <Svg width={size} height={size} fill="none" viewBox="0 0 20 20" {...props}>
      <Path
        fill={props.type === "empty" ? "#979797" : "#FF8200"}
        fillRule="evenodd"
        d={
          props.type === "empty"
            ? "M10.277.852c.123.06.223.16.283.284l2.379 4.819 5.318.773a.625.625 0 01.347 1.066l-3.849 3.751.909 5.297a.626.626 0 01-.907.659L10 15l-4.757 2.5a.623.623 0 01-.907-.658l.909-5.297-3.849-3.751a.625.625 0 01.347-1.066l5.318-.773 2.379-4.82a.624.624 0 01.837-.283zM8.069 7.314l-4.319.66 3.125 3.203-.738 4.523L10 13.564l3.863 2.136-.738-4.523 3.125-3.203-4.319-.66L10 3.2 8.069 7.314z"
            : "M10 15l-4.981 2.619a.5.5 0 01-.726-.527l.952-5.547-4.03-3.928a.5.5 0 01.277-.853l5.57-.81L9.551.909a.5.5 0 01.896 0l2.49 5.047 5.57.81a.5.5 0 01.277.852l-4.03 3.928.952 5.547a.5.5 0 01-.726.527L10 15z"
        }
        clipRule="evenodd"
      />
    </Svg>
  );
}
export function TotalRatingStars(props: { rating: number; dense?: boolean }) {
  return (
    <View style={{ flexDirection: "row" }}>
      <RatingStar type={getStarType(1, props.rating)} dense={props.dense} />
      <RatingStar type={getStarType(2, props.rating)} dense={props.dense} />
      <RatingStar type={getStarType(3, props.rating)} dense={props.dense} />
      <RatingStar type={getStarType(4, props.rating)} dense={props.dense} />
      <RatingStar type={getStarType(5, props.rating)} dense={props.dense} />
    </View>
  );
}

const RatingScreen = () => {
  const { t } = useTranslation();
  const employerData = useSelector((state: RootState) => state.auth);
  const [totalRating, reviews, getReviews, totalCount] = useRatingHook();

  useEffect(() => {
    getReviews();
  }, []);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.titleContainer}>
        <WPText style={styles.title}>{t("titles.rating")}</WPText>
      </View>
      <View style={styles.formContainer}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <WPAvatar source={employerData.avatar} size="l" />
          <View style={{ marginLeft: 12 }}>
            <WPText style={{ fontFamily: "black", fontSize: 18 }}>
              {employerData.company}
            </WPText>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <WPText
                style={{ fontFamily: "black", marginRight: 4, fontSize: 18 }}
              >
                {totalRating.general}
              </WPText>
              <TotalRatingStars rating={totalRating.general} dense />
              <WPText
                style={{
                  marginLeft: 12,
                  fontSize: 14,
                  color: theme.colors.mains[3],
                }}
              >{`${totalCount} ${t("labels.reviews", {
                count: totalCount,
              })}`}</WPText>
            </View>
          </View>
        </View>
        <WPSeparator />
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <View style={{ marginRight: 36 }}>
            {Object.keys(totalRating)
              .filter((key) => key !== "general")
              .map((key) => (
                <WPText key={key} style={{ marginBottom: 12 }}>
                  {t(`labels.${key}`)}
                </WPText>
              ))}
          </View>
          <View>
            {Object.keys(totalRating)
              .filter((key) => key !== "general")
              .map((key) => (
                <View style={{ justifyContent: "space-between", flex: 1 }}>
                  <TotalRatingStars rating={totalRating[key]} />
                </View>
              ))}
          </View>
        </View>
        <WPSeparator />
        <FlatList
          data={reviews}
          keyExtractor={(item) => item.date_created}
          onEndReached={getReviews}
          ItemSeparatorComponent={() => <WPSeparator />}
          renderItem={({ item }) => (
            <View>
              <ReviewNode
                avatar={item.employee.photo}
                name={getEmployeeDisplayName(item.employee)}
                text={item.content}
                date={item.date_created}
              />
            </View>
          )}
        />
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
  },
  spacer: {
    flex: 1,
  },
  titleContainer: {
    justifyContent: "space-between",
    backgroundColor: theme.colors.white,
    marginBottom: 32,
    paddingBottom: 20,
    paddingTop: 12,
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 10,
    paddingHorizontal: 16,
    flexDirection: isMobileView() ? "column" : "row",
  },
  title: {
    fontFamily: "bold",
    fontSize: 28,
  },
  formContainer: {
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
});

export default RatingScreen;
