// TODO: make an abstraction
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import CRMDataScreen from "../../common/CRMDataScreen";
import { useGetEstateList, useRenderEstateTile } from "../estateUtils";
import { navigateTo } from "../../../navigationHelpers";
import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { RootState } from "../../../../services/store/reducers/mainReducer";
import { isMobileView } from "../../../../utils/theme";

export default function EstateListScreen() {
  const isUpdateScheduled = useSelector(
    (state: RootState) => state.ui.shouldUpdateList
  );
  const navigation = useNavigation();
  const { i18n, t } = useTranslation();
  const getEstates = useGetEstateList();

  const navigateToAddJob = useCallback(() => {
    navigateTo("CRM.JOB.CREATE_ESTATE");
  }, []);

  const renderItem = useRenderEstateTile({
    actionConfig: {},
    isCompactView: isMobileView(),
  });

  return (
    <CRMDataScreen
      navigation={navigation}
      title={t("labels.estate_offers")}
      getData={getEstates}
      renderItem={renderItem}
      addLabel={t("labels.add_estate_offer")}
      onAdd={navigateToAddJob}
      language={i18n.language}
      isUpdateScheduled={isUpdateScheduled}
    />
  );
}
