import React from "react";
import Svg, { Path, G, Defs, ClipPath } from "../SVG";
import { IconProps } from "../WPIcon";

const ListViewIcon: React.FC<IconProps> = (props) => {
  let height = 12;
  let width = 12;
  const ratio = width / height;
  const color = props.color || "#0076FF";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg
      {...props}
      width={width}
      height={height}
      style={[{ height, width }, props.style]}
      viewBox={`0 0 12 12`}
    >
      <G fill="#919EAB" clipPath="url(#prefix__clip0)">
        <Path d="M11.924 5.84C11.817 5.695 9.262 2.25 6 2.25 2.738 2.25.183 5.694.076 5.84a.4.4 0 000 .468c.107.146 2.662 3.59 5.924 3.59 3.262 0 5.816-3.444 5.924-3.59a.398.398 0 000-.467zM6 9.108c-2.403 0-4.484-2.286-5.1-3.033C1.515 5.326 3.592 3.04 6 3.04c2.403 0 4.484 2.286 5.1 3.033-.615.748-2.692 3.033-5.1 3.033z" />
        <Path d="M6 3.7a2.376 2.376 0 00-2.374 2.374A2.376 2.376 0 006 8.447a2.376 2.376 0 002.373-2.373A2.377 2.377 0 006 3.7zm0 3.956c-.873 0-1.583-.71-1.583-1.582 0-.873.71-1.583 1.583-1.583.872 0 1.582.71 1.582 1.583 0 .872-.71 1.582-1.582 1.582z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h12v12H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

ListViewIcon.displayName = "ListViewIcon";

export default ListViewIcon;
