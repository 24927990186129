import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import InfoRow from "./InfoRow";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store/reducers/mainReducer";
import { useBaseEmployeeInfo } from "../../services/hooks/employeeHooks";

const MISSING_DATA_VALUE = "-";

function BaseUserInfoPreview(props: { userId: number }) {
  const { t } = useTranslation();

  const userData = useBaseEmployeeInfo(props.userId, true);

  if (!userData) {
    return null;
  }

  return (
    <View>
      <InfoRow
        label={t("labels.first_name")}
        value={userData.first_name}
        style={{ marginBottom: 10 }}
      />
      <InfoRow
        label={t("labels.last_name")}
        value={userData.last_name}
        style={{ marginBottom: 10 }}
      />
      <InfoRow
        label={t("labels.phone")}
        value={userData.phone_number}
        style={{ marginBottom: 10 }}
      />
      <InfoRow
        label={t("labels.age_range")}
        value={t(`select.age_range.${userData.age}`, MISSING_DATA_VALUE)}
        style={{ marginBottom: 10 }}
      />
      <InfoRow
        label={t("labels.gender")}
        value={t(`select.gender.${userData.gender}`, MISSING_DATA_VALUE)}
        style={{ marginBottom: 10 }}
      />
      <InfoRow
        label={t("labels.citizenship")}
        value={userData.citizenship}
        style={{ marginBottom: 10 }}
      />
      <InfoRow
        label={t("labels.visa")}
        value={t(`select.visa_type.${userData.visa_type}`, MISSING_DATA_VALUE)}
        style={{ marginBottom: 10 }}
      />
      <InfoRow
        label={t("labels.skills")}
        value={
          userData.skill_list
            ? userData.skill_list.join("\n")
            : MISSING_DATA_VALUE
        }
      />
    </View>
  );
}

export default memo(BaseUserInfoPreview);
