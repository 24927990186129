import React from "react";
import { defaultCRMScreenOptions, RouterType } from "../../navigationConstants";
import ChatListScreen from "./screens/ChatListScreen";
import {
  buildRouter,
  RouterConfig,
  withScreenWrapper,
} from "../../navigationHelpers";
import theme from "../../../utils/theme";
import UserTile from "../../../components/misc/UserTile";
import ConversationScreen from "./screens/ConversationScreen";

import UserInfoButton from "../../../components/misc/UserInfoButton";
import BackButton from "../../common/BackButton";

const CHAT_LIST = "CHAT_LIST";
const CONVERSATION = "CONVERSATION";

export const ChatRouterConfig: RouterConfig = {
  type: RouterType.stack,
  containerConfig: {
    headerMode: "screen",
    initialRouteName: CHAT_LIST,
    screenOptions: defaultCRMScreenOptions,
  },
  screens: {
    [CHAT_LIST]: {
      name: CHAT_LIST,
      component: ChatListScreen,
      options: {
        ...defaultCRMScreenOptions,
        headerLeft: () => (
          <BackButton fallBackRoute="CRM.JOB.ACTIVE_JOB_LIST" />
        ),
      },
    },
    [CONVERSATION]: {
      name: CONVERSATION,
      component: withScreenWrapper(ConversationScreen, true),

      options: ({ route }) => ({
        headerStyle: [
          defaultCRMScreenOptions.headerStyle,
          {
            backgroundColor: theme.colors.white,
          },
        ],
        headerLeft: () => (
          <BackButton
            color={theme.colors.main}
            fallBackRoute="CRM.JOB.CHAT_LIST"
          />
        ),

        headerTitle: () => {
          const { params = {} } = route;

          return <UserTile userId={params["userId"]} />;
        },
        headerRight: () => {
          const { params = {} } = route;
          const userId = Number(params["userId"]);
          return <UserInfoButton userId={userId} />;
        },
      }),
    },
  },
};

export default buildRouter(ChatRouterConfig);
