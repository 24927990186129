import React, { Component } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  ViewStyle,
  StatusBar,
  Platform,
  GestureResponderEvent,
} from "react-native";
import WPList, { WPListProps } from "../list/WPList";
import theme, { getThemeResponsiveValue } from "../../utils/theme";
import WPModal from "../modal/WPModal";

type DropDownWrapperProps<T> = WPListProps<T> & {
  disabled?: boolean;
  isFocused: boolean;
  manualTrigger?: boolean;
  setIsFocused: (focusState: boolean) => void;
};

type DropDownWrapperState<T> = {
  listConfig?: {
    isLeft: boolean;
    isTop: boolean;
    screenHeight: number;
    screenWidth: number;
    wrapperHeight: number;
    wrapperWidth: number;
    wrapperX: number;
    wrapperY: number;
  };
  selectedValue?: T;
};

const LIST_HEIGHT = getThemeResponsiveValue(theme.input.size.regular) * 6;

class DropDownWrapper<T> extends Component<
  DropDownWrapperProps<T>,
  DropDownWrapperState<T>
> {
  state = {
    listConfig: undefined,
    selectedValue: undefined,
  };
  wrapperRef = React.createRef<TouchableOpacity>();

  calculatePosition = (onDone: () => void) => {
    this.wrapperRef.current?.measure((_x, _y, width, height, pageX, pageY) => {
      const screenDimensions = Dimensions.get("window");

      const deviceWidth = screenDimensions.width;
      const deviceHeight = screenDimensions.height;

      this.setState(
        (state) => ({
          ...state,
          listConfig: {
            isLeft: pageX > deviceWidth / 2,
            isTop:
              pageY > deviceHeight / 2 &&
              deviceHeight - pageY <
                getThemeResponsiveValue(theme.input.size.regular) * 5,
            screenHeight: deviceHeight,
            screenWidth: deviceWidth,
            wrapperHeight: height,
            wrapperWidth: width,
            wrapperX: pageX,
            wrapperY: pageY,
          },
        }),
        onDone
      );
    });
  };

  onFocus = () => {
    this.calculatePosition(
      () => !this.props.isFocused && this.props.setIsFocused(true)
    );
  };

  onPress = (e: GestureResponderEvent) => {
    this.calculatePosition(() => {
      this.props.setIsFocused(!this.props.isFocused);
    });
  };

  onBlur = () => {
    this.calculatePosition(() => this.props.setIsFocused(false));
  };

  onChange = () => {
    const selectedValue = this.state.selectedValue;

    if (typeof this.props.onChange === "function" && selectedValue) {
      this.props.onChange(selectedValue);
    }

    this.setState((state) => ({
      ...state,
      selectedValue: undefined,
    }));
  };

  onSelected = (value: T) => {
    this.onBlur();

    this.setState(
      (state) => ({
        ...state,
        selectedValue: value,
      }),
      () => (Platform.OS === "web" ? this.onChange() : undefined)
    );
  };

  render() {
    const {
      children,
      style,
      isFocused,
      setIsFocused,
      ...restProps
    } = this.props;
    return (
      <>
        <TouchableOpacity
          ref={this.wrapperRef}
          onPress={this.onPress}
          style={style}
          disabled={this.props.disabled}
        >
          {children}
        </TouchableOpacity>
        <WPModal
          isVisible={isFocused}
          animationIn="fadeIn"
          animationOut="fadeOut"
          animationOutTiming={10}
          backdropOpacity={0}
          style={styles.listModal}
          onBackdropPress={this.onBlur}
          coverScreen={true}
          onModalHide={this.onChange}
        >
          <WPList
            {...restProps}
            style={getListStyle(this.state)}
            onChange={!this.props.manualTrigger ? this.onSelected : undefined}
          />
        </WPModal>
      </>
    );
  }
}

const styles = StyleSheet.create({
  listModal: {
    margin: 0,
  },
  list: {
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    height: "auto",
    maxHeight: LIST_HEIGHT,
  },
});

function getListStyle(state: DropDownWrapperState<any>) {
  // Modal does not extends to the very top edge of the screen, so we are forced to compensate this
  let delta = Platform.OS === "android" ? StatusBar.currentHeight || 0 : 0;
  return state.listConfig
    ? [
        {
          position: "absolute",
          minWidth: state.listConfig?.wrapperWidth,
          top: state.listConfig?.isTop
            ? "auto"
            : (state.listConfig?.wrapperY || 0) +
              (state.listConfig?.wrapperHeight || 0) -
              delta +
              4,
          bottom: state.listConfig?.isTop
            ? state.listConfig?.screenHeight -
              (state.listConfig?.wrapperY - 4) +
              delta
            : "auto",
          left: state.listConfig?.isLeft ? "auto" : state.listConfig?.wrapperX,
          right: state.listConfig?.isLeft
            ? state.listConfig?.screenWidth -
              (state.listConfig?.wrapperX + state.listConfig?.wrapperWidth)
            : "auto",
        } as ViewStyle,
        styles.list,
      ]
    : styles.list;
}

export default DropDownWrapper;
