import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserPreviewId } from "../../services/store/actions/uiActions";
import { RootState } from "../../services/store/reducers/mainReducer";
import WPButton from "../Button/WPButton";

export default function UserInfoButton(props: { userId: number }) {
  const dispatch = useDispatch();
  const activeUserPreviewId = useSelector(
    (state: RootState) => state.ui.userPreviewId
  );
  const targetId =
    activeUserPreviewId === props.userId ? undefined : props.userId;

  const toggleUserInfo = useCallback(() => {
    dispatch(setUserPreviewId(targetId));
  }, [targetId]);

  return (
    <WPButton
      type="icon"
      name="info"
      appearance="link"
      onPress={toggleUserInfo}
    />
  );
}
