import React, { memo, useCallback } from "react";
import { View, SafeAreaView, StyleSheet, ScrollView } from "react-native";
import theme from "../../../utils/theme";
import CRMDrawerGroup from "./CRMDrawerGroup";
import { useTranslation } from "react-i18next";
import WPLanguageSelect from "../../../components/input/components/misc/WPLanguageSelect";
import { useSignOut } from "./signOutHook";
import { useSelector, useDispatch } from "react-redux";
import {
  getAuthState,
  RootState,
} from "../../../services/store/reducers/mainReducer";
import { signedOutAction } from "../../../services/store/actions/authActions";
import WPSeparator from "../../../components/misc/WPSeparator";
import config from "../../../../package.json";
import WPText from "../../../components/Text/WPText";

function CRMDrawerContent(props: { isVisible: boolean }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const signOut = useSignOut();
  const authState = useSelector(getAuthState);
  const signOutDecorated = useCallback(() => {
    signOut(() => dispatch(signedOutAction()));
  }, [dispatch]);

  const totalActive = useSelector((state: RootState) => state.jobs.totalActive);
  const totalExpired = useSelector(
    (state: RootState) => state.jobs.totalExpired
  );
  const totalEstates = useSelector((state: RootState) => state.estate.total);
  const totalCandidates = useSelector(
    (state: RootState) => state.jobCandidates.total
  );

  const userItems = {
    image: authState.avatar || "",
    title: authState.userName || "",
    items: [
      {
        title: t("labels.company_data"),
        link: "CRM.JOB.EDIT_PROFILE",
        label: undefined,
      },
      { title: t("titles.bills"), link: "CRM.JOB.BILLS", label: undefined },
      { title: t("titles.rating"), link: "CRM.JOB.RATING", label: undefined },
    ],
  };

  const workItems = [
    {
      title: t("titles.active_job_offers"),
      link: "CRM.JOB.ACTIVE_JOB_LIST",
      label: totalActive ? totalActive.toString() : undefined,
    },
    {
      title: t("titles.expired_job_offers"),
      link: "CRM.JOB.EXPIRED_JOB_LIST",
      label: totalExpired ? totalExpired.toString() : undefined,
    },
    {
      title: t("titles.job_candidates"),
      link: "CRM.JOB.ALL_CANDIDATES",
      label: totalCandidates ? totalCandidates.toString() : undefined,
    },
  ];

  const estateItems = [
    {
      title: t("titles.estate_list"),
      link: "CRM.JOB.ESTATE_LIST",
      label: totalEstates ? totalEstates.toString() : undefined,
    },
  ];

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.navigationContainer}>
        <CRMDrawerGroup {...userItems} isVisible={props.isVisible} />
        <CRMDrawerGroup
          icon="user"
          title={t("titles.work")}
          items={workItems}
          isVisible={props.isVisible}
        />
        <CRMDrawerGroup
          icon="home"
          title={t("titles.estate")}
          items={estateItems}
          isVisible={props.isVisible}
        />
        <WPSeparator style={styles.separator} />
        <CRMDrawerGroup
          icon="logout"
          title={t("labels.logout")}
          items={[]}
          isVisible={props.isVisible}
          onClick={signOutDecorated}
        />
      </ScrollView>
      <View style={styles.bottomBar}>
        <WPLanguageSelect
          labelColor={theme.colors.white}
          isShort={!props.isVisible}
        />
        <View>
          {props.isVisible ? (
            <WPText style={{ color: "white", marginRight: 16, opacity: 0.5 }}>
              v{config.version}
            </WPText>
          ) : null}
        </View>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.main,
  },
  navigationContainer: {
    flex: 1,
    borderBottomColor: theme.colors.mains[2],
    borderTopColor: theme.colors.transparent,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    paddingBottom: 28,
  },
  userName: {
    color: theme.colors.white,
    fontFamily: theme.fontFamily.bold,
  },
  linkText: {
    color: theme.colors.white,
    fontFamily: theme.fontFamily.bold,
    fontSize: 14,
  },
  topBar: { padding: theme.button.paddingHorizontal },
  bottomBar: {
    height: 54,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  editContainer: { flexDirection: "row", alignItems: "center" },
  editIcon: {
    marginRight: 4,
  },
  editText: { color: theme.colors.primaries[2], fontSize: 14 },
  separator: {
    borderBottomColor: theme.colors.mains[2],
  },
});

export default memo(CRMDrawerContent);
