import React, { useState, memo } from "react";
import {
  StyleProp,
  ViewStyle,
  TouchableOpacity,
  SafeAreaView,
  StyleSheet,
  View,
  Image,
} from "react-native";
import WPModal from "../modal/WPModal";
import WPButton from "../Button/WPButton";
import theme from "../../utils/theme";
import WPText from "../Text/WPText";
import { useTranslation } from "react-i18next";
import { saveToLibrary } from "../../utils/fileUtils";

function WPImagePreviewWrapper(props: {
  style?: StyleProp<ViewStyle>;
  imageUri: string;
  imageName?: string;
  images?: { imageUri: string; imageName?: string }[];
  children: React.ReactNode;
}) {
  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation();

  return (
    <TouchableOpacity onPress={() => setIsActive(true)} style={props.style}>
      {props.children}

      <WPModal isVisible={isActive} backdropOpacity={0.95}>
        <SafeAreaView style={style.container}>
          <SafeAreaView style={style.innerContainer}>
            <Image
              resizeMode="contain"
              source={{
                uri: props.imageUri,
              }}
              style={style.container}
            />
          </SafeAreaView>
          <View style={style.buttons}>
            <WPButton appearance="link" onPress={() => setIsActive(false)}>
              <WPText style={style.buttonText}>{t("labels.close")}</WPText>
            </WPButton>
            <WPButton
              appearance="link"
              onPress={() => {
                saveToLibrary(props.imageUri, props.imageName, () =>
                  setIsActive(false)
                );
              }}
            >
              <WPText style={style.buttonText}>{t("labels.save")}</WPText>
            </WPButton>
          </View>
        </SafeAreaView>
      </WPModal>
    </TouchableOpacity>
  );
}

export default memo(WPImagePreviewWrapper);

const style = StyleSheet.create({
  container: {
    flex: 1,
  },
  innerContainer: { flex: 1, marginBottom: 12, position: "relative" },
  buttons: { flexDirection: "row", justifyContent: "space-between" },
  buttonText: { fontFamily: "bold", color: theme.colors.white },
});
