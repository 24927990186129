import { Platform } from "react-native";

import { PlatformOSType } from "react-native";

import wpRequest, { fileRequest } from "../../utils/wpRequest";
import {
  getMimeType,
  getFileExtensionFromMimeType,
} from "../../utils/mixUtils";
import { ListCall } from "./jobApi";

export type BaseAccountInfoCallData = {
  email: string;
  first_name: string;
  id: number;
  is_active: boolean;
  last_name: string;
};

export type LoginCallData = BaseAccountInfoCallData & {
  access_token: string;
  date_joined: string;
  model: string;
  refresh_token: string;
  username: string; // email
};

export type JobApplicationEmployeeData = {
  id: number;
  phone_number: string;
  first_name: string;
  last_name: string;
  locations: null;
  liked: null;
  photo: string;
};

export type AccountInfoCallData = {
  id?: number;
  address: string;
  company_name: string;
  company_type: "BR";
  description: string;
  email: string;
  first_name: string;
  is_active: boolean;
  last_name: string;
  location: string;
  logo: string; // url
  nip: string;
  phone: string;
  regon: string;
};

export type CreateAccountData = {
  id?: number;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  company_name: string;
  logo: string;
  location?: string | number;
  phone?: string;
  user?: {
    id?: number;
  };
};

export const signInCall = (data: { username: string; password: string }) =>
  wpRequest.post<LoginCallData>("auth/token/", {
    username: data.username,
    password: data.password,
  });

export const baseAccountInfoCall = () =>
  wpRequest.get<BaseAccountInfoCallData>("employer/");

export const accountInfoCall = () =>
  wpRequest.get<AccountInfoCallData>("employer/profile/");

export const resetPasswordStart = (email: string) =>
  wpRequest.post("employer/password/", {
    email,
  });

export const resetPasswordFinish = (config: {
  password: string;
  token: string;
  uuid: string;
}) =>
  wpRequest.put("employer/password/", {
    new_password: config.password,
    token: config.token,
    uidb64: config.uuid,
  });

const accountHandler = async (accountData: Partial<AccountInfoCallData>) => {
  const accountFormData = new FormData();

  for (let [key, value] of Object.entries(accountData)) {
    if (value) {
      const normalizedValue = value.toString();

      switch (key) {
        case "logo":
          const mimeType = getMimeType(normalizedValue);
          const fileExtension = getFileExtensionFromMimeType(mimeType);

          if (Platform.OS === "web") {
            const file = await fileRequest(normalizedValue);
            accountFormData.append(key, file, `logo.${fileExtension}`);
          } else {
            accountFormData.append(key, {
              // @ts-ignore
              uri: normalizedValue, // React native specific behavior
              name: `logo.${fileExtension}`,
              type: getMimeType(normalizedValue),
            });
          }

          break;

        default:
          accountFormData.append(key, normalizedValue);
          break;
      }
    }
  }

  if (accountData.id) {
    return wpRequest.put<CreateAccountData>(
      "employer/profile/",
      accountFormData
    );
  }

  return wpRequest.post<CreateAccountData>(
    "employers/registration/",
    accountFormData
  );
};

export const signUpCall = accountHandler;
export const updateAccountCall = accountHandler;

export const uploadProfileImage = async (image: string) => {
  const formData = new FormData();

  const mimeType = getMimeType(image);
  const fileExtension = getFileExtensionFromMimeType(mimeType);

  if (Platform.OS === "web") {
    const file = await fileRequest(image);
    formData.append("image", file, `logo.${fileExtension}`);
  } else {
    formData.append("image", {
      // @ts-ignore
      uri: image, // React native specific behavior
      name: `logo.${fileExtension}`,
      type: getMimeType(image),
    });
  }

  return wpRequest.put("employer/logo/", formData);
};

export type SaveTokenReqData = {
  registration_id: string;
  device_id: string;
  device_type: PlatformOSType;
  language: string;
};

export const saveNotificationToken = (tokenData: SaveTokenReqData) =>
  wpRequest.post("devices/", tokenData);

export type EmployerReview = {
  employer: number;
  employee: {
    first_name: string;
    last_name: string;
    photo: null | string;
  };
  content: string;
  date_created: string;
  stars: {
    response_time: number;
    salary_on_time: number;
    accommodation: number;
    board: number;
  };
};

export const getTotalRating = (employerId: number) => {
  return wpRequest
    .get<{
      avg_rating: {
        accommodation: number;
        board: number;
        general: number;
        response_time: number;
        salary_on_time: number;
      };
    }>(`employers/${employerId}/`)
    .then((res) => res.avg_rating);
};

export const getReviews: ListCall<EmployerReview> = (config) => {
  return wpRequest.get(
    `employers/${config.filter.employerId}/reviews/?page=${config.pagination.page_num}`
  );
};
