import React from "react";
import { useGetEstate } from "../../../../services/hooks/estateHooks";
import EstateEditor from "./components/EstateEditor";

export default function EstateEditScreen(props: {
  route: { params: { estateId: number } };
}) {
  const estate = useGetEstate(Number(props.route.params.estateId));

  if (!estate) {
    return null;
  }
  if (!("data" in estate) || estate.data === undefined) {
    return null;
  }

  return <EstateEditor estate={estate.data} />;
}
