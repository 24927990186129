import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import WPIcon, { IconName } from "../../../components/Icon/WPIcon";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import theme, { getThemeResponsiveValue } from "../../../utils/theme";
import WPText from "../../../components/Text/WPText";
import WPLinkButton from "../../../components/Button/WPLinkButton";
import PrependAppendDecorator from "../../../components/misc/PrependAppendDecorator";

type CRMDrawerGroupProps =
  | {
      icon: IconName;
      title: string;
      items: { title: string; link: string; label?: string }[];
      isVisible: boolean;
      onClick?: () => void;
    }
  | {
      image: string;
      title: string;
      items: { title: string; link: string; label?: string }[];
      isVisible: boolean;
      onClick?: () => void;
    };

const titleSize = 30;

export default function CRMDrawerGroup(props: CRMDrawerGroupProps) {
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (!props.isVisible && isOpened) {
      setIsOpened(false);
    }
  }, [props.isVisible, isOpened]);

  const toggleVisibility = useCallback(() => {
    if (props.onClick) {
      return props.onClick();
    }
    setIsOpened(!isOpened);
  }, [isOpened, setIsOpened, props.onClick]);

  const source = useMemo(() => ({ uri: "image" in props ? props.image : "" }), [
    "image" in props ? props.image : undefined,
  ]);

  return (
    <View style={isOpened ? styles.containerOpened : styles.container}>
      <PrependAppendDecorator
        prepend={
          <TouchableOpacity
            style={styles.prependIcon}
            onPress={toggleVisibility}
          >
            <View
              style={
                isOpened ? styles.iconDecoratorOpened : styles.iconDecorator
              }
            >
              {"icon" in props ? (
                <WPIcon
                  name={props.icon}
                  color={theme.colors.white}
                  size={20}
                />
              ) : (
                <Image source={source} style={styles.image} />
              )}
            </View>
          </TouchableOpacity>
        }
      >
        {props.isVisible ? (
          <>
            <TouchableOpacity
              style={styles.titleContainer}
              onPress={toggleVisibility}
            >
              <WPText style={styles.linkText}>{props.title}</WPText>
              {props.items.length ? (
                <TouchableOpacity
                  style={styles.icon}
                  onPress={toggleVisibility}
                >
                  <WPIcon
                    name="chevron"
                    style={
                      !isOpened ? styles.collapseIsCollapsed : styles.collapse
                    }
                    size={20}
                  />
                </TouchableOpacity>
              ) : null}
            </TouchableOpacity>
            {isOpened ? (
              <View style={styles.options}>
                {props.items.map((item) => (
                  <WPLinkButton
                    key={item.title}
                    pageLink={item.link}
                    appearance="link"
                    append={
                      item.label && (
                        <View style={styles.label}>
                          <WPText style={styles.labelText}>{item.label}</WPText>
                        </View>
                      )
                    }
                    style={{ paddingLeft: 0 }}
                  >
                    <WPText style={styles.subLinkText}>{item.title}</WPText>
                  </WPLinkButton>
                ))}
              </View>
            ) : null}
          </>
        ) : null}
      </PrependAppendDecorator>
    </View>
  );
}

const styles = StyleSheet.create({
  image: {
    height: 28,
    width: 28,
    overflow: "hidden",
  },
  container: {
    paddingLeft: theme.button.paddingHorizontal,
    paddingVertical: 12,
  },
  containerOpened: {
    paddingLeft: theme.button.paddingHorizontal,
    backgroundColor: theme.colors.mains[0],
    paddingVertical: 12,
  },
  prependIcon: {
    alignItems: "flex-start",
    marginRight: 8,
    flex: 1,
  },
  iconDecoratorOpened: {
    alignItems: "center",
    justifyContent: "center",
    height: 30,
    width: 30,
    borderRadius: 15,
    overflow: "hidden",
    borderWidth: 1,
    borderColor: theme.colors.mains[4],
  },
  iconDecorator: {
    alignItems: "center",
    justifyContent: "center",
    height: 30,
    width: 30,
    borderRadius: 15,
    overflow: "hidden",
    borderWidth: 1,
    borderColor: theme.colors.mains[4],
    backgroundColor: theme.colors.mains[4],
  },
  icon: {
    marginRight: 8,
  },
  main: {
    flex: 1,
    alignItems: "stretch",
  },
  titleContainer: {
    height: titleSize,
    alignItems: "center",
    flexDirection: "row",
    flex: 1,
    width: "100%",
  },
  linkText: {
    color: theme.colors.mains[4],
    fontFamily: theme.fontFamily.bold,
    fontSize: getThemeResponsiveValue(theme.button.fontSize),
    flex: 1,
    //
  },
  subLinkText: {
    color: theme.colors.mains[3],
    fontFamily: theme.fontFamily.normal,
    fontSize: getThemeResponsiveValue(theme.button.fontSize),
  },
  options: {
    backgroundColor: theme.colors.mains[0],
  },
  label: {
    borderRadius: 10,
    height: 20,
    minWidth: 30,
    backgroundColor: theme.colors.mains[2],
    justifyContent: "center",
    alignItems: "center",
  },
  labelText: {
    color: theme.colors.white,
    fontSize: 12,
    fontFamily: "bold",
  },
  collapse: {
    marginRight: 8,
    transform: [{ rotate: "180deg" }],
  },
  collapseIsCollapsed: {
    marginRight: 8,
    transform: [{ rotate: "0deg" }],
  },
});
