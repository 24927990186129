import React from "react";
import Svg, { Path } from "react-native-svg";
import { WPIconProps } from "../WPIcon";

const BumpIcon: React.FC<WPIconProps> = (props) => {
  let height = 20;
  let width = 20;
  const ratio = width / height;
  const color = props.color || "#637381";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M10 2a8 8 0 110 16 8 8 0 010-16zm0 1.5c-3.584 0-6.5 2.916-6.5 6.5s2.916 6.5 6.5 6.5 6.5-2.916 6.5-6.5-2.916-6.5-6.5-6.5zm.447 2.639l.084.073 2.515 2.516a.749.749 0 01-.977 1.133l-.084-.073-1.236-1.234.001 4.685a.75.75 0 01-1.493.102l-.007-.102V8.552L8.014 9.788a.752.752 0 01-.977.073l-.084-.073a.75.75 0 01-.073-.976l.073-.085L9.47 6.211a.75.75 0 01.977-.072z"
      />
    </Svg>
  );
};

BumpIcon.displayName = "BumpIcon";

export default BumpIcon;
