import React, { memo } from "react";
import { StyleProp, ViewStyle } from "react-native";

import LogoIcon from "./icons/LogoIcon";
import CameraIcon from "./icons/CameraIcon";
import CheckboxIcon from "./icons/CheckboxIcon";
import CheckboxSelectedIcon from "./icons/CheckboxSelectedIcon";
import SendEmailIcon from "./icons/SendEmailIcon";
import MenuIcon from "./icons/MenuIcon";
import CloseIcon from "./icons/CloseIcon";
import BackIcon from "./icons/BackIcon";
import ChatIcon from "./icons/ChatIcon";
import InfoIcon from "./icons/InfoIcon";
import ClipIcon from "./icons/ClipIcon";
import MessageSeenMark from "./icons/MessageSeenMark";
import ArrowIcon from "./icons/ArrowIcon";
import DownloadIcon from "./icons/DownloadIcon";
import PlaceIcon from "./icons/PlaceIcon";
import ChevronIcon from "./icons/ChevronIcon";
import CalendarIcon from "./icons/CalendarIcon";
import MoreIcon from "./icons/MoreIcon";
import TrashIcon from "./icons/TrashIcon";
import PencilIcon from "./icons/PencilIcon";
import LogoutIcon from "./icons/LogoutIcon";
import UsersIcon from "./icons/UsersIcon";
import PlusIcon from "./icons/PlusIcon";
import UserIcon from "./icons/UserIcon";
import SearchIcon from "./icons/SearchIcon";
import ClearInputIcon from "./icons/ClearInputIcon";
import AddIcon from "./icons/AddIcon";
import HomeIcon from "./icons/HomeIcon";
import DuplicateIcon from "./icons/DuplicateIcon";
import FileIcon from "./icons/FileIcon";
import RefreshIcon from "./icons/RefreshIcon";
import DisableIcon from "./icons/DisableIcon";
import RadioButtonIcon from "./icons/RadioButtonIcon";
import RadioButtonSelectedIcon from "./icons/RadioButtonSelectedIcon";
import AppliedStatusIcon from "./icons/AppliedStatusIcon";
import RejectedStatusIcon from "./icons/RejectedStatusIcon";
import ChangeStatusIcon from "./icons/ChangeStatusIcon";
import LoadingIcon from "./icons/LoadingIcon";
import BumpIcon from "./icons/BumoIcon";
import AttentionIcon from "./icons/Attention";
import PasswordIcon from "./icons/Password";
import StatsIcon from "./icons/StatsIcon";
import ListViewIcon from "./icons/ListViewIcon";
import ApplyIcon from "./icons/ApplyIcon";
import PhoneIcon from "./icons/PhoneIcon";
import DetailsIcon from "./icons/DetailsIcon";
import ClockIcon from "./icons/ClockIcon";
import CheckMarkIcon from "./icons/CheckMarkIcon";
import StarIcon from "./icons/StarIcon";
import SuccessIcon from "./icons/SuccessIcon";
export type IconName =
  | "attention"
  | "logo"
  | "camera"
  | "checkbox"
  | "checkbox_selected"
  | "radiobutton"
  | "radiobutton_selected"
  | "send_email"
  | "menu"
  | "close"
  | "back"
  | "chat"
  | "info"
  | "clip"
  | "seen_mark"
  | "arrow"
  | "download"
  | "place"
  | "chevron"
  | "calendar"
  | "more"
  | "trash"
  | "pencil"
  | "logout"
  | "users"
  | "plus"
  | "user"
  | "search"
  | "clear-input"
  | "add"
  | "home"
  | "duplicate"
  | "file"
  | "refresh"
  | "disable"
  | "appliedStatus"
  | "rejectedStatus"
  | "changedStatus"
  | "loading"
  | "bump"
  | "password"
  | "stats"
  | "listview"
  | "apply"
  | "phone"
  | "details"
  | "clock"
  | "checkmark"
  | "star"
  | "success";

export type WPIconProps = {
  name?: IconName;
  color?: string;
  size?: number;
  style?: StyleProp<ViewStyle>;
};

const WPIcon: React.FC<WPIconProps> = (props) => {
  switch (props.name) {
    case "loading":
      return <LoadingIcon {...props} />;
    case "logo":
      return <LogoIcon {...props} />;
    case "camera":
      return <CameraIcon {...props} />;
    case "checkbox":
      return <CheckboxIcon {...props} />;
    case "checkbox_selected":
      return <CheckboxSelectedIcon {...props} />;
    case "send_email":
      return <SendEmailIcon {...props} />;
    case "menu":
      return <MenuIcon {...props} />;
    case "close":
      return <CloseIcon {...props} />;
    case "back":
      return <BackIcon {...props} />;
    case "chat":
      return <ChatIcon {...props} />;
    case "info":
      return <InfoIcon {...props} />;
    case "clip":
      return <ClipIcon {...props} />;
    case "seen_mark":
      return <MessageSeenMark {...props} />;
    case "arrow":
      return <ArrowIcon {...props} />;
    case "download":
      return <DownloadIcon {...props} />;
    case "place":
      return <PlaceIcon {...props} />;
    case "chevron":
      return <ChevronIcon {...props} />;
    case "calendar":
      return <CalendarIcon {...props} />;
    case "more":
      return <MoreIcon {...props} />;
    case "trash":
      return <TrashIcon {...props} />;
    case "pencil":
      return <PencilIcon {...props} />;
    case "logout":
      return <LogoutIcon {...props} />;
    case "users":
      return <UsersIcon {...props} />;
    case "plus":
      return <PlusIcon {...props} />;
    case "user":
      return <UserIcon {...props} />;
    case "search":
      return <SearchIcon {...props} />;
    case "clear-input":
      return <ClearInputIcon {...props} />;
    case "add":
      return <AddIcon {...props} />;
    case "home":
      return <HomeIcon {...props} />;
    case "duplicate":
      return <DuplicateIcon {...props} />;
    case "file":
      return <FileIcon {...props} />;
    case "refresh":
      return <RefreshIcon {...props} />;
    case "disable":
      return <DisableIcon {...props} />;
    case "radiobutton":
      return <RadioButtonIcon {...props} />;
    case "radiobutton_selected":
      return <RadioButtonSelectedIcon {...props} />;
    case "appliedStatus":
      return <AppliedStatusIcon {...props} />;
    case "rejectedStatus":
      return <RejectedStatusIcon {...props} />;
    case "changedStatus":
      return <ChangeStatusIcon {...props} />;
    case "bump":
      return <BumpIcon {...props} />;
    case "attention":
      return <AttentionIcon {...props} />;
    case "password":
      return <PasswordIcon {...props} />;
    case "stats":
      return <StatsIcon {...props} />;
    case "listview":
      return <ListViewIcon {...props} />;
    case "apply":
      return <ApplyIcon {...props} />;
    case "phone":
      return <PhoneIcon {...props} />;
    case "details":
      return <DetailsIcon {...props} />;
    case "clock":
      return <ClockIcon {...props} />;
    case "checkmark":
      return <CheckMarkIcon {...props} />;
    case "star":
      return <StarIcon {...props} />;
    case "success":
      return <SuccessIcon {...props} />;

    default:
      return null;
  }
};

export default memo(WPIcon);
