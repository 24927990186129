import React from "react";
import {
  getUserConversationGroups,
  useOpenChatByChatType,
} from "./conversationHooks";
import WPSwitcher from "../../../../components/misc/WPSwitcher";
import { useSelector } from "react-redux";
import { RootState } from "../../../../services/store/reducers/mainReducer";
import { useTranslation } from "react-i18next";
import { ConversationType } from "../../../../services/chatService";

export default function WPConversationGroupSelect() {
  const openJobChat = useOpenChatByChatType(ConversationType.JOB);
  const openEstateChat = useOpenChatByChatType(ConversationType.ESTATE);

  const chatData = getUserConversationGroups();

  const activeConversationId = useSelector(
    (state: RootState) => state.chat.activeConversationId
  );

  const { t } = useTranslation();

  if (!chatData) {
    return null;
  }

  if (Object.keys(chatData.chats).length <= 1) {
    return null;
  }

  const items = Object.keys(chatData.chats).map((group) => ({
    label:
      group === "job" || group === "undefined"
        ? t("titles.work")
        : t(`titles.${group}`),
    isActive: chatData.chats[group] === activeConversationId,
    onPress: () =>
      group === "job"
        ? openJobChat(chatData.userId)
        : openEstateChat(chatData.userId),
  }));

  return <WPSwitcher items={items} />;
}
