import React, { memo, useState, useEffect, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import theme from "../../utils/theme";
import WPText from "../Text/WPText";
import { useTranslation } from "react-i18next";
import {
  getJobStatisticsCall,
  JobStatistics,
} from "../../services/apis/jobApi";
import WPIcon, { IconName } from "../Icon/WPIcon";

function StatContainer(props: {
  value?: number | string;
  icon: IconName;
  isCompactView?: boolean;
}) {
  const textStyle = useMemo(() => {
    return [styles.stat, props.isCompactView ? { fontSize: 12 } : {}];
  }, [props.isCompactView]);
  return (
    <View style={styles.titleContainer}>
      <WPIcon name={props.icon} size={12} />
      <WPText style={textStyle}>{props.value || "-"}</WPText>
    </View>
  );
}

function JobOffersStatistics(props: {
  offerId?: number | undefined;
  expired?: boolean;
  isCompactView?: boolean;
}) {
  const [statistics, setStatistics] = useState<JobStatistics>({});
  const { t } = useTranslation();

  const getStats = useMemo(() => {
    return () => {
      getJobStatisticsCall(!!props.expired, props.offerId)
        .then(setStatistics)
        .catch(() => {});
    };
  }, [props.expired, props.expired]);

  useEffect(getStats, []);

  const containerStyle = useMemo(() => {
    return props.isCompactView
      ? [
          styles.statContainer,
          styles.statContainerMobile,
          { paddingVertical: 2 },
        ]
      : [styles.statContainer, { height: theme.button.size.regular.m }];
  }, [props.isCompactView]);

  return (
    <View style={containerStyle}>
      {props.isCompactView ? null : (
        <View style={styles.titleContainer}>
          <WPIcon name="stats" />
          <WPText style={styles.title}>{t("labels.statistics")}</WPText>
        </View>
      )}
      <StatContainer
        value={statistics.job_list_view}
        icon="listview"
        isCompactView={props.isCompactView}
      />
      <StatContainer
        value={statistics.job_detail_view}
        icon="details"
        isCompactView={props.isCompactView}
      />
      <StatContainer
        value={statistics.job_apply}
        icon="apply"
        isCompactView={props.isCompactView}
      />
      <StatContainer
        value={statistics.job_phone_press}
        icon="phone"
        isCompactView={props.isCompactView}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  statContainer: {
    alignSelf: "flex-start",
    paddingHorizontal: 12,
    backgroundColor: theme.colors.mains[5],
    borderRadius: theme.button.borderRadius,
    flexDirection: "row",
    alignItems: "center",
  },
  statContainerMobile: {
    paddingHorizontal: 0,
    paddingVertical: 0,
    backgroundColor: "transparent",
    alignSelf: "stretch",
    flex: 1,
    justifyContent: "space-between",
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 16,
    minWidth: 40,
  },
  title: {
    marginLeft: 4,
  },
  stat: {
    color: theme.colors.mains[3],
    marginLeft: 8,
  },
});

export default memo(JobOffersStatistics);
