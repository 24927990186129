import WPFirebase from "./firebase";
import "firebase/firestore";

// import Constants from "expo-constants";
import config from "./config";

const {
  FIREBASE_CHAT_COLLECTION = "conversation_v2_dev",
  FIREBASE_MESSAGE_COLLECTION = "messages_dev",
  NEWS_COLLECTION = "news_crm",
} = config;

const firestore = WPFirebase.firestore();

export const chatRef = firestore.collection(FIREBASE_CHAT_COLLECTION);
export const messageRef = firestore.collection(FIREBASE_MESSAGE_COLLECTION);
export const newsRef = firestore.collection(NEWS_COLLECTION);
export const fireBaseArrayUnion = WPFirebase.firestore.FieldValue.arrayUnion;
