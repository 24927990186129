import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const ClockIcon: React.FC<IconProps> = (props) => {
  let height = 24;
  let width = 24;
  const ratio = width / height;
  const color = props.color || "#637381";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" {...props}>
      <Path
        fill={color}
        d="M14.614 10.338c1.061-.968 2.496-2.276 2.626-5.4h.948V4H6v.938h.947c.13 3.124 1.565 4.432 2.626 5.4.712.648 1.114 1.046 1.114 1.662 0 .617-.402 1.014-1.114 1.662-1.06.968-2.495 2.276-2.626 5.4H6V20h12.188v-.938h-.948c-.13-3.124-1.565-4.432-2.626-5.4-.711-.648-1.114-1.046-1.114-1.662 0-.617.403-1.014 1.114-1.662zm-2.989 5.176c-.145.03-.288.073-.427.13l-2.872 1.189c.47-1.192 1.224-1.881 1.879-2.478.73-.666 1.42-1.295 1.42-2.355v3.514zm2.358-1.159c.654.597 1.41 1.286 1.878 2.478l-2.871-1.188a2.37 2.37 0 00-.428-.131V12c0 1.06.69 1.69 1.42 2.355zM8.31 7.125c-.227-.59-.383-1.304-.424-2.188h8.416c-.041.884-.198 1.597-.425 2.188H8.31z"
      />
    </Svg>
  );
};

ClockIcon.displayName = "ClockIcon";

export default ClockIcon;
