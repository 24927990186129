import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const AddIcon: React.FC<IconProps> = (props) => {
  let height = 24;
  let width = 24;
  const ratio = width / height;
  const color = props.color || "#919EAB";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M12 2.4a9.6 9.6 0 110 19.2 9.6 9.6 0 010-19.2zm0 1.8a7.8 7.8 0 100 15.6 7.8 7.8 0 000-15.6zm0 3.54a.9.9 0 01.9.9v2.459l2.46.001a.9.9 0 110 1.8l-2.46-.001v2.461a.9.9 0 11-1.8 0v-2.461l-2.46.001a.9.9 0 110-1.8l2.46-.001V8.64a.9.9 0 01.9-.9z"
      />
    </Svg>
  );
};

AddIcon.displayName = "AddIcon";

export default AddIcon;
