import React from "react";
import WPText from "../../Text/WPText";
import { StyleSheet, View } from "react-native";
import theme, { getThemeResponsiveValue } from "../../../utils/theme";
import { WPInputState } from "../WPInput";

type WPLabelProps = {
  label: string;
  inputState: WPInputState;
  required?: boolean;
};

const WPLabel: React.FC<WPLabelProps> = (props) => {
  return (
    <View style={styles.container}>
      <WPText style={getStyle(props)} numberOfLines={1}>
        {props.label}
      </WPText>
      {props.required ? <WPText style={styles.required}> *</WPText> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  default: {
    fontFamily: theme.fontFamily.normal,
    fontSize: getThemeResponsiveValue(theme.input.labelBlurFontSize),
  },
  blurred: {
    color: theme.input.blurColor,
  },
  blurredWithValue: {
    fontSize: getThemeResponsiveValue(theme.input.labelFocusFontSize),
  },
  focused: {
    fontFamily: theme.fontFamily.bold,
    color: theme.input.focusColor,
  },
  error: {
    color: theme.input.errorColor,
  },
  required: {
    color: theme.colors.error,
    fontFamily: "bold",
  },
});

const focusedStyle = [styles.default, styles.blurredWithValue, styles.focused];
const blurredStyle = [styles.default, styles.blurred];
const blurredWithValueStyle = [
  styles.default,
  styles.blurred,
  styles.blurredWithValue,
];
const focusedWithErrorStyle = [...focusedStyle, styles.error];
const blurredWithErrorStyle = [...blurredStyle, styles.error];

function getStyle(props: WPLabelProps) {
  switch (props.inputState) {
    case WPInputState.blurred:
      return blurredStyle;
    case WPInputState.focused:
    case WPInputState.focusedWithValue:
      return focusedStyle;
    case WPInputState.blurredWithValue:
      return blurredWithValueStyle;
    case WPInputState.focusedError:
    case WPInputState.focusedWithValueWithError:
      return focusedWithErrorStyle;
    case WPInputState.blurredError:
    case WPInputState.blurredWithValueWithError:
      return blurredWithErrorStyle;

    default:
      return blurredStyle;
  }
}

export default React.memo(WPLabel);
