import { createReducer, action } from "deox";
import { Estate } from "../../../apis/estateApi";
import {
  gotEstates,
  gotEstate,
  getEstate,
  deletedEstate,
} from "../../actions/estateActions";
import { isLoaded } from "expo-font";

type authState = {
  estates: {
    [key: number]: {
      data?: Estate;
      isLoading: boolean;
      isLoaded: boolean;
      isDeleted: boolean;
    };
  };
  total: number;
};

const defaultState: authState = {
  estates: {},
  total: 0,
};

const estateReducer = createReducer(defaultState, (handleAction) => [
  handleAction(gotEstates, (state, action) => {
    return {
      ...state,
      total: action.payload.total,
      estates: action.payload.estates.reduce(
        (estate, newEstate) => ({
          ...estate,
          [newEstate.id]: {
            data: newEstate,
            isLoaded: true,
            isLoading: false,
            isDeleted: false,
          },
        }),
        state.estates
      ),
    };
  }),

  handleAction(gotEstate, (state, action) => ({
    ...state,
    estates: {
      ...state.estates,
      [action.payload.id]: {
        data: action.payload,
        isLoaded: true,
        isLoading: false,
        isDeleted: false,
      },
    },
  })),
  handleAction(getEstate, (state, action) => ({
    ...state,
    estates: {
      ...state.estates,
      [action.payload]: {
        isLoaded: false,
        isLoading: true,
        isDeleted: false,
      },
    },
  })),
  handleAction(deletedEstate, (state, action) => ({
    ...state,
    estates: {
      ...state.estates,
      [action.payload]: {
        isLoaded: false,
        isLoading: false,
        isDeleted: true,
      },
    },
  })),
]);

export default estateReducer;
