import React from "react";
import AuthForm from "../components/AuthForm";
import FormField from "../../../components/form/FormField";
import FormRow from "../../../components/form/FormRow";
import { ScrollView } from "react-native-gesture-handler";
import { StyleSheet } from "react-native";
import { useTranslation } from "react-i18next";
import WPButton from "../../../components/Button/WPButton";
import { Formik } from "formik";
import FormContainer from "../../../components/form/FormContainer";
import { useNewPassRequest } from "./hooks/forgotPassHook";
import AuthNotification from "../components/AuthNotification";
import { navigateTo } from "../../navigationHelpers";
import FormError from "../../../components/form/FormError";
import AuthBackGround from "../components/AuthBackGround";

const initialValues = {
  new_password: "",
};

const NewPassScreen = (props: { uid: string; token: string }) => {
  const { t } = useTranslation();

  const [isLoading, isSent, errors, send] = useNewPassRequest();

  return (
    <AuthBackGround>
      <ScrollView contentContainerStyle={styles.container}>
        <AuthNotification
          isVisible={isSent}
          title={t("titles.reset_password")}
          iconName="send_email"
          description={t("misc.reset_password_success")}
          onClose={() => {
            navigateTo("AUTH.SIGN_IN");
          }}
          buttonLabel={t("labels.got_it")}
        />
        <AuthForm
          title={t("titles.reset_password")}
          subtitle={t("titles.reset_password_finish_sub")}
          secondaryLinkLabel={t("labels.cancel")}
          link="AUTH.SIGN_IN"
        >
          <Formik
            initialValues={initialValues}
            onSubmit={(values) =>
              send(values.new_password, props.token, props.uid)
            }
          >
            {(formikProps) => (
              <FormContainer>
                <FormRow>
                  <FormField
                    name="new_password"
                    label={t("labels.password")}
                    {...formikProps}
                    errors={errors}
                    type="password"
                  >
                    <FormError
                      error={
                        errors["non_field_errors"] ||
                        errors["token"] ||
                        errors["uidb64"] ||
                        errors["message"]
                      }
                    />
                  </FormField>
                </FormRow>

                <WPButton
                  onPress={formikProps.handleSubmit}
                  isLoading={isLoading}
                >
                  {t("labels.save")}
                </WPButton>
              </FormContainer>
            )}
          </Formik>
        </AuthForm>
      </ScrollView>
    </AuthBackGround>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
  forgotPass: {
    justifyContent: "flex-end",
    flexDirection: "row",
  },
});

export default NewPassScreen;
