import React, { memo, useMemo } from "react";
import { View, StyleProp, ViewStyle, StyleSheet } from "react-native";
import theme from "../../utils/theme";
function WPSeparator(props: { style?: StyleProp<ViewStyle>; dense?: boolean }) {
  const style = useMemo(() => {
    let normalizedStyle: StyleProp<ViewStyle> = styles.separator;

    if (props.style) {
      normalizedStyle = StyleSheet.compose(normalizedStyle, props.style);
    }

    if (props.dense) {
      normalizedStyle = StyleSheet.compose(normalizedStyle, styles.dense);
    }

    return normalizedStyle;
  }, [props.style, props.dense]);
  return <View style={style} />;
}

export default memo(WPSeparator);

const styles = StyleSheet.create({
  separator: {
    marginVertical: 20,
    borderBottomColor: theme.colors.mains[5],
    borderBottomWidth: 1,
  },
  dense: {
    marginVertical: 0,
  },
});
