import React, { memo, useMemo } from "react";
import { WPInputProps, WPInputState } from "../WPInput";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
  StyleProp,
} from "react-native";
import WPLabel from "./WPLabel";
import theme from "../../../utils/theme";

function WPToggle(props: WPInputProps<boolean>) {
  const toggleContainerStyle = useMemo(() => {
    return [styles.toggleContainer];
  }, [props.value]);
  const indicatorStyle: StyleProp<ViewStyle> = useMemo(() => {
    return [
      styles.indicator,
      props.value
        ? {
            alignSelf: "flex-end",
          }
        : {
            alignSelf: "flex-start",
          },
    ];
  }, [props.value]);

  return (
    <View>
      {props.label ? (
        <View style={styles.label}>
          <WPLabel
            label={props.label}
            inputState={WPInputState.blurredWithValue}
          />
        </View>
      ) : null}
      <TouchableOpacity
        style={toggleContainerStyle}
        onPress={() => props.onChange && props.onChange(!props.value)}
      >
        <View style={indicatorStyle} />
      </TouchableOpacity>
    </View>
  );
}

export default memo(WPToggle);

const padding = 8;
const size = theme.input.size.small.s;

const styles = StyleSheet.create({
  label: {
    marginBottom: 4,
  },
  toggleContainer: {
    height: size,
    width: size * 2,
    paddingHorizontal: padding / 2,
    backgroundColor: theme.colors.primary,
    // backgroundColor: theme.colors.transparent,
    borderRadius: size / 2,
    justifyContent: "center",
  },
  indicator: {
    height: size - padding,
    width: size - padding,
    borderRadius: (size - padding) / 2,
    backgroundColor: theme.colors.white,
  },
});
