import React, { useState, useCallback, memo } from "react";
import { TouchableOpacity } from "react-native";
import WPIcon from "../../../Icon/WPIcon";
import WPTextInput, { WPTextInputProps } from "../WPTextInput";
import { hitSlop } from "../../../../utils/constants";

export type WPPasswordInputProps<T> = WPTextInputProps;

function WPPasswordInput<T>(props: WPPasswordInputProps<T>) {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisible = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);

  return (
    <WPTextInput
      {...props}
      autocompleteType={isVisible ? "off" : "password"}
      append={
        <TouchableOpacity onPress={toggleVisible} hitSlop={hitSlop}>
          <WPIcon name="password" />
        </TouchableOpacity>
      }
    />
  );
}

export default memo(WPPasswordInput);
