import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const DuplicateIcon: React.FC<IconProps> = (props) => {
  let height = 20;
  let width = 20;
  const ratio = width / height;
  const color = props.color || "#637381";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M11.25 3a2.335 2.335 0 012.336 2.335l-.001.979h1.089c1.233 0 2.239.965 2.32 2.184l.006.16v5.998A2.335 2.335 0 0114.674 17H8.742a2.335 2.335 0 01-2.327-2.344v-.971H5.336A2.336 2.336 0 013 11.35V5.335A2.336 2.336 0 015.336 3h5.915zm2.336 8.35a2.335 2.335 0 01-2.335 2.336l-3.281-.001v.971c0 .4.293.73.668.781l.104.007h5.932a.78.78 0 00.77-.788V8.658a.78.78 0 00-.77-.788l-1.089-.001v3.481zM11.25 4.556H5.336a.78.78 0 00-.78.78v6.014c0 .43.35.78.78.78h1.826l.03-.001h.031l4.028.001a.78.78 0 00.78-.78V5.335a.78.78 0 00-.78-.78z"
      />
    </Svg>
  );
};

DuplicateIcon.displayName = "DuplicateIcon";

export default DuplicateIcon;
