import React, { memo } from "react";
import { View, StyleSheet } from "react-native";
import WPText from "../Text/WPText";
import LoadingIcon from "../Icon/icons/LoadingIcon";
import theme from "../../utils/theme";
import { useTranslation } from "react-i18next";

function NoDataState(props: { isLoading: boolean }) {
  const { t } = useTranslation();
  return (
    <View>
      {props.isLoading ? (
        <LoadingIcon color={theme.colors.primary} />
      ) : (
        <WPText style={styles.text}>{t("labels.no_data")}</WPText>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  text: {
    marginHorizontal: theme.button.paddingHorizontal,
  },
});

export default memo(NoDataState);
