// TODO: make an abstraction
import React from "react";
import { ScrollView, StyleSheet } from "react-native";
import WPText from "../../../../components/Text/WPText";
import { useTranslation } from "react-i18next";
import theme, {
  getThemeResponsiveValue,
  isMobileView,
} from "../../../../utils/theme";
import { useGetNewsConfig } from "../../../../services/hooks/newsHook";

export default function NewsScreen() {
  const { i18n } = useTranslation();
  const newsConfig = useGetNewsConfig();
  // const windowWidth = Dimensions.get("screen").width;

  // const image = images[i18n.language][isMobileView() ? "mobile" : "normal"];
  // const [imageHeight, setImageHeight] = useState(0);

  // useEffect(() => {
  //   if (Platform.OS === "web") {
  //     Image.getSize(
  //       image,
  //       (width, height) => {
  //         setImageHeight((windowWidth / width) * height);
  //       },
  //       () => {
  //         setImageHeight(Dimensions.get("screen").height);
  //       }
  //     );
  //   } else {
  //     const size = Image.resolveAssetSource(image);

  //     setImageHeight((windowWidth / size.width) * size.height);
  //   }
  // }, []);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <WPText style={styles.text}>
        {newsConfig ? newsConfig[i18n.language].text : null}
      </WPText>
      {/* {isMobileView() ? (
        <Image
          style={{ width: windowWidth, height: imageHeight }}
          source={image}
        />
      ) : (
        <Image
          style={{ height: 400, width: windowWidth }}
          source={image}
          resizeMode="contain"
        />
      )} */}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    flex: 1,
    alignItems: "center",
  },
  text: {
    lineHeight: getThemeResponsiveValue(theme.input.fontSize) * 1.5,
    fontSize: isMobileView() ? 18 : 16,
    marginBottom: 16,
  },
});
