import React from "react";
import { View, StyleSheet } from "react-native";
import theme from "../../utils/theme";

export default function Separator() {
  return <View style={styles.separator} />;
}

const styles = StyleSheet.create({
  separator: {
    borderTopWidth: 1,
    borderTopColor: theme.colors.mains[5],
  },
});
