import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
  View,
  StyleSheet,
  ScrollView,
  TextInput,
  TouchableOpacity,
  ImageBackground,
} from "react-native";
import WPText from "../../../../components/Text/WPText";
import { Formik } from "formik";
import FormContainer from "../../../../components/form/FormContainer";
import FormRow from "../../../../components/form/FormRow";
import FormField from "../../../../components/form/FormField";
import { useTranslation } from "react-i18next";
import WPButton from "../../../../components/Button/WPButton";
import Separator from "../../../../components/misc/Separator";
import WPWebLink from "../../../../components/misc/WPWebLink";
import theme, { isMobileView } from "../../../../utils/theme";
import { useGetPacksByType } from "../../../../services/hooks/packsHooks";
import {
  usePayment,
  TransactionStatus,
} from "../../../../services/hooks/paymentHooks";
import WPError from "../../../../components/input/components/WPError";
import AuthNotification from "../../../auth/components/AuthNotification";
import { navigateTo, goBack } from "../../../navigationHelpers";
import analytics from "../../../../services/analytics/analytics";
import {
  getPromocodeDesc,
  Promo,
} from "../../../../services/apis/adPackConfigApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../../services/store/reducers/mainReducer";

type PaymentsScreenProps = {
  route: {
    params: {
      paymentType: PaymentType;
      offerId: number;
      packId: number;
      packValue: number;
      callBackRoute: string;
      promoCode?: string;
      discount?: number;
    };
  };
};

export enum PaymentType {
  job,
}

export enum BillType {
  firm = "firm",
  person = "person",
}

export const TERMS_URL = "https://workport.pl/mobile/terms-general";

const initialValues = {
  paymentForm: BillType.firm,
  employer_company_name: undefined,
  nip: undefined,
  country: undefined,
  city: undefined,
  index: undefined,
  street: undefined,
  first_name: undefined,
  last_name: undefined,
  terms: false,
};

export default function PaymentsScreen(props: PaymentsScreenProps) {
  const promoCode = props.route?.params?.promoCode;
  const accountInfo = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [localPromoCode, setLocalPromoCode] = useState(
    promoCode !== "undefined" ? promoCode : ""
  );
  const [promo, setPromo] = useState<Promo | undefined>(undefined);
  const [localPromo, setLocalPromo] = useState<Promo | string | undefined>(
    undefined
  );
  const packages = useGetPacksByType();

  const paymentData = props.route?.params || {};

  const targetPack = packages.find(
    (pack) => pack.id === Number(paymentData?.packId)
  );

  useEffect(() => {
    if (promoCode && targetPack && promoCode !== "undefined" && paymentData) {
      getPromocodeDesc(promoCode, paymentData?.packId, paymentData?.packValue)
        .then((res) => {
          if (res.applicableTo.includes(Number(targetPack.id))) {
            setPromo(res);
          } else {
            setLocalPromo(t("enterprise.missing_promo"));
          }
        })
        .catch(() => setLocalPromo(t("enterprise.missing_promo")));
    } else {
      setPromo(undefined);
      setLocalPromo(undefined);
      setLocalPromoCode("");
    }
  }, [promoCode, targetPack, paymentData]);

  const rawPrice =
    targetPack &&
    targetPack.payConfig.find(
      (payData) => payData.amount === Number(paymentData.packValue)
    )?.price;

  const normalizedPromo = typeof localPromo === "object" ? localPromo : promo;
  const discount =
    Math.abs(normalizedPromo?.priceOffset || 0) +
    (rawPrice || 0) * (1 - (normalizedPromo?.priceGain || 1));
  const packPrice = rawPrice && rawPrice - discount;
  const vat = packPrice && (packPrice * 0.23).toFixed(2);

  const [isLoading, getPaymentLinkData] = usePayment();
  const [transactionStatus, setTransactionStatus] = useState<
    TransactionStatus | undefined
  >(undefined);

  const intialFormState = useMemo(
    () => ({
      ...initialValues,
      employer_company_name:
        accountInfo.company_name || initialValues.employer_company_name,
      first_name: accountInfo.first_name,
      last_name: accountInfo.last_name,
      nip: accountInfo.nip,
    }),
    [accountInfo]
  );

  const navigateBack = useCallback(() => {
    navigateTo("CRM.JOB.ACTIVE_JOB_LIST");
  }, []);

  useEffect(() => {
    if (
      targetPack?.name === "up_1" &&
      transactionStatus === TransactionStatus.Successful
    ) {
      analytics.logBump();
    }
  }, [targetPack, transactionStatus]);

  return (
    <>
      <AuthNotification
        isVisible={!!transactionStatus}
        title={
          transactionStatus === TransactionStatus.Successful
            ? targetPack?.name === "up_1"
              ? t("titles.payment_success_up_action")
              : t("titles.payment_success")
            : t("titles.payment_error")
        }
        iconName={
          transactionStatus === TransactionStatus.Successful
            ? "success"
            : "attention"
        }
        description={
          transactionStatus === TransactionStatus.Successful
            ? targetPack?.name === "up_1"
              ? t("misc.payment_success_up_action")
              : t("misc.payment_success")
            : t("misc.payment_error")
        }
        onClose={() => {
          navigateBack();
        }}
        buttonLabel={t("labels.got_it")}
      />
      <ScrollView>
        <Formik
          initialValues={intialFormState}
          onSubmit={(invoice) => {
            const emptyKeyErrors = Object.keys(invoice).reduce((acc, key) => {
              if (!invoice[key]) {
                acc[key] = t("misc.error_cant_be_empty");
              } else {
                if (/[А-я]/.test(invoice[key])) {
                  acc[key] = t("misc.only_latin");
                }
              }
              return acc;
            }, {} as { [key: string]: string });

            if (invoice.paymentForm === BillType.firm) {
              delete emptyKeyErrors["last_name"];
              delete emptyKeyErrors["first_name"];
            } else {
              delete emptyKeyErrors["employer_company_name"];
              delete emptyKeyErrors["nip"];
            }

            if (Object.keys(emptyKeyErrors).length) {
              return setErrors(emptyKeyErrors);
            } else {
              setErrors({});
            }

            console.log(normalizedPromo);

            targetPack &&
              getPaymentLinkData({
                packId: targetPack.id,
                packOption:
                  targetPack.payConfig.find(
                    (payData) =>
                      payData.amount === Number(paymentData.packValue)
                  )?.amount || 1,
                invoice: {
                  ...invoice,
                  company_name: invoice.employer_company_name || "",
                },
                link: { offers: [Number(props.route.params.offerId)] },
                promo_code: normalizedPromo?.code,
              })
                .catch((errs) => {
                  console.log(errs);
                  // setErrors(errs);
                })
                .then((res) => {
                  if (res) {
                    setTransactionStatus(res);
                  }
                });
          }}
          validate={() => {}}
        >
          {(formikProps) => (
            <FormContainer style={styles.container}>
              <FormRow>
                <WPText style={styles.title}>{t("titles.payment")}</WPText>
              </FormRow>
              <FormRow>
                <FormField
                  name="paymentForm"
                  type="select"
                  {...formikProps}
                  label=""
                  errors={errors}
                  disableTooltip
                  required
                  dense
                />
              </FormRow>
              <FormRow>
                <FormField
                  name={
                    formikProps.values.paymentForm === BillType.firm
                      ? "employer_company_name"
                      : "first_name"
                  }
                  {...formikProps}
                  errors={errors}
                  disableTooltip={
                    formikProps.values.paymentForm === BillType.firm
                      ? !!errors["employer_company_name"]
                      : !!errors["first_name"]
                  }
                  tooltip={t("misc.only_latin")}
                  required
                  key={
                    formikProps.values.paymentForm === BillType.firm
                      ? "employer_company_name"
                      : "first_name"
                  }
                />
                <FormField
                  name={
                    formikProps.values.paymentForm === BillType.firm
                      ? "nip"
                      : "last_name"
                  }
                  {...formikProps}
                  errors={errors}
                  disableTooltip={
                    formikProps.values.paymentForm === BillType.firm ||
                    !!errors["last_name"]
                  }
                  tooltip={
                    formikProps.values.paymentForm === BillType.firm
                      ? undefined
                      : t("misc.only_latin")
                  }
                  required
                  key={
                    formikProps.values.paymentForm === BillType.firm
                      ? "nip"
                      : "last_name"
                  }
                />
              </FormRow>
              <FormRow>
                <FormField
                  name="country"
                  {...formikProps}
                  errors={errors}
                  disableTooltip={!!errors["country"]}
                  tooltip={t("misc.only_latin")}
                  required
                />
                <FormField
                  name="index"
                  {...formikProps}
                  errors={errors}
                  disableTooltip
                  required
                />
              </FormRow>
              <FormRow>
                <FormField
                  name="city"
                  {...formikProps}
                  errors={errors}
                  disableTooltip={!!errors["city"]}
                  tooltip={t("misc.only_latin")}
                  required
                />
                <FormField
                  name="street"
                  {...formikProps}
                  errors={errors}
                  disableTooltip={!!errors["street"]}
                  tooltip={t("misc.only_latin")}
                  required
                />
              </FormRow>
              <WPText style={styles.title}>{t("titles.payment")}</WPText>
              <FormRow>
                <View style={styles.paymentDescriptionContainer}>
                  {isMobileView() ? (
                    <WPText
                      style={[
                        styles.paymentAdditionalTitleText,
                        { paddingBottom: 12 },
                      ]}
                    >
                      {t("labels.product_name")}
                    </WPText>
                  ) : null}
                  <View
                    style={[styles.paymentRow, styles.paymentRowWithBackground]}
                  >
                    <View style={styles.paymentMainPart}>
                      <WPText style={styles.paymentTitle}>
                        {`${t("labels.bundle")} ${(
                          targetPack?.name || "-"
                        ).toUpperCase()} - ${Number(paymentData.packValue)} ${t(
                          "labels.offer_label",
                          {
                            count: Number(paymentData.packValue),
                          }
                        ).toLowerCase()} ${
                          targetPack && targetPack.actionsConfig.multiplier
                            ? `+ ${
                                Number(paymentData.packValue) *
                                  targetPack.actionsConfig.multiplier -
                                Number(paymentData.packValue)
                              } ${t("misc.as_present")}`
                            : ""
                        } `}
                      </WPText>
                    </View>
                    {isMobileView() ? null : (
                      <View style={styles.paymentAdditionalPart}>
                        <View style={styles.paymentAdditionalTitle}>
                          {/* <WPText>title:</WPText> */}
                        </View>
                        <View style={styles.paymentAdditionalValue}>
                          <WPText
                            style={styles.paymentBasePrice}
                          >{`${rawPrice?.toFixed(2)} zł`}</WPText>
                        </View>
                      </View>
                    )}
                  </View>
                  {isMobileView() ? (
                    <WPText
                      style={[
                        styles.paymentAdditionalTitleText,
                        { paddingBottom: 12, paddingTop: 24 },
                      ]}
                    >
                      {t("labels.price")}
                    </WPText>
                  ) : null}
                  {isMobileView() ? (
                    <View
                      style={[
                        styles.paymentAdditionalPart,
                        styles.paymentRowWithBackground,
                      ]}
                    >
                      <View style={styles.paymentAdditionalTitle}>
                        <WPText style={styles.paymentAdditionalTitleText}>
                          {t("labels.price")}
                        </WPText>
                      </View>
                      <View style={styles.paymentAdditionalValue}>
                        <WPText
                          style={[
                            styles.paymentAdditionalValueText,
                            { fontFamily: "black" },
                          ]}
                        >
                          {`${rawPrice?.toFixed(2)} zł`}
                        </WPText>
                      </View>
                    </View>
                  ) : null}
                  <View style={styles.paymentRow}>
                    <View
                      style={{
                        marginTop: 12,
                        width: isMobileView() ? "100%" : undefined,
                      }}
                    >
                      <WPText>{t("enterprise.have_promo")}</WPText>
                      <View
                        style={{
                          flexDirection: "row",
                          height: 36,
                          marginVertical: 8,
                          width: isMobileView() ? "100%" : undefined,
                        }}
                      >
                        <TextInput
                          style={{
                            borderTopLeftRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderColor: "#c4cdd5",
                            borderWidth: 1,
                            paddingHorizontal: 16,
                            flex: 1,
                          }}
                          // placeholder="21NYWP"
                          value={localPromoCode || ""}
                          onChangeText={setLocalPromoCode}
                        />
                        <TouchableOpacity
                          style={{
                            backgroundColor: theme.colors.primary,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            alignItems: "center",
                            justifyContent: "center",
                            paddingHorizontal: 16,
                          }}
                          onPress={() => {
                            getPromocodeDesc(
                              localPromoCode || "",
                              paymentData.packId,
                              paymentData.packValue
                            )
                              .then((res) => {
                                if (
                                  targetPack &&
                                  res.applicableTo.includes(targetPack.id)
                                ) {
                                  setLocalPromo(res);
                                } else {
                                  setLocalPromo(t("enterprise.missing_promo"));
                                }
                              })
                              .catch(() =>
                                setLocalPromo(t("enterprise.missing_promo"))
                              );
                          }}
                        >
                          <WPText style={{ color: "#fff" }}>
                            {t("enterprise.activate")}
                          </WPText>
                        </TouchableOpacity>
                      </View>
                      {typeof localPromo === "string" ? (
                        <WPText style={{ color: theme.colors.warning }}>
                          {localPromo}
                        </WPText>
                      ) : null}
                    </View>
                  </View>
                  {normalizedPromo ? (
                    <View style={styles.paymentRow}>
                      {isMobileView() ? null : (
                        <View style={styles.paymentMainPart} />
                      )}
                      <View
                        style={
                          isMobileView()
                            ? {
                                flex: 1,
                                paddingHorizontal: 16,
                                marginHorizontal: -16,
                              }
                            : { flex: 1, paddingHorizontal: 16 }
                        }
                      >
                        <View
                          style={[
                            styles.paymentAdditionalPart,
                            { paddingTop: 32, paddingBottom: 0 },
                          ]}
                        >
                          <View style={styles.paymentAdditionalTitle}>
                            <WPText
                              style={[
                                styles.paymentAdditionalTitleText,
                                {
                                  // color: "#fff",
                                  fontFamily: "bold",
                                  fontSize: 20,
                                },
                              ]}
                            >
                              {t("misc.discount")}
                            </WPText>
                          </View>
                          <View style={styles.paymentAdditionalValue}>
                            <WPText
                              style={[
                                styles.paymentAdditionalValueText,
                                {
                                  // color: "#fff",
                                  fontFamily: "bold",
                                  fontSize: 20,
                                },
                              ]}
                            >
                              {`${discount?.toFixed(2)} zł`}
                            </WPText>
                          </View>
                        </View>
                        <View style={[styles.paymentAdditionalPart]}>
                          <View style={styles.paymentAdditionalTitle}>
                            <WPText style={styles.paymentAdditionalTitleText}>
                              {t("misc.price_with_discount")}
                            </WPText>
                          </View>
                          <View style={styles.paymentAdditionalValue}>
                            <WPText style={styles.paymentAdditionalValueText}>
                              {`${packPrice?.toFixed(2)} zł`}
                            </WPText>
                          </View>
                        </View>
                      </View>
                    </View>
                  ) : null}
                  <View style={styles.paymentRow}>
                    {isMobileView() ? null : (
                      <View style={styles.paymentMainPart} />
                    )}
                    <View style={styles.paymentAdditionalPart}>
                      <View style={styles.paymentAdditionalTitle}>
                        <WPText style={styles.paymentAdditionalTitleText}>
                          VAT 23%
                        </WPText>
                      </View>
                      <View style={styles.paymentAdditionalValue}>
                        <WPText style={styles.paymentAdditionalValueText}>
                          {`${vat || "-"} zł`}
                        </WPText>
                      </View>
                    </View>
                  </View>
                  <View style={styles.paymentRow}>
                    {isMobileView() ? null : (
                      <View style={styles.paymentMainPart} />
                    )}
                    <View
                      style={[
                        styles.paymentAdditionalPart,
                        styles.paymentRowWithBackground,
                      ]}
                    >
                      <View style={styles.paymentAdditionalTitle}>
                        <WPText style={styles.paymentAdditionalTitleText}>
                          {t("labels.total")}
                        </WPText>
                      </View>
                      <View style={styles.paymentAdditionalValue}>
                        <WPText
                          style={[
                            styles.paymentAdditionalValueText,
                            { fontFamily: "black" },
                          ]}
                        >
                          {`${
                            (packPrice &&
                              vat &&
                              (Number(packPrice) + Number(vat)).toFixed(2)) ||
                            "-"
                          } zł`}
                        </WPText>
                      </View>
                    </View>
                  </View>
                </View>
              </FormRow>
              {errors["non_field_error"] ? (
                <FormRow>
                  <Separator />
                  <WPError error={errors["non_field_error"]} />
                </FormRow>
              ) : null}
              <Separator />
              <FormRow>
                <FormField
                  {...formikProps}
                  name="terms"
                  type="checkbox"
                  style={styles.checkboxContainer}
                  errors={errors}
                >
                  <WPWebLink
                    link={TERMS_URL}
                    text={" " + t("labels.terms_link")}
                  />
                </FormField>
              </FormRow>
              <Separator />
              <FormRow style={styles.actionButtons}>
                <WPButton
                  onPress={() => goBack("CRM.JOB.ACTIVE_JOB_LIST")}
                  appearance="outlined"
                >
                  {t("labels.back")}
                </WPButton>
                <WPButton
                  onPress={formikProps.handleSubmit}
                  isLoading={isLoading}
                  disabled={!formikProps.values.terms}
                >
                  {t("labels.confirm_pay")}
                </WPButton>
              </FormRow>
            </FormContainer>
          )}
        </Formik>
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 12,
  },
  actionButtons: {
    // flexDirection: 'row',
    justifyContent: "flex-end",
  },
  title: {
    fontFamily: "bold",
    fontSize: 28,
  },
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  paymentDescriptionContainer: {
    flex: 1,
  },
  paymentRow: {
    flexDirection: "row",
    // flex: 1,
  },
  paymentTitle: {
    fontSize: 16,
  },
  paymentBasePrice: {
    fontSize: 20,
    fontFamily: "bold",
  },
  paymentMainPart: {
    flex: 3,
    padding: 16,
  },
  paymentAdditionalPart: {
    flex: 1,
    padding: 16,
    flexDirection: "row",
    alignItems: "center",
  },
  paymentAdditionalTitle: {
    flex: 1,
    alignItems: isMobileView() ? "flex-start" : "flex-end",
    paddingRight: 16,
  },
  paymentAdditionalTitleText: {
    fontSize: 16,
    color: theme.colors.mains[3],
  },
  paymentAdditionalValue: {
    flex: 1,
    alignItems: isMobileView() ? "flex-end" : "flex-start",
  },
  paymentAdditionalValueText: {
    fontSize: 16,
  },
  paymentAdditionalValueTextBold: {
    fontSize: 16,
    fontFamily: "bold",
  },
  paymentRowWithBackground: {
    backgroundColor: theme.colors.mains[6],
    borderRadius: theme.button.borderRadius,
  },
});
