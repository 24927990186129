import React from "react";
import Svg, { Path, G, Defs, ClipPath } from "../SVG";
import { IconProps } from "../WPIcon";

const PhoneIcon: React.FC<IconProps> = (props) => {
  let height = 12;
  let width = 12;
  const ratio = width / height;
  const color = props.color || "#0076FF";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg
      {...props}
      width={width}
      height={height}
      style={[{ height, width }, props.style]}
      viewBox={`0 0 12 12`}
    >
      <G fill="#919EAB" clipPath="url(#prefix__clip0)">
        <Path d="M8.624 12c-.387 0-.771-.069-1.147-.205a12.244 12.244 0 01-4.441-2.83A12.258 12.258 0 01.205 4.522a3.331 3.331 0 01.409-3.08A3.393 3.393 0 011.784.399a3.337 3.337 0 011.6-.4c.178 0 .33.124.367.296l.589 2.747a.376.376 0 01-.101.344L3.233 4.393a9.874 9.874 0 004.374 4.374l1.006-1.006c.09-.09.22-.128.344-.101l2.746.589c.173.037.297.19.297.366 0 .569-.134 1.107-.4 1.6A3.397 3.397 0 018.624 12zM3.085.766a2.597 2.597 0 00-1.857 1.107A2.581 2.581 0 00.91 4.267a11.372 11.372 0 006.823 6.823c.812.295 1.685.18 2.394-.318a2.6 2.6 0 001.107-1.857l-2.235-.479-1.05 1.05a.376.376 0 01-.425.075 10.622 10.622 0 01-5.085-5.085.376.376 0 01.075-.425l1.05-1.05L3.085.766zm8.54 5.609A.375.375 0 0111.25 6 5.256 5.256 0 006 .75.375.375 0 016 0c1.603 0 3.11.624 4.242 1.757A5.954 5.954 0 0112 6a.375.375 0 01-.375.375z" />
        <Path d="M10.125 6.375A.375.375 0 019.75 6 3.754 3.754 0 006 2.25a.375.375 0 010-.75c2.481 0 4.5 2.019 4.5 4.5a.375.375 0 01-.375.375z" />
        <Path d="M8.625 6.374a.375.375 0 01-.375-.375c0-1.24-1.01-2.25-2.25-2.25A.374.374 0 116 3c1.654 0 3 1.345 3 3a.375.375 0 01-.375.374z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h12v12H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

PhoneIcon.displayName = "PhoneIcon";

export default PhoneIcon;
