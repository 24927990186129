import React from "react";
import Svg, { G, Circle, Path } from "react-native-svg";
import theme from "../../../utils/theme";
import { WPIconProps } from "../WPIcon";

const AppliedStatusIcon: React.FC<WPIconProps> = (props) => {
  let height = 19;
  let width = 19;
  const ratio = width / height;

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg viewBox="0 0 19 19" width={width} height={height}>
      <G fill="none" fillRule="evenodd">
        <Circle cx={9.5} cy={9.5} r={9.5} fill={theme.colors.success} />
        <Path
          fill="#FFF"
          d="M13 14h2V8h-2v6zm-2.001 0c.549 0 1-.45 1-1.001v-5c0-.249-.1-.498-.3-.698L8.4 4l-.55.5c-.1.15-.2.35-.2.55v.151l.501 2.3h-3.15C4.45 7.501 4 7.95 4 8.5v1.002a.71.71 0 00.1.348l1.5 3.55c.15.352.5.601.9.601h4.499z"
        />
      </G>
    </Svg>
  );
};

export default AppliedStatusIcon;
