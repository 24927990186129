import { Platform } from "react-native";
import { JobOffer } from "../services/apis/jobApi";
import { TFunction } from "i18next";
import { useRef, useEffect } from "react";
import { Estate } from "../services/apis/estateApi";

export const getFileExtension = (fileName: string) => {
  const extension = fileName.match(/(\.[A-z]{2,3}$)/);

  if (extension) {
    return extension[0];
  }

  return "";
};

export const getFileName = (fileUrl: string) => {
  const search = fileUrl.match(/\/([^?/]+)(\?|$)/);

  if (search !== null) {
    return search[1];
  }

  return "?";
};

export const wpDebounce = <T extends Function>(cb: T, wait = 20) => {
  let h: ReturnType<typeof setTimeout> | undefined = undefined;
  let callable = (...args: any) => {
    if (h) {
      clearTimeout(h);
    }
    h = setTimeout(() => cb(...args), wait);
  };
  return <T>(<any>callable);
};

export const getMimeType = (dataURI: string): string => {
  if (Platform.OS === "web") {
    return dataURI.split(",")[0].split(":")[1].split(";")[0];
  }

  const res = dataURI.match(/[^.]+$/);

  if (res === null) {
    return "";
  }

  return "image/" + res[0];
};

export const getFileExtensionFromMimeType = (mimeType: string): string =>
  mimeType.replace(/^[^/]+\//, "");

export const getNormalizedDate = (
  date: string | number,
  language = "en-US",
  skipYear?: boolean,
  addTime?: boolean
) => {
  const dateObj = new Date(date);

  if (date) {
    return dateObj.toLocaleDateString(language, {
      day: "numeric",
      month: "short",
      year: skipYear ? undefined : "numeric",
      hour: addTime ? "numeric" : undefined,
      minute: addTime ? "numeric" : undefined,
    });
  }

  return "";
};

export const getMessageDate = (seconds: string | number, language = "en") => {
  const getMinutesWithLeadingZero = (minutes: number | string): string =>
    `0${minutes}`.slice(-2);

  const getHoursWithTimeZoneOffset = (hours: number) => {
    const offsetInHours = dateObj.getTimezoneOffset() / 60;

    return offsetInHours < 0
      ? hours + Math.abs(offsetInHours)
      : hours + offsetInHours;
  };

  const todayObj = new Date();
  const dateObj = new Date(1970, 0, 1, 0);

  dateObj.setSeconds(Number(seconds));

  const year = dateObj.getFullYear();
  const month = dateObj.getMonth();
  const day = dateObj.getDate();

  if (
    year !== todayObj.getFullYear() ||
    month !== todayObj.getMonth() ||
    todayObj.getDate() - day >= 1 ||
    getHoursWithTimeZoneOffset(dateObj.getHours()) === 24
  ) {
    return getNormalizedDate(dateObj.toString(), language, true);
  }

  return `${getHoursWithTimeZoneOffset(
    dateObj.getHours()
  )}:${getMinutesWithLeadingZero(dateObj.getMinutes())}`;
};

export const getPaymentInfo = (jobOffer: Partial<JobOffer>, t: TFunction) => {
  return `${jobOffer.min_rate} ${
    jobOffer.max_rate ? "- " + jobOffer.max_rate : ""
  } ${jobOffer.income_currency?.toLowerCase() || "pln"}/${
    jobOffer.payment_form
      ? t(`select.payment_form.${jobOffer.payment_form}`).toLowerCase()
      : ""
  } ${t(`select.income_type.${jobOffer.income_type}`).toLowerCase()}`;
};

export const getCurrencyFromJobOffer = (jobOffer: Partial<JobOffer>) => {
  if (jobOffer.income_currency) {
    return jobOffer.income_currency.toLowerCase();
  }

  const cityDisplayName = jobOffer.city?.display_name;

  if (cityDisplayName) {
    return cityDisplayName.indexOf("Poland") !== -1 ? "zł" : "€";
  }

  return "";
};

export const getFilterAsString = (filter: { [key: string]: any }) =>
  Object.keys(filter).reduce((acc, filterKey) => {
    if (typeof filter[filterKey] === "undefined") {
      return acc;
    }

    return (acc += `${filterKey}=${filter[filterKey]}&`);
  }, "");

export const getUniqEntities = <T extends { id: number }>(entities: T[]) => {
  const normalizedEntities = entities.reverse();
  const result = [];
  const map = new Map();

  for (const item of normalizedEntities) {
    if (!map.has(item.id)) {
      map.set(item.id, true); // set any value to Map
      result.push(item);
    }
  }

  return result.reverse();
};

export const usePrevious = <T extends {} | undefined>(
  value: T
): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const getEstatePaymentInfo = (estate: Estate, t: TFunction) => {
  return `${estate.price} pln/${t(
    `select.billing_period.${estate.billing_period}`
  ).toLowerCase()}`;
};

export const splitArrayIntoChunks = <T>(array: T[], n: number) => {
  let [...arr] = array;
  var res = [];
  while (arr.length) {
    res.push(arr.splice(0, n));
  }
  return res;
};
