import React, { useState } from "react";
import {
  StyleSheet,
  ScrollView,
  StyleProp,
  ViewStyle,
  View,
} from "react-native";
import theme from "../../utils/theme";
import WPButton from "../Button/WPButton";
import Separator from "../misc/Separator";

export type ListItem<T> = {
  label: string;
  value: T;
  tooltip?: string;
};

export type WPListProps<T> = {
  style?: StyleProp<ViewStyle>;
  selectable?: boolean;
  items: ListItem<T>[];
  header?: React.ReactNode;
  customRenderItem?: (item: T) => React.ReactNode;
  onChange?: (item: T) => void;
};

function WPList<T>(props: WPListProps<T>) {
  const [hoveredIdx, setHoveredIdx] = useState<number | undefined>(undefined);

  return (
    <ScrollView
      style={props.style ? [styles.container, props.style] : styles.container}
    >
      {props.items.map((item, idx) => (
        <React.Fragment key={item.label}>
          <View
            style={idx === hoveredIdx ? styles.hovered : undefined}
            onMouseEnter={() => setHoveredIdx(idx)}
            onMouseLeave={() => setHoveredIdx(undefined)}
          >
            {props.onChange ? (
              <WPButton
                appearance={idx === hoveredIdx ? "listItemHovered" : "listItem"}
                onPress={() => props.onChange && props.onChange(item.value)}
              >
                {props.customRenderItem
                  ? props.customRenderItem(item.value)
                  : item.label}
              </WPButton>
            ) : props.customRenderItem ? (
              props.customRenderItem(item.value)
            ) : (
              item.label
            )}
          </View>

          {idx !== props.items.length - 1 && <Separator />}
        </React.Fragment>
      ))}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.white,
    borderRadius: theme.input.borderRadius,
    borderColor: theme.input.blurColor,
    borderWidth: 1,
    overflow: "hidden",
  },
  hovered: {
    backgroundColor: theme.colors.primary,
  },
});

export default WPList;
