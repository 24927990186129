import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import theme from "../../utils/theme";

export default function UIExtraContainer(props: {
  children: React.ReactNode;
  isHidden?: boolean;
}) {
  const [style, setStyle] = useState(getStyle(props.isHidden || false));

  useEffect(() => {
    setStyle(getStyle(props.isHidden || false));
  }, [props.isHidden]);

  return <View style={style}>{props.children}</View>;
}

const styles = StyleSheet.create({
  container: {
    width: 320,
    borderLeftColor: theme.colors.mains[3],
    borderLeftWidth: 1,
    height: "100%",
  },
  hidden: {
    display: "none",
  },
});

function getStyle(isHidden: boolean) {
  return isHidden ? [styles.container, styles.hidden] : styles.container;
}
