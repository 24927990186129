import React from "react";
import { View, Image, StyleSheet } from "react-native";
import { useTranslation } from "react-i18next";
import theme from "../../utils/theme";
import { Document } from "../../services/store/reducers/employee/employeeReducer";
import { getFileName, getNormalizedDate } from "../../utils/mixUtils";
import WPText from "../Text/WPText";

export default function DocumentTile(props: Document) {
  const { i18n } = useTranslation();
  return (
    <View style={styles.container}>
      <Image
        source={{
          uri: props.file,
          height: 40,
          width: 40,
          cache: "force-cache",
        }}
        style={styles.image}
      />
      <View style={styles.info}>
        <WPText style={styles.fileName}>{getFileName(props.file)}</WPText>
        <WPText style={styles.date}>
          {getNormalizedDate(props.date_created, i18n.language)}
        </WPText>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  image: {
    borderRadius: theme.input.borderRadius,
    marginRight: 12,
  },
  info: { justifyContent: "space-evenly" },
  fileName: {
    fontFamily: "bold",
  },
  date: {
    fontFamily: "bold",
    fontSize: 12,
    color: theme.colors.mains[3],
  },
});
