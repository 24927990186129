import React from "react";
import { ListItem } from "../../list/WPList";
import { TouchableOpacity, StyleSheet } from "react-native";
import theme from "../../../utils/theme";
import WPIcon from "../../Icon/WPIcon";
import WPText from "../../Text/WPText";

const tapArea = { bottom: 10, top: 10, left: 10, right: 10 };

export type CheckboxRadioWrapperProps<T> = Omit<ListItem<T>, "label"> & {
  onChange: (value: boolean) => void;
  label?: string;
  type?: "checkbox" | "radiobutton";
  tooltip?: string;
};

export default function CheckboxRadioWrapper<T>(
  props: CheckboxRadioWrapperProps<T>
) {
  let iconName = props.type;

  if (props.value) {
    iconName += `_selected`;
  }

  return (
    <TouchableOpacity
      onPress={() => props.onChange(!props.value)}
      style={styles.container}
      hitSlop={tapArea}
    >
      <WPIcon
        size={theme.checkbox.size}
        name={iconName}
        color={
          props.value
            ? theme.checkbox.selectedColor
            : theme.checkbox.defaultColor
        }
      />
      {props.label ? <WPText style={styles.label}>{props.label}</WPText> : null}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  label: {
    marginLeft: 8,
    fontSize: 12,
  },
});
