import React from "react";
import WPTextInput, { WPTextInputProps } from "./components/WPTextInput";
import WPSearchInput, { WPSearchInputProps } from "./components/WPSearchInput";
import WPCitySearch, { WPCitySearchProps } from "./components/WPCitySearch";

export type WPBaseInputProps<T> = {
  label?: string;
  error?: string | string[];
  value?: T;
  success?: boolean;
  onChange?: (value: T) => void;
};

export type WPInputProps<T> =
  | ({
      type?: "text";
      numberOfLines?: number;
    } & WPTextInputProps)
  | ({ type: "search" } & WPSearchInputProps<T>)
  | ({ type: "city" } & WPCitySearchProps);

export enum WPInputState {
  focused = "focused",
  blurred = "blurred",
  blurredError = "blurredError",
  focusedError = "focusedError",
  blurredWithValue = "blurredWithValue",
  blurredWithValueWithError = "blurredWithValueWithError",
  blurredWithValueWithSuccess = "blurredWithValueWithSuccess",
  focusedWithValue = "focusedWithValue",
  focusedWithValueWithError = "focusedWithValueWithError",
  focusedWithValueWithSuccess = "focusedWithValueWithSuccess",
  disabled = "disabled",
  disabledWithValue = "disabledWithValue",
}

function WPInput<T>(props: WPInputProps<T>) {
  switch (props.type) {
    case "text":
      return <WPTextInput {...props} />;

    case "search":
      return <WPSearchInput {...props} />;

    case "city":
      return <WPCitySearch {...props} />;

    default:
      return <WPTextInput {...props} />;
  }
}

export default WPInput;
