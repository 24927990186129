import React, { memo, useState, useEffect } from "react";
import theme from "../../utils/theme";
import { View, StyleSheet } from "react-native";
import WPButton from "../Button/WPButton";
import { ScrollView } from "react-native-gesture-handler";
import UserTile from "./UserTile";
import WPSwitcher, { SwitcherItem } from "./WPSwitcher";
import { useTranslation } from "react-i18next";
import Collapsible from "./Collapsible";
import BaseUserInfoPreview from "./BaseUserInfoPreview";
import DocumentsUserInfoPreview from "./DocumentsUserInfoPreview";
import NotesUserInfoPreview from "./NotesUserInfoPreview";
import UserApplicationsPreview from "./UserApplicationsPreview";

function UserPreview(props: { userId: number; onClose: () => void }) {
  const { t, i18n } = useTranslation();
  const [activeIdx, setActiveIdx] = useState(0);
  const [items, setItems] = useState<SwitcherItem[]>([]);
  useEffect(() => {
    setItems([
      {
        label: t("labels.profile"),
        onPress: () => {
          setActiveIdx(0);
        },
        isActive: activeIdx === 0,
      },
      {
        label: t("labels.applications"),
        onPress: () => {
          setActiveIdx(1);
        },
        isActive: activeIdx === 1,
      },
    ]);
  }, [activeIdx, i18n.language]);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.userTile}>
          <UserTile userId={props.userId} size="xl" />
        </View>
        <View>
          <WPButton
            type="icon"
            name="close"
            appearance="link"
            color={theme.colors.main}
            onPress={props.onClose}
          />
        </View>
      </View>

      <ScrollView style={styles.preview} removeClippedSubviews={true}>
        <View style={styles.defaultSpacer}>
          <WPSwitcher items={items} />
        </View>
        {activeIdx === 0 ? (
          <>
            <Collapsible label={t("labels.personal_data")}>
              <BaseUserInfoPreview userId={props.userId} />
            </Collapsible>
            <Collapsible label={t("labels.documents")}>
              <DocumentsUserInfoPreview userId={props.userId} />
            </Collapsible>
            <Collapsible label={t("labels.notes")}>
              <NotesUserInfoPreview userId={props.userId} />
            </Collapsible>
          </>
        ) : (
          <UserApplicationsPreview userId={props.userId} />
        )}
      </ScrollView>
    </View>
  );
}

export default memo(UserPreview);

const styles = StyleSheet.create({
  container: { flex: 1 },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 16,
  },
  userTile: {
    paddingLeft: 16,
    // flex: 1,
  },
  defaultSpacer: { marginBottom: 16 },
  preview: { flex: 1, padding: 16 },
});
