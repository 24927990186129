import React, { memo } from "react";
import WPText from "../../../../../components/Text/WPText";
import { StyleSheet } from "react-native";
import theme from "../../../../../utils/theme";

function WPTextMessage(props: { message: string; isEmployee: boolean }) {
  const style = props.isEmployee ? employeeStyle : employerStyle;
  return <WPText style={style}>{props.message.trim()}</WPText>;
}

export default memo(WPTextMessage);

const styles = StyleSheet.create({
  default: {
    borderRadius: theme.button.borderRadius,
    paddingVertical: 8,
    paddingHorizontal: 16,
    overflow: "hidden",
    backgroundColor: theme.colors.primary,
  },
  employee: {
    backgroundColor: theme.colors.primaries[4],
  },
  employer: {
    color: theme.colors.white,
  },
});

const employeeStyle = [styles.default, styles.employee];
const employerStyle = [styles.default, styles.employer];
