import React from "react";
import { InputWrapperProps } from "./InputWrapper";
import { WPListProps } from "../../list/WPList";
import { View, StyleSheet } from "react-native";
import WPCheckbox from "./WPCheckbox";
import WPRadioButton from "./WPRadioButton";
import WPLabel from "./WPLabel";
import { WPInputState } from "../WPInput";
import theme, {
  isMobileView,
  getThemeResponsiveValue,
} from "../../../utils/theme";
import WPError from "./WPError";
import { splitArrayIntoChunks } from "../../../utils/mixUtils";
import WPTooltipText from "./WPTooltipText";

type BaseProps<T> = Omit<
  Omit<Omit<InputWrapperProps<T>, "isFocused"> & WPListProps<T>, "onChange">,
  "value"
> & {
  dense?: boolean;
};

type WPSelectMultiProps<T> = {
  multiple: true;
  value?: T[];
  onChange: (value: T[]) => void;
} & BaseProps<T>;

type WPSelectSingleProps<T> = {
  multiple?: false;
  component_type?: "checkbox" | "radiobutton";
  value?: T;
  onChange: (value?: T) => void;
} & BaseProps<T>;

export type WPSelectProps<T> = WPSelectMultiProps<T> | WPSelectSingleProps<T>;

function isPropsForMultiple<T>(
  props: WPSelectProps<T>
): props is WPSelectMultiProps<T> {
  return "multiple" in props && props.multiple === true;
}

export default class WPSelect<T> extends React.Component<WPSelectProps<T>> {
  isItemSelected = (item: T) => {
    const { multiple, value } = this.props;

    if (!multiple) {
      return value === item;
    }

    if (multiple && value && Array.isArray(value)) {
      return value.indexOf(item) !== -1;
    }

    return false;
  };

  onChange = (item: T) => {
    if (!this.props.onChange) {
      return;
    }

    const isSelected = this.isItemSelected(item);

    if (isPropsForMultiple<T>(this.props)) {
      let normalizedValue = this.props.value || [];

      if (!isSelected) {
        this.props.onChange([...normalizedValue, item]);
      } else {
        normalizedValue.splice(normalizedValue.indexOf(item), 1);
        this.props.onChange([...normalizedValue]);
      }
    } else {
      this.props.onChange(isSelected ? undefined : item);
    }
  };

  render() {
    const OptionComponent =
      this.props.multiple || this.props.component_type === "checkbox"
        ? WPCheckbox
        : WPRadioButton;

    const itemChunks = splitArrayIntoChunks(this.props.items || [], 3);

    return (
      <View>
        {this.props.label ? (
          <View style={styles.label}>
            <WPLabel
              label={this.props.label}
              required={this.props.required}
              inputState={WPInputState.blurredWithValue}
            />
          </View>
        ) : null}
        <View style={styles.mobileContainer}>
          {itemChunks.map((chunk, idx) => (
            <React.Fragment key={"chunk" + idx}>
              <View style={isMobileView() ? styles.mobileChunk : styles.chunk}>
                {chunk.map((item, itemIdx) => (
                  <React.Fragment key={item.label}>
                    <View
                      style={
                        isMobileView()
                          ? styles.mobileOption
                          : this.props.dense
                          ? styles.denseOption
                          : styles.option
                      }
                    >
                      <OptionComponent
                        {...item}
                        value={this.isItemSelected(item.value)}
                        onChange={(value) => this.onChange(item.value)}
                      />
                    </View>
                    {isMobileView() || itemIdx === chunk.length - 1 ? null : (
                      <View style={styles.spacer} />
                    )}
                  </React.Fragment>
                ))}
              </View>
              {isMobileView() || idx === itemChunks.length - 1 ? null : (
                <View style={styles.chunkSpacer} />
              )}
            </React.Fragment>
          ))}
        </View>
        {this.props.error && <WPError error={this.props.error} />}
        {this.props.tooltip ? (
          <View style={styles.marginTop}>
            <WPTooltipText text={this.props.tooltip} />
          </View>
        ) : null}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  label: {
    marginTop: -4,
    marginBottom: 10,
  },
  option: {
    marginTop: 4,
    flex: 1,
  },
  denseOption: {
    marginTop: 4,
    marginRight: 24,
  },
  chunk: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  mobileChunk: {
    flexDirection: "column",
  },
  mobileContainer: {
    flexDirection: "column",
  },
  mobileOption: {
    marginBottom: 12,
  },
  spacer: {
    width: getThemeResponsiveValue(theme.formRow.spacer.width),
  },
  chunkSpacer: {
    marginBottom: 9,
  },
  marginTop: {
    marginTop: 4,
  },
});
