import React from "react";
import WPIcon, { WPIconProps } from "../../components/Icon/WPIcon";
import theme from "../../utils/theme";
import { View } from "react-native";

const HeaderLogo: React.FC<WPIconProps> = (props) => {
  return (
    <View
      style={{
        margin: "auto",
        paddingHorizontal: theme.button.paddingHorizontal,
      }}
    >
      <WPIcon {...props} name="logo" />
    </View>
  );
};

export default React.memo(HeaderLogo);
