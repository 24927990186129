import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const ClipIcon: React.FC<IconProps> = (props) => {
  let height = 32;
  let width = 32;
  const ratio = width / height;
  const color = props.color || "#919EAB";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 32 32" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M18.856 3.2c3.612 0 6.56 2.918 6.736 6.587l.008.346v9.62c0 4.995-3.94 9.047-8.8 9.047-4.743 0-8.612-3.86-8.793-8.692L8 19.753v-8.09c0-.676.533-1.224 1.191-1.224.603 0 1.101.46 1.18 1.058l.011.166v8.09c0 3.643 2.875 6.598 6.419 6.598 3.543 0 6.417-2.955 6.417-6.598v-9.62c0-2.478-1.952-4.484-4.362-4.484-2.321 0-4.218 1.86-4.355 4.21l-.008.274v5.64l-.001.02v3.89c0 1.305 1.029 2.361 2.298 2.361 1.194 0 2.174-.935 2.285-2.133l.01-.227v-7.367c0-.677.533-1.225 1.191-1.225.603 0 1.101.46 1.18 1.059l.011.166v7.367c0 2.656-2.093 4.809-4.677 4.809-2.492 0-4.53-2.002-4.671-4.527l-.008-.282v-9.551c0-3.83 3.019-6.933 6.745-6.933z"
      />
    </Svg>
  );
};

ClipIcon.displayName = "ClipIcon";

export default ClipIcon;
