import React, { useCallback, memo } from "react";
import CRMDataScreen from "../../common/CRMDataScreen";
import { useGetEmployeeApplications } from "../../../../services/hooks/employeeHooks";
import { useRenderEmployeeApplicationsTile } from "../candidatesUtils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setUserPreviewId } from "../../../../services/store/actions/uiActions";
import { useNavigation } from "@react-navigation/native";
import { RootState } from "../../../../services/store/reducers/mainReducer";

function CandidatesScreen() {
  const isUpdateScheduled = useSelector(
    (state: RootState) => state.ui.shouldUpdateList
  );
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const toggleUserInfo = useCallback((id: number) => {
    dispatch(setUserPreviewId(id));
  }, []);
  const getCall = useGetEmployeeApplications();
  const { i18n, t } = useTranslation();

  const renderItem = useRenderEmployeeApplicationsTile(toggleUserInfo);

  return (
    <CRMDataScreen
      navigation={navigation}
      title={t("titles.candidates")}
      getData={getCall}
      renderItem={renderItem}
      language={i18n.language}
      isUpdateScheduled={isUpdateScheduled}
    />
  );
}

export default memo(CandidatesScreen);
