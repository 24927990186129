// export default {
//   API_URL: "https://dev.workport.pl/api/v1/",
//   API_V2: "https://alt.dev.workport.pl/",
//   TOKEN_URL: "auth/token/",
//   REGISTRATION_URL: "employers/registration/",
//   FIREBASE_CHAT_COLLECTION: "conversation_v2_dev",
//   FIREBASE_MESSAGE_COLLECTION: "messages_dev",
//   NEWS_ID: "1",
//   TOKEN_KEY: "WP_TOKEN",
//   REFRESH_TOKEN_KEY: "WP_REFRESH_TOKEN",
//   STORE_KEY: "WP_STORE",
//   EXPERIENCE: "@gusberry/workport-biz",
//   NEWS_COLLECTION: "news_crm",
//   REDIRECT: "WP_REDIRECT",
//   // EXPERIENCE: "workport-biz",
// };

export default {
  API_URL: "https://api.workport.pl/api/v1/",
  API_V2: "https://alt.api.workport.pl/",
  TOKEN_URL: "auth/token/",
  REGISTRATION_URL: "employers/registration/",
  FIREBASE_CHAT_COLLECTION: "conversation_v2_prod",
  FIREBASE_MESSAGE_COLLECTION: "messages_prod",
  NEWS_ID: "1",
  TOKEN_KEY: "WP_TOKEN",
  REFRESH_TOKEN_KEY: "WP_REFRESH_TOKEN",
  STORE_KEY: "WP_STORE",
  EXPERIENCE: "@gusberry/workport-biz",
  REDIRECT: "WP_REDIRECT",
  NEWS_COLLECTION: "news_crm",
  // EXPERIENCE: "workport-biz",
};
