import React, { useState, useEffect } from "react";
import WPNotificationModal from "../../../components/modal/modalVariants/WPNotificationModal";
import { View, StyleSheet } from "react-native";
import theme from "../../../utils/theme";
import WPButton from "../../../components/Button/WPButton";
import WPText from "../../../components/Text/WPText";
import WPIcon, { IconName } from "../../../components/Icon/WPIcon";

type AuthNotification = {
  isVisible: boolean;
  onClose: () => void;
  iconName: IconName;
  title: string;
  description: string;
  buttonLabel?: string;
};

export default function AuthNotification(props: AuthNotification) {
  const [isVisible, setIsVisible] = useState(props.isVisible);

  useEffect(() => {
    if (props.isVisible) {
      setIsVisible(true);
    }
  }, [props.isVisible]);

  return (
    <WPNotificationModal isVisible={isVisible} onDismiss={props.onClose}>
      <View style={styles.container}>
        <WPIcon
          name={props.iconName}
          color={
            props.iconName === "attention"
              ? theme.colors.warning
              : theme.colors.primary
          }
          style={styles.icon}
          size={111}
        />
        <WPText style={styles.title}>{props.title}</WPText>
        <WPText style={styles.description}>{props.description}</WPText>
        {props.buttonLabel ? (
          <WPButton
            onPress={() => {
              setIsVisible(false);
            }}
            style={styles.button}
          >
            {props.buttonLabel}
          </WPButton>
        ) : null}
      </View>
    </WPNotificationModal>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    padding: 16,
  },
  icon: {
    marginBottom: 24,
  },
  title: {
    fontSize: 28,
    marginBottom: 24,
  },
  description: {
    marginBottom: 50,
    textAlign: "center",
    fontSize: 18,
    lineHeight: 18 * 1.4,
  },
  button: { width: "80%" },
});
