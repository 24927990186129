import React, { memo } from "react";
import { useGetMessage } from "./conversationHooks";
import { View, StyleSheet } from "react-native";
import WPTextMessage from "./messages/WPTextMessage";
import WPText from "../../../../components/Text/WPText";
import { getMessageDate } from "../../../../utils/mixUtils";
import { useTranslation } from "react-i18next";
import theme, {
  getThemeResponsiveValue,
  isMobileView,
} from "../../../../utils/theme";
import WPIcon from "../../../../components/Icon/WPIcon";
import WPAvatar from "../../../../components/misc/WPAvatar";
import WPJobMessage from "./messages/WPJobMessage";
import WPImageMessage from "./messages/WPImageMessage";
import WPEstateMessage from "./messages/WPEstateMessage";

type WPChatMessageProps = {
  messageId: string;
  isLastSeen?: boolean;
};

function WPChatMessage(props: WPChatMessageProps) {
  const [message, avatar] = useGetMessage(props.messageId);
  const { i18n } = useTranslation();

  if (!message) {
    return null;
  }

  const seenMarkSize = isMobileView() ? 20 : 12;
  const containerStyle = message.is_employee ? employeeStyle : employerStyle;
  const dateContainerStyle = message.is_employee
    ? styles.dateContainerEmployee
    : styles.dateContainerEmployer;

  let messageNode: React.ReactNode = null;
  const { text, job_id, status, file, estate_id } = message;

  switch (true) {
    case !!job_id:
      messageNode = (
        <WPJobMessage
          jobId={job_id as number}
          status={status}
          isEmployer={!message.is_employee}
        />
      );
      break;

    case !!estate_id:
      messageNode = <WPEstateMessage estateId={estate_id as number} />;
      break;

    case !!file:
      messageNode = <WPImageMessage image={file || ""} />;
      break;

    case text !== undefined:
      messageNode = (
        <WPTextMessage message={text} isEmployee={message.is_employee} />
      );
      break;

    default:
      break;
  }

  return (
    <View>
      <View style={containerStyle}>
        {message.is_employee && avatar && (
          <View style={styles.avatarContainer}>
            <WPAvatar source={avatar} size="s" />
          </View>
        )}
        <View
          style={
            message.is_employee
              ? styles.messageContainerEmployee
              : styles.messageContainerEmployer
          }
        >
          {messageNode}
          <View style={dateContainerStyle}>
            {props.isLastSeen ? (
              <WPIcon name="seen_mark" size={seenMarkSize} />
            ) : null}
            <View style={styles.spacer} />
            <WPText style={styles.date}>
              {getMessageDate(message.created_at["seconds"], i18n.language)}
            </WPText>
          </View>
        </View>
      </View>
    </View>
  );
}

export default memo(WPChatMessage);

const styles = StyleSheet.create({
  container: {
    marginBottom: 20,
    flexDirection: "row",
    // flex: 1,
  },
  spacer: {
    marginHorizontal: 2,
  },
  messageContainerEmployee: {
    flex: 1,
    alignItems: "flex-start",
  },
  messageContainerEmployer: {
    flex: 1,
    alignItems: "flex-end",
  },
  employer: {
    flex: 1,
    justifyContent: "flex-end",
  },
  avatarContainer: {
    justifyContent: "flex-end",
    marginRight: 4,
  },
  dateContainerEmployee: {
    marginTop: 4,
    flexDirection: "row",
    alignItems: "center",
  },
  dateContainerEmployer: {
    marginTop: 4,
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  date: {
    color: theme.colors.mains[3],
    fontSize: getThemeResponsiveValue(theme.input.fontSize) - 2,
  },
});

const employeeStyle = [styles.container];
const employerStyle = [styles.container, styles.employer];
