import React, { memo } from "react";
import {
  TouchableOpacity,
  TouchableOpacityProps,
  StyleSheet,
} from "react-native";
import WPIcon, { WPIconProps } from "../Icon/WPIcon";
import WPText from "../Text/WPText";
import theme, { getThemeResponsiveValue } from "../../utils/theme";
import PrependAppendDecorator, {
  PrependAppendDecoratorProps,
} from "../misc/PrependAppendDecorator";
import LoadingIcon from "../Icon/icons/LoadingIcon";

type DefaultBaseButtonProps = Omit<TouchableOpacityProps, "onPress"> &
  PrependAppendDecoratorProps & {
    type?: "regular" | "icon";
    size?: keyof typeof theme.button.size;
    appearance?:
      | "fill"
      | "outlined"
      | "link"
      | "listItem"
      | "inline"
      | "listItemHovered";
    textColor?: string;
    isLoading?: boolean;
    onPress?: () => void;
  };

type RegularButtonProps = DefaultBaseButtonProps & {
  type: "regular";
};

export type IconButtonProps = DefaultBaseButtonProps & {
  type: "icon";
} & WPIconProps;

function isIconProps(
  props: DefaultBaseButtonProps | RegularButtonProps | IconButtonProps
): props is IconButtonProps {
  return props.type === "icon";
}

export type WPBaseButtonProps =
  | DefaultBaseButtonProps
  | RegularButtonProps
  | IconButtonProps;

const WPBaseButton: React.FC<WPBaseButtonProps> = (props) => {
  return (
    <TouchableOpacity
      {...props}
      style={getButtonStyle(props)}
      disabled={props.disabled || props.isLoading}
    >
      <PrependAppendDecorator prepend={props.prepend} append={props.append}>
        {props.isLoading ? (
          <LoadingIcon color={theme.colors.white} />
        ) : isIconProps(props) ? (
          <WPIcon name={props.name} color={props.color} size={props.size} />
        ) : typeof props.children === "string" ? (
          <WPText style={getTextColor(props)}>{props.children}</WPText>
        ) : (
          props.children
        )}
      </PrependAppendDecorator>
    </TouchableOpacity>
  );
};

WPBaseButton.defaultProps = {
  type: "regular",
};

const style = StyleSheet.create({
  defaultText: {
    color: theme.colors.white,
    fontFamily: "bold",
    fontSize: getThemeResponsiveValue(theme.button.fontSize),
    textAlign: "center",
  },
  default: {
    backgroundColor: theme.button.regularColor,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: theme.button.paddingHorizontal,
    borderRadius: theme.button.borderRadius,
    position: "relative",
  },
  disabled: {
    opacity: 0.3,
  },
  regular: {
    minHeight: getThemeResponsiveValue(theme.button.size.regular),
  },
  big: {
    minHeight: getThemeResponsiveValue(theme.button.size.big),
  },
  small: {
    minHeight: getThemeResponsiveValue(theme.button.size.small),
  },
  fill: {
    backgroundColor: theme.button.regularColor,
  },
  outlined: {
    backgroundColor: theme.colors.transparent,
    borderWidth: 2,
    borderColor: theme.button.regularColor,
  },
  link: {
    backgroundColor: theme.colors.transparent,
  },
  listItem: {
    backgroundColor: theme.colors.transparent,
  },
  listItemHovered: {
    backgroundColor: theme.colors.transparent,
  },
  fillText: {
    color: theme.colors.white,
  },
  outlinedText: {
    color: theme.button.regularColor,
  },
  linkText: {
    color: theme.button.regularColor,
  },
  listItemText: {
    textAlign: "left",
    color: theme.colors.main,
  },
  listItemHoveredText: {
    textAlign: "left",
    color: theme.colors.white,
  },
  inline: {
    paddingHorizontal: 0,
    backgroundColor: theme.colors.transparent,
  },
});

function getButtonStyle(props: WPBaseButtonProps) {
  const sizeStyle = style[props.size || "regular"];
  const appearanceStyle = style[props.appearance || "fill"];
  const normalizedStyle = [
    style.default,
    sizeStyle,
    appearanceStyle,
    props.style,
  ];

  if (props.disabled) {
    normalizedStyle.push(style.disabled);
  }

  return normalizedStyle;
}

function getTextColor(props: WPBaseButtonProps) {
  const appearance = `${props.appearance || "fill"}Text`;
  return [style.defaultText, style[appearance]];
}

export default memo(WPBaseButton);
