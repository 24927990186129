import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const PasswordIcon: React.FC<IconProps> = (props) => {
  let height = 26;
  let width = 26;
  const ratio = width / height;
  const color = props.color || "#637381";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 26 26" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M12.99 8.5c-2.793 0-5.301 1.167-6.61 2.98a.767.767 0 01-1.06.175.743.743 0 01-.179-1.046C6.748 8.384 9.723 7 12.99 7c3.284 0 6.273 1.399 7.872 3.643a.742.742 0 01-.184 1.044.768.768 0 01-1.06-.181C18.316 9.678 15.798 8.5 12.99 8.5zm3.613 6.25c0 2.071-1.702 3.75-3.802 3.75S9 16.821 9 14.75C9 12.679 10.702 11 12.802 11s3.801 1.679 3.801 3.75zm-1.52 0c0-1.243-1.022-2.25-2.281-2.25-1.26 0-2.281 1.007-2.281 2.25S11.54 17 12.8 17s2.281-1.007 2.281-2.25z"
        clipRule="evenodd"
      />
    </Svg>
  );
};

PasswordIcon.displayName = "PasswordIcon";

export default PasswordIcon;
