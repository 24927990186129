import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  SafeAreaView,
  TextInput,
  Platform,
  StyleProp,
  TextStyle,
  View,
  TouchableOpacity,
} from "react-native";
import { StyleSheet } from "react-native";
import theme, { getThemeResponsiveValue } from "../../../../utils/theme";
import WPIcon from "../../../../components/Icon/WPIcon";
import { useSendMessage } from "../../../../services/hooks/chatHooks";
import WPMessageAttachmentHandler from "./WPMessageAttachmentHandler";
import WPJobMessageTile from "./misc/WPJobMessageTile";
import { hitSlop } from "../../../../utils/constants";
import { getActiveConversationType } from "./conversationHooks";
import { ConversationType } from "../../../../services/chatService";
import WPEstateMessage from "./messages/WPEstateMessage";
import { useTranslation } from "react-i18next";

const textInputHitSlop = {
  top: 16,
};

export default function WPConversationInput() {
  const { t } = useTranslation();
  const activeConversationType = getActiveConversationType();
  const inputRef = useRef<TextInput>(null);
  const [text, setText] = useState("");
  const [entity, setEntity] = useState<
    | {
        id: number;
        type: "job" | "estate";
      }
    | undefined
  >(undefined);
  const [textStyle, setTextStyle] = useState<StyleProp<TextStyle>>([
    styles.text,
  ]);

  const newLineCount = text.split(/\n/).length;
  const numberOfLines = newLineCount < 4 ? newLineCount : 4;

  useEffect(() => {
    if (Platform.OS === "ios") {
      const fontSize = getThemeResponsiveValue(theme.input.fontSize);
      const height = numberOfLines * fontSize * 1.2;
      const heightStyle = { height };
      setTextStyle([styles.text, heightStyle]);
    }
  }, [Platform.OS, numberOfLines]);

  const resetInputs = useCallback(() => {
    setText("");
    setEntity(undefined);
  }, [setText, setEntity]);

  const onSpacerClick = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const sendMessage = useSendMessage();
  const normalizedSendMessage = useCallback(() => {
    switch (true) {
      case !!text:
        sendMessage(text, "text");
        break;
      case !!entity:
        if (entity) sendMessage(entity.id, entity.type);
        break;

      default:
        break;
    }
    resetInputs();
  }, [text, entity, sendMessage, resetInputs]);

  const isDisabled = !text && !entity;
  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.innerContainer}>
        <View style={styles.textInputContainer}>
          {entity ? (
            <View style={styles.entity}>
              <TouchableOpacity
                hitSlop={hitSlop}
                style={styles.closeIconContainer}
                onPress={resetInputs}
              >
                <View style={styles.closeIcon}>
                  <WPIcon size={8} name="close" color={theme.colors.main} />
                </View>
              </TouchableOpacity>
              {activeConversationType === ConversationType.JOB ? (
                <WPJobMessageTile jobId={entity.id} />
              ) : (
                <WPEstateMessage estateId={entity.id} />
              )}
            </View>
          ) : (
            <TextInput
              style={textStyle}
              multiline
              value={text}
              onChangeText={setText}
              numberOfLines={numberOfLines}
              hitSlop={textInputHitSlop}
              ref={inputRef}
              placeholder={t("misc.message")}
            />
          )}

          <TouchableOpacity
            style={styles.textInputContainer}
            onPress={onSpacerClick}
          />
          <View style={styles.attachmentContainer}>
            <WPMessageAttachmentHandler onSelected={setEntity} />
            <TouchableOpacity
              style={styles.textInputContainer}
              onPress={onSpacerClick}
            />
          </View>
        </View>
        <TouchableOpacity
          style={[styles.send, isDisabled && { opacity: 0.6 }]}
          disabled={isDisabled}
          onPress={normalizedSendMessage}
        >
          <WPIcon
            name="back"
            color={theme.colors.white}
            style={styles.backButtonIcon}
          />
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    borderTopColor: theme.colors.mains[4],
    borderTopWidth: 1,
    alignItems: "stretch",
  },
  innerContainer: {
    paddingHorizontal: theme.input.paddingHorizontal,
    paddingVertical: theme.input.paddingHorizontal / 2,
    flexDirection: "row",
  },
  attachmentContainer: {
    flexDirection: "row",
  },
  textInputContainer: {
    flex: 1,
  },
  text: {
    fontSize: getThemeResponsiveValue(theme.input.fontSize),
  },
  send: {
    height: 52,
    width: 52,
    borderRadius: 26,
    backgroundColor: theme.colors.primary,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-end",
  },
  backButtonIcon: { transform: [{ rotate: "180deg" }] },
  entity: { position: "relative", marginBottom: 8, marginRight: 8 },
  closeIconContainer: {
    position: "absolute",
    padding: 8,
    alignItems: "center",
    justifyContent: "center",
    top: -12,
    right: -12,
    zIndex: 1,
  },
  closeIcon: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: theme.colors.mains[5],
    alignItems: "center",
    justifyContent: "center",
  },
});
