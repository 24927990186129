import React from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, View } from "react-native";
import { Formik } from "formik";
import FormContainer from "../../../../components/form/FormContainer";
import FormRow from "../../../../components/form/FormRow";
import FormField from "../../../../components/form/FormField";
import WPButton from "../../../../components/Button/WPButton";
import { useChangeProfile } from "../../../../services/hooks/authHooks";
import WPText from "../../../../components/Text/WPText";
import theme, { isMobileView } from "../../../../utils/theme";
import WPSeparator from "../../../../components/misc/WPSeparator";

const ProfileEditScreen = () => {
  const { t } = useTranslation();
  const [accountData, isLoading, errors, saveChanges] = useChangeProfile();

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.titleContainer}>
        <WPText style={styles.title}>{t("labels.company_data")}</WPText>
      </View>
      <View style={styles.formContainer}>
        <Formik
          initialValues={accountData || {}}
          enableReinitialize
          onSubmit={saveChanges}
        >
          {(formikProps) => (
            <FormContainer>
              <FormRow>
                <FormField
                  {...formikProps}
                  style={styles.container}
                  name="logo"
                  type="image"
                  errors={errors}
                />
                {isMobileView() ? null : <View style={styles.spacer} />}
              </FormRow>
              <FormRow>
                <FormField
                  name="company_name"
                  {...formikProps}
                  errors={errors}
                  disableTooltip
                  label={t("labels.employer_company_name")}
                  disabled
                />
              </FormRow>
              <FormRow>
                <FormField
                  name="email"
                  {...formikProps}
                  errors={errors}
                  disabled
                  disableTooltip
                />
                <FormField
                  name="phone"
                  {...formikProps}
                  errors={errors}
                  disableTooltip
                />
              </FormRow>
              <FormRow>
                <FormField
                  name="nip"
                  {...formikProps}
                  errors={errors}
                  disableTooltip
                  disabled
                />
                <FormField
                  name="country"
                  {...formikProps}
                  errors={errors}
                  disableTooltip
                />
              </FormRow>
              <FormRow>
                <FormField
                  name="location"
                  {...formikProps}
                  errors={errors}
                  disableTooltip
                />
                <FormField
                  name="address"
                  {...formikProps}
                  errors={errors}
                  disableTooltip
                />
              </FormRow>
              <FormRow>
                <FormField
                  name="description"
                  {...formikProps}
                  errors={errors}
                  numberOfLines={4}
                />
              </FormRow>
              <WPSeparator dense />

              <View style={styles.buttonContainer}>
                <WPButton
                  onPress={formikProps.handleSubmit}
                  isLoading={isLoading}
                >
                  {t("labels.save")}
                </WPButton>
              </View>
            </FormContainer>
          )}
        </Formik>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
  },
  spacer: {
    flex: 1,
  },
  titleContainer: {
    justifyContent: "space-between",
    backgroundColor: theme.colors.white,
    marginBottom: 32,
    paddingBottom: 20,
    paddingTop: 12,
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 10,
    paddingHorizontal: 16,
    flexDirection: isMobileView() ? "column" : "row",
  },
  title: {
    fontFamily: "bold",
    fontSize: 28,
  },
  formContainer: {
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  buttonContainer: {
    alignItems: "flex-end",
  },
});

export default ProfileEditScreen;
