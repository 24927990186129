import React, { memo, useMemo } from "react";
import { View, Image, StyleSheet } from "react-native";
import theme from "../../../../utils/theme";
import WPText from "../../../../components/Text/WPText";
import LoadingIcon from "../../../../components/Icon/icons/LoadingIcon";
import WPIcon from "../../../../components/Icon/WPIcon";

function EntityPreview(
  props:
    | {
        image?: string;
        title: string;
        subtitle?: string;
        background?: string;
        isLoading: boolean;
        isDeleted: boolean;
      }
    | {
        isLoading: boolean;
        isDeleted: boolean;
      }
) {
  const background =
    "background" in props ? props.background : theme.colors.mains[5];
  const image = "image" in props ? props.image : undefined;
  const title = "title" in props ? props.title : undefined;
  const subtitle = "subtitle" in props ? props.subtitle : undefined;
  const isLoading = "isLoading" in props ? props.isLoading : undefined;
  const isDeleted = "isDeleted" in props ? props.isDeleted : undefined;

  const shouldShowJobData = !isLoading && !isDeleted ? true : false;

  const style = useMemo(
    () => [styles.container, { backgroundColor: background }],
    [background]
  );
  const imageSrc = useMemo(() => ({ uri: image, width: 74, height: 74 }), [
    image,
  ]);

  return (
    <View style={style}>
      {shouldShowJobData ? (
        <>
          {image ? <Image source={imageSrc} /> : null}
          <View style={styles.desc}>
            <WPText style={styles.title} numberOfLines={1}>
              {title}
            </WPText>
            <WPText numberOfLines={1} style={styles.subtitle}>
              {subtitle}
            </WPText>
          </View>
        </>
      ) : (
        <View style={styles.missingContainer}>
          {isLoading ? (
            <LoadingIcon color={theme.colors.white} />
          ) : (
            <WPIcon name="trash" color={theme.colors.error} />
          )}
        </View>
      )}
    </View>
  );
}

export default memo(EntityPreview);

const styles = StyleSheet.create({
  title: {
    fontFamily: "bold",
  },
  subtitle: {
    opacity: 0.8,
  },
  container: {
    height: 74,
    borderRadius: theme.input.borderRadius,

    overflow: "hidden",
    width: "100%",
    flexDirection: "row",
  },
  desc: {
    padding: 12,
    justifyContent: "space-between",
    flex: 1,
  },
  missingContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: 100,
  },
});
