import { useCallback, useEffect, useState } from "react";
import {
  updateAccountCall,
  uploadProfileImage,
  accountInfoCall,
  AccountInfoCallData,
  EmployerReview,
  getReviews,
  getTotalRating,
} from "../apis/authApi";
import { ErrorResponse } from "../../utils/wpRequest";
import { goBack } from "../../navigation/navigationHelpers";
import { logError } from "../logger";
import { useDispatch, useSelector } from "react-redux";
import { signedInAction } from "../store/actions/authActions";
import { RootState } from "../store/reducers/mainReducer";
import { City, defaultPagination } from "../apis/jobApi";
import { useFocusEffect } from "@react-navigation/native";

export const useChangeProfile = (): [
  AccountInfoCallData | undefined,
  boolean,
  ErrorResponse,
  (data: Partial<AccountInfoCallData & { location: City }>) => Promise<void>
] => {
  const userId = useSelector((state: RootState) => state.auth.userId);
  const [accountInfo, setAccountInfo] = useState<
    AccountInfoCallData | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({} as ErrorResponse);

  useFocusEffect(() => {
    if (!accountInfo) {
      accountInfoCall().then(setAccountInfo).catch(logError);
    }
  });

  const dispatch = useDispatch();

  const signIn = async (
    accountData: Partial<AccountInfoCallData & { location: City }>
  ) => {
    // return goBack("CRM.JOB.ACTIVE_JOB_LIST");
    setIsLoading(true);
    const normalizedData = JSON.parse(JSON.stringify(accountData));

    if (
      normalizedData.location &&
      typeof normalizedData.location === "object"
    ) {
      normalizedData.location = normalizedData.location.geoname_id.toString();
    } else {
      delete normalizedData.location;
    }

    try {
      await updateAccountCall({
        id: userId,
        ...normalizedData,
      });

      if (accountData.logo) {
        await uploadProfileImage(accountData.logo);
      }

      const newInfo = await accountInfoCall();

      if (userId) {
        dispatch(
          signedInAction({
            userId,
            userName: `${newInfo.first_name} ${newInfo.last_name}`,
            avatar: newInfo.logo,
            email: newInfo.email,
            company: newInfo.company_name,
          })
        );
      }

      goBack("CRM.JOB.ACTIVE_JOB_LIST");
    } catch (err) {
      setErrors(err);
    }

    setIsLoading(false);
  };

  return [accountInfo, isLoading, errors, signIn];
};

export const useRatingHook = (): [
  {
    accommodation: number;
    board: number;
    general: number;
    response_time: number;
    salary_on_time: number;
  },
  EmployerReview[],
  () => void,
  number
] => {
  const employerId = useSelector((state: RootState) => state.auth.userId);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [reviews, setReviews] = useState<EmployerReview[]>([]);
  const [overallRating, setOverallRating] = useState({
    accommodation: 0,
    board: 0,
    general: 0,
    response_time: 0,
    salary_on_time: 0,
  });

  useEffect(() => {
    if (employerId) {
      getTotalRating(employerId)
        .then((res) => {
          setOverallRating(res);
        })
        .catch(logError);
    }
  }, [employerId, setOverallRating]);

  const getReviewsWrapper = useCallback(() => {
    async function getData() {
      if (employerId) {
        try {
          const reviewsRes = await getReviews({
            filter: { employerId },
            pagination: { ...defaultPagination, page_num: page },
          });

          setReviews(reviews.concat(reviewsRes.results));
          setPage(page + 1);
          setTotal(reviewsRes.count);
        } catch (err) {
          logError(err);
        }
      }
    }

    getData();
  }, [employerId, setReviews, page, setPage, setTotal]);

  return [overallRating, reviews, getReviewsWrapper, total];
};
