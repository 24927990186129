import { TFunction } from "i18next";
import { Platform } from "react-native";
import { ListCall, City } from "./jobApi";
import wpRequest, { fileRequest } from "../../utils/wpRequest";
import { getFilterAsString } from "../../utils/mixUtils";

export type Image = { id: number; image: string } | string;

export enum OfferStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  ENDED = "ENDED",
}

export enum PropertyType {
  HOSTEL = "HOSTEL",
  HOUSE = "HOUSE",
  APARTMENT = "APARTMENT",
}

export enum RentType {
  HOUSE = "HOUSE",
  APARTMENT = "APARTMENT",
  ROOM = "ROOM",
  BED = "BED",
}

export enum AdvertiserType {
  OWNER = "OWNER",
  AGENT = "AGENT",
}

export enum BillingPeriod {
  MONTH = "MONTH",
  DAY = "DAY",
}

export enum Renter {
  ANY = "ANY",
  MEN = "MEN",
  WOMEN = "WOMEN",
  COUPLES = "COUPLES",
}

export enum BedType {
  SINGLE = "SINGLE",
  DOUBLE = "DOUBLE",
  BUNK = "BUNK",
}

export enum Equipment {
  furniture = "furniture",
  "washing machine" = "washing machine",
  "internet" = "internet",
  refrigerator = "refrigerator",
  dishwasher = "dishwasher",
  "cable tv" = "cable tv",
  tv = "tv",
  oven = "oven",
  "air conditioner" = "air conditioner",
}

export enum Facilities {
  balcony = "balcony",
  garden = "garden",
  "bus stop nearby" = "bus stop nearby",
  "terrace" = "terrace",
  "garage/parking space" = "garage/parking space",
  "tram stop nearby" = "tram stop nearby",
  "elevator" = "elevator",
  "own kitchen" = "own kitchen",
  "shops nearby" = "shops nearby",
}

export enum Restrictions {
  "no smoking" = "no smoking",
  "no strangers" = "no strangers",
  "obligatory night silence" = "obligatory night silence",
}

export enum IamApps {
  telegram = "telegram",
  viber = "viber",
  whatsapp = "whatsapp",
}

export type Estate = {
  employer: { id: number; name: string; logo: null | string };
  status: OfferStatus;
  title: string;
  property_type: PropertyType;
  rent_type: RentType;
  advertiser_type: AdvertiserType;
  price: number;
  billing_period: BillingPeriod;
  city: City;
  district: string;
  street: string;
  postal_code: string;
  description: string;
  renter: Renter;
  number_of_rooms: number | null;
  number_of_floors: number | null;
  number_of_beds: number | null;
  total_area: number | null;
  lot_area: number | null;
  utilities: number | null;
  deposit: number | null;
  floor: number | null;
  bed_type: BedType;
  extra_payments: number | null;
  equipment: Equipment[];
  facilities: Facilities[];
  restrictions: Restrictions[];
  start_date: string;
  minimum_rent_term: number;
  phone: string;
  im_apps: IamApps[];
  images: Image[];
  images_obj: Image[];
  created: string;
  id: number;
};

export const deleteEstateImage = (estateId: number, imageId: number) => {
  wpRequest
    .delete(`admin/housing/offers/${estateId}/images/${imageId}/`)
    .catch(console.warn);
};

export const getEstateListCall: ListCall<Estate> = (config) =>
  wpRequest.get(
    `admin/housing/offers/?page=${config.pagination.page_num}&page_size=${
      config.pagination.page_size
    }&${getFilterAsString(config.filter)}`
  );

export const getEstateCall = (estateId: number) =>
  wpRequest.get<Estate>(`admin/housing/offers/${estateId}/`);

export const createEstateCall = (offer: Partial<Estate>) =>
  wpRequest.post<Estate>(`admin/housing/offers/`, offer);

export const deleteEstateCall = (offerId: number) =>
  wpRequest.delete(`admin/housing/offers/${offerId}/`);

export const updateEstateCall = (offer: Estate) =>
  wpRequest.put<Estate>(`admin/housing/offers/${offer.id}/`, offer);

export const uploadEstateImagesCall = async (
  offerId: number,
  images: Image[]
) => {
  const imagesToSave = images.filter(
    (image) =>
      !!image &&
      !(typeof image === "object" && "id" in image) &&
      (typeof image !== "string" || !/http(s)?:/.test(image))
  ) as string[];

  const imagesCalls = imagesToSave.map(async (image) => {
    let fileReader: Promise<File | { uri: string; name: string }>;

    if (Platform.OS === "web") {
      fileReader = fileRequest(image).then(
        (file) =>
          new File([file], "image.png", {
            type: "image/png",
            lastModified: Number(new Date()),
          })
      );
    } else {
      fileReader = Promise.resolve({
        uri: image,
        name: "image.png",
      });
    }

    return fileReader.then((file: File | { uri: string; name: string }) => {
      const formData = new FormData();

      // @ts-ignore server specifics
      formData.append("image", file);

      return wpRequest.post(
        `admin/housing/offers/${offerId}/images/`,
        formData
      );
    });
  });

  return Promise.all(imagesCalls);
};

export const getTotalEstatePrice = (estate: Estate, t: TFunction) => {
  let totalPrice =
    estate.price.toString() +
    " pln/" +
    t(`select.billing_period.${estate.billing_period}`);

  if (estate.utilities) {
    totalPrice += ` (${estate.utilities})`;
  }

  return totalPrice;
};
