import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const PlusIcon: React.FC<IconProps> = (props) => {
  let height = 24;
  let width = 24;
  const ratio = width / height;
  const color = props.color || "#FFF";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" {...props}>
      <Path
        d="M12 1.615v20.77M1.615 12h20.77"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
      />
    </Svg>
  );
};

PlusIcon.displayName = "PlusIcon";

export default PlusIcon;
