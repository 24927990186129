import React from "react";
import { useGetJob } from "../../../../../services/hooks/jobHooks";
import EntityPreview from "../EntityPreview";
import { getPaymentInfo } from "../../../../../utils/mixUtils";
import { useTranslation } from "react-i18next";
import theme from "../../../../../utils/theme";

type WPJobMessageTileProps = {
  jobId: number;
  background?: string;
};

export default function WPJobMessageTile(props: WPJobMessageTileProps) {
  const job = useGetJob(props.jobId);
  const { t } = useTranslation();

  if (!job) {
    return null;
  }

  const { data, isLoading, isDeleted } = job;

  let entityProps;

  if (isLoading || isDeleted) {
    entityProps = {
      isLoading,
      isDeleted,
    };
  } else {
    const firstImage = data?.images[0];
    let imageSource: string | undefined = undefined;

    if (typeof firstImage === "string") {
      imageSource = firstImage;
    } else {
      imageSource = firstImage?.image;
    }

    entityProps = {
      image: imageSource,
      title: data?.title || "",
      subtitle: data ? getPaymentInfo(data, t) : "",
      background: props.background || theme.colors.statuses.default.secondary,
      isLoading: false,
      isDeleted: false,
    };
  }

  return <EntityPreview {...entityProps} />;
}
