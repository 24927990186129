import React, { memo } from "react";

import DatePicker from "./misc/WPDatePickerUtils";
import { InputWrapperProps } from "./InputWrapper";

type WPDatePickerProps<T> = Omit<InputWrapperProps<T>, "isFocused">;

function WPDatePicker(props: WPDatePickerProps<string>) {
  return <DatePicker {...props} />;
}

export default memo(WPDatePicker);
