import React from "react";
import { View, StyleSheet } from "react-native";
import WPIcon from "../Icon/WPIcon";
import WPText from "../Text/WPText";

export default function DescList(props: {
  list: Array<string | { text: string; isNegative: boolean }>;
}) {
  return (
    <View>
      {props.list.map((option) => (
        <View
          style={styles.cardOption}
          key={typeof option === "string" ? option : option.text}
        >
          <WPIcon
            style={{minWidth:12, minHeight:12}}
            name={
              typeof option === "string"
                ? "checkmark"
                : option.isNegative
                ? "close"
                : "checkmark"
            }
            color={
              typeof option !== "string" && option.isNegative
                ? "red"
                : undefined
            }
          />
          <WPText style={styles.cardOptionText}>
            {typeof option === "string" ? option : option.text}
          </WPText>
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  cardOption: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 12,
  },
  cardOptionText: {
    marginLeft: 8,
  },
});
