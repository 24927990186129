import React from "react";
import { defaultAuthScreenOptions, RouterType } from "../navigationConstants";
import SignInScreen from "./screens/SignInScreen";
import SignUpScreen from "./screens/SignUpScreen";
import { buildRouter, RouterConfig } from "../navigationHelpers";
import ForgotPassScreen from "./screens/ForgotPassScreen";
import NewPassScreen from "./screens/NewPassScreen";

const SIGN_IN = "SIGN_IN";
const SIGN_UP = "SIGN_UP";
const FORGOT_PASS_START = "FORGOT_PASS_START";
const NEW_PASS_SCREEN = "NEW_PASS_SCREEN";

function NewPass({
  route,
}: {
  route: {
    params: {
      uid: string;
      token: string;
    };
  };
}) {
  return <NewPassScreen uid={route.params?.uid} token={route.params?.token} />;
}

const AuthRouterConfig: RouterConfig = {
  type: RouterType.stack,
  containerConfig: {
    headerMode: "float",
    initialRouteName: SIGN_IN,
    screenOptions: { ...defaultAuthScreenOptions, barStyle: "dark-content" },
  },
  screens: {
    [SIGN_IN]: {
      name: SIGN_IN,
      component: SignInScreen,
    },
    [SIGN_UP]: {
      name: SIGN_UP,
      component: SignUpScreen,
    },
    [FORGOT_PASS_START]: {
      name: FORGOT_PASS_START,
      component: ForgotPassScreen,
    },
    [NEW_PASS_SCREEN]: {
      name: NEW_PASS_SCREEN,
      component: NewPass,
    },
  },
};

export default buildRouter(AuthRouterConfig);
