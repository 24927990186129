import React, { ReactNode, useMemo } from "react";
import { StyleSheet, View, Image, TouchableOpacity } from "react-native";
import theme from "../../utils/theme";
import WPButton from "../Button/WPButton";
import WPIcon, { IconName } from "../Icon/WPIcon";
import WPText from "../Text/WPText";
import WPMoreButton from "./WPMoreButton";

type SubtitleProps = {
  text: string;
  icon?: IconName;
  additionalNode?: ReactNode;
  isAdditional?: boolean;
  color?: string;
  isCompactView?: boolean;
  size?: number;
};

type Action = {
  text: string;
  label?: string;
  isButton?: boolean;
  isDisabled?: boolean;
  color?: string;
  onClick?: () => void;
};

type DataListItemProps = {
  title: string;
  onTitleClick: () => void;
  image?: {
    uri?: string;
    label?: string;
    color?: string;
    size?: "s" | "m" | "l" | "xl";
  };
  subtitle1?: SubtitleProps;
  subtitle2?: SubtitleProps;
  subtitle3?: SubtitleProps;
  actions?: Action[];
  sideNode?: ReactNode;
  additionalNode?: ReactNode;
  isCompactView?: boolean;
  status?: SubtitleProps;
  additionalAction?: {
    text: string;
    onClick: () => void;
    label?: string;
  };
};

const imageSizeMap = {
  s: 46,
  m: 65,
  l: 146,
  xl: 200,
};

function Subtitle(props: SubtitleProps) {
  const textStyle = useMemo(() => {
    let sizeStyle = props.isCompactView
      ? [styles.subtitle, styles.subtitleMobile]
      : [styles.subtitle];

    if (props.additionalNode) {
      //@ts-ignore
      sizeStyle = [{ marginRight: 8, maxWidth: "40%" }].concat(sizeStyle);
    }

    if (props.size) {
      sizeStyle = [{ fontSize: props.size }].concat(sizeStyle);
    }
    if (props.color) {
      //@ts-ignore
      return [{ color: props.color, fontFamily: "bold" }].concat(sizeStyle);
    }

    return sizeStyle;
  }, [props.isCompactView, props.color]);

  return (
    <View style={styles.subtitleContainer}>
      {props.icon ? (
        <View>
          <WPIcon
            name={props.icon}
            size={props.size ? props.size : props.isCompactView ? 12 : 14}
            color={props.color ? props.color : theme.colors.mains[1]}
          />
        </View>
      ) : null}
      <WPText style={textStyle} numberOfLines={1}>
        {props.text}
      </WPText>
      {props.additionalNode ? props.additionalNode : null}
    </View>
  );
}

export default function DataListItem(props: DataListItemProps) {
  const ImageContainerStyle = useMemo(() => {
    const sizeCode = props.image?.size || "m";
    const size = imageSizeMap[sizeCode];
    return [
      {
        height: size,
        width: size,
      },
      styles.imageContainer,
    ];
  }, [props.image?.size]);

  const titleStyle = useMemo(() => {
    return props.isCompactView
      ? [styles.title, styles.titleMobile]
      : styles.title;
  }, [props.isCompactView]);

  const mobileActions = useMemo(() => {
    if (props.actions) {
      return props.actions.map<{ label: string; onPress: () => void }>(
        (action) => ({
          label: action.text,
          isDisabled: action.isDisabled,
          onPress: action.onClick || console.log,
        })
      );
    }
    return null;
  }, [props.isCompactView, props.actions]);
  const subtitlesContainerStyle = useMemo(() => {
    return props.isCompactView
      ? styles.subtitlesContainerMobile
      : styles.subtitlesContainer;
  }, [props.isCompactView]);

  const labelStyle = useMemo(() => {
    return props.isCompactView
      ? [styles.imageLabel, styles.imageLabelCompact]
      : styles.imageLabel;
  }, [props.isCompactView]);

  return (
    <View style={styles.container}>
      <View style={styles.mainWrapper}>
        <View style={ImageContainerStyle}>
          {props.image?.uri ? (
            <Image source={{ uri: props.image?.uri }} style={styles.image} />
          ) : (
            <View style={styles.image}>
              <WPIcon name="camera" />
            </View>
          )}
          {props.image?.label ? (
            <WPText style={labelStyle}>{props.image.label}</WPText>
          ) : null}
        </View>
        <View style={styles.descriptionContainer}>
          <View style={styles.titleContainer}>
            <View style={styles.flex}>
              <TouchableOpacity
                onPress={props.onTitleClick}
                disabled={!props.onTitleClick}
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <WPText style={titleStyle} numberOfLines={1}>
                  {props.title}
                </WPText>
                {props.status && !props.isCompactView ? (
                  <Subtitle {...props.status} isCompactView={false} />
                ) : null}
              </TouchableOpacity>
              {props.status || props.subtitle1 ? (
                <View style={subtitlesContainerStyle}>
                  <View style={styles.subtitles}>
                    {props.status && props.isCompactView ? (
                      <Subtitle
                        {...props.status}
                        isCompactView={props.isCompactView}
                      />
                    ) : (
                      <>
                        {props.subtitle1 ? (
                          <Subtitle
                            {...props.subtitle1}
                            isCompactView={props.isCompactView}
                          />
                        ) : null}
                        {props.subtitle2 ? (
                          <Subtitle
                            {...props.subtitle2}
                            isCompactView={props.isCompactView}
                          />
                        ) : null}
                      </>
                    )}
                  </View>
                  {props.subtitle3 && !props.status ? (
                    <Subtitle
                      {...props.subtitle3}
                      isCompactView={props.isCompactView}
                    />
                  ) : null}
                </View>
              ) : null}
              {props.additionalAction && props.isCompactView ? (
                <TouchableOpacity
                  onPress={props.additionalAction.onClick}
                  style={{ alignSelf: "flex-start", position: "relative" }}
                >
                  <WPText style={styles.link}>
                    {props.additionalAction.text}
                  </WPText>
                  {props.additionalAction.label ? (
                    <View style={styles.actionLabel}>
                      <WPText style={styles.actionLabelText}>
                        {props.additionalAction.label}
                      </WPText>
                    </View>
                  ) : null}
                </TouchableOpacity>
              ) : null}
            </View>
            {mobileActions?.length && props.isCompactView ? (
              <View style={styles.moreButton}>
                <WPMoreButton options={mobileActions} />
              </View>
            ) : null}
          </View>
          <View>
            {props.actions && !props.isCompactView ? (
              <View style={styles.actionButton}>
                {props.actions.map((action, actionIdx) => (
                  <React.Fragment key={action.text}>
                    <WPText
                      style={
                        action.isDisabled
                          ? styles.actionDisabled
                          : action.isButton
                          ? styles.actionRowButton
                          : styles.action
                      }
                      onPress={action.isDisabled ? undefined : action.onClick}
                    >
                      {action.text}
                      {action.label ? (
                        <WPText style={styles.labelText}>{action.label}</WPText>
                      ) : null}
                    </WPText>
                    {props.actions &&
                    actionIdx !== props.actions?.length - 1 ? (
                      <WPText style={styles.actionSeparator}>|</WPText>
                    ) : null}
                  </React.Fragment>
                ))}
              </View>
            ) : null}
            {props.additionalNode && !props.isCompactView ? (
              <View style={styles.additionalNode}>
                {props.additionalNode}
                {props.additionalAction ? (
                  <WPButton
                    style={styles.additionalNodeAction}
                    appearance="outlined"
                    onPress={props.additionalAction.onClick}
                  >
                    {props.additionalAction?.text}
                  </WPButton>
                ) : null}
              </View>
            ) : null}
          </View>
        </View>
      </View>
      {props.additionalNode && props.isCompactView ? (
        <View style={styles.additionalNodeCompact}>{props.additionalNode}</View>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: theme.button.borderRadius,
    padding: theme.button.borderRadius,
    backgroundColor: theme.colors.mains[6],
    position: "relative",
    overflow: "hidden",
  },
  link: {
    fontSize: 12,
    color: theme.colors.primary,
    fontFamily: "bold",
    marginTop: 4,
    alignSelf: "flex-start",
  },
  mainWrapper: {
    flexDirection: "row",
  },
  imageContainer: {
    borderRadius: theme.button.borderRadius,
    marginRight: 16,
    overflow: "hidden",
    position: "relative",
  },
  image: {
    flex: 1,
    backgroundColor: theme.colors.mains[4],
    alignItems: "center",
    justifyContent: "center",
  },
  imageLabel: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    color: theme.colors.white,
    backgroundColor: "rgba(0,0,0,.2)",
    textAlign: "center",
    paddingVertical: 4,
  },
  imageLabelCompact: {
    fontSize: 10,
  },
  descriptionContainer: {
    flex: 1,
    justifyContent: "space-between",
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flex: {
    flex: 1,
  },
  sideNode: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  title: {
    fontSize: 16,
    fontFamily: "bold",
    color: theme.colors.primary,
    marginBottom: 8,
  },
  titleMobile: {
    fontSize: 14,
    fontFamily: "bold",
    color: theme.colors.primary,
    marginBottom: 0,
  },
  subtitlesContainer: {
    flexDirection: "row",
  },
  subtitlesContainerMobile: {
    flexDirection: "column",
    marginTop: 4,
  },
  subtitles: {
    flexDirection: "row",
  },
  subtitleContainer: {
    maxWidth: "100%",
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: "flex-start",
    marginRight: 12,
    marginBottom: 4,
    overflow: "hidden",
  },
  subtitle: {
    fontSize: 12,
  },
  subtitleMobile: {
    fontSize: 10,
  },
  moreButton: {
    marginTop: 4,
    alignSelf: "flex-start",
  },
  additionalSubtitle: {},
  additionalSubtitleIcon: {},
  actionButton: {
    flexDirection: "row",
    marginBottom: 12,
  },
  action: {
    fontSize: 12,
    color: theme.colors.primary,
  },
  actionRowButton: {
    fontSize: 12,
    color: theme.colors.primary,
    borderRadius: 4,
    borderColor: theme.colors.primary,
    borderWidth: 1,
    paddingHorizontal: 10,
    position: "relative",
  },

  labelText: {
    position: "absolute",
    color: theme.colors.white,
    fontSize: 10,
    fontFamily: "bold",
    borderRadius: 7,
    height: 14,
    minWidth: 14,
    textAlign: "center",
    backgroundColor: theme.colors.warning,
    top: -7,
    right: -7,
  },
  actionLabel: {
    position: "absolute",
    borderRadius: 7,
    height: 12,
    minWidth: 12,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.warning,
    top: 3,
    right: -12,
  },
  actionLabelText: {
    color: theme.colors.white,
    fontFamily: "bold",
    fontSize: 8,
    lineHeight: 8,
  },
  actionDisabled: {
    fontSize: 12,
    color: theme.colors.mains[4],
  },
  actionSeparator: {
    fontSize: 12,
    marginHorizontal: 8,
  },
  additionalNodeCompact: {
    marginTop: 8,
    flex: 1,
  },
  additionalNode: {
    flexDirection: "row",
  },
  additionalNodeAction: {
    marginLeft: 12,
  },
});
