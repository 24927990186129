import React, { useMemo } from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";
import theme, { getThemeResponsiveValue } from "../../utils/theme";

function renderChildren(children: React.ReactNode, forceRow?: boolean) {
  const totalChildren = React.Children.count(children);
  return React.Children.map(children, (child, idx) => (
    <>
      {child}
      {idx < totalChildren - 1 && (
        <View style={forceRow ? styles.spacerForced : styles.spacer} />
      )}
    </>
  ));
}

const FormRow: React.FC<{
  style?: StyleProp<ViewStyle>;
  forceRow?: boolean;
}> = (props) => {
  const rowStyle = useMemo(
    () => [styles.container, props.forceRow && styles.forced, props.style],
    [props.forceRow]
  );

  return (
    <View style={rowStyle}>
      {renderChildren(props.children, props.forceRow)}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: getThemeResponsiveValue(
      theme.formRow.flexDirection
    ) as "row",
    alignItems: "stretch",
  },
  forced: {
    flexDirection: "row",
  },
  spacer: {
    width: getThemeResponsiveValue(theme.formRow.spacer.width),
    marginBottom: getThemeResponsiveValue(theme.formRow.spacer.marginBottom),
  },
  spacerForced: {
    ...theme.formRow.spacerForced,
  },
});

FormRow.displayName = "FormRow";

export default FormRow;
