import {
  resetPasswordStart,
  resetPasswordFinish,
} from "../../../../services/apis/authApi";
import { useState } from "react";
import { ErrorResponse } from "../../../../utils/wpRequest";

export const useForgotPassRequest = (): [
  boolean,
  boolean,
  ErrorResponse,
  (email: string) => void
] => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [errors, setErrors] = useState<ErrorResponse>({});

  const send = async (email: string) => {
    setIsLoading(true);

    try {
      await resetPasswordStart(email);
      setIsSent(true);
    } catch (err) {
      setErrors(err);
    }

    setIsLoading(false);
  };

  return [isLoading, isSent, errors, send];
};

export const useNewPassRequest = (): [
  boolean,
  boolean,
  ErrorResponse,
  (newPass: string, token: string, uuid: string) => void
] => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [errors, setErrors] = useState<ErrorResponse>({});

  const send = async (password: string, token: string, uuid: string) => {
    setIsLoading(true);

    try {
      await resetPasswordFinish({
        password,
        token,
        uuid,
      });
      setIsSent(true);
    } catch (err) {
      setErrors(err);
    }

    setIsLoading(false);
  };

  return [isLoading, isSent, errors, send];
};
