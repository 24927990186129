import appCache from "../../../services/appCache";
// import Constants from "expo-constants";
import { navigateTo } from "../../navigationHelpers";
import config from "../../../services/config";

// const { TOKEN_KEY = "", REFRESH_TOKEN_KEY = "" } = Constants.manifest.extra;
const { TOKEN_KEY = "", REFRESH_TOKEN_KEY = "" } = config;

export const useSignOut = (): ((onDone: () => void) => void) => {
  return (onDone) => {
    appCache.setValue(TOKEN_KEY, "");
    appCache.setValue(REFRESH_TOKEN_KEY, "");
    appCache.setValue("NEWS", "");
    appCache.setValue("WP_STORE", "");

    navigateTo("AUTH.SIGN_IN");

    onDone();
  };
};
