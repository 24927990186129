import React, { memo } from "react";
import { StyleSheet, View } from "react-native";
import WPText from "../Text/WPText";
import theme, { getThemeResponsiveValue } from "../../utils/theme";
import WPIcon from "../Icon/WPIcon";
import { getNormalizedDate } from "../../utils/mixUtils";
import { useGetJob } from "../../services/hooks/jobHooks";
import MobileListTile from "../misc/MobileListTile";
import { useRenderJobOfferTile } from "../../navigation/crm/job/jobUtils";

type WPJobTileProps = {
  jobId: number;
};

function WPJobTile(props: WPJobTileProps) {
  const job = useGetJob(props.jobId);
  const renderItem = useRenderJobOfferTile({
    isCompactView: true,
    hideStatistics: true,
  });

  if (!job || !("data" in job)) {
    return null;
  }

  const jobData = job.data;

  if (!jobData) {
    return null;
  }

  const baseColor = theme.colors.mains[3];

  return renderItem(jobData, () => {});

  // <MobileListTile
  //   title={jobData.title}
  //   subtitle={jobData.company_name}
  //   location={jobData.city.name}
  //   firstDesc={
  //     <View style={styles.container}>
  //       <WPIcon
  //         size={20}
  //         name="users"
  //         style={styles.userIcon}
  //         color={userColor}
  //       />
  //       <WPText style={styles.text}>
  //         {`${jobData.hired_count}/${jobData.position_count}`}
  //       </WPText>
  //     </View>
  //   }
  //   secondDesc={
  //     <View style={styles.container}>
  //       <WPIcon name="calendar" style={styles.userIcon} color={baseColor} />
  //       <WPText style={styles.text}>
  //         {`${t("labels.from")} ${getNormalizedDate(
  //           jobData.contract_start_date,
  //           i18n.language
  //         )}`}
  //       </WPText>
  //     </View>
  //   }
  // />
  // );
}

export default memo(WPJobTile);

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },

  userIcon: {
    marginRight: 8,
  },
  text: {
    fontSize: getThemeResponsiveValue(theme.button.fontSize) - 2,
  },
});
