import React from "react";
import Svg, { G, Circle, Path } from "react-native-svg";
import { WPIconProps } from "../WPIcon";

const ChangeStatusIcon: React.FC<WPIconProps> = (props) => {
  let height = 19;
  let width = 19;
  const ratio = width / height;

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg viewBox="0 0 19 19" width={width} height={height}>
      <G fill="none" fillRule="evenodd" transform="translate(0 .5)">
        <Circle cx={9.5} cy={9.5} r={9.5} fill="#FF8200" />
        <Circle cx={9.5} cy={9.5} r={5} fill="#FFF" stroke="#FFF" />
        <Path stroke="#FF8200" d="M9.5 6.761v3.033l1.517 1.517" />
      </G>
    </Svg>
  );
};

export default ChangeStatusIcon;
