import React from "react";
import Svg, { Path, G } from "../SVG";
import { IconProps } from "../WPIcon";

const SendEmailIcon: React.FC<IconProps> = (props) => {
  let height = 102;
  let width = 111;
  const ratio = width / height;
  const color = props.color || "#09F";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg
      {...props}
      width={width}
      height={height}
      style={[{ height, width }, props.style]}
      viewBox={`0 0 ${width} ${height}`}
    >
      <G
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <Path d="M91.749 50.186V94.73c0 3.006-2.415 5.445-5.42 5.445H25.55a5.446 5.446 0 01-5.445-5.445V50.186" />
        <Path d="M91.749 90.639v4.09c0 .196 0 .371-.025.568a8.26 8.26 0 01-.224 1.057 5.544 5.544 0 01-1.353 2.22 5.431 5.431 0 01-3.843 1.599H25.55a5.445 5.445 0 01-5.445-5.445v-4.09l12.267-5.294 16.433-7.073 4.311-1.872a7.178 7.178 0 015.62 0l4.311 1.872 16.433 7.073 12.269 5.295zm-8.674-31.978l8.675-8.475-8.675-7.637M38.903 33.63L52.65 21.51a4.955 4.955 0 016.553 0L72.95 33.63m-44.174 8.919l-8.672 7.637 8.672 8.475" />
        <Path d="M72.951 33.63H28.775v25.031l20.03 19.61 4.311-1.872a7.172 7.172 0 015.617 0l4.312 1.873 20.03-19.611V33.63H72.95zM20.105 50.186h8.67m62.974 0h-8.67m-48.832-8.967H77.77M34.247 51.483h34.284m-34.284 5.749h10.059m-10.059-10.88h20.118" />
        <Path
          d="M12.045 39.322H1.22m13.501-25.471l7.893 5.992m75.813 19.479h10.826m-15.12-25.471l-7.894 5.992M55.236 9.613V1.222"
          opacity={0.5}
        />
      </G>
    </Svg>
  );
};

SendEmailIcon.displayName = "SendEmailIcon";

export default SendEmailIcon;
