import { createReducer, action } from "deox";
import i18n from "../../../../utils/i18n";
import {
  receivedEmployees,
  receivedDocuments,
} from "../../actions/employeeActions";

export type Employee = {
  id: number;
  first_name: string;
  language: string;
  last_name: string;
  phone_number: string;
  employee: number; // id;
  locations: [];
  skill_list: [];
  location_list: [];
  is_completed: false;
  citizenship: "UA";
  visa_type: "WV";
  gender: "M";
  age: "Y";
  employment: "P";
  last_job_position: "";
  photo: string;
  skills: [];
};

export type Document = {
  date_created: string;
  file: string;
};

export type Note = {
  id: number;
  body: string;
  date_created: string;
  date_updated: string;
  employee: number;
  employer: number;
};

type employeeState = {
  [key: number]: {
    isLoading: boolean;
    baseData: Employee;
    documents: Document[] | undefined;
    notes: Note[];
    lastRefreshed: Date;
  };
};

const defaultState: employeeState = {};

const employeeReducer = createReducer(defaultState, (handleAction) => [
  handleAction(receivedEmployees, (state, action) => {
    const nextState = { ...state };

    action.payload.forEach((employee) => {
      const data = nextState[employee.id] || {
        isLoading: false,
        baseData: employee,
        notes: [],
        lastRefreshed: new Date(),
      };

      data.baseData = employee;

      nextState[employee.id] = data;
    });

    return nextState;
  }),
  handleAction(receivedDocuments, (state, action) => ({
    ...state,
    [action.payload.employeeId]: {
      ...state[action.payload.employeeId],
      documents: action.payload.documents,
    },
  })),
]);

export default employeeReducer;

export const getEmployeeBaseData = (state: employeeState, id: number) =>
  state[id]?.baseData;

export const getEmployeeDisplayName = (data: {
  first_name?: string;
  last_name?: string;
  phone_number?: string;
}) => {
  let displayName = "";

  if (data?.first_name) {
    displayName += data.first_name;
  }

  if (data?.last_name) {
    displayName += ` ${data.last_name}`;
  }

  if (displayName) {
    return displayName;
  }

  if (!displayName && data?.phone_number) {
    displayName = data.phone_number;
    return displayName;
  }

  displayName = i18n.t("missing_name");

  return displayName;
};
