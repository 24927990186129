import React, {
  useState,
  useEffect,
  ComponentType,
  useCallback,
  memo,
} from "react";
// import Constants from "expo-constants";
import {
  NavigationContainerRef,
  DrawerActions,
  NavigationState,
} from "@react-navigation/native";
import { NAVIGATION_CACHE_KEY, RouterType } from "./navigationConstants";
import appCache from "../services/appCache";
import { CRMPermissions, getCRMPermissions } from "../services/permissions";
import { createStackNavigator } from "@react-navigation/stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import {
  SharedElement,
  createSharedElementStackNavigator,
} from "react-navigation-shared-element";
import theme, { isMobileView, getHeaderHeight } from "../utils/theme";
import UIExtras from "../components/UIExtras";
import { useGetNews, useGetNewsConfig } from "../services/hooks/newsHook";
import WPText from "../components/Text/WPText";
import WPIcon from "../components/Icon/WPIcon";
import { useTranslation } from "react-i18next";
import CRMDrawerContent from "./crm/common/CRMDrawerContent";
import config from "../services/config";
import i18n from "../utils/i18n";

export const navigationRef = React.createRef<NavigationContainerRef>();

export const goBack = (fallBackRoute: string) => {
  const state = navigationRef.current?.dangerouslyGetState();

  let prevRouteName = "";

  if (state?.routes.length) {
    prevRouteName = state.routes[0].name;
  }

  if (navigationRef.current?.canGoBack() && prevRouteName !== "AUTH") {
    navigationRef.current?.goBack();
  } else {
    navigateTo(fallBackRoute);
  }
};

export const useRedirect = async () => {
  const redirectUrl = await appCache.getRedirectValue();
  if (redirectUrl) {
    const normalizedRedirectUrl = redirectUrl.substr(1).replace(/\//gm, ".");
    navigateTo(normalizedRedirectUrl);
    await appCache.setRedirectValue("");
  } else {
    navigateTo("CRM");
  }
};

export const navigate = (name: string, params?: any) =>
  navigationRef.current?.navigate(name, params);

export const toggleDrawer = () =>
  navigationRef.current?.dispatch(DrawerActions.toggleDrawer());

export const saveNavigationState = (state: NavigationState) =>
  appCache.setValue(NAVIGATION_CACHE_KEY, JSON.stringify(state));

export const getNavigationState = async (): Promise<
  NavigationState | undefined
> => {
  try {
    const savedState = await appCache.getValue(NAVIGATION_CACHE_KEY);

    if (!savedState) {
      return undefined;
    }

    return JSON.parse(savedState);
  } catch (err) {
    return undefined;
  }
};

/**
 * returns object that matches WPLinkButton nav props
 * @param path like CRM.JOBS.DETAILS?jobId=123&mode=edit.TEST.
 */
export const navigateTo = (path: string) => {
  type Route = {
    params: Route;
    [key: string]: any;
    screen?: string;
  };

  const [firstRoute, ...routeParts] = path.split(".");

  const navConfig =
    routeParts &&
    routeParts.reduce<Route | undefined>((config, route) => {
      const [routeName, routeParams] = route.split("?");

      const routeConfig = {
        screen: routeName,
        params: {},
      } as Route;

      if (routeParams) {
        routeParams.split("&").forEach((param) => {
          const [paramName, paramValue] = param.split("=");
          routeConfig.params[paramName] = paramValue;
        });
      }

      if (config) {
        config.params = routeConfig;
        return config;
      }

      return routeConfig;
    }, undefined);

  if (firstRoute) {
    navigate(firstRoute, navConfig);
  }
};

export const redirectToSignIn = () => navigateTo("AUTH.SIGN_IN");

export const withAuth = (Screen: React.ComponentType) => () => {
  const [crmPermissions, setCrmPermissions] = useState<CRMPermissions>();

  useEffect(() => {
    if (!crmPermissions) {
      getCRMPermissions()
        .then((permissions) => {
          if (!permissions.isAuthenticated) {
            return redirectToSignIn();
          }

          setCrmPermissions(permissions);
        })
        .catch(() => {
          redirectToSignIn();
        });
    }
  }, [crmPermissions]);

  if (!crmPermissions) {
    return null;
  }

  return <Screen />;
};

function SideBar() {
  const [isFocused, setIsFocused] = useState(false);
  const close = useCallback(() => setIsFocused(false), []);
  const open = useCallback(() => setIsFocused(true), []);
  return (
    <View
      style={isFocused ? styles.sideBarFocused : undefined}
      onMouseEnter={open}
      onMouseLeave={close}
    >
      <CRMDrawerContent isVisible={isFocused} />
    </View>
  );
}

export function withScreenWrapper<T>(
  ScreenComponent: ComponentType<T>,
  hideSideBar?: boolean
) {
  function ScreenContainer(props: T) {
    const [areNewsSeen, closeNews] = useGetNews(
      // Number(Constants.manifest.extra.NEWS_ID || 1)
      Number(config.NEWS_ID)
    );

    const newsConfig = useGetNewsConfig();

    const { t } = useTranslation();
    const navigateToNews = useCallback(() => {
      navigateTo("CRM.JOB.NEWS");
    }, []);

    const newsTitle =
      newsConfig && newsConfig[i18n.language]
        ? newsConfig[i18n.language].title
        : null;

    return (
      <View style={styles.screen}>
        <View style={styles.screenContainer}>
          {isMobileView() || hideSideBar ? null : <SideBar />}
          <View style={styles.screen}>
            {areNewsSeen || !newsTitle ? null : (
              <View style={styles.banner}>
                <View style={styles.bannerTextContainer}>
                  <WPText style={styles.bannerText}>
                    {/* <WPText style={styles.attention}>
                      {t("news.attention") + " "}
                    </WPText> */}
                    {newsTitle}
                  </WPText>
                  <WPText style={styles.bannerLink} onPress={navigateToNews}>
                    {t("misc.follow_link")}
                  </WPText>
                </View>
                <TouchableOpacity style={styles.close} onPress={closeNews}>
                  <WPIcon name="close" color="black" />
                </TouchableOpacity>
              </View>
            )}
            <View style={styles.screenContainer}>
              <View style={styles.screenComponent}>
                <ScreenComponent {...props} />
              </View>
              <SharedElement id="uiExtras">
                <UIExtras />
              </SharedElement>
            </View>
          </View>
        </View>
      </View>
    );
  }
  return memo(ScreenContainer);
}

const styles = StyleSheet.create({
  sideBarFocused: {
    width: 300,
  },
  screen: { flex: 1 },
  screenContainer: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: theme.colors.white,
  },
  screenComponent: {
    flex: 1,
  },
  banner: {
    position: "relative",
    flexDirection: "row",
    backgroundColor: theme.colors.primaries[4],
    justifyContent: "space-between",
    minHeight: getHeaderHeight(),
    // alignItems: "center",
  },
  bannerTextContainer: {
    flex: 1,
    paddingVertical: 4,
    paddingHorizontal: 16,
    alignItems: isMobileView() ? "flex-start" : "center",
    justifyContent: "center",
    flexDirection: isMobileView() ? "column" : "row",
  },
  bannerText: {
    alignItems: "center",
  },
  attention: {
    fontFamily: "bold",
    textTransform: "uppercase",
  },
  bannerLink: {
    color: theme.colors.primary,
    textDecorationLine: "underline",
  },
  close: {
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
  },
});

const RegularStack = createStackNavigator();
const SharedStack = createSharedElementStackNavigator();
const Drawer = createDrawerNavigator();

type StackContainerOptions = Omit<
  React.ComponentProps<typeof RegularStack.Navigator>,
  "children"
>;
type StackScreenOptions = React.ComponentProps<typeof RegularStack.Screen>;
type DrawerContainerOptions = Omit<
  React.ComponentProps<typeof Drawer.Navigator>,
  "children"
>;
type DrawerScreenOptions = React.ComponentProps<typeof Drawer.Screen>;

export type RouterConfig =
  | {
      type: RouterType.stack;
      containerConfig: StackContainerOptions;
      isShared?: boolean;
      screens: {
        [key: string]: StackScreenOptions;
      };
    }
  | {
      type: RouterType.drawer;
      containerConfig: DrawerContainerOptions;
      screens: {
        [key: string]: DrawerScreenOptions;
      };
    };

const sharedElements = () => ["uiExtras"];

export const buildRouter = (config: RouterConfig) => {
  switch (config.type) {
    case RouterType.stack:
      return () => {
        const Stack = config.isShared
          ? // @ts-ignore: createSharedElementStackNavigator typing issue
            SharedStack
          : RegularStack;
        // const Stack = RegularStack;

        return (
          // <View style={styles.screenComponent}>
          <Stack.Navigator {...config.containerConfig}>
            {Object.keys(config.screens).map((screenName) => (
              // @ts-ignore
              <Stack.Screen
                {...config.screens[screenName]}
                key={screenName}
                sharedElements={sharedElements}
              />
            ))}
          </Stack.Navigator>
          // </View>
        );
      };

    case RouterType.drawer:
      return () => {
        const Drawer = createDrawerNavigator();

        return (
          <Drawer.Navigator {...config.containerConfig}>
            {Object.keys(config.screens).map((screenName) => (
              <Drawer.Screen {...config.screens[screenName]} key={screenName} />
            ))}
          </Drawer.Navigator>
        );
      };
    default:
      return () => null;
  }
};
