import {
  Conversation,
  ConversationType,
  ConversationMessage,
  getMessage,
  setSeenBy,
  sendMessage,
} from "../../../../services/chatService";
import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../services/store/reducers/mainReducer";
import { logToConsole, logError } from "../../../../services/logger";
import {
  gotMessage,
  openConversation,
} from "../../../../services/store/actions/chatActions";
import { navigateTo } from "../../../navigationHelpers";
import { isMobileView } from "../../../../utils/theme";
import { BaseChatData } from "../../../../services/store/reducers/chat/chatReducer";

export const useUserChats = (userId: number) => {
  const userChats = useSelector((state: RootState) =>
    state.chat.chatOrder.find((orderData) => orderData.userId === userId)
  );

  if (userChats) {
    return userChats.chats;
  }

  return undefined;
};

export const useOpenChat = () => {
  const dispatch = useDispatch();
  const chats = useSelector((state: RootState) => state.chat.chats);
  const loggedInUser = useSelector((state: RootState) => state.auth.userId);

  return (chatId: string, userId: number) => {
    dispatch(openConversation(chatId));

    if (isMobileView()) {
      navigateTo(`CRM.JOB.CONVERSATION?userId=${userId}`);
    }

    const conversation = chats[chatId];

    if (conversation && loggedInUser) {
      const lastMessage =
        conversation.messages[conversation.messages.length - 1];

      if (lastMessage !== conversation.messages_seen_map?.[loggedInUser]) {
        setSeenBy(loggedInUser, lastMessage, conversation.id);
      }
    }
  };
};

export const useOpenChatByChatType = (chatGroup: ConversationType) => {
  const openChat = useOpenChat();
  const chatOrder = useSelector((state: RootState) => state.chat.chatOrder);

  const openChatByChatType = useCallback(
    (userId: number) => {
      const userChatId = chatOrder.find((chat) => chat.userId === userId)
        ?.chats[chatGroup];

      if (userChatId) {
        openChat(userChatId, userId);
      }
    },
    [useSelector, openChat]
  );

  return openChatByChatType;
};

export const getActiveConversationType = () =>
  useSelector((state: RootState) => {
    if (state.chat.activeConversationId) {
      return state.chat.chats[state.chat.activeConversationId].group;
    }
    return ConversationType.JOB;
  });

export const getUserConversationGroups = (): BaseChatData | undefined => {
  const activeConversationId = useSelector(
    (state: RootState) => state.chat.activeConversationId
  );

  return useSelector((state: RootState) => {
    const chatConfig = state.chat.chatOrder.find(
      (chatConfig) =>
        chatConfig.chats.estate === activeConversationId ||
        chatConfig.chats.job === activeConversationId
    );

    return chatConfig || undefined;
  });
};

export const getActiveConversation = (): Conversation | undefined => {
  const activeConversationId = useSelector(
    (state: RootState) => state.chat.activeConversationId
  );
  const [conversation, setConversation] = useState<Conversation | undefined>(
    undefined
  );

  useSelector((state: RootState) => {
    if (
      activeConversationId &&
      conversation !== state.chat.chats[activeConversationId]
    ) {
      setConversation(state.chat.chats[activeConversationId]);
    }
  });

  return conversation;
};

export const useGetMessage = (
  messageId: string
): [ConversationMessage | undefined, string | undefined] => {
  const message = useSelector(
    (state: RootState) => state.chat.messages[messageId]
  );

  const userData = useSelector((state: RootState) => {
    if (message) {
      return state.employee[Number(message.employees[0])];
    }
    return undefined;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!message && messageId) {
      getMessage(messageId)
        .then((loadedMessage) => {
          if (loadedMessage) {
            dispatch(gotMessage(loadedMessage));
          }
        })
        .catch(logError);
    }
  }, [message, messageId]);

  return [message, userData?.baseData?.photo];
};

export const useSendMessageByTypeByUser = (
  userId: number,
  type: ConversationType
) => {
  const conversation = useSelector((state: RootState) => {
    const userChatData = state.chat.chatOrder.find(
      (data) => data.userId === userId
    );

    if (!userChatData || !userChatData.chats[type]) {
      return undefined;
    }

    const conversationId = userChatData.chats[type];

    if (!conversationId) {
      return undefined;
    }

    return state.chat.chats[conversationId];
  });

  return (
    payload: string | number,
    type: "text" | ConversationType,
    extraData?: { [key: string]: any }
  ) => {
    if (conversation) {
      sendMessage(payload, type, conversation, extraData);
    }
  };
};
