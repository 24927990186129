import WPFirebase from "../firebase";
import "firebase/analytics";
import { FirebaseAnalytics } from "./firebaseAnalytics.types";

WPFirebase.analytics();

const firebaseAnalytics: FirebaseAnalytics = {
  logEvent: (eventName: string) => {
    WPFirebase.analytics().logEvent(eventName);
  },

  logPurchase: (amount) => {
    WPFirebase.analytics().logEvent("BIZ_purchase", {
      amount,
    });
  },
  setScreen: (screenName: string) => {
    WPFirebase.analytics().setCurrentScreen(screenName);
  },
};

export default firebaseAnalytics;
