import { createReducer } from "deox";
import { JobOffer } from "../../../apis/jobApi";
import {
  setJobPreview,
  setUserPreviewId,
  openActionModal,
  closeActionModal,
} from "../../actions/uiActions";
import {
  closeConversation,
  scheduleListUpdate,
  dismissListUpdate,
} from "../../actions/chatActions";
import { ImageStyle, StyleProp } from "react-native";

type authState = {
  jobPreviewData: Partial<JobOffer> | undefined;
  userPreviewId: number | undefined;
  shouldUpdateList: boolean;
  actionModal?: {
    title: string;
    description: string;
    options?: string[];
    onClose: () => void;
    buttonLabel: string;
    customImageStyle?: StyleProp<ImageStyle>;
    image: string;
  };
};

const defaultState: authState = {
  jobPreviewData: undefined,
  userPreviewId: undefined,
  shouldUpdateList: false,
  actionModal: undefined,
};

const uiReducer = createReducer(defaultState, (handleAction) => [
  handleAction(setJobPreview, (state, action) => ({
    ...state,
    jobPreviewData: action.payload,
  })),
  handleAction(setUserPreviewId, (state, action) => ({
    ...state,
    userPreviewId: action.payload,
  })),
  handleAction(closeConversation, (state, action) => ({
    ...state,
    userPreviewId: undefined,
  })),
  handleAction(scheduleListUpdate, (state, action) => ({
    ...state,
    shouldUpdateList: true,
  })),
  handleAction(dismissListUpdate, (state, action) => ({
    ...state,
    shouldUpdateList: false,
  })),
  handleAction(openActionModal, (state, action) => ({
    ...state,
    actionModal: action.payload,
  })),
  handleAction(closeActionModal, (state, action) => ({
    ...state,
    actionModal: undefined,
  })),
]);

export default uiReducer;
