import { createActionCreator } from "deox";
import { resolve } from "react-native-svg/src/lib/resolve";
import { AccountInfoCallData } from "../../apis/authApi";

export const signedInAction = createActionCreator(
  "SIGN_IN_SUCCESS",
  (resolve) => (authValues: {
    userId: number;
    userName: string;
    avatar: string;
    email: string;
    company: string;
  }) => resolve(authValues)
);

export const profileInfoAction = createActionCreator(
  "GOT_PROFILE_INFO",
  (resolve) => (profile: AccountInfoCallData) => resolve(profile)
);

export const signedOutAction = createActionCreator("SIGN_OUT_SUCCESS");
