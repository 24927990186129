import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";
import theme from "../../../utils/theme";

const StarIcon: React.FC<IconProps> = (props) => {
  let height = 11;
  let width = 11;
  const ratio = width / height;
  const color = props.color || "#fff";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 11 11" {...props}>
      <Path
        d="M10.984 4.238a.322.322 0 00-.26-.22l-3.41-.495L5.789.433a.322.322 0 00-.578 0l-1.525 3.09-3.41.496a.322.322 0 00-.179.55l2.468 2.405-.583 3.396a.322.322 0 00.468.34L5.5 9.105l3.05 1.604a.322.322 0 00.468-.34l-.583-3.396 2.468-2.406a.322.322 0 00.081-.33z"
        fill={color}
      />
    </Svg>
  );
};

StarIcon.displayName = "StarIcon";

export default StarIcon;
