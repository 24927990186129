import React from "react";
import {
  HeaderStyleInterpolators,
  StackNavigationOptions,
} from "@react-navigation/stack";
import Constants from "expo-constants";
import { StyleSheet, Platform } from "react-native";
import theme, { getHeaderHeight, isMobileView } from "../utils/theme";
import HeaderLogo from "./common/HeaderLogo";
import DrawerToggle from "./common/DrawerToggle";
import ChatToggle from "./common/ChatToggle";
import WPLanguageSelect from "../components/input/components/misc/WPLanguageSelect";

export enum RouterType {
  stack = "stack",
  drawer = "drawer",
}

export const NAVIGATION_CACHE_KEY = "NAVIGATION_STATE";

const headerHeight = getHeaderHeight() + Number(Constants.statusBarHeight);

const styles = StyleSheet.create({
  header: {
    backgroundColor: theme.colors.white,
    height: headerHeight,
  },
  headerCRM: {
    backgroundColor: theme.colors.main,
  },
  headerTitleContainerStyle: {
    alignItems: "center",
    justifyContent: "center",
    position: undefined,
    left: 0,
    flex: 1,
  },
  card: {
    flex: 1,
  },
  headerAuth: {
    borderStyle: undefined,
    borderBottomColor: "transparent",
    backgroundColor: "transparent",
  },
});

export const defaultAuthScreenOptions: StackNavigationOptions = {
  gestureEnabled: false,
  headerStyleInterpolator: HeaderStyleInterpolators.forNoAnimation,
  headerTitle: () => null,
  headerLeft: () => <HeaderLogo size={isMobileView() ? 25 : undefined} />,
  headerRight: () => <WPLanguageSelect />,
  headerStyle: styles.headerAuth,
  headerTitleContainerStyle: styles.headerTitleContainerStyle,
  cardStyle: styles.card,
};

export const defaultCRMScreenOptions = {
  ...defaultAuthScreenOptions,
  animationEnabled: Platform.OS === "web" ? false : true,
  headerStyle: [styles.header, styles.headerCRM],
  headerTitle: () => <HeaderLogo color="#fff" />,
  headerLeft: () => <DrawerToggle color="#fff" />,
  headerRight: () => <ChatToggle color="#fff" />,
};
