import { chatRef, messageRef, fireBaseArrayUnion } from "./firebaseFirestore";
import WPFirebase from "./firebase";
import { logError } from "./logger";
import analytics from "./analytics/analytics";

export type ConversationChanges = {
  added: Conversation[];
  modified: Conversation[];
  removed: Conversation[];
};

type ConversationHandler = (changes: ConversationChanges) => void;

export class ConversationListener {
  private listener: () => void;

  constructor(private onChange: ConversationHandler, userId: number) {
    this.listener = chatRef
      .where("owner", "==", userId)
      .onSnapshot((snap) => this.handleChanges(snap, userId));
  }

  remove = () => this.listener();

  private handleChanges = (
    snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
    userId: number
  ) => {
    const changes: ConversationChanges = {
      added: [],
      modified: [],
      removed: [],
    };

    snapshot.docChanges().forEach(function (change) {
      changes[change.type].push({
        ...(change.doc.data() as Conversation),
        id: change.doc.id,
      });
    });

    this.onChange(changes);
  };
}

export const getMessage = async (
  messageId: string
): Promise<ConversationMessage | undefined> => {
  const messageDoc = await messageRef.doc(messageId).get();

  if (messageDoc.exists) {
    return {
      id: messageId,
      ...messageDoc.data(),
    } as ConversationMessage;
  }

  return undefined;
};

export async function setSeenBy(
  seenById: number,
  messageId: string,
  conversationId: string
) {
  return chatRef
    .doc(conversationId)
    .update({
      last_modified_at: new Date(),
      [`messages_seen_map.${seenById}`]: messageId,
      messages: WPFirebase.firestore.FieldValue.arrayUnion(messageId),
    })
    .catch(logError);
}

export async function sendMessage(
  payload: string | number,
  type: "text" | "job" | "estate",
  conversation: Conversation,
  extraData?: { [key: string]: any }
) {
  let draftMessage: any;

  switch (type) {
    case "text":
      draftMessage = { text: payload };
      break;

    case "job":
      draftMessage = { job_id: payload };
      break;

    case "estate":
      draftMessage = { estate_id: payload };
      break;

    default:
      draftMessage = { text: payload };
      break;
  }

  if (extraData) {
    draftMessage = {
      ...draftMessage,
      ...extraData,
    };
  }

  const employerId = conversation.employers[0];

  const message = await messageRef.add({
    user: employerId,
    file: null,
    is_employee: false,
    created_at: new Date(),
    employees: conversation.employees,
    employers: conversation.employers,
    ...draftMessage,
  } as ConversationMessage);

  const chatDoc = chatRef.doc(conversation.id);

  const doc = await chatDoc.get();

  if ((doc.data() as Conversation | undefined)?.messages.length === 1) {
    analytics.logFirstReplyInChat();
  }

  chatDoc
    .update({
      is_hidden_for_employee: false,
      last_modified_at: new Date(),
      [`messages_seen_map.${employerId}`]: message.id,
      messages: fireBaseArrayUnion(message.id),
    })
    .catch(logError);
}

// Array.from(new Array(10)).forEach(() => {
//   sendMessage("test message", "text", {
//     id: "BGwQ4dt8G4BUSiza6n3e",
//     employees: ["9119"],
//     employers: [172],
//     group: "job",
//     is_hidden_for_employee: false,
//     is_hidden_for_employer: false,
//     messages: [],
//     owner: 172,
//     messages_seen_map: {},
//     last_modified_at: {
//       seconds: 1,
//     },
//   });
// });

export enum ApplicationStatus {
  NEW = "NEW",
  PND = "PND",
  ACP = "ACP",
  PPW = "PPW",
  RSV = "RSV",
  HRD = "HRD",
  RFS = "RFS",
  BAN = "BAN",
}

export type ConversationMessage = {
  id: string;
  is_employee: boolean;
  file: string | null;
  created_at:
    | {
        seconds: number;
      }
    | Date;
  text: string;
  user: number;
  employees: string[];
  employers: number[];
  job_id?: number;
  status?: ApplicationStatus;
  estate_id?: number;
};

export type Conversation = {
  id: string;
  employers: number[];
  employees: string[];
  owner: number;
  messages: string[];
  messages_seen_map: { [key: number]: string };
  is_hidden_for_employee: boolean;
  is_hidden_for_employer: boolean;
  last_modified_at:
    | {
        seconds: number;
      }
    | Date;
  group: ConversationType;
};

export enum ConversationType {
  JOB = "job",
  ESTATE = "estate",
}
