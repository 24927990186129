import React, { ReactNode } from "react";
import { View, StyleSheet, ViewStyle, StyleProp, Platform } from "react-native";
import { FormikProps, getIn, FormikErrors } from "formik";
import { WithTranslation, withTranslation } from "react-i18next";
import WPInput from "../input/WPInput";
import WPDropDownSelect from "../input/components/WPDropDownSelect";
import { ListItem } from "../list/WPList";
import { getSelectOptions } from "../../services/selectOptions";
import WPSelect from "../input/components/WPSelect";
import WPCheckbox from "../input/components/WPCheckbox";
import WPImageLoader from "../input/components/WPImageLoader";
import WPJobCategorySelect from "../input/components/WPJobCategorySelect";
import WPDatePicker from "../input/components/WPDatePicker";
import WPPasswordInput from "../input/components/misc/WPPasswordInput";

type FormFieldProps = {
  name: string;
  children?: React.ReactNode;
  type?:
    | "text"
    | "dropdown"
    | "select"
    | "checkbox"
    | "image"
    | "city"
    | "jobCategory"
    | "date"
    | "password";
  items?: ListItem<any>[];
  multiple?: boolean;
  isRounded?: boolean;
  style?: StyleProp<ViewStyle>;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  autocompleteType?: string;
  optionKeys?: object;
  numberOfLines?: number;
  tooltip?: string;
  append?: ReactNode;
  prepend?: ReactNode;
  dense?: boolean;
  successMap?: { [key: string]: boolean };
  isMain?: boolean;
  disableTooltip?: boolean;
  error?: string | string[];
  onDelete?: () => void;
} & FormikProps<any> &
  WithTranslation;
// Pick<WPInputProps<any>, "autocompleteType">;

class FormField extends React.Component<FormFieldProps> {
  constructor(props: FormFieldProps) {
    super(props);

    props.i18n.on("languageChanged", this.onLanguageChange);
  }

  componentWillUnmount() {
    this.props.i18n.off("languageChanged", this.onLanguageChange);
  }

  onLanguageChange = () => {
    this.forceUpdate();
  };

  private pickSuccess = (successMap: { [key: string]: boolean }) =>
    getIn(successMap, this.props.name);

  private pickError = (errors: FormikErrors<any>) =>
    getIn(errors, this.props.name);

  private pickValue = (values: any) => getIn(values, this.props.name);

  shouldComponentUpdate(nextProps: FormFieldProps) {
    const valueHasChanged =
      this.pickValue(this.props.values) !== this.pickValue(nextProps.values);
    const errorHasChanged =
      this.pickError(this.props.errors) !== this.pickError(nextProps.errors);
    const isChildrenChanged = this.props.children !== nextProps.children;
    const successHasChanged =
      this.pickSuccess(this.props.successMap || {}) !==
      this.pickSuccess(nextProps.successMap || {});
    const tooltips = this.props.disableTooltip !== nextProps.disableTooltip;
    const nameHasChanged = this.props.name !== nextProps.name;

    return (
      valueHasChanged ||
      errorHasChanged ||
      isChildrenChanged ||
      successHasChanged ||
      tooltips ||
      nameHasChanged
    );
  }

  handleChange = (value: any) =>
    this.props.setFieldValue(this.props.name, value);

  getInputType = () => {
    const label =
      this.props.label !== undefined
        ? this.props.label
        : this.props.t(`labels.${this.props.name}`);
    const tooltip = this.props.disableTooltip
      ? undefined
      : this.props.tooltip !== undefined
      ? this.props.tooltip
      : this.props.t(`tooltip.${this.props.name}`, "");
    const value = this.pickValue(this.props.values);
    const error = this.props.error || this.pickError(this.props.errors);
    const success = this.pickSuccess(this.props.successMap || {});
    const disabled = this.props.disabled;
    const required = this.props.required;

    const autocompleteType = this.props.autocompleteType || this.props.name;

    const defaultProps = {
      label,
      value,
      error,
      disabled,
      required,
      tooltip,
      success,
      append: this.props.append,
      prepend: this.props.prepend,
      onChange: this.handleChange,
    };
    switch (this.props.type) {
      case "password":
        return <WPPasswordInput {...defaultProps} />;
      case "text":
        return (
          <WPInput
            {...defaultProps}
            autocompleteType={autocompleteType as "off"}
            numberOfLines={this.props.numberOfLines}
          />
        );

      case "select":
        return (
          <WPSelect
            {...defaultProps}
            items={
              this.props.items ||
              getSelectOptions(this.props.name, this.props.t)
            }
            multiple={this.props.multiple}
            dense={this.props.dense}
          />
        );

      case "checkbox":
        return <WPCheckbox {...defaultProps} />;

      case "dropdown":
        return (
          <WPDropDownSelect
            {...defaultProps}
            items={
              this.props.items ||
              getSelectOptions(this.props.name, this.props.t)
            }
          />
        );

      case "image":
        return (
          <WPImageLoader
            {...defaultProps}
            onDelete={this.props.onDelete}
            isRounded={this.props.isRounded}
            isMain={this.props.isMain}
          />
        );

      case "city":
        return <WPInput type="city" {...defaultProps} />;

      case "jobCategory":
        return <WPJobCategorySelect {...defaultProps} />;
      case "date":
        return <WPDatePicker {...defaultProps} />;

      default:
        return (
          <WPInput
            {...defaultProps}
            numberOfLines={this.props.numberOfLines}
            autocompleteType={autocompleteType as "off"}
          />
        );
    }
  };

  render() {
    return (
      <View
        style={
          this.props.style
            ? [styles.container, this.props.style]
            : styles.container
        }
      >
        {this.getInputType()}
        {this.props.children}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container:
    Platform.OS === "web"
      ? {
          flex: 1,
        }
      : {
          flexShrink: 1,
          flexBasis: "auto",
          flexGrow: 1,
        },
});

export const formFieldStyle = styles.container;

export default withTranslation()(FormField);
