import React, { ReactNode, useState, useEffect, useCallback } from "react";
import {
  ViewStyle,
  Platform,
  Alert,
  StyleProp,
  Dimensions,
} from "react-native";
import * as ImagePicker from "expo-image-picker";
import * as Permissions from "expo-permissions";
import DropDownWrapper from "./DropDownWrapper";
import { TFunction } from "i18next";
import { ListItem } from "../list/WPList";
import { useTranslation } from "react-i18next";
import { isMobileView } from "../../utils/theme";

type WPImageLoaderWrapperProps = {
  onImageSelected: (url: string) => void;
  children: ReactNode;
  onDelete?: () => void;
  style?: StyleProp<ViewStyle>;
};

enum ImageLoaderOption {
  LIBRARY = "L",
  CAMERA = "C",
  DELETE = "D",
}

interface OptionsGenerator {
  (
    otherOptions: ListItem<ImageLoaderOption>[],
    t: TFunction
  ): ListItem<ImageLoaderOption>[];
}

const getDesktopItems: OptionsGenerator = (otherOptions, t) => [
  ...otherOptions,
  { label: t("labels.library"), value: ImageLoaderOption.LIBRARY },
];

const getMobileItems: OptionsGenerator = (otherOptions, t) =>
  [
    ...getDesktopItems(otherOptions, t),
    {
      label: t("labels.camera"),
      value: ImageLoaderOption.CAMERA,
    },
  ].filter((item) => {
    return !(
      Platform.OS === "ios" &&
      Dimensions.get("window").scale === 2 &&
      item.value === ImageLoaderOption.CAMERA
    );
  });

const getItemsWithDelete: OptionsGenerator = (otherOptions, t) => [
  ...otherOptions,
  {
    label: t("labels.delete"),
    value: ImageLoaderOption.DELETE,
  },
];

const getPermissions = async (
  target: Permissions.PermissionType
): Promise<boolean> => {
  if (Platform.OS === "ios") {
    // @ts-ignore
    const { status: existingStatus } = await Permissions.getAsync(target);

    if (existingStatus !== "granted") {
      // @ts-ignore
      const { status } = await Permissions.askAsync(target);

      if (status !== "granted") {
        Alert.alert(
          "Error",
          `Sorry, we need ${target} permissions to make this work!`
        );

        return false;
      }
    }
  }
  return true;
};

const pickImage = async (
  from: Permissions.PermissionType,
  onPicked: (uri: string) => void
) => {
  if (!(await getPermissions(from))) {
    return;
  }

  let imageLoader:
    | typeof ImagePicker.launchImageLibraryAsync
    | typeof ImagePicker.launchCameraAsync
    | undefined;

  switch (from) {
    case Permissions.CAMERA_ROLL:
      imageLoader = ImagePicker.launchImageLibraryAsync;
      break;

    case Permissions.CAMERA:
      imageLoader = ImagePicker.launchCameraAsync;
      break;

    default:
      break;
  }

  if (imageLoader) {
    try {
      let result = await imageLoader({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: false,
        base64: false,
        aspect: [4, 3],
        quality: 1,
      });

      if (result.cancelled === false) {
        onPicked(result.uri);
      }
    } catch (err) {
      onPicked("");
    }
  }
};

export default function WPImageLoaderWrapper(props: WPImageLoaderWrapperProps) {
  const [isFocused, setIsFocused] = useState(false);
  const [options, setOptions] = useState([] as ListItem<ImageLoaderOption>[]);
  const { t } = useTranslation();

  useEffect(() => {
    let imageSources: ListItem<ImageLoaderOption>[] = [];
    if (isMobileView()) {
      imageSources = getMobileItems(imageSources, t);
    } else {
      imageSources = getDesktopItems(imageSources, t);
    }

    if (props.onDelete) {
      imageSources = getItemsWithDelete(imageSources, t);
    }

    setOptions(imageSources);
  }, [props.onDelete]);

  const onChange = useCallback(
    (value: ImageLoaderOption) => {
      setTimeout(() => {
        switch (value) {
          case ImageLoaderOption.DELETE:
            if (typeof props.onDelete === "function") {
              props.onDelete();
            }
            break;

          case ImageLoaderOption.LIBRARY:
            pickImage(Permissions.CAMERA_ROLL, props.onImageSelected);

            break;
          case ImageLoaderOption.CAMERA:
            pickImage(Permissions.CAMERA, props.onImageSelected);

            break;
          default:
            break;
        }
      }, 110);
    },
    [props.onDelete]
  );

  return (
    <DropDownWrapper
      isFocused={isFocused}
      items={options}
      setIsFocused={setIsFocused}
      style={props.style}
      onChange={onChange}
    >
      {props.children}
    </DropDownWrapper>
  );
}
