// TODO: bring here all hooks from other files related to chat

import { useSelector } from "react-redux";
import { RootState } from "../store/reducers/mainReducer";
import { sendMessage } from "../chatService";

export const useSendMessage = () => {
  const activeConversation = useSelector((state: RootState) =>
    state.chat.activeConversationId
      ? state.chat.chats[state.chat.activeConversationId]
      : undefined
  );
  return (
    payload: string | number,
    type: "text" | "job" | "estate",
    extraData?: { [key: string]: any }
  ) => {
    if (activeConversation) {
      sendMessage(payload, type, activeConversation, extraData);
    }
  };
};
