import { combineReducers } from "redux";
import authReducer from "./auth/authReducer";
import chatReducer from "./chat/chatReducer";
import employeeReducer from "./employee/employeeReducer";
import jobReducer from "./job/jobReducer";
import uiReducer from "./ui/uiReducer";
import applicationReducer from "./application/applicationReducer";
import estateReducer from "./estate/estateReducer";
import jobCandidatesReducer from "./jobCandidates/jobCandidatesReducer";
import packsReducer from "./packs/packsReducer";

const mainReducer = combineReducers({
  auth: authReducer,
  chat: chatReducer,
  employee: employeeReducer,
  jobs: jobReducer,
  ui: uiReducer,
  application: applicationReducer,
  estate: estateReducer,
  jobCandidates: jobCandidatesReducer,
  packs: packsReducer,
});

export type RootState = ReturnType<typeof mainReducer>;

export default mainReducer;

export const getAuthState = (state: RootState) => state.auth;
export const getChatState = (state: RootState) => state.chat;
export const getEmployeeState = (state: RootState) => state.employee;
export const getTotalUnreadCount = (state: RootState) =>
  state.chat.chatOrder.reduce((total, chat) => {
    return total + chat.unreadCount;
  }, 0);
