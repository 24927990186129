import React, { memo, useCallback, useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../services/store/reducers/mainReducer";
import { View, ScrollView, StyleSheet } from "react-native";
import theme, { isMobileView } from "../utils/theme";
import JobPreview from "./misc/JobPreview";
import {
  setJobPreview,
  setUserPreviewId,
} from "../services/store/actions/uiActions";
import WPNotificationModal from "./modal/modalVariants/WPNotificationModal";
import UIExtraContainer from "./misc/UIExtraContainer";
import WPChatList from "../navigation/crm/chat/components/WPChatList";
import WPConversation from "../navigation/crm/chat/components/WPConversation";
import WPButton from "./Button/WPButton";
import { closeConversation } from "../services/store/actions/chatActions";
import { Conversation } from "../services/chatService";
import UserTile from "./misc/UserTile";
import UserPreview from "./misc/UserPreview";
import UserInfoButton from "./misc/UserInfoButton";
import { useIsFocused } from "@react-navigation/native";
import ActionModalContent from "./misc/ActionModalContent";

function UIExtras() {
  const [] = useState(Math.ceil(Math.random() * 10));
  const jobPreviewData = useSelector(
    (state: RootState) => state.ui.jobPreviewData
  );
  const activeConversation = useSelector(
    (state: RootState) => state.chat.activeConversationId
  );
  const userPreviewId = useSelector(
    (state: RootState) => state.ui.userPreviewId
  );
  const conversationData:
    | Conversation
    | undefined = useSelector((state: RootState) =>
    activeConversation ? state.chat.chats[activeConversation] : undefined
  );

  const actionModalData = useSelector(
    (state: RootState) => state.ui.actionModal
  );

  const chatUserId = conversationData
    ? Number(conversationData.employees[0])
    : undefined;

  const isFocused = useIsFocused();

  const dispatch = useDispatch();

  const closeJobPreview = useCallback(() => {
    dispatch(setJobPreview(undefined));
  }, [dispatch]);

  const closeChat = useCallback(() => {
    dispatch(closeConversation());
  }, [dispatch]);

  const closeUserPreview = useCallback(() => {
    dispatch(setUserPreviewId());
  }, [dispatch]);

  const userPreview = useMemo(
    () =>
      userPreviewId ? (
        <UserPreview userId={userPreviewId} onClose={closeUserPreview} />
      ) : null,
    [userPreviewId]
  );

  const jobPreview = useMemo(
    () =>
      jobPreviewData ? (
        <JobPreview job={jobPreviewData} onClose={closeJobPreview} />
      ) : null,
    [jobPreviewData]
  );

  const chatUserCard = useMemo(
    () =>
      chatUserId ? (
        <UserTile
          userId={chatUserId}
          append={<UserInfoButton userId={chatUserId} />}
        />
      ) : null,
    [chatUserId]
  );

  if (!isFocused) {
    return null;
  }

  return (
    <View style={styles.container}>
      <WPNotificationModal
        isVisible={!!actionModalData}
        isWide
        withoutOverflow={isMobileView()}
      >
        {actionModalData ? <ActionModalContent {...actionModalData} /> : null}
      </WPNotificationModal>

      {isMobileView() ? (
        <WPNotificationModal
          isVisible={!!jobPreviewData || userPreviewId !== undefined}
          isFullscreen
        >
          {jobPreviewData || userPreviewId ? (
            <ScrollView style={{ height: "90%" }} removeClippedSubviews>
              {jobPreviewData ? jobPreview : userPreview}
            </ScrollView>
          ) : null}
        </WPNotificationModal>
      ) : (
        <View style={styles.wideContainer}>
          <UIExtraContainer isHidden={!jobPreviewData}>
            {jobPreview}
          </UIExtraContainer>
          <UIExtraContainer isHidden={!userPreviewId}>
            {userPreview}
          </UIExtraContainer>
          <UIExtraContainer isHidden={false}>
            <View
              style={!!activeConversation ? styles.hidden : styles.container}
            >
              <WPChatList />
            </View>
            <View
              style={!activeConversation ? styles.hidden : styles.container}
            >
              <View style={styles.header}>
                <WPButton
                  type="icon"
                  name="back"
                  appearance="link"
                  onPress={closeChat}
                />
                <View style={styles.userTileContainer}>{chatUserCard}</View>
              </View>
              <WPConversation />
            </View>
          </UIExtraContainer>
        </View>
      )}
    </View>
  );
}

export default memo(UIExtras);

const styles = StyleSheet.create({
  container: { flex: 1 },
  header: {
    backgroundColor: theme.colors.white,
    borderBottomColor: theme.colors.mains[3],
    borderBottomWidth: 1,
    flexDirection: "row",
  },
  userTileContainer: {
    flex: 1,
  },
  wideContainer: {
    flexDirection: "row",
    flex: 1,
  },
  hidden: {
    display: "none",
    flex: 1,
  },
});
