import React, { useCallback } from "react";
import JobEditor from "../components/JobEditor";
import {
  Accommodation,
  Meals,
  IncomeType,
} from "../../../../services/apis/jobApi";
import { useTranslation } from "react-i18next";
import { navigateTo } from "../../../navigationHelpers";

export default function CreateJobScreen() {
  const { t } = useTranslation();
  const onSaved = useCallback((offerId: number) => {
    navigateTo(`CRM.JOB.JOB_PACKAGE?jobId=${offerId}`);
  }, []);

  return (
    <JobEditor
      title={t("titles.add_job_offer")}
      onSaved={onSaved}
      job={{
        lma: "F" as any,
        accommodation: Accommodation.N,
        meals: Meals.N,
        // contact_email: props.email,
        contract_start_date: new Date().toISOString().replace(/T.+/, ""),
        age_range: [],
        im_apps: [],
        preferred_employees: [],
        income_type: IncomeType.G,
        // recruiter_company_name: props.recruiterCompanyName,
      }}
    />
  );
}
