import { useState, useEffect, useCallback } from "react";
import appCache from "../appCache";
import { newsRef } from "../firebaseFirestore";

const NEWS_KEY = "NEWS";

export const useGetNews = (id: number): [boolean, () => void] => {
  const [isSeen, setIsSeen] = useState(true);

  const closeNews = useCallback(() => {
    appCache.setValue(NEWS_KEY, id.toString());
    setIsSeen(true);
  }, [setIsSeen]);

  useEffect(() => {
    appCache.getValue(NEWS_KEY).then((savedState) => {
      if (
        (Number(savedState) || savedState === null || savedState === "") &&
        Number(savedState) !== id
      ) {
        setIsSeen(false);
      }
    });
  }, [setIsSeen, id]);

  return [isSeen, closeNews];
};

type NewsConfig = {
  id: number;
  ru: {
    title: string;
    text: string;
  };
  pl: {
    title: string;
    text: string;
  };
  uk: {
    title: string;
    text: string;
  };
  en: {
    title: string;
    text: string;
  };
};

export const useGetNewsConfig = () => {
  const [newsCofnig, setNewsConfig] = useState<NewsConfig | undefined>(
    undefined
  );

  useEffect(() => {
    newsRef
      .doc("news")
      .get()
      .then((res) => setNewsConfig(res.data() as NewsConfig | undefined));
  }, []);

  return newsCofnig;
};
