import React, { useState, useCallback, useEffect } from "react";
import WPTextInput, { WPTextInputProps } from "./WPTextInput";
import WPIcon from "../../Icon/WPIcon";
import { TouchableOpacity } from "react-native";

export type WPSearchInputProps<T> = WPTextInputProps & {
  searchCall: (searchTerm: string) => Promise<T>;
  onSearchResults: (res: T) => void;
};

export default function WPSearchInput<T>(props: WPSearchInputProps<T>) {
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const onSearchChange = useCallback(
    (text: string) => {
      setSearchTerm(text);
      setIsLoading(true);
      props
        .searchCall(text)
        .then((res) => {
          props.onSearchResults(res);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    },
    [setIsLoading, setSearchTerm, props.onSearchResults, props.searchCall]
  );

  useEffect(() => {
    onSearchChange("");
  }, [onSearchChange]);

  return (
    <WPTextInput
      {...props}
      value={searchTerm}
      onChange={onSearchChange}
      append={
        isLoading ? (
          <WPIcon name="loading" />
        ) : searchTerm ? (
          <TouchableOpacity onPress={() => setSearchTerm("")}>
            <WPIcon name="close" />
          </TouchableOpacity>
        ) : null
      }
    />
  );
}
