import React from "react";
import { StyleSheet, View } from "react-native";
import theme from "../../utils/theme";
import WPText from "../Text/WPText";

export default function NotificationLabel(props: { label: string }) {
  return (
    <View style={styles.container}>
      <WPText style={styles.text}>{props.label}</WPText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    height: 17,
    minWidth: 17,
    borderRadius: 17 / 2,
    backgroundColor: theme.colors.warning,
  },
  text: {
    fontSize: 10,
    color: theme.colors.white,
    fontFamily: "bold",
  },
});
