import i18n, { LanguageDetectorModule, BackendModule } from "i18next";
import { locale } from "expo-localization";
import { Platform } from "react-native";

export const supportedLocales = {
  en: {
    name: "Eng",
    translationFileLoader: () => require("./locales/en/translation.json"),
  },
  pl: {
    name: "Pl",
    translationFileLoader: () => require("./locales/pl/translation.json"),
  },
  ru: {
    name: "Ru",
    translationFileLoader: () => require("./locales/ru/translation.json"),
  },
  uk: {
    name: "Ua",
    translationFileLoader: () => require("./locales/uk/translation.json"),
  },
  de: {
    name: "De",
    translationFileLoader: () => require("./locales/de/translation.json"),
  },
};

const translationLoader: BackendModule = {
  type: "backend",
  init: () => {},
  create: function () {},
  read: function (
    language: "en" | "pl" | "ru" | "uk" | "de",
    _namespace,
    callback
  ) {
    let resource,
      error = null;

    try {
      resource = supportedLocales[language].translationFileLoader();
    } catch (_error) {
      error = _error;
    }

    callback(error, resource);
  },
};

const languageDetector: LanguageDetectorModule = {
  type: "languageDetector",
  detect: () => {
    if (Platform.OS === "web") {
      let lang: string | null;

      const langReg = window.location.search.match(/lang=(\w+)&?/);

      lang = langReg && langReg[1];

      if (lang) {
        window.history.pushState({}, document.title, window.location.pathname);
        return lang;
      }

      lang = localStorage.getItem("language");

      if (lang) {
        return lang;
      }
    }
    return locale.split("-")[0];
  },
  init: () => {},
  cacheUserLanguage: (lng) => {
    if (Platform.OS === "web") {
      localStorage.setItem("language", lng);
    }
  },
};

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(translationLoader)
  .use(languageDetector)
  .init({
    fallbackLng: "pl",
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    // special options for react-i18next
    // learn more: https://react.i18next.com/components/i18next-instance
    react: {
      wait: true,
    },
  });
export default i18n;
