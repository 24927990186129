import React from "react";
import { View, StyleSheet } from "react-native";
import WPError from "../input/components/WPError";

export default function FormError(props: { error?: string | string[] }) {
  if (!props.error) {
    return null;
  }
  return (
    <View style={styles.formError}>
      <WPError error={props.error} />
    </View>
  );
}

const styles = StyleSheet.create({
  formError: {
    marginBottom: 0,
  },
});
