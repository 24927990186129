import {
  Employee,
  Note,
  Document,
} from "../store/reducers/employee/employeeReducer";
import wpRequest, { getBaseUrl } from "../../utils/wpRequest";
import { ListCall } from "./jobApi";
import { ApplicationStatus } from "../chatService";
import { getFilterAsString } from "../../utils/mixUtils";

export type EmployeeApplications = {
  id: number;
  photo: string;
  phone_number: string;
  first_name: string;
  last_name: string;
  applications: {
    id: number;
    status: ApplicationStatus;
    job_company_name: string;
    job_title: string;
  }[];
};

export const getEmployeeInfoCall = (employeeId: number) =>
  wpRequest.get<Employee>(`employees/${employeeId}/`).catch(() => {});

export const getBulkEmployeeInfoCall = (employeeIds: number[]) =>
  wpRequest
    .get<{ results: Employee[] }>(`employees/?id__in=${employeeIds.join(",")}`)
    .then((res) => res.results);

export const getEmployeeDocumentsCall = (employeeId: number) =>
  wpRequest
    .get<{ results: Document[] }>(`employees/${employeeId}/documents/`)
    .then((res) => res.results);

export const getEmployeeAllDocumentsLink = (employeeId: number) => {
  const baseUrl = getBaseUrl();

  return baseUrl + `employees/${employeeId}/documents_archive/`;
};

export const getEmployeeNotesCall = (employeeId: number) =>
  wpRequest
    .get<{ results: Note[] }>(`employees/${employeeId}/notes/`)
    .then((res) => res.results);

export const addEmployeeNoteCall = (employeeId: number, note: string) =>
  wpRequest.post(`employees/${employeeId}/notes/`, { body: note });

export const getEmployeeApplications: ListCall<EmployeeApplications> = (
  config
) =>
  wpRequest.get(
    `admin/job-applicants/?page=${config.pagination.page_num}&page_size=${
      config.pagination.page_size
    }&${getFilterAsString(config.filter)}`
  );
