import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const StatsIcon: React.FC<IconProps> = (props) => {
  let height = 24;
  let width = 24;
  const ratio = width / height;
  const color = props.color || "#0076FF";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg
      {...props}
      width={width}
      height={height}
      style={[{ height, width }, props.style]}
      viewBox={`0 0 24 24`}
    >
      <Path
        fill={color}
        d="M3 19a1 1 0 01-.708-1.706l7-7a1 1 0 011.154-.188l3.356 1.678 6.49-6.49a1 1 0 011.414 1.414l-7 7a.994.994 0 01-1.154.188l-3.356-1.678-6.49 6.49A.996.996 0 013 19z"
      />
      <Path
        fill={color}
        d="M21 13a1 1 0 01-1-1V7h-5a1 1 0 010-2h6a1 1 0 011 1v6a1 1 0 01-1 1z"
      />
    </Svg>
  );
};

StatsIcon.displayName = "StatsIcon";

export default StatsIcon;
