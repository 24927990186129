import wpRequest, { fileRequest } from "../../utils/wpRequest";
import { getFilterAsString, getMimeType } from "../../utils/mixUtils";
import { ApplicationStatus } from "../chatService";
import { JobApplicationEmployeeData } from "./authApi";
import { Platform } from "react-native";
// import Constants from "expo-constants";
import config from "../config";

// const { API_V2 = "" } = Constants.manifest.extra;
const { API_V2 = "" } = config;
export const defaultPagination = {
  page_count: 1,
  page_num: 1,
  page_size: 1000,
  total: 1,
};

export type ListCall<T> = (
  config: EntityListCallConfig
) => Promise<PaginationConfig & { results: T[]; count: number; total: number }>;

export type EntityListCallConfig = {
  filter:
    | Partial<{
        job_offer: string;
        company_name: string;
        search: string; // title
        employee: number;
        expired: boolean;
      }>
    | Partial<{
        job_offer?: number;
        company_name?: string;
        job_title?: string;
        employee?: number;
      }>
    | Partial<{
        search?: string;
      }>
    | Partial<{ id: number }>
    | Partial<{ employerId: number }>;
  pagination: PaginationConfig;
};

export const getJobListCall: ListCall<JobOffer> = (config) =>
  wpRequest.get(
    `admin/job-offers/?page=${config.pagination.page_num}&page_size=${
      config.pagination.page_size
    }&${getFilterAsString(config.filter)}`
  );

export const getJobOfferCall = (jobOfferId: number) =>
  wpRequest.get<JobOffer>(`admin/job-offers/${jobOfferId}/`);

export const bumpJobOffer = (offerId: number) => {
  return wpRequest.post(`${API_V2}packets/status`, {
    offer_id: offerId,
    packet_config_id: 3,
    action: "up",
  });
};

export const createJobOfferCall = (offer: Partial<JobOffer>) =>
  wpRequest.post<JobOffer>(`admin/job-offers/`, offer);

export const disableJobOfferCall = (offerId: number) =>
  wpRequest.post(`admin/job-offers/${offerId}/disable`, {});

export const updateJobOfferCall = (offer: Partial<JobOffer>) =>
  wpRequest.put<JobOffer>(`admin/job-offers/${offer.id}/`, offer);

export const deleteJobOfferCall = (offerId: number) =>
  wpRequest.delete(`admin/job-offers/${offerId}/`);

export const republishJobOfferCall = (offerId: number) =>
  wpRequest.post(`admin/jobs/republish/`, {
    job_offer: offerId,
  });

export const searchCitiesCall = (searchTerm: string) =>
  wpRequest
    .get<{ results: City[] }>(`cities/cities/?q=${searchTerm}`)
    .then((res) => res.results);

export const getCategoriesCall = () =>
  wpRequest.get<Category[]>(`jobs/categories/`);

export const getApplicationsCall: ListCall<Application> = (config) =>
  wpRequest.get(
    `admin/job-applications/?page=${config.pagination.page_num}&page_size=${
      config.pagination.page_size
    }&${getFilterAsString(config.filter)}`
  );

export const updateApplicationStatusCall = (
  applicationId: number,
  status: ApplicationStatus
) =>
  wpRequest.put(`admin/job-applications/${applicationId}/`, {
    status,
  });

export const uploadOfferImagesCall = (
  offerId: number,
  images: JobOfferImage[]
) => {
  const imagesToSave = images.filter((image) => {
    return (
      image &&
      ((typeof image === "object" && !("image" in image)) ||
        (typeof image === "string" && !/http(s)?:/.test(image)))
    );
  }) as string[];

  const imagesCalls = imagesToSave.map(async (image) => {
    let fileReader: Promise<File | { uri: string; name: string }>;

    if (Platform.OS === "web") {
      fileReader = fileRequest(image).then(
        (file) =>
          new File([file], "image.png", {
            type: "image/png",
            lastModified: Number(new Date()),
          })
      );
    } else {
      const mimeType = getMimeType(image);
      fileReader = Promise.resolve({
        uri: image,
        name: mimeType.replace("/", "."),
        type: mimeType,
      });
    }

    return fileReader.then((file: File | { uri: string; name: string }) => {
      const formData = new FormData();

      // @ts-ignore server specifics
      formData.append("image", file);

      return wpRequest.post(`admin/job-offers/${offerId}/images/`, formData);
    });
  });

  return Promise.all(imagesCalls);
};

export const getJobStatisticsCall = (expired: boolean, jobId?: number) => {
  return Promise.resolve({});
  if (jobId) {
    return wpRequest.get<JobStatistics>(`jobs/jobs/${jobId}/events/`);
  }
  return wpRequest.get<JobStatistics>(
    `jobs/events/mine/?expired=${expired ? "true" : "false"}`
  );
};

export const deleteJobImage = (jobId: number, imageId: number) => {
  wpRequest
    .delete(`admin/job-offers/${jobId}/images/${imageId}/`)
    .catch(console.warn);
};

export type JobStatistics = {
  job_detail_view?: number;
  job_list_view?: number;
  job_apply?: number;
  job_phone_press?: number;
  job_translate_ru?: number;
  job_translate_en?: number;
  job_translate_uk?: number;
  job_translate_pl?: number;
  job_translate_de?: number;
};

export type PaginationConfig = {
  page_count: number;
  page_num: number;
  page_size: number;
  total: number;
};

export type JobOfferImage = { id: number; image: string } | string;

export enum Employment {
  P = "P",
  T = "T",
}

export enum IncomeType {
  N = "N",
  G = "G",
}

export enum PaymentForm {
  H = "H",
  M = "M",
}

export enum Accommodation {
  N = "N",
  P = "P",
  F = "F",
}

export enum Meals {
  N = "N",
  P = "P",
  F = "F",
}

export enum PreferredEmployees {
  M = "M",
  F = "F",
  C = "C",
}

export enum AgeRange {
  Y = "Y",
  M = "M",
  O = "O",
}

export enum JobOfferType {
  T = "T",
  F = "F",
}

export type City = {
  geoname_id: number;
  name: string;
  display_name: string;
};

export type Category = {
  id: number;
  name: string;
  offers: number;
};

export enum IamApps {
  // telegram = "telegram",
  viber = "viber",
  whatsapp = "whatsapp",
}

export enum JobOfferStatus {
  PUBLISHED = "PUBLISHED",
  DRAFT = "DRAFT",
}

export type JobOffer = {
  id: number;
  city: City;
  hired_count: number;
  images: JobOfferImage[];
  created: string;
  modified: string;
  title: string;
  company_name: string;
  position_count: number;
  responsibilities: string;
  conditions: string;
  category: number;
  employment: Employment; // EMPLOYMENT
  min_working_hours: number;
  max_working_hours: number;
  min_rate: string;
  max_rate: string;
  income_type: IncomeType; // INCOME_TYPE
  payment_form: PaymentForm; // PAYMENT_FORM
  contract_start_date: string;
  contract_end_date: string | null;
  accommodation: Accommodation; // ACCOMMODATION
  meals: Meals; // MEALS
  preferred_employees: PreferredEmployees[]; // preferred_employees enum
  age_range: AgeRange[]; // age_range enum
  contact_email: string;
  expire_date: string;
  is_premium: boolean;
  income_currency: string; // income_currency enum
  status: JobOfferStatus; // status enum
  published_at: string;
  phone: string;
  im_apps: IamApps[];
  lma: boolean;
  bumpable?: boolean;
  // workplace: string;
};

export type Application = {
  id: number;
  employee: JobApplicationEmployeeData;
  job_offer: JobOffer;
  created: string;
  modified: string;
  status: ApplicationStatus;
};
