import React, { memo } from "react";
import { StyleSheet, Image } from "react-native";
import theme from "../../../../../utils/theme";
import WPImagePreviewWrapper from "../../../../../components/misc/WPImagePreviewWrapper";

function WPImageMessage(props: { image: string }) {
  return (
    <WPImagePreviewWrapper imageUri={props.image}>
      <Image
        source={{ uri: props.image, cache: "force-cache" }}
        style={styles.default}
      />
    </WPImagePreviewWrapper>
  );
}

export default memo(WPImageMessage);

const styles = StyleSheet.create({
  default: {
    borderRadius: theme.button.borderRadius,
    overflow: "hidden",
    height: 100,
    width: 100,
  },
});
