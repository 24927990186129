import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const SearchIcon: React.FC<IconProps> = (props) => {
  let height = 20;
  let width = 20;
  const ratio = width / height;
  const color = props.color || "#637381";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M9.356 2a7.357 7.357 0 015.802 11.882l-.069.086 1.456 1.456 1.223 1.224a.792.792 0 01.077 1.031l-.077.089a.788.788 0 01-.395.215l-.11.015h-.11a.789.789 0 01-.505-.23l-2.68-2.68a7.324 7.324 0 01-4.37 1.62l-.242.005A7.357 7.357 0 019.356 2zm0 1.584a5.773 5.773 0 104.015 9.921l.062-.072a.8.8 0 01.066-.06 5.773 5.773 0 00-4.143-9.79z"
      />
    </Svg>
  );
};

SearchIcon.displayName = "SearchIcon";

export default SearchIcon;
