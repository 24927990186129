import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/reducers/mainReducer";
import { useEffect, useState } from "react";
import {
  getEstateCall,
  updateEstateCall,
  createEstateCall,
  Estate,
  uploadEstateImagesCall,
  deleteEstateCall,
  deleteEstateImage,
} from "../apis/estateApi";
import { gotEstate, deletedEstate } from "../store/actions/estateActions";
import { ErrorResponse } from "../../utils/wpRequest";
import { goBack } from "../../navigation/navigationHelpers";
import { scheduleListUpdate } from "../store/actions/chatActions";

const defaultEntity = {
  isLoading: false,
  isLoaded: false,
  isDeleted: false,
};
export const useGetEstate = (id: number) => {
  const estate = useSelector((state: RootState) => state.estate.estates[id]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !estate ||
      (estate && !estate["data"] && !estate.isLoading && !estate.isDeleted)
    ) {
      getEstateCall(id)
        .then((estate) => dispatch(gotEstate(estate)))
        .catch(() => deletedEstate(id));
    }
  }, []);

  return estate || defaultEntity;
};

export const useDeleteEstateImage = (estateId: number) => {
  return (imageId: number) => deleteEstateImage(estateId, imageId);
};

export const useSaveEstate = (
  isUpdate: boolean
): [
  boolean,
  ErrorResponse,
  { [key: string]: boolean },
  (estate: Partial<Estate>) => Promise<void>
] => {
  const [successMap, setSuccessMap] = useState<{ [key: string]: boolean }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<ErrorResponse>({});
  const dispatch = useDispatch();
  const saveOfferCall = isUpdate ? updateEstateCall : createEstateCall;

  const updateErrorsAndSuccess = (
    estate: Partial<Estate>,
    errors: ErrorResponse
  ) => {
    const newSuccessMap = Object.keys(estate).reduce((acc, estateKey) => {
      if (!(estateKey in errors)) {
        acc[estateKey] = true;
      }

      return acc;
    }, {});

    setErrors(errors);
    setSuccessMap(newSuccessMap);
  };

  async function saveOffer(estate: Partial<Estate>) {
    const normalizedOffer = JSON.parse(JSON.stringify(estate));

    delete normalizedOffer.images;
    delete normalizedOffer.images_obj;

    if (normalizedOffer.contract_start_date) {
      normalizedOffer.contract_start_date = normalizedOffer.contract_start_date.replace(
        /T.+/,
        ""
      );
    }

    if ("city" in normalizedOffer && typeof normalizedOffer.city === "object") {
      normalizedOffer["city"] = normalizedOffer.city.geoname_id;
    }

    let offer: Estate | undefined = undefined;

    try {
      setIsLoading(true);
      offer = await saveOfferCall(normalizedOffer);
    } catch (err) {
      setIsLoading(false);
      return updateErrorsAndSuccess(normalizedOffer, err);
    }

    try {
      const id = offer.id || estate.id;
      if (estate.images_obj && id) {
        await uploadEstateImagesCall(id, estate.images_obj);
      }
    } catch (imageErr) {
      if (offer !== undefined && !isUpdate) {
        await deleteEstateCall(offer.id);
      }

      setIsLoading(false);
      return updateErrorsAndSuccess(normalizedOffer, imageErr);
    }

    dispatch(scheduleListUpdate());
    goBack("CRM.JOB.ESTATE_LIST");
    setIsLoading(false);
  }

  return [isLoading, errors, successMap, saveOffer];
};
