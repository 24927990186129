import React, { useState, useCallback } from "react";
import DP from "react-datepicker";
import InputWrapper, { InputWrapperProps } from "../InputWrapper";
import { useTranslation } from "react-i18next";
import ValueComponent from "./ValueCustom";
import DropDownWrapper from "../../../misc/DropDownWrapper";
import "react-datepicker/dist/react-datepicker.css";

type DatePickerProps<T> = Omit<InputWrapperProps<T>, "isFocused">;

function addDay(date: Date) {
  date.setDate(date.getDate() + 1);

  return date;
}

export default function DatePicker(props: DatePickerProps<string>) {
  const now = new Date();
  const startDate = new Date(props.value || now);
  const [isFocused, setIsFocused] = useState(false);
  const { i18n } = useTranslation();

  const saveChanges = useCallback((date) => {
    if ("onChange" in props && typeof props.onChange === "function") {
      props.onChange(date.toISOString().replace(/T.+/, ""));
    }
    setIsFocused(false);
  }, []);

  return (
    <DropDownWrapper
      isFocused={isFocused}
      setIsFocused={setIsFocused}
      onChange={saveChanges}
      items={[{ label: "date", value: startDate }]}
      manualTrigger
      customRenderItem={(item) => (
        <DP
          onChange={saveChanges}
          selected={item}
          inline
          locale={i18n.language}
          minDate={now}
        />
      )}
    >
      <InputWrapper isFocused={isFocused} {...props}>
        <ValueComponent value={props.value} />
      </InputWrapper>
    </DropDownWrapper>
  );
}
