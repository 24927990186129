import React, { memo } from "react";
import { StyleProp, ViewStyle, View } from "react-native";
import WPText from "../Text/WPText";

const InfoRow: React.FC<{
  label: string;
  value: string;
  isSingleLine?: boolean;
  style?: StyleProp<ViewStyle>;
}> = (props) => (
  <View style={[{ flexDirection: "row" }, props.style]}>
    <WPText
      style={{ opacity: 0.6, flex: 1, fontSize: 14, lineHeight: 14 * 1.3 }}
      numberOfLines={1}
    >
      {props.label}
    </WPText>
    <WPText
      style={{ flex: 1, fontSize: 14, lineHeight: 14 * 1.3 }}
      numberOfLines={props.isSingleLine ? 1 : 0}
    >
      {props.value || "-"}
    </WPText>
  </View>
);

InfoRow.displayName = "InfoRow";

export default memo(InfoRow);
