import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const MenuIcon: React.FC<IconProps> = (props) => {
  let height = 20;
  let width = 20;
  const ratio = width / height;
  const color = props.color || "#FFF";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M17.111 15c.491 0 .889.448.889 1s-.398 1-.889 1H2.89c-.492 0-.89-.448-.89-1s.398-1 .889-1zm0-6c.491 0 .889.448.889 1s-.398 1-.889 1H2.89c-.492 0-.89-.448-.89-1s.398-1 .889-1zm0-6c.491 0 .889.448.889 1s-.398 1-.889 1H2.89C2.398 5 2 4.552 2 4s.398-1 .889-1z"
      />
    </Svg>
  );
};

MenuIcon.displayName = "MenuIcon";

export default MenuIcon;
