import React, { memo } from "react";
import { View, StyleSheet, Platform } from "react-native";
import theme, { getThemeResponsiveValue } from "../../../utils/theme";
import { WPInputState, WPBaseInputProps } from "../WPInput";
import WPLabel from "./WPLabel";
import PrependAppendDecorator, {
  PrependAppendDecoratorProps,
} from "../../misc/PrependAppendDecorator";
import WPError from "./WPError";
import WPTooltipText from "./WPTooltipText";

export type InputWrapperProps<T> = WPBaseInputProps<T> & {
  isFocused: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  children?: React.ReactNode;
  tooltip?: string;
  disabled?: boolean;
  required?: boolean;
  success?: boolean;
} & PrependAppendDecoratorProps;

function InputWrapper<T>(props: InputWrapperProps<T>) {
  const { label } = props;
  const isMissingValue =
    props.value === undefined ||
    props.value === null ||
    (typeof props.value === "string" && props.value === "");

  const inputState = getInputState(
    props.isFocused,
    props.error !== undefined,
    !isMissingValue,
    !!props.disabled,
    props.success
  );

  const { prepend, append, error, tooltip } = props;

  return (
    <>
      <View style={getContainerStyle(inputState)}>
        {label ? (
          <View style={getLabelPosition(inputState)}>
            <WPLabel
              inputState={inputState}
              label={label}
              required={props.required}
            />
          </View>
        ) : null}
        <PrependAppendDecorator prepend={prepend} append={append}>
          {props.children}
        </PrependAppendDecorator>
      </View>
      {error && <WPError error={error} />}
      {tooltip ? <WPTooltipText text={tooltip} /> : null}
    </>
  );
}

const delta = Platform.OS === "android" ? 6 : 2;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "stretch",
    paddingHorizontal: theme.input.paddingHorizontal,
    minHeight: getThemeResponsiveValue(theme.input.size.regular),
    borderWidth: 1,
    borderRadius: theme.input.borderRadius,
    borderColor: theme.input.blurColor,
    position: "relative",
    flexDirection: "row",
    flexShrink: 1,
    flexGrow: 1,
    flexBasis: "auto",
  },
  containerFocused: {
    borderWidth: 2,
    paddingHorizontal: theme.input.paddingHorizontal - 1,
    borderColor: theme.input.focusColor,
  },
  withError: {
    borderColor: theme.input.errorColor,
  },
  withSuccess: {
    borderColor: theme.input.successColor,
  },
  labelBlurPosition: {
    borderRadius: 4,
    position: "absolute",
    left: theme.input.paddingHorizontal,
    top:
      getThemeResponsiveValue(theme.input.size.regular) / 2 -
      getThemeResponsiveValue(theme.input.labelBlurFontSize) / 2 -
      delta,
  },
  labelFocusedPosition: {
    backgroundColor: theme.colors.white,
    top: -(getThemeResponsiveValue(theme.input.labelBlurFontSize) / 2) - delta,
    paddingHorizontal: 4,
    left: theme.input.paddingHorizontal - 4,
  },
  labelFocusedPositionWithValue: {
    top:
      -(getThemeResponsiveValue(theme.input.labelBlurFontSize) / 2) - delta / 2,
    paddingHorizontal: 4,
    left: theme.input.paddingHorizontal - 4,
  },
  disabled: {
    backgroundColor: theme.colors.mains[5],
  },
});

const labelFocusedPosition = [
  styles.labelBlurPosition,
  styles.labelFocusedPosition,
];
const labelBlurredWithValuePosition = [
  ...labelFocusedPosition,
  styles.labelFocusedPositionWithValue,
];

const containerFocusedStyle = [styles.container, styles.containerFocused];
const blurredWithErrorStyle = [styles.container, styles.withError];
const blurredWithSuccessStyle = [styles.container, styles.withSuccess];
const focusedWithErrorStyle = [...containerFocusedStyle, styles.withError];
const focusedWithSuccessStyle = [...containerFocusedStyle, styles.withSuccess];
const disabled = [styles.container, styles.disabled];

function getContainerStyle(inputState: WPInputState) {
  switch (inputState) {
    case WPInputState.blurred:
    case WPInputState.blurredWithValue:
      return styles.container;
    case WPInputState.focused:
    case WPInputState.focusedWithValue:
      return containerFocusedStyle;
    case WPInputState.focusedError:
    case WPInputState.focusedWithValueWithError:
      return focusedWithErrorStyle;
    case WPInputState.blurredError:
    case WPInputState.blurredWithValueWithError:
      return blurredWithErrorStyle;

    case WPInputState.focusedWithValueWithSuccess:
      return focusedWithSuccessStyle;
    case WPInputState.blurredWithValueWithSuccess:
      return blurredWithSuccessStyle;

    case WPInputState.disabled:
    case WPInputState.disabledWithValue:
      return disabled;

    default:
      return styles.container;
  }
}

function getLabelPosition(inputState: WPInputState) {
  switch (inputState) {
    case WPInputState.blurred:
    case WPInputState.blurredError:
    case WPInputState.disabled:
      return styles.labelBlurPosition;
    case WPInputState.blurredWithValue:
    case WPInputState.disabledWithValue:
    case WPInputState.blurredWithValueWithError:
    case WPInputState.blurredWithValueWithSuccess:
      return labelBlurredWithValuePosition;
    case WPInputState.focused:
    case WPInputState.focusedError:
    case WPInputState.focusedWithValue:
    case WPInputState.focusedWithValueWithError:
    case WPInputState.focusedWithValueWithSuccess:
      return labelFocusedPosition;

    default:
      return styles.labelBlurPosition;
  }
}

function getInputState(
  isFocused: boolean,
  withError: boolean,
  withValue: boolean,
  isDisabled: boolean,
  isSuccess?: boolean
): WPInputState {
  if (isSuccess === true) {
    if (isFocused) {
      return WPInputState.focusedWithValueWithSuccess;
    }
    return WPInputState.blurredWithValueWithSuccess;
  }

  if (isDisabled)
    if (withValue) {
      return WPInputState.disabledWithValue;
    } else {
      return WPInputState.disabled;
    }

  if (!isFocused) {
    if (withValue) {
      return !withError
        ? WPInputState.blurredWithValue
        : WPInputState.blurredWithValueWithError;
    }
    return !withError ? WPInputState.blurred : WPInputState.blurredError;
  }

  if (withValue) {
    return !withError
      ? WPInputState.focusedWithValue
      : WPInputState.focusedWithValueWithError;
  }

  return !withError ? WPInputState.focused : WPInputState.focusedError;
}

export default memo(InputWrapper);
