import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const InfoIcon: React.FC<IconProps> = (props) => {
  let height = 20;
  let width = 20;
  const ratio = width / height;
  const color = props.color || "#0076FF";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M12 2.4c5.302 0 9.6 4.298 9.6 9.6s-4.298 9.6-9.6 9.6-9.6-4.298-9.6-9.6S6.698 2.4 12 2.4zm0 8.208a.9.9 0 00-.9.9v4.913a.9.9 0 101.8 0v-4.913a.9.9 0 00-.9-.9zm0-3.93a.9.9 0 00-.9.9v.828a.9.9 0 101.8 0v-.828a.9.9 0 00-.9-.9z"
      />
    </Svg>
  );
};

InfoIcon.displayName = "InfoIcon";

export default InfoIcon;
