import React, { memo, useCallback, useState, useEffect } from "react";
import { FlatList, ScrollView } from "react-native-gesture-handler";
import { getActiveConversation } from "./conversationHooks";
import { View, StyleSheet, Platform } from "react-native";
import WPChatMessage from "./WPChatMessage";
import { getEmployeeIdFromConversation } from "../../../../services/store/reducers/chat/chatReducer";
import WPConversationGroupSelect from "./WPConversationGroupSelect";
import { isMobileView } from "../../../../utils/theme";

function WPMessagesContainer() {
  function getMessages(messages: string[]) {
    if (Platform.OS === "web") {
      return messages;
    }

    return [...messages].reverse();
  }

  const activeConversation = getActiveConversation();
  const [messages, setMessages] = useState(
    getMessages(activeConversation?.messages || [])
  );

  const userId =
    activeConversation && getEmployeeIdFromConversation(activeConversation);

  useEffect(() => {
    if (messages.length !== activeConversation?.messages.length) {
      setMessages(getMessages(activeConversation?.messages || []));
    }
  }, [activeConversation?.messages.length]);

  const renderChatMessage = useCallback(
    (data: { item: string }) => (
      <WPChatMessage
        messageId={data.item}
        isLastSeen={
          activeConversation?.messages_seen_map?.[userId || 0] === data.item
        }
      />
    ),
    []
  );

  const keyExtractor = useCallback((item: string) => item, []);

  const renderWeb = useCallback(
    (item) => (
      <WPChatMessage
        key={keyExtractor(item)}
        messageId={item}
        isLastSeen={
          activeConversation?.messages_seen_map?.[userId || 0] === item
        }
      />
    ),
    []
  );

  if (!activeConversation) {
    return <View style={styles.container} />;
  }

  return (
    <View style={styles.mainContainer}>
      <View style={styles.switcher}>
        <WPConversationGroupSelect />
      </View>
      {Platform.OS === "web" && !isMobileView() ? (
        <ScrollView style={styles.messagesContainer}>
          {messages.map(renderWeb)}
        </ScrollView>
      ) : (
        <FlatList
          inverted={Platform.OS !== "web"}
          removeClippedSubviews
          contentContainerStyle={styles.messagesContainer}
          data={messages}
          renderItem={renderChatMessage}
          keyExtractor={keyExtractor}
          updateCellsBatchingPeriod={100}
        />
      )}

      {/* <View style={styles.switcher}> */}

      {/* </View> */}
    </View>
  );
}

export default memo(WPMessagesContainer);

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    position: "relative",
  },
  container: {
    flex: 1,
  },
  messagesContainer: {
    paddingHorizontal: 8,
  },
  padding: {
    backgroundColor: "red",
    paddingVertical: 50,
    height: 100,
  },
  switcher: {
    margin: 8,
  },
});
