import React, { useMemo } from "react";
import {
  StyleSheet,
  Image,
  View,
  ImageBackground,
  Platform,
} from "react-native";
import theme from "../../../utils/theme";
import WPImageLoaderWrapper from "../../misc/WPImageLoaderWrapper";
import WPText from "../../Text/WPText";
import { WPBaseInputProps } from "../WPInput";
import WPIcon from "../../Icon/WPIcon";
import WPError from "./WPError";
import WPTooltipText from "./WPTooltipText";
import { useTranslation } from "react-i18next";

type WPImageLoaderProps<T> = WPBaseInputProps<T> & {
  isRounded?: boolean;
  tooltip?: string;
  isMain?: boolean;
  onDelete?: () => void;
};

export default function WPImageLoader(
  props: WPImageLoaderProps<string | { image: string; id: number }>
) {
  const { t } = useTranslation();
  let extraActions: { [key: string]: () => void } = {};

  if (props.value) {
    extraActions.onDelete = () => {
      props.onChange && props.onChange("");
      if (props.onDelete) {
        props.onDelete();
      }
    };
  }

  const image = useMemo(() => {
    return {
      uri:
        typeof props.value === "object" && props.value
          ? props.value.image
          : props.value,
    };
  }, [props.value]);

  const onChange = (url: string) => {
    if (props.value && props.onDelete) {
      props.onDelete();
    }

    props.onChange && props.onChange(url);
  };

  return (
    <>
      <View style={styles.container}>
        <WPImageLoaderWrapper
          style={getWrapperStyle(!!props.value, !!props.isRounded)}
          onImageSelected={onChange}
          {...extraActions}
        >
          {props.value ? (
            Platform.OS === "web" ? (
              <div
                style={StyleSheet.flatten([
                  getImageStyle(!!props.isRounded),
                  {
                    backgroundImage: `url(${image.uri})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  },
                ])}
              />
            ) : (
              <ImageBackground
                source={image}
                style={getImageStyle(!!props.isRounded)}
              />
            )
          ) : (
            <View style={getPlaceholderStyle(!!props.isRounded)}>
              <WPIcon name="camera" size={20} color={theme.colors.main} />
              {props.isMain && (
                <WPText style={styles.label}>{t("labels.main")}</WPText>
              )}
            </View>
          )}
        </WPImageLoaderWrapper>
        {props.tooltip ? (
          <View style={styles.tooltip}>
            <WPTooltipText text={props.tooltip} />
          </View>
        ) : null}
      </View>

      {props.error && <WPError error={props.error} />}
    </>
  );
}

const size = 100;
const imageWidth = 85;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  tooltip: {
    marginLeft: 12,
    flex: 1,
    alignItems: "center",
  },
  default: {
    // padding: 10,
    height: size,
    width: size,
    borderStyle: "dashed",
    borderWidth: 1,
    borderRadius: theme.input.borderRadius,
    borderColor: theme.input.blurColor,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
    // backgroundColor:
    //   ? theme.components.imageInput.backgroundColorWithImage
  },
  withValue: {
    borderStyle: "solid",
    borderColor: theme.colors.mains[3],
    backgroundColor: theme.colors.mains[6],
  },
  rounder: {
    borderRadius: size / 2,
  },
  label: {
    borderTopRightRadius: theme.input.borderRadius,
    textAlign: "center",
    fontSize: 12,
    paddingHorizontal: 16,
    backgroundColor: theme.colors.main,
    color: theme.colors.white,
    bottom: 0,
    left: 0,
    paddingVertical: 4,
    position: "absolute",
    // color: theme.input.blurColor,
  },
  image: {
    width: imageWidth,
    height: imageWidth,
    borderRadius: theme.input.borderRadius,
    overflow: "hidden",
    resizeMode: "cover",
    justifyContent: "center",
  },
  imageRounded: {
    borderRadius: imageWidth / 2,
  },
  placeholder: {
    backgroundColor: theme.colors.mains[6],
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "absolute",
  },
});

function getWrapperStyle(hasValue: boolean, isRounded: boolean) {
  if (!hasValue && !isRounded) {
    return styles.default;
  }

  const style: any[] = [styles.default];

  if (isRounded) {
    style.push(styles.rounder);
  }

  if (hasValue) {
    style.push(styles.withValue);
  }

  return style;
}

function getImageStyle(isRounded: boolean) {
  if (!isRounded) {
    return styles.image;
  }

  return StyleSheet.flatten([styles.image, styles.imageRounded]);
}

function getPlaceholderStyle(isRounded: boolean) {
  return [styles.placeholder, getImageStyle(isRounded)];
}
