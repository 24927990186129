import React, { memo, useCallback } from "react";
import HeaderButton from "./HeaderButton";
import { goBack } from "../navigationHelpers";
import theme from "../../utils/theme";

function BackButton(props: { color?: string; fallBackRoute?: string }) {
  const back = useCallback(() => {
    goBack(props.fallBackRoute || "CRM");
  }, [props.fallBackRoute]);

  return (
    <HeaderButton
      onPress={back}
      name="back"
      type="icon"
      color={props.color || theme.colors.white}
    />
  );
}

export default memo(BackButton);
