import * as WPFirebase from "firebase/app";
import { firebaseConfig } from "../utils/appConfig";

WPFirebase.initializeApp(firebaseConfig);

export default WPFirebase;

// console.log(WPFirebase.messaging());

// export const wpGetToken = WPFirebase.messaging().getToken;
