import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const MoreIcon: React.FC<IconProps> = (props) => {
  let height = 18;
  let width = 4;
  const ratio = width / height;
  const color = props.color || "#919EAB";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0  4 18" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M2 14a2 2 0 110 4 2 2 0 010-4zm0-7a2 2 0 110 4 2 2 0 010-4zm0-7a2 2 0 110 4 2 2 0 010-4z"
      />
    </Svg>
  );
};

MoreIcon.displayName = "MoreIcon";

export default MoreIcon;
