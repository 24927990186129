import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const DisableIcon: React.FC<IconProps> = (props) => {
  let height = 20;
  let width = 20;
  const ratio = width / height;
  const color = props.color || "#637381";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M10 2a8 8 0 110 16 8 8 0 010-16zm0 1.5c-3.584 0-6.5 2.916-6.5 6.5s2.916 6.5 6.5 6.5 6.5-2.916 6.5-6.5-2.916-6.5-6.5-6.5zm3.2 5.75a.75.75 0 01.102 1.493l-.102.007H6.8a.75.75 0 01-.102-1.493L6.8 9.25h6.4z"
      />
    </Svg>
  );
};

DisableIcon.displayName = "DisableIcon";

export default DisableIcon;
