import React from "react";
import WPText from "../../Text/WPText";
import { StyleSheet } from "react-native";
import theme, { getThemeResponsiveValue } from "../../../utils/theme";

type WPErrorProps = {
  error: string | string[];
};

const WPError: React.FC<WPErrorProps> = (props) => {
  return <WPText style={styles.default}>{props.error}</WPText>;
};

const styles = StyleSheet.create({
  default: {
    fontFamily: theme.fontFamily.normal,
    fontSize: getThemeResponsiveValue(theme.input.labelBlurFontSize) - 2,
    color: theme.input.errorColor,
  },
});

export default React.memo(WPError);
