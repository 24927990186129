import React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import theme, {
  getThemeResponsiveValue,
  isMobileView,
} from "../../utils/theme";
import WPText from "../Text/WPText";
import PrependAppendDecorator from "./PrependAppendDecorator";

export type SwitcherItem = {
  label: React.ReactNode;
  badge?: string | number;
  onPress: () => void;
  isActive: boolean;
};

type WPSwitcherProps = {
  items: SwitcherItem[];
  appearance?: "link";
};

export default function WPSwitcher(props: WPSwitcherProps) {
  return (
    <View
      style={
        props.appearance === "link" ? styles.containerLink : styles.container
      }
    >
      {props.items.map((item, idx) => {
        const orderStyle =
          idx === 0 && props.appearance !== "link"
            ? styles.firstItem
            : idx === props.items.length - 1 && props.appearance !== "link"
            ? styles.lastItem
            : undefined;
        const mainStyle =
          props.appearance === "link" ? styles.itemLink : styles.item;
        const activeStyle =
          props.appearance === "link" && item.isActive
            ? styles.activeStateLink
            : props.appearance === "link"
            ? styles.inActiveStateLink
            : item.isActive
            ? styles.activeState
            : styles.inActiveState;

        const style = [orderStyle, mainStyle, activeStyle];

        return (
          <TouchableOpacity
            style={style}
            key={item.label?.toString()}
            onPress={item.onPress}
          >
            <PrependAppendDecorator>
              <View style={styles.centered}>
                <WPText
                  style={
                    item.isActive
                      ? props.appearance === "link"
                        ? styles.activeTextLink
                        : styles.activeText
                      : styles.nonActiveTextLink
                  }
                >
                  {item.label}
                </WPText>
              </View>
            </PrependAppendDecorator>
          </TouchableOpacity>
        );
      })}
    </View>
  );
}

const fontSize = isMobileView()
  ? getThemeResponsiveValue(theme.button.fontSize) - 2
  : getThemeResponsiveValue(theme.button.fontSize);

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    height: getThemeResponsiveValue(theme.button.size.small),
  },
  containerLink: {
    flexDirection: "row",
    height: getThemeResponsiveValue(theme.button.size.regular),
  },
  centered: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  item: {
    overflow: "hidden",
    flex: 1,
    borderColor: theme.colors.primary,
    borderWidth: 1,
  },
  itemLink: {
    flex: 1,
    overflow: "hidden",
  },
  firstItem: {
    borderTopLeftRadius: theme.button.borderRadius,
    borderBottomLeftRadius: theme.button.borderRadius,
  },
  lastItem: {
    borderTopRightRadius: theme.button.borderRadius,
    borderBottomRightRadius: theme.button.borderRadius,
  },
  activeState: {
    backgroundColor: theme.colors.primary,
  },
  inActiveState: {
    backgroundColor: theme.colors.white,
    borderColor: theme.colors.primary,
  },
  inActiveStateLink: {
    borderBottomColor: theme.colors.transparent,
    borderBottomWidth: 3,
  },
  activeStateLink: {
    borderBottomColor: theme.colors.primary,
    borderBottomWidth: 3,
  },
  activeText: {
    color: theme.colors.white,
    fontFamily: "bold",
    fontSize,
  },
  activeTextLink: {
    color: theme.colors.primary,
    fontFamily: "bold",
    fontSize,
  },
  nonActiveTextLink: {
    fontFamily: "bold",
    fontSize,
  },
});
