import CrmRouter from "./crm/CrmRouter";
import AuthRouter from "./auth/AuthRouter";
import { buildRouter, RouterConfig } from "./navigationHelpers";
import { RouterType } from "./navigationConstants";

const AUTH = "AUTH";
const CRM = "CRM";

const RootRouterConfig: RouterConfig = {
  type: RouterType.stack,
  containerConfig: {
    initialRouteName: CRM,
    screenOptions: { gestureEnabled: false },
  },
  screens: {
    [AUTH]: {
      options: { headerShown: false },
      name: AUTH,
      component: AuthRouter,
    },
    [CRM]: {
      options: { headerShown: false },
      name: CRM,
      component: CrmRouter,
    },
  },
};

export default buildRouter(RootRouterConfig);
