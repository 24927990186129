import React, { ReactNode } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import theme, { getThemeResponsiveValue } from "../../utils/theme";
import WPIcon from "../Icon/WPIcon";

export default function ModalContentWithClose(props: {
  children: ReactNode;
  onClose: () => void;
}) {
  return (
    <View style={styles.container}>
      {props.children}

      <TouchableOpacity onPress={props.onClose} style={styles.close}>
        <WPIcon name="plus" color={theme.colors.white} size={16} />
      </TouchableOpacity>
    </View>
  );
}

const addButtonSize = getThemeResponsiveValue(theme.button.size.regular);

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    position: "relative",
    width: "100%",
  },

  close: {
    position: "absolute",
    right: addButtonSize / 2,
    top: -(addButtonSize / 2),
    backgroundColor: theme.colors.primary,
    height: addButtonSize,
    width: addButtonSize,
    borderRadius: addButtonSize / 2,
    alignItems: "center",
    justifyContent: "center",
    transform: [{ rotate: "45deg" }],
  },
});
