import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityListCallConfig } from "../../../services/apis/jobApi";
import { useCallback } from "react";
import {
  getEstateListCall,
  Estate,
  OfferStatus,
  getTotalEstatePrice,
  getEstateCall,
  deleteEstateCall,
} from "../../../services/apis/estateApi";
import {
  gotEstates,
  gotEstate,
  getEstate,
} from "../../../services/store/actions/estateActions";
import theme from "../../../utils/theme";
import { TFunction } from "i18next";
import WPText from "../../../components/Text/WPText";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import WPMoreButton from "../../../components/misc/WPMoreButton";
import { IconName } from "../../../components/Icon/WPIcon";
import { navigateTo } from "../../navigationHelpers";
import { RootState } from "../../../services/store/reducers/mainReducer";
import EstateTile from "./screens/components/EstateTile";
import { useTranslation } from "react-i18next";
import DataListItem from "../../../components/misc/DataListItem";
import { getNormalizedDate } from "../../../utils/mixUtils";

export const useGetEstate = (id: number) => {
  const dispatch = useDispatch();
  const estate = useSelector((state: RootState) => state.estate.estates[id]);

  useEffect(() => {
    if (
      !estate ||
      (estate && !estate["data"] && !estate.isLoading && !estate.isLoaded)
    ) {
      dispatch(getEstate(id));
      getEstateCall(id).then((estate) => dispatch(gotEstate(estate)));
    }
  }, [estate]);

  return estate;
};

export const useGetEstateList = () => {
  const dispatch = useDispatch();

  return useCallback(
    (config: EntityListCallConfig) =>
      getEstateListCall(config).then((res) => {
        dispatch(
          gotEstates({
            estates: res.results,
            total: res.total,
          })
        );
        return res;
      }),
    [dispatch]
  );
};

export const getEstateMoreOptions = (
  t: TFunction,
  estateId: number,
  refresh: () => void
) =>
  [
    {
      text: t("labels.edit"),
      icon: "pencil",
      onClick: () => navigateTo(`CRM.JOB.ESTATE_EDIT?estateId=${estateId}`),
    },
    {
      text: t("labels.duplicate"),
      icon: "duplicate",
      onClick: () =>
        navigateTo(`CRM.JOB.ESTATE_DUPLICATE?estateId=${estateId}`),
    },
    {
      text: t("labels.delete"),
      icon: "trash",
      onClick: () => deleteEstateCall(estateId).then(refresh).catch(refresh),
    },
  ].filter((item) => !!item) as {
    text: string;
    icon: IconName;
    onClick: () => void;
  }[];

export const useRenderEstateTile = (config: {
  actionConfig?: { [key: string]: boolean };
  isCompactView?: boolean;
  hideStatistics?: boolean;
  onTileClick?: (item: any) => void;
}) => {
  const { t, i18n } = useTranslation();

  const renderItem = useCallback((item: Estate, refresh: () => void) => {
    let image: string | undefined = undefined;

    if (item.images && item.images.length) {
      if (typeof item.images[0] === "object") {
        image = item.images[0].image;
      } else {
        image = item.images[0];
      }
    }

    return (
      <TouchableOpacity
        disabled={!config.onTileClick}
        onPress={() => config.onTileClick && config.onTileClick(item)}
      >
        <DataListItem
          isCompactView={config.isCompactView}
          image={{
            uri: image,
            size: config.isCompactView ? "m" : "l",
          }}
          title={item.title}
          onTitleClick={() => {
            // !config.onTileClick && showJobCandidates(item.id);
          }}
          subtitle1={{
            text: `${t("labels.from")}: ${getNormalizedDate(
              item.start_date,
              i18n.language,
              true
            )}`,
          }}
          subtitle2={
            config.isCompactView
              ? undefined
              : {
                  text: item.city.name,
                  icon: "place",
                  isAdditional: true,
                }
          }
          subtitle3={
            config.isCompactView
              ? {
                  text: item.city.name,
                  icon: "place",
                  isAdditional: true,
                }
              : undefined
          }
          actions={
            config.actionConfig
              ? getEstateMoreOptions(t, item.id, refresh)
              : undefined
          }
          // additionalNode={
          //   config.hideStatistics ? undefined : (
          //     <JobStatistics
          //       offerId={item.id}
          //       isCompactView={config.isCompactView}
          //     />
          //   )
          // }
        />
      </TouchableOpacity>
    );
  }, []);
  return renderItem;
};

const styles = StyleSheet.create({
  employeeTile: {
    paddingHorizontal: theme.input.paddingHorizontal,
  },
  tileContainer: {
    paddingLeft: theme.input.paddingHorizontal,
    paddingRight: theme.input.paddingHorizontal / 2,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  title: {
    fontFamily: "bold",
    color: theme.colors.primary,
  },
  moreButton: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  tile: {
    flex: 1,
    overflow: "hidden",
  },
  tileMoreButton: {
    alignItems: "flex-start",
  },
});
