import React from "react";
import WPText from "../../Text/WPText";
import { StyleSheet } from "react-native";
import theme, { getThemeResponsiveValue } from "../../../utils/theme";

type WPTooltipTextProps = {
  text: string | string[];
};

const WPTooltipText: React.FC<WPTooltipTextProps> = (props) => {
  let normalizedText = Array.isArray(props.text)
    ? props.text.join(", ")
    : props.text;

  normalizedText = normalizedText.trim();

  normalizedText =
    normalizedText.charAt(0).toUpperCase() + normalizedText.slice(1);

  return <WPText style={styles.default}>{normalizedText}</WPText>;
};

const styles = StyleSheet.create({
  default: {
    fontFamily: theme.fontFamily.normal,
    fontSize: getThemeResponsiveValue(theme.input.labelBlurFontSize) - 2,
    opacity: 0.6,
  },
});

export default React.memo(WPTooltipText);
