import React, { memo, useCallback } from "react";
import { useGetJob } from "../../../../services/hooks/jobHooks";
import JobEditor from "../components/JobEditor";
import { useTranslation } from "react-i18next";
import { navigateTo } from "../../../navigationHelpers";

type EditJobScreenProps = {
  route: {
    params: {
      jobId: number;
    };
  };
};

function EditJobScreen(props: EditJobScreenProps) {
  const { t } = useTranslation();
  const job = useGetJob(props.route.params.jobId);

  const onSaved = useCallback((offerId: number) => {
    navigateTo(`CRM.JOB.ACTIVE_JOB_LIST`);
  }, []);

  if (!job || !job?.data) {
    return null;
  }

  return (
    <JobEditor
      job={job.data}
      title={t("titles.edit_job_offer")}
      onSaved={onSaved}
    />
  );
}

export default memo(EditJobScreen);
