import React, { memo, useState, useEffect, useCallback } from "react";
import {
  Application,
  getApplicationsCall,
  defaultPagination,
  updateApplicationStatusCall,
} from "../../services/apis/jobApi";
import { logError } from "../../services/logger";
import { useSendMessageByTypeByUser } from "../../navigation/crm/chat/components/conversationHooks";
import {
  ConversationType,
  ApplicationStatus,
} from "../../services/chatService";
import { View, StyleSheet } from "react-native";
import MobileListTile from "./MobileListTile";
import WPText from "../Text/WPText";
import theme from "../../utils/theme";
import { useTranslation } from "react-i18next";
import { getNormalizedDate, getPaymentInfo } from "../../utils/mixUtils";
import WPSeparator from "./WPSeparator";
import { Formik } from "formik";
import FormContainer from "../form/FormContainer";
import FormRow from "../form/FormRow";
import FormField from "../form/FormField";
import LoadingIcon from "../Icon/icons/LoadingIcon";

function UserApplicationsPreview(props: { userId: number }) {
  const { t, i18n } = useTranslation();
  const sendNewStatusMessage = useSendMessageByTypeByUser(
    props.userId,
    ConversationType.JOB
  );
  const [applications, setApplications] = useState<Application[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getApplicationsCall({
      filter: {
        employee: props.userId,
      },
      pagination: defaultPagination,
    })
      .then((res) => {
        setIsLoading(false);
        setApplications(res.results);
      })
      .catch((err) => {
        setIsLoading(false);
        logError(err);
      });
  }, [props.userId]);

  const changeStatus = useCallback(
    (application: Application, status: ApplicationStatus) => {
      updateApplicationStatusCall(application.id, status)
        .then(() => {
          return sendNewStatusMessage(
            application.job_offer.id,
            ConversationType.JOB,
            { status }
          );
        })
        .catch(logError);
    },
    [props.userId]
  );
  if (isLoading) {
    return <LoadingIcon />;
  }

  return (
    <View>
      {applications.map((item) => (
        <View key={item.id.toString()}>
          <View>
            <WPText style={styles.date}>
              {`${t("labels.application_date")}: ${getNormalizedDate(
                item.created,
                i18n.language
              )}`}
            </WPText>
            <View style={styles.mainData}>
              <MobileListTile
                title={item.job_offer.title}
                subtitle={item.job_offer.company_name}
                location={item.job_offer.city.name}
                firstDesc={
                  <WPText style={styles.paymentInfo}>
                    {getPaymentInfo(item.job_offer, t)}
                  </WPText>
                }
              />
            </View>
            <Formik
              initialValues={{ job_application_status: item.status }}
              onSubmit={(values) => {}}
              validate={(values) => {
                changeStatus(item, values.job_application_status);
              }}
            >
              {(formikProps) => (
                <FormContainer>
                  <FormRow>
                    <FormField
                      disabled={item.job_offer.status !== "PUBLISHED"}
                      label={t("labels.status")}
                      name="job_application_status"
                      type="dropdown"
                      {...formikProps}
                      errors={{}}
                    />
                  </FormRow>
                </FormContainer>
              )}
            </Formik>
          </View>
          <WPSeparator />
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  date: {
    fontSize: 12,
    color: theme.colors.mains[3],
    marginBottom: 4,
    fontFamily: "bold",
  },
  paymentInfo: {
    fontFamily: "bold",
  },
  mainData: {
    marginBottom: 12,
  },
});

export default memo(UserApplicationsPreview);
