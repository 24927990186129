import { memo } from "react";
import { useGetEstate, useRenderEstateTile } from "../../estateUtils";

function EstateTile(props: { id: number }) {
  const estate = useGetEstate(props.id);
  const renderItem = useRenderEstateTile({
    isCompactView: true,
    hideStatistics: true,
  });

  if (!estate || !("data" in estate)) {
    return null;
  }

  const estateData = estate.data;

  if (!estateData) {
    return null;
  }

  return renderItem(estateData, () => {});
}
export default memo(EstateTile);
