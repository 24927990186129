import React from "react";
import { StyleSheet, View } from "react-native";

export type PrependAppendDecoratorProps = {
  prepend?: React.ReactNode;
  append?: React.ReactNode;
};

const PrependAppendDecorator: React.FC<PrependAppendDecoratorProps> = (
  props
) => {
  return (
    <View style={style.container}>
      {props.prepend && <View style={style.prepend}>{props.prepend}</View>}
      <View style={style.main}>{props.children}</View>
      {props.append && <View style={style.append}>{props.append}</View>}
    </View>
  );
};

const style = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: "center",
    flexDirection: "row",
  },
  main: {
    flexGrow: 1,
    flexShrink: 4,
    flexBasis: "auto",
    justifyContent: "center",
  },
  append: {
    flexGrow: 0,
    flexShrink: 1,
    justifyContent: "center",
    marginLeft: 8,
  },
  prepend: {
    flexGrow: 0,
    flexShrink: 1,
    justifyContent: "center",
    marginRight: 8,
  },
});

export default PrependAppendDecorator;
