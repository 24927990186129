// TODO: make an abstraction
import React from "react";
import { useTranslation } from "react-i18next";
import CRMDataScreen from "../../common/CRMDataScreen";
import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { RootState } from "../../../../services/store/reducers/mainReducer";
import {
  useRenderPaymentCard,
  useGetTransactions,
} from "../../../../services/hooks/paymentHooks";
import { isMobileView } from "../../../../utils/theme";
export default function BillsScreen() {
  const isUpdateScheduled = useSelector(
    (state: RootState) => state.ui.shouldUpdateList
  );

  const navigation = useNavigation();
  const getTransactions = useGetTransactions();
  const { i18n, t } = useTranslation();

  const renderItem = useRenderPaymentCard(isMobileView());

  return (
    <CRMDataScreen
      navigation={navigation}
      title={t("titles.bills")}
      getData={getTransactions}
      renderItem={renderItem}
      language={i18n.language}
      isUpdateScheduled={isUpdateScheduled}
    />
  );
}
