import React, { memo } from "react";
import { useGetJob } from "../../../../../services/hooks/jobHooks";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import WPText from "../../../../../components/Text/WPText";
import theme, { isMobileView } from "../../../../../utils/theme";
import { useTranslation } from "react-i18next";
import { ApplicationStatus } from "../../../../../services/chatService";
import WPIcon, { IconName } from "../../../../../components/Icon/WPIcon";
import { useDispatch } from "react-redux";
import { setJobPreview } from "../../../../../services/store/actions/uiActions";
import WPJobMessageTile from "../misc/WPJobMessageTile";

function WPJobMessage(props: {
  jobId: number;
  status?: ApplicationStatus;
  isEmployer: boolean;
}) {
  const dispatch = useDispatch();
  const job = useGetJob(props.jobId);
  const { t } = useTranslation();

  let icon: IconName;
  let color: string;
  let text: string;

  switch (props.status) {
    case ApplicationStatus.NEW:
      icon = "appliedStatus";
      color = theme.colors.statuses[ApplicationStatus.NEW].primary;
      text = t("misc.chat_applied_text");
      break;

    case ApplicationStatus.RFS:
      icon = "rejectedStatus";
      color = theme.colors.error;
      text = t("misc.chat_rejected_text");

      break;

    default:
      icon = "changedStatus";
      color = theme.colors.warning;
      text = `${t("misc.chat_common_status_text")} "${t(
        `select.job_application_status.${props.status}`
      )}"`;
      break;
  }

  const jobData = job ? job.data : undefined;
  const bgColor = props.status
    ? theme.colors.statuses[props.status]?.secondary
    : theme.colors.statuses.default.secondary;

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => dispatch(setJobPreview(jobData))}
    >
      {props.status && (
        <View
          style={props.isEmployer ? employerStatus : styles.statusContainer}
        >
          <WPIcon name={icon} />

          <WPText
            style={{
              marginLeft: 4,
              color,
            }}
            numberOfLines={1}
          >
            {text}
          </WPText>
        </View>
      )}
      {props.jobId ? (
        <WPJobMessageTile jobId={props.jobId} background={bgColor} />
      ) : null}
    </TouchableOpacity>
  );
}

export default memo(WPJobMessage);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 280,
  },
  statusContainer: {
    flexDirection: "row",
    marginBottom: isMobileView() ? 12 : 9,
    alignItems: "center",
  },
  statusEmployer: {
    justifyContent: "flex-end",
  },
});

const employerStatus = [styles.statusContainer, styles.statusEmployer];
