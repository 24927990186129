import React, { useCallback } from "react";
import { IconButtonProps } from "../../components/Button/WPBaseButton";
import { useSelector, useDispatch } from "react-redux";
import { getTotalUnreadCount } from "../../services/store/reducers/mainReducer";
import NotificationLabel from "../../components/misc/NotificationLabel";
import { View, StyleSheet } from "react-native";
import WPButton from "../../components/Button/WPButton";
import WPIcon from "../../components/Icon/WPIcon";
import theme, { isMobileView } from "../../utils/theme";
import { closeConversation } from "../../services/store/actions/chatActions";

const ChatToggle: React.FC<Omit<IconButtonProps, "type" | "name"> & {}> = (
  props
) => {
  const totalUnread = useSelector(getTotalUnreadCount);
  const dispatch = useDispatch();
  const closeActiveChat = useCallback(() => {
    dispatch(closeConversation());
  }, [dispatch]);

  return (
    <WPButton
      pageLink={isMobileView() ? "CRM.JOB.CHAT_LIST" : undefined}
      appearance="link"
      onPress={closeActiveChat}
    >
      <WPIcon name="chat" color={theme.colors.white} />

      {totalUnread ? (
        <View style={style.label}>
          <NotificationLabel label={totalUnread.toString()} />
        </View>
      ) : null}
    </WPButton>
  );
};

const style = StyleSheet.create({
  label: {
    position: "absolute",
    top: 7,
    right: -7,
  },
});

export default React.memo(ChatToggle);
