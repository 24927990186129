import React, { useMemo, memo } from "react";
import { StyleSheet, View, ViewStyle, StyleProp } from "react-native";
import WPError from "../input/components/WPError";
type FormContainerProp = {
  children: React.ReactNode;
  withPadding?: boolean;
  style?: StyleProp<ViewStyle>;
  error?: string | string[];
};

function mapChildren(children: React.ReactNode) {
  const totalChildren = React.Children.count(children);

  return React.Children.map(children, (child, idx) => (
    <>
      {child}
      <View style={idx < totalChildren - 1 ? styles.formChild : undefined} />
    </>
  ));
}

function FormContainer(props: FormContainerProp) {
  const containerStyling = useMemo(
    () => [
      props.withPadding
        ? styles.formContainerWithPadding
        : styles.formContainer,
      props.style,
    ],
    [props.withPadding]
  );

  return (
    <View style={containerStyling}>
      {props.error && (
        <View style={styles.formError}>
          <WPError error={props.error} />
        </View>
      )}
      {mapChildren(props.children)}
    </View>
  );
}

const styles = StyleSheet.create({
  formContainer: {
    flexDirection: "column",
    // alignItems: "stretch",
  },
  formContainerWithPadding: {
    flexDirection: "column",
    padding: 16,
  },
  formChild: {
    marginBottom: 20,
  },
  formError: {
    marginBottom: 8,
  },
});

export default memo(FormContainer);
