import React from "react";
import Svg, { Path, G, Defs, ClipPath } from "../SVG";
import { IconProps } from "../WPIcon";

const ApplyIcon: React.FC<IconProps> = (props) => {
  let height = 13;
  let width = 13;
  const ratio = width / height;
  const color = props.color || "#0076FF";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg
      {...props}
      width={width}
      height={height}
      style={[{ height, width }, props.style]}
      viewBox={`0 0 13 13`}
    >
      <G fill="#919EAB" clipPath="url(#prefix__clip0)">
        <Path d="M5.045 7.594h-.41a2.151 2.151 0 01-2.148-2.149V3.107c0-1.184.964-2.148 2.148-2.148h.41c1.184 0 2.148.964 2.148 2.148v2.34a2.151 2.151 0 01-2.148 2.148zm-.41-5.965a1.48 1.48 0 00-1.477 1.477v2.34a1.48 1.48 0 001.477 1.477h.41a1.48 1.48 0 001.477-1.478V3.107A1.48 1.48 0 005.045 1.63h-.41z" />
        <Path d="M4.84 10.96H.335a.335.335 0 01-.336-.336v-.802c-.03-.876 2.18-1.546 3.676-1.898v-.621a.335.335 0 11.671 0v.888a.335.335 0 01-.262.327c-1.71.384-3.307.996-3.416 1.311l.001.46h4.17a.335.335 0 01-.001.67z" />
        <Path d="M9.344 10.96H4.84a.335.335 0 010-.671h4.168v-.467c-.107-.308-1.703-.92-3.413-1.304a.334.334 0 11.146-.654c1.484.332 3.97 1.03 3.938 1.97v.79c0 .185-.15.335-.335.335zm4.404-8.488h-4.36a.335.335 0 010-.67h4.36a.335.335 0 010 .67zm0 2.14h-4.36a.335.335 0 010-.671h4.36a.335.335 0 010 .671zm0 2.138h-4.36a.335.335 0 010-.671h4.36a.335.335 0 010 .671z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path
            fill="#fff"
            d="M0 0h14.083v14.083H0z"
            transform="translate(0 -1.083)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

ApplyIcon.displayName = "ApplyIcon";

export default ApplyIcon;
