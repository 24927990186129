import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const MessageSeenMark: React.FC<IconProps> = (props) => {
  let height = 20;
  let width = 20;
  const ratio = width / height;
  const color = props.color || "#50B83C";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }
  return (
    <Svg width={width} height={height} viewBox="0 0 16 16" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M8 1.6a6.4 6.4 0 110 12.8A6.4 6.4 0 018 1.6zm2.19 4.562l-2.62 2.82-1.254-1.149a.6.6 0 10-.811.885l1.693 1.552a.6.6 0 00.845-.034l3.025-3.258a.6.6 0 00-.879-.816z"
      />
    </Svg>
  );
};

MessageSeenMark.displayName = "MessageSeenMark";

export default MessageSeenMark;
