import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const LogoIcon: React.FC<IconProps> = (props) => {
  let height = 35;
  let width = 123;
  const ratio = width / height;
  const color = props.color || "#1C1C1C";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg
      {...props}
      width={width}
      height={height}
      style={[{ height, width }, props.style]}
      viewBox="0 0 123 35"
    >
      <Path
        fill={color}
        fillRule="evenodd"
        d="M74.305 20.882c1.893 0 3.273-1.4 3.273-3.502 0-2.073-1.38-3.498-3.273-3.498-1.76 0-3.33 1.425-3.33 3.498 0 2.102 1.57 3.502 3.33 3.502zm.891 3.663c-1.759 0-3.165-.538-4.22-1.481v7.54h-4.329V10.622h4.329V11.7c1.055-.945 2.461-1.481 4.22-1.481 3.6 0 6.63 2.908 6.63 7.16 0 4.285-3.03 7.166-6.63 7.166zm7.195 6.484a8.702 8.702 0 01-6.142-2.515l-2.806 2.823A12.674 12.674 0 0082.391 35c3.37 0 6.55-1.3 8.948-3.663l-2.805-2.823a8.706 8.706 0 01-6.143 2.515zM63.619 10.667h-5.062l-4.897 5.414V3.451h-4.328v20.737h4.328v-2.29l2.059-2.314 3.624 4.604h5.331l-6.061-7.864 5.006-5.657zm30.349 6.713c0-2.099-1.596-3.498-3.273-3.498-1.76 0-3.357 1.4-3.357 3.498 0 2.13 1.597 3.502 3.357 3.502 1.677 0 3.273-1.372 3.273-3.502zm4.167 0c0 4.284-3.41 7.137-7.44 7.137-4.115 0-7.524-2.853-7.524-7.137 0-4.253 3.409-7.11 7.524-7.11 4.03 0 7.44 2.857 7.44 7.11zm6.101-4.173v-2.585h-4.328v13.52h4.328v-4.229c0-3.502 1.65-5.062 4.682-5.062h.702v-4.358a3.924 3.924 0 00-.702-.06c-2.22 0-3.708 1.158-4.682 2.774zM122 20.155c-.569.324-1.379.538-1.975.538-1.135 0-1.813-.565-1.813-1.965v-4.686h4.005v-3.42h-4.005V6.097h-4.329v4.525h-2.463v3.42h2.463v5.117c0 3.556 2.029 5.386 5.412 5.386 1.542 0 2.65-.43 3.706-.997L122 20.155zm-107.332-2.19l-2.571-7.298H9.038L6.467 17.94l-2.218-7.273H0l4.358 13.548h3.758l2.464-7.325 2.435 7.325h3.76l4.358-13.548h-4.248l-2.218 7.299zm17.271-.537c0-2.101-1.597-3.5-3.275-3.5-1.76 0-3.355 1.399-3.355 3.5 0 2.128 1.596 3.5 3.355 3.5 1.678 0 3.275-1.372 3.275-3.5zm4.167 0c0 4.282-3.409 7.135-7.442 7.135-4.113 0-7.522-2.853-7.522-7.135 0-4.255 3.41-7.11 7.522-7.11 4.033 0 7.442 2.855 7.442 7.11zm6.084-4.176v-2.585h-4.33v13.52h4.329V19.96c0-3.502 1.651-5.063 4.681-5.063h.706v-4.358a3.999 3.999 0 00-.706-.057c-2.218 0-3.706 1.157-4.681 2.771zm-1.53-8.324L37.856 7.75a13.087 13.087 0 00-9.233-3.78 13.08 13.08 0 00-9.233 3.78l-2.804-2.823A17.051 17.051 0 0128.623 0c4.534 0 8.81 1.75 12.038 4.928z"
      />
    </Svg>
  );
};

LogoIcon.displayName = "LogoIcon";

export default LogoIcon;
