import React, { useState } from "react";
import { StyleSheet, View, Image } from "react-native";
import WPText from "../../../../components/Text/WPText";
import DropDownWrapper from "../../../../components/misc/DropDownWrapper";
import PrependAppendDecorator from "../../../../components/misc/PrependAppendDecorator";
import WPIcon from "../../../../components/Icon/WPIcon";
import WPButton from "../../../../components/Button/WPButton";
import theme, { isMobileView } from "../../../../utils/theme";
import { ListItem } from "../../../../components/list/WPList";
import DescList from "../../../../components/misc/DescList";
import { useTranslation } from "react-i18next";

export default function PackageCard(props: {
  title: string;
  selectValue?: string;
  selectOptions?: ListItem<{ amount: number; price: number }>[];
  onSelectOptionsChange?: (value: { amount: number; price: number }) => void;
  value: string | number;
  subValue: string;
  valueDesc: string;
  options: string[] | { text: string; isNegative: boolean }[];
  buttonText: string;
  onPackageSelected: () => void;
  isHighlighted?: boolean;
  hideButton?: boolean;
  multiplier?: number;
  multiplierText?: string;
}) {
  const [isFocused, setIsFocused] = useState(false);
  const { t } = useTranslation();
  return (
    <View
      style={
        props.isHighlighted
          ? [styles.card, styles.cardHighlighted]
          : styles.card
      }
    >
      {props.isHighlighted ? (
        <View style={styles.starContainer}>
          <View style={styles.star}>
            <WPIcon name="star" style={{ marginLeft: -0.5, marginTop: -1 }} />
          </View>
        </View>
      ) : null}
      {props.multiplier ? (
        <View
          style={{
            marginTop: -32,
            marginBottom: 8,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Image
            style={{ width: 65, height: 67, marginRight: 16 }}
            source={
              props.multiplier === 2
                ? require("../assets/packPromotions/x2.png")
                : require("../assets/packPromotions/x3.png")
            }
          />
          {props.multiplierText ? (
            <WPText
              style={{
                color: theme.colors.mains[3],
                maxWidth: 160,
                fontSize: 12,
              }}
            >
              {props.multiplierText}
            </WPText>
          ) : null}
        </View>
      ) : null}
      <View style={styles.cardTitleContainer}>
        <WPText style={styles.cardTitle}>{props.title.toUpperCase()}</WPText>
        {props.selectValue &&
        props.selectOptions &&
        props.onSelectOptionsChange ? (
          <DropDownWrapper
            items={props.selectOptions}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            onChange={props.onSelectOptionsChange}
          >
            <View style={styles.cardTitleSelect}>
              <PrependAppendDecorator
                append={
                  <WPIcon
                    size={12}
                    name="chevron"
                    style={isFocused ? styles.itemSelected : undefined}
                  />
                }
              >
                <WPText style={styles.cardTitleSelectText}>
                  {`${props.selectValue} ${t("labels.offer_label", {
                    count: +props.selectValue,
                  }).toLowerCase()}`}
                </WPText>
              </PrependAppendDecorator>
            </View>
          </DropDownWrapper>
        ) : null}
      </View>
      <WPText style={styles.cardValue}>
        {props.value} <WPText style={styles.cardSubValue}>zl</WPText>
      </WPText>
      <WPText style={styles.cardValueDesc2}>{t("misc.pack_duration")}</WPText>
      <WPText style={styles.cardValueDesc}>{props.valueDesc}</WPText>
      <View style={styles.cardOptionContainer}>
        <DescList list={props.options} />
      </View>
      {props.hideButton ? null : (
        <WPButton
          size="small"
          style={{ marginHorizontal: 20 }}
          onPress={props.onPackageSelected}
        >
          {props.buttonText}
        </WPButton>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  card: {
    position: "relative",
    paddingHorizontal: 24,
    paddingVertical: 32,
    borderRadius: theme.input.borderRadius,
    backgroundColor: theme.colors.mains[6],
    maxWidth: isMobileView() ? undefined : 320,
    minWidth: 300,
    flex: 1,
  },
  cardHighlighted: {
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.1,
    shadowRadius: 14,
    elevation: 8,
  },
  starContainer: {
    position: "absolute",
    top: -1,
    right: 8,
    left: 8,
    borderTopColor: theme.colors.primary,
    borderTopWidth: 2,
    alignItems: "center",
  },
  star: {
    marginTop: -14,
    height: 28,
    width: 28,
    borderRadius: 14,
    backgroundColor: theme.colors.primary,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderColor: "white",
  },
  cardTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 4,
  },
  cardTitle: {
    fontSize: 18,
    color: theme.colors.primary,
    fontFamily: "black",
    marginRight: 8,
    flex: 1,
  },
  cardTitleSelect: {
    borderRadius: theme.input.borderRadius / 2,
    minHeight: 30,
    backgroundColor: theme.colors.mains[4],
    paddingHorizontal: 8,
  },
  cardTitleSelectText: {
    fontSize: 12,
  },
  cardValue: {
    fontSize: 40,
    fontFamily: "black",
  },
  cardSubValue: {
    fontSize: 40,
    fontFamily: "light",
  },
  cardValueDesc: {
    color: theme.colors.mains[3],
    marginBottom: 32,
  },
  cardValueDesc2: {
    color: theme.colors.mains[3],
    marginBottom: 4,
  },
  cardOptionContainer: {
    marginBottom: 12,
    flex: 1,
  },
  cardOption: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 12,
  },
  cardOptionText: {
    marginLeft: 8,
  },
  itemSelected: {
    transform: [{ rotate: "180deg" }],
  },
});
