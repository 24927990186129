import React from "react";
import { Image, StyleProp, ImageStyle } from "react-native";
import theme from "../../utils/theme";

export default function WPAvatar(props: {
  source?: string;
  size?: "s" | "m" | "l" | "xl";
  style?: StyleProp<ImageStyle>;
}) {
  const avatarSize = theme.avatar.size[props.size || "s"];
  const avatarStyle = {
    height: avatarSize,
    width: avatarSize,
  };

  const avatarSource = props.source
    ? { uri: props.source, ...avatarStyle, cache: "force-cache" }
    : require("../../../assets/avatar.png");

  const style = {
    ...avatarStyle,
    borderRadius: (avatarSize || 40) / 2,
  };

  return (
    <Image
      resizeMode="cover"
      source={avatarSource}
      loadingIndicatorSource={require("../../../assets/avatar.png")}
      style={[style, props.style]}
    />
  );
}
