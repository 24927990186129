import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  Image,
  TextInput,
  TouchableOpacity,
} from "react-native";
import { useGetJob } from "../../../../services/hooks/jobHooks";
import PackageCard from "../components/PackageCard";
import WPText from "../../../../components/Text/WPText";
import { useTranslation } from "react-i18next";
import theme, { isMobileView } from "../../../../utils/theme";
import WPButton from "../../../../components/Button/WPButton";
import {
  useGetPacksByType,
  useGetAvailablePacks,
  useGetDemoPack,
} from "../../../../services/hooks/packsHooks";
import {
  AdPackConfig,
  AdPackType,
  getPromocodeDesc,
  Promo,
} from "../../../../services/apis/adPackConfigApi";
import { navigateTo } from "../../../navigationHelpers";
import { PaymentType } from "./PaymentsScreen";
import { TFunction } from "i18next";
import { useConnectPackToOffer } from "../../../../services/hooks/paymentHooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../../services/store/reducers/mainReducer";
import WPSeparator from "../../../../components/misc/WPSeparator";
import analytics from "../../../../services/analytics/analytics";
import DescList from "../../../../components/misc/DescList";
import WPNotificationModal from "../../../../components/modal/modalVariants/WPNotificationModal";
import ModalContentWithClose from "../../../../components/misc/ModalContentWithClose";

type SelectMap = { [key: number]: { price: number; amount: number } };

type JobPackageSelectScreenParams = {
  route: {
    params: {
      jobId: number;
    };
  };
};

function getPackSelectOptions(pack: AdPackConfig, t: TFunction) {
  return pack.payConfig.map((payOptionData) => ({
    label: `${payOptionData.amount} ${t("labels.offer_label", {
      count: +payOptionData.amount,
    }).toLowerCase()}`,
    value: payOptionData,
  }));
}

function getSelectedPayOption(pack: AdPackConfig, selectMap: SelectMap) {
  return selectMap[pack.id];
}

function getPackDeltaPrice(pack: AdPackConfig, selectMap: SelectMap) {
  const firstOption = pack && pack.payConfig && pack.payConfig[0];
  const selectedOption = getSelectedPayOption(pack, selectMap);

  if (selectedOption) {
    return (
      selectedOption.amount * firstOption.price -
      selectedOption.price
    ).toFixed(2);
  }

  return 0;
}

function getOptionsDescriptions(pack: AdPackConfig, t: TFunction) {
  if (!pack) {
    return [];
  }

  const actionMap = {
    edit: t(`misc.edit_action`),
    desc_photo: t(`misc.desc_photo_action`),
    detail_logo: t(`misc.detail_logo_action`),
    up: t(`misc.up_action`),
    // hot: t(`misc.hot_action`),
    // manager: t(`misc.manager_action`),
  };

  const unusedActions = Object.keys(actionMap);
  let actions: { text: string; isNegative: boolean }[] = [];

  unusedActions.forEach((actionName) => {
    console.log(pack);
    const actionValue = pack.actionsConfig[actionName];
    const actionDesc = actionMap[actionName];

    if (actionValue) {
      actions = [
        {
          text: (actionValue === 9999 ? "" : `${actionValue} `) + actionDesc,
          isNegative: false,
        },
        ...actions,
      ];
    } 
    else {
      actions = [
        ...actions,
        {
          text: actionDesc,
          isNegative: true,
        },
      ];
    }
  });

  return actions;
}

export default function JobPackageSelectScreen(
  props: JobPackageSelectScreenParams
) {
  const targetJobId = props.route?.params?.jobId;
  const { t } = useTranslation();
  const allPackages = useGetPacksByType(AdPackType.job_packet);
  const demoPack = useGetDemoPack();
  const availablePacks = useGetAvailablePacks();
  const job = useGetJob(targetJobId);
  const [isOpened, setIsOpened] = useState(false);
  const [selectedPackId, setSelectedPackId] = useState(0);
  const [nyPromo, setNyPromo] = useState<Promo | undefined>(undefined);
  const [promocode, setPromocode] = useState("");
  const [promocodeRes, setPromocodeRes] = useState<Promo | string | undefined>(
    undefined
  );
  const [packSelectMap, setPackSelectMap] = useState<SelectMap>({});
  const availableJobsCount = useSelector(
    (state: RootState) => state.jobs.totalActive
  );

  const packages = useMemo(() => {
    return allPackages.slice(0, 3);
  }, [allPackages]);

  const enterprisePack = allPackages.find(
    (pack) => pack.name.toLowerCase() === "gold"
  );

  const selectPackage = packages.find((pack) => pack.id === selectedPackId);

  const changeSelection = useCallback(
    (packId, value) => {
      setPackSelectMap({
        ...packSelectMap,
        [packId]: value,
      });
    },
    [setPackSelectMap, packSelectMap]
  );

  useEffect(() => {
    if (packages.length && Object.keys(packSelectMap).length === 0) {
      setSelectedPackId(packages[0].id);
      setPackSelectMap(
        packages.reduce((acc, pack) => {
          acc[pack.id] = pack.payConfig[0];
          return acc;
        }, {})
      );
    }
  }, [packages]);

  useEffect(() => {
    // getPromocodeDesc("WPFORUM")
    //   .then(setNyPromo)
    //   .catch(() => setPromocodeRes(undefined));
    setPromocodeRes(undefined);
  }, []);

  const makePayment = useCallback(
    (packId, value, promoCode?: string, discount?: number) => {
      navigateTo(
        `CRM.JOB.PAYMENTS?paymentType=${PaymentType.job}&offerId=${targetJobId}&packId=${packId}&packValue=${value}&promoCode=${promoCode}&discount=${discount}`
      );
    },
    [targetJobId]
  );

  const connectPackToOffer = useConnectPackToOffer();

  function getAvailablePackAmount(pack: AdPackConfig) {
    return availablePacks.find((data) => data.name === pack.name)?.amount || 0;
  }

  function getPackStatus(
    pack: AdPackConfig,
    isBuyOnlyPack: boolean
  ): "buy" | "use" | "hide" {
    const isPackInWallet = !!getAvailablePackAmount(pack);

    const areJobsAvailable = availableJobsCount;
    const isForConnectJob = !!targetJobId;

    if (isForConnectJob) {
      return isPackInWallet ? "buy" : "use";
    }

    if (areJobsAvailable || isBuyOnlyPack) {
      return isPackInWallet ? "hide" : "buy";
    }

    return "hide";
  }

  const getPackProps = useCallback(
    (pack: AdPackConfig, isBuyOnlyPack: boolean) => {
      const availablePacksAmount = getAvailablePackAmount(pack);

      return {
        title: pack.name,
        value: getSelectedPayOption(pack, packSelectMap)?.price,
        subValue: "zł",
        valueDesc: pack.payConfig[0].amount === 1 ? t("misc.standart_1") : pack.payConfig[0].amount === 3 ?  t("misc.optimum_1") : t("misc.business_1"),
        // availablePacksAmount
        //   ? `${availablePacksAmount} ${t("labels.offer_label", {
        //       count: +availablePacksAmount,
        //     }).toLowerCase()}`
        //   : `${t("misc.advantage")}: ${getPackDeltaPrice(
        //       pack,
        //       packSelectMap
        //     )} zł`,
        options: getOptionsDescriptions(pack, t),
        buttonText: availablePacksAmount ? t("labels.use") : t("labels.buy"),
        onPackageSelected: () => {
          availablePacksAmount
            ? connectPackToOffer({
                packet_config_id: pack.id,
                offer_id: targetJobId,
              })
            : makePayment(pack.id, packSelectMap[pack.id].amount);
        },
        isHighlighted: pack.name === "optimum",
        // selectOptions: availablePacksAmount
        //   ? undefined
        //   : getPackSelectOptions(pack, t),
        // selectValue: getSelectedPayOption(
        //   pack,
        //   packSelectMap
        // )?.amount.toString(),
        // onSelectOptionsChange: (value: any) => {
        //   changeSelection(pack.id, value);
        // },

        hideButton: getPackStatus(pack, isBuyOnlyPack) === "hide",
        multiplier: pack?.actionsConfig?.multiplier,
        multiplierText: pack?.actionsConfig?.multiplier
          ? pack?.actionsConfig?.multiplier === 2
            ? t("promotion.blackFriday")
            : t("promotion.blackFriday_x3")
          : undefined,
      };
    },
    [makePayment, packSelectMap]
  );

  const isEnterpriseAvailabel =
    enterprisePack &&
    enterprisePack.payConfig &&
    Array.isArray(enterprisePack.payConfig);

  const nyPrice =
    enterprisePack && isEnterpriseAvailabel
      ? enterprisePack.payConfig[0].price - Math.abs(nyPromo?.priceOffset || 0)
      : 0;

  const priceWithPromo =
    promocodeRes &&
    typeof promocodeRes !== "string" &&
    enterprisePack &&
    isEnterpriseAvailabel
      ? enterprisePack.payConfig[0].price - Math.abs(promocodeRes.priceOffset)
      : nyPrice;

  const promoName =
    promocodeRes && typeof promocodeRes !== "string"
      ? promocodeRes.code
      : nyPromo
      ? nyPromo.code
      : undefined;
  const promoDiscount =
    promocodeRes && typeof promocodeRes !== "string"
      ? Math.abs(promocodeRes.priceOffset)
      : nyPromo
      ? Math.abs(nyPromo.priceOffset)
      : undefined;

  return (
    <ScrollView style={{ flex: 1 }} contentContainerStyle={styles.container}>
      <WPNotificationModal
        isVisible={isOpened}
        isWide
        withoutOverflow={isMobileView()}
      >
        <ModalContentWithClose onClose={() => setIsOpened(false)}>
          {enterprisePack ? (
            <View
              style={{
                paddingHorizontal: 16,
                paddingTop: 26,
                paddingBottom: 60,
                width: isMobileView() ? "100%" : 760,
                flex: 1,
              }}
            >
              <View
                style={{
                  marginBottom: 16,
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <WPText
                  style={{
                    fontSize: 36,
                    fontFamily: "bold",
                    textTransform: "uppercase",
                    color: theme.colors.primary,
                    marginRight: 50,
                  }}
                >
                  {enterprisePack.name}
                </WPText>
                <View
                  style={{
                    flexDirection: isMobileView() ? "column" : "row",
                    alignItems: isMobileView() ? "flex-start" : "flex-end",
                  }}
                >
                  <WPText
                    style={{
                      fontSize: 30,
                      fontFamily: "bold",
                      marginRight: 20,
                      paddingBottom: 2,
                    }}
                  >
                    {priceWithPromo}{" "}
                    <WPText style={{ fontFamily: "light", fontSize: 20 }}>
                      zł netto
                    </WPText>
                  </WPText>
                  {enterprisePack &&
                  isEnterpriseAvailabel &&
                  priceWithPromo ===
                    enterprisePack.payConfig[0].price ? null : (
                    <WPText
                      style={{
                        textDecorationLine: "line-through",
                        color: "#919eab",
                        fontSize: 18,
                        paddingBottom: 4,
                      }}
                    >
                      {(enterprisePack &&
                        isEnterpriseAvailabel &&
                        enterprisePack.payConfig[0].price) ||
                        ""}{" "}
                      zł
                    </WPText>
                  )}
                </View>
              </View>
              <WPText style={{ fontSize: 16, marginBottom: 12 }}>
                {t("enterprise.have_promo")}
              </WPText>
              <View
                style={{ flexDirection: "row", height: 36, marginBottom: 8 }}
              >
                <TextInput
                  style={{
                    borderTopLeftRadius: 8,
                    borderBottomLeftRadius: 8,
                    borderColor: "#c4cdd5",
                    borderWidth: 1,
                    paddingHorizontal: 16,
                  }}
                  placeholder="21NYWP"
                  value={promocode}
                  onChangeText={setPromocode}
                />
                <TouchableOpacity
                  style={{
                    backgroundColor: theme.colors.primary,
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                    alignItems: "center",
                    justifyContent: "center",
                    paddingHorizontal: 16,
                  }}
                  onPress={() => {
                    getPromocodeDesc(promocode)
                      .then(setPromocodeRes)
                      .catch(() =>
                        setPromocodeRes(t("enterprise.missing_promo"))
                      );
                  }}
                >
                  <WPText style={{ color: "#fff" }}>
                    {t("enterprise.activate")}
                  </WPText>
                </TouchableOpacity>
              </View>
              <WPText
                style={{
                  fontSize: 10,
                  fontStyle: "italic",
                  color: "#637381",
                  marginBottom: 12,
                }}
              >
                {t("enterprise.promo_desc")}
              </WPText>
              <View style={{ height: 52 }}>
                {promocodeRes ? (
                  typeof promocodeRes === "string" ? (
                    <WPText style={{ color: theme.colors.warning }}>
                      {promocodeRes}
                    </WPText>
                  ) : (
                    <>
                      <WPText style={{ color: "#637381" }}>
                        {t("enterprise.promo_descount")}{" "}
                        <WPText style={{ fontFamily: "bold" }}>
                          {Math.abs(promocodeRes.priceOffset)}
                        </WPText>
                      </WPText>
                      <WPText style={{ color: "#637381" }}>
                        {t("enterprise.promo_descount_final")}{" "}
                        <WPText style={{ fontFamily: "bold" }}>
                          {priceWithPromo}
                        </WPText>
                      </WPText>
                    </>
                  )
                ) : null}
              </View>
              <WPButton
                onPress={() => {
                  setIsOpened(false);
                  makePayment(
                    enterprisePack.id,
                    enterprisePack.payConfig[0].amount,
                    promoName,
                    promoDiscount
                  );
                }}
                style={{
                  backgroundColor: "#ce0a1b",
                  paddingHorizontal: 50,
                  marginBottom: isMobileView() ? 50 : 0,
                  alignSelf: isMobileView() ? undefined : "flex-start",
                }}
              >
                <WPText
                  style={{
                    color: "#fff",
                    fontFamily: "bold",
                    alignSelf: "center",
                  }}
                >
                  {t("labels.buy")} Enterprise
                </WPText>
              </WPButton>
              {/* <Image
                width={isMobileView() ? 456 / 2 : 456}
                height={isMobileView() ? 222 / 2 : 222}
                style={{
                  width: isMobileView() ? 456 / 2 : 456,
                  height: isMobileView() ? 222 / 2 : 222,
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                }}
                source={require("../assets/ny.png")}
              /> */}
            </View>
          ) : null}
        </ModalContentWithClose>
      </WPNotificationModal>
      <WPText style={styles.title}>{job.data?.title}</WPText>
      {/* {targetJobId && demoPack ? (
        <>
          <WPText style={styles.demoDesc}>{t("misc.packages_demo")}</WPText>
          <WPButton
            size="small"
            onPress={() => {
              analytics.logDemoPeriod();

              connectPackToOffer({
                packet_config_id: demoPack.id,
                offer_id: targetJobId,
              });
            }}
          >
            {t("labels.demo_period")}
          </WPButton>
          <WPSeparator style={styles.demoSeparator} />
        </>
      ) : null} */}
      <WPText style={styles.titleDesc}>{t("misc.packages")}</WPText>
      {packages && packages.length && packages[0].actionsConfig?.multiplier ? (
        <Image
          width={230}
          height={130}
          style={{ width: 320, height: 130, marginBottom: 32 }}
          source={require("../assets/packPromotions/blackFriday.png")}
        />
      ) : null}
      {enterprisePack ? (
        <View
          style={{
            overflow: "hidden",
            position: "relative",
            height: isMobileView() ? "auto" : 222,
            width: "100%",
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 6,
            },
            shadowOpacity: 0.1,
            shadowRadius: 17,
            elevation: 8,
            borderRadius: 8,
            paddingVertical: 22,
            paddingHorizontal: 25,
            alignItems: "flex-start",
            marginBottom: 32,
          }}
        >
          <View
            style={{
              marginBottom: 16,
              flexDirection: isMobileView() ? "column" : "row",
              alignItems: isMobileView() ? "flex-start" : "flex-end",
            }}
          >
            <WPText
              style={{
                fontSize: 36,
                fontFamily: "bold",
                textTransform: "uppercase",
                color: theme.colors.primary,
                marginRight: 50,
              }}
            >
              {enterprisePack.name}
            </WPText>
            <View
              style={{
                flexDirection: isMobileView() ? "column" : "row",
                alignItems: isMobileView() ? "flex-start" : "flex-end",
              }}
            >
              <WPText
                style={{
                  fontSize: 30,
                  fontFamily: "bold",
                  marginRight: 20,
                  paddingBottom: 2,
                }}
              >
                {nyPrice}{" "}
                <WPText style={{ fontFamily: "light", fontSize: 20 }}>
                  zł netto
                </WPText>
              </WPText>
              {enterprisePack &&
              isEnterpriseAvailabel &&
              nyPrice === enterprisePack.payConfig[0].price ? null : (
                <WPText
                  style={{
                    textDecorationLine: "line-through",
                    color: "#919eab",
                    fontSize: 18,
                    paddingBottom: 4,
                  }}
                >
                  {(enterprisePack &&
                    isEnterpriseAvailabel &&
                    enterprisePack.payConfig[0].price) ||
                    ""}{" "}
                  zł
                </WPText>
              )}
            </View>
          </View>
          <View style={{ flexDirection: isMobileView() ? "column" : "row" }}>
            <View style={{ marginRight: 32 }}>
              <DescList
                list={[
                  { isNegative: false, text: t("enterprise.feature_1") },
                  { isNegative: false, text: t("enterprise.feature_2") },
                ]}
              />
            </View>
            <DescList
              list={[
                { isNegative: false, text: t("enterprise.feature_3") },
                { isNegative: false, text: t("enterprise.feature_4") },
              ]}
            />
          </View>
          {getAvailablePackAmount(enterprisePack) &&
          !props.route?.params?.jobId ? null : (
            <WPButton
              onPress={() => {
                const availablePacksAmount =
                  getAvailablePackAmount(enterprisePack);

                availablePacksAmount
                  ? connectPackToOffer({
                      packet_config_id: enterprisePack.id,
                      offer_id: targetJobId,
                    })
                  : setIsOpened(true);
              }}
              style={{
                backgroundColor: "#ce0a1b",
                paddingHorizontal: 50,
                marginBottom: isMobileView() ? 50 : 0,
              }}
            >
              {getAvailablePackAmount(enterprisePack)
                ? t("labels.use")
                : t("labels.buy")}
            </WPButton>
          )}
          {/* <Image
            width={isMobileView() ? 456 / 2 : 456}
            height={isMobileView() ? 222 / 2 : 222}
            style={{
              width: isMobileView() ? 456 / 2 : 456,
              height: isMobileView() ? 222 / 2 : 222,
              position: "absolute",
              right: 0,
              top: isMobileView() ? "auto" : 0,
              bottom: isMobileView() ? 0 : "auto",
            }}
            source={require("../assets/ny.png")}
          /> */}
        </View>
      ) : null}
      {isMobileView() ? (
        <View style={{ alignItems: "flex-start" }}>
          <WPText style={styles.packagesLabel}>{t("labels.packages")}</WPText>
          <ScrollView horizontal style={{ flexGrow: 0, marginBottom: 40 }}>
            {packages.map((pack, idx) => (
              <WPButton
                size="small"
                key={pack.name}
                appearance={pack.id === selectedPackId ? "fill" : "outlined"}
                onPress={() => setSelectedPackId(pack.id)}
                style={{ marginRight: 12 }}
              >
                {pack.name}
              </WPButton>
            ))}
          </ScrollView>
        </View>
      ) : null}
      {isMobileView() && selectPackage ? (
        <View style={styles.packContainer}>
          <PackageCard {...getPackProps(selectPackage, !targetJobId)} />
        </View>
      ) : (
        <ScrollView
          horizontal
          contentContainerStyle={styles.packagesContainer}
          style={styles.packages}
        >
          {packages.map((pack, idx) => (
            <View
              key={pack.name}
              style={
                idx !== packages.length - 1 ? styles.packContainer : undefined
              }
            >
              <PackageCard {...getPackProps(pack, !targetJobId)} />
            </View>
          ))}
        </ScrollView>
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "flex-start",
    paddingHorizontal: isMobileView() ? 12 : 24,
    paddingVertical: isMobileView() ? 32 : 44,
  },
  packSelect: {
    flexDirection: "row",
  },
  title: {
    fontFamily: "black",
    fontSize: isMobileView() ? 18 : 28,
    marginBottom: 8,
  },
  demoDesc: {
    color: theme.colors.mains[3],
    marginBottom: 12,
  },
  demoSeparator: {
    width: "100%",
  },
  titleDesc: {
    color: theme.colors.mains[3],
    marginBottom: 36,
  },
  packages: {
    maxWidth: "100%",
  },
  packagesContainer: {
    paddingVertical: 16,
    flex: 1,
    maxWidth: "100%",
  },
  packContainer: {
    marginRight: 32,
    width: isMobileView() ? "100%" : undefined,
  },
  packagesLabel: {
    fontFamily: "black",
    marginBottom: 8,
  },
});
