import { Platform } from "react-native";

export const addWebGlobalStyles = () => {
  if (Platform.OS === "web") {
    const style = document.createElement("style");
    style.type = "text/css";
    style.appendChild(
      document.createTextNode(
        `
              * {
                user-select: initial !important
              }
        
              *:focus {
                outline: none;
              }
              
              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
        
              /* Firefox */
              input[type=number] {
                -moz-appearance: textfield;
              }
        `
      )
    );
    document.head.appendChild(style);
  }
};

export const initGlobals = () => {
  addWebGlobalStyles();
};
