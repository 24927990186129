import { fileRequest } from "./wpRequest";
import { getFileExtension, getFileName } from "./mixUtils";
import { logToConsole, logError } from "../services/logger";

export const saveToLibrary = async (
  uri: string,
  fileName?: string,
  onDone?: () => void
) => {
  try {
    const blob = await fileRequest(uri);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;

    a.download = fileName || getFileName(uri);

    if (!fileName || !/\.[A-z]{2,3}$/.test(fileName)) {
      a.download = a.download + getFileExtension(uri);
    }

    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    if (onDone && typeof onDone === "function") {
      onDone();
    }
  } catch (err) {
    logError(err);
    alert("Error while saving image");

    window.open(uri);

    if (onDone && typeof onDone === "function") {
      onDone();
    }
  }
};
