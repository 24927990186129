import React, { memo } from "react";
import { useGetEstate } from "../../../../services/hooks/estateHooks";
import EstateEditor from "./components/EstateEditor";

function EstateDuplicateScreen(props: {
  route: { params: { estateId: number } };
}) {
  const estate = useGetEstate(Number(props.route.params.estateId));

  if (!estate) {
    return null;
  }
  if (!("data" in estate) || estate.data === undefined) {
    return null;
  }

  const duplicate = JSON.parse(JSON.stringify(estate.data));

  delete duplicate.id;
  delete duplicate.images;

  return <EstateEditor estate={duplicate} />;
}

export default memo(EstateDuplicateScreen);
