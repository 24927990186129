import React, { memo, ReactNode } from "react";
import { ImageBackground, StyleSheet } from "react-native";

function AuthBackGround(props: { children: ReactNode }) {
  return (
    <ImageBackground
      source={require("../../../../assets/auth_background.png")}
      style={styles.image}
    >
      {props.children}
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  image: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
  },
});

export default memo(AuthBackGround);
