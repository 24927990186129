import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const FileIcon: React.FC<IconProps> = (props) => {
  let height = 20;
  let width = 20;
  const ratio = width / height;
  const color = props.color || "#919EAB";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M11.199 2.063l.042.022.005.003a.566.566 0 01.097.058 1 1 0 01.1.085l-.092-.08a.84.84 0 01.091.08l4.83 4.84a.676.676 0 01.076.087l.013.018c.1.141.144.302.139.459l-.001 9.59c0 .428-.35.775-.78.775H4.279a.777.777 0 01-.779-.774V2.776c0-.428.35-.774.78-.774h6.562c.12-.008.242.01.357.061zM10.127 3.55H5.06v12.903h9.879l-.001-8.062h-3.93a.777.777 0 01-.78-.755l-.101-4.086zm1.589 1.156l.053 2.137 2.078-.001-.577-.579-1.554-1.557z"
      />
    </Svg>
  );
};

FileIcon.displayName = "FileIcon";

export default FileIcon;
