import React from "react";
import { View, StyleProp, ViewStyle } from "react-native";
import { useTranslation } from "react-i18next";
import { ApplicationStatus } from "../../../../services/chatService";
import theme, { isMobileView } from "../../../../utils/theme";
import WPText from "../../../../components/Text/WPText";

const CandidateStatus: React.FC<{
  status?: ApplicationStatus | undefined;
  total?: number;
  style?: StyleProp<ViewStyle>;
  isCompactView: boolean;
}> = (props) => {
  const { t } = useTranslation();

  const computedStatus =
    props.total && props.total > 1
      ? t("labels.open_to_see")
      : t(`select.job_application_status.${props.status}`, "-");

  let statusColor = theme.colors.mains[3];

  if ((!props.total || props.total === 1) && props.status) {
    switch (props.status) {
      case ApplicationStatus.NEW:
        statusColor = theme.colors.primary;
        break;
      case ApplicationStatus.HRD:
        statusColor = theme.colors.success;
        break;
      case ApplicationStatus.BAN:
        statusColor = theme.colors.error;
        break;

      default:
        statusColor = theme.colors.mains[3];
        break;
    }
  }

  return (
    <View
      style={{
        height: 12,
        paddingHorizontal: 12,
        backgroundColor: statusColor,
        borderRadius: 12 / 2,
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "flex-start",
      }}
    >
      <WPText
        style={{ fontFamily: "bold", color: theme.colors.white, fontSize: 8 }}
        numberOfLines={1}
      >
        {computedStatus}
      </WPText>
    </View>
  );
};

export default CandidateStatus;
