import React from "react";
import Svg, { Path, G, Circle } from "../SVG";
import { IconProps } from "../WPIcon";

const ClearInputIcon: React.FC<IconProps> = (props) => {
  let height = 16;
  let width = 16;
  const ratio = width / height;
  const color = props.color || "#DFE3E8";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 16 16" {...props}>
      <G fill="none" fillRule="evenodd">
        <Circle cx={8} cy={8} r={8} fill={color} />
        <Path
          fill="#919EAB"
          d="M5.22 5.22a.75.75 0 011.06 0L8 6.939l1.72-1.72a.75.75 0 01.976-.072l.084.073a.75.75 0 010 1.06L9.061 8l1.72 1.72a.75.75 0 01.072.976l-.073.084a.75.75 0 01-1.06 0L8 9.061l-1.72 1.72a.75.75 0 01-.976.072l-.084-.073a.75.75 0 010-1.06L6.939 8l-1.72-1.72a.75.75 0 01-.072-.976z"
        />
      </G>
    </Svg>
  );
};

ClearInputIcon.displayName = "ClearInputIcon";

export default ClearInputIcon;
