import React from "react";
import { toggleDrawer } from "../navigationHelpers";
import HeaderButton from "./HeaderButton";
import { IconButtonProps } from "../../components/Button/WPBaseButton";

const DrawerToggle: React.FC<Omit<IconButtonProps, "name" | "type">> = (
  props
) => {
  return (
    <HeaderButton {...props} type="icon" name="menu" onPress={toggleDrawer} />
  );
};

export default React.memo(DrawerToggle);
