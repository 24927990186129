import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const SuccessIcon: React.FC<IconProps> = (props) => {
  let height = 110;
  let width = 112;
  const ratio = width / height;

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 112 110"
      {...props}
    >
      <Path
        stroke="#0076FF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M54.84.772v14.844m29.943-6.042l-8.084 12.449m28.443 11.038L91.69 39.334m17.918 24.613l-14.7-2.065m1.739 30.454l-11.203-9.738m-15.059 26.596l-4.092-14.269M39.3 109.194l4.092-14.268m-30.354-2.589l11.203-9.738M.076 63.948l14.699-2.066M4.542 33.06l13.453 6.274m6.908-29.76l8.084 12.449M54.84.772v14.844m29.943-6.042l-8.084 12.449m28.443 11.038L91.69 39.334m17.918 24.613l-14.7-2.065m1.739 30.454l-11.203-9.738m-15.059 26.596l-4.092-14.269M39.3 109.194l4.092-14.268m-30.354-2.589l11.203-9.738M.076 63.948l14.699-2.066M4.542 33.06l13.453 6.274m6.908-29.76l8.084 12.449"
        opacity={0.2}
      />
      <Path
        fill="#CCE4FF"
        d="M69.18 40.407H40.504a3.186 3.186 0 00-3.187 3.186v22.303a3.187 3.187 0 003.187 3.187h3.186v6.372l6.372-6.372H69.18a3.186 3.186 0 003.186-3.187V43.593a3.185 3.185 0 00-3.186-3.186z"
      />
      <Path
        fill="#007AFF"
        d="M69.18 40.165H40.502a3.986 3.986 0 00-3.982 3.982v22.304a3.987 3.987 0 003.983 3.983h2.39v5.575a.795.795 0 001.359.563l6.139-6.139h18.787a3.986 3.986 0 003.983-3.982V44.147a3.987 3.987 0 00-3.983-3.982zm2.389 26.286a2.393 2.393 0 01-2.39 2.39H50.062a.791.791 0 00-.563.233l-5.013 5.013v-4.45a.796.796 0 00-.796-.797h-3.186a2.393 2.393 0 01-2.39-2.39V44.148a2.393 2.393 0 012.39-2.39h28.675a2.393 2.393 0 012.39 2.39v22.304z"
      />
      <Path
        fill="#007AFF"
        d="M50.062 53.948c.44 0 .797-.356.797-.796v-6.373a.796.796 0 10-1.593.001v6.372c0 .44.356.796.796.796zm9.558 0a.796.796 0 00.797-.796v-6.373a.796.796 0 10-1.593.001v6.372c0 .44.356.796.797.796zm6.373 1.593a.796.796 0 00-.796.797 5.583 5.583 0 01-5.576 5.576h-9.559a5.583 5.583 0 01-5.576-5.576.796.796 0 10-1.593 0c0 3.953 3.216 7.169 7.17 7.169h9.558c3.953 0 7.169-3.216 7.169-7.169a.797.797 0 00-.797-.797z"
      />
    </Svg>
  );
};

SuccessIcon.displayName = "SuccessIcon";

export default SuccessIcon;
