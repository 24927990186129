import React from "react";
import Svg, { G, Circle, Path } from "react-native-svg";
import { WPIconProps } from "../WPIcon";

const RejectedStatusIcon: React.FC<WPIconProps> = (props) => {
  let height = 19;
  let width = 19;
  const ratio = width / height;

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg viewBox="0 0 19 19" width={width} height={height}>
      <G fill="none" fillRule="evenodd">
        <Circle cx={9.5} cy={9.5} r={9.5} fill="#FF4747" />
        <Path
          d="M12.666 6.333l-6.333 6.333m0-6.333l6.333 6.333"
          stroke="#FFF"
          strokeWidth={2}
        />
      </G>
    </Svg>
  );
};

export default RejectedStatusIcon;
