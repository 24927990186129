import AsyncStorage from "@react-native-community/async-storage";
import { Platform } from "react-native";
import config from "./config";
// import Constants from "expo-constants";
import { logError } from "./logger";

const {
  STORE_KEY = "",
  TOKEN_KEY = "",
  REFRESH_TOKEN_KEY = "",
  REDIRECT,
} = config;

// const {
//   STORE_KEY = "",
//   TOKEN_KEY = "",
//   REFRESH_TOKEN_KEY = "",
// } = Constants.manifest.extra;

const STORED_TOKEN_KEY = "WP_NOTIFICATION_TOKEN";
const SEEN_INTRO = "SEEN_INTRO";

const appCache = {
  setRedirectValue: async (redirectPath: string) =>
    appCache.setValue(
      REDIRECT,
      !redirectPath.includes("AUTH") ? redirectPath : ""
    ),
  getRedirectValue: async () => appCache.getValue(REDIRECT),
  clearCache: async () => AsyncStorage.clear(),
  setValue: async (key: string, value: string) =>
    AsyncStorage.setItem(
      key,
      value,
      (err) => err && logError({ err, key, value })
    ).catch(logError),
  getValue: async (key: string) => AsyncStorage.getItem(key),
  saveStoreValue: (storeData: object) =>
    appCache.setValue(STORE_KEY, JSON.stringify(storeData)).catch(() => {}),
  getStoreValue: async () => {
    const storeData = await appCache.getValue(STORE_KEY);

    if (!storeData) {
      return undefined;
    }

    try {
      return JSON.parse(storeData);
    } catch (err) {
      return undefined;
    }
  },
  getApiToken: () => AsyncStorage.getItem(TOKEN_KEY),
  getApiRefreshToken: () => AsyncStorage.getItem(REFRESH_TOKEN_KEY),
  getCachedToken: async () => appCache.getValue(STORED_TOKEN_KEY),
  saveCachedToken: async (token: string) =>
    appCache.setValue(STORED_TOKEN_KEY, token),

  getIsSeenIntro: async () => appCache.getValue(SEEN_INTRO),
  setIsSeenIntro: async (val: "true" | "false") =>
    appCache.setValue(SEEN_INTRO, val),
};

export default appCache;
