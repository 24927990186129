// TODO: make an abstraction
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import CRMDataScreen from "../../common/CRMDataScreen";
import { useRenderJobCandidateTile } from "../jobUtils";
import {
  useGetJob,
  useGetJobApplicationsList,
} from "../../../../services/hooks/jobHooks";
import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { RootState } from "../../../../services/store/reducers/mainReducer";

type JobCandidatesScreenProps = {
  route: {
    params: {
      jobId: number;
    };
  };
};

function JobCandidatesScreen(props: JobCandidatesScreenProps) {
  const isUpdateScheduled = useSelector(
    (state: RootState) => state.ui.shouldUpdateList
  );
  const navigation = useNavigation();
  const getJobApplications = useGetJobApplicationsList(
    props.route.params.jobId
  );
  const { i18n } = useTranslation();
  const job = useGetJob(props.route.params.jobId);

  const title = job?.data ? job.data.title : "-";

  const jobId =
    typeof props.route.params.jobId !== "undefined"
      ? Number(props.route.params.jobId)
      : undefined;

  const renderItem = useRenderJobCandidateTile();

  return (
    <CRMDataScreen
      navigation={navigation}
      title={title}
      getData={getJobApplications}
      renderItem={renderItem}
      initialFilter={{ job_offer: jobId }}
      language={i18n.language}
      isUpdateScheduled={isUpdateScheduled}
    />
  );
}

export default memo(JobCandidatesScreen);
