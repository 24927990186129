import * as React from "react";
import { defaultCRMScreenOptions, RouterType } from "../../navigationConstants";
import {
  RouterConfig,
  buildRouter,
  withScreenWrapper,
} from "../../navigationHelpers";
import ActiveJobsScreen from "./screens/ActiveJobsScreen";
import ExpiredJobsScreen from "./screens/ExpiredJobsScreen";
import EditJobScreen from "./screens/EditJobScreen";
import CreateJobScreen from "./screens/CreateJobScreen";
import JobCandidatesScreen from "./screens/JobCandidatesScreen";
import BackButton from "../../common/BackButton";
import { ChatRouterConfig } from "../chat/ChatRouter";
import { EstateRouterConfig } from "../estate/EstateRouter";
import DuplicateJobScreen from "./screens/DuplicateJobScreen";
import RestoreJobScreen from "./screens/RestoreJobScreen";
import ProfileEditScreen from "./screens/ProfileEditScreen";
import CandidatesScreen from "./screens/CandidatesScreen";
import PaymentsScreen, { PaymentType } from "./screens/PaymentsScreen";
import JobPackageSelectScreen from "./screens/JobPackageSelectScreen";
import NewsScreen from "./screens/NewsScreen";
import BillsScreen from "./screens/BillsScreen";
import RatingScreen from "./screens/RatingScreen";

const ACTIVE_JOB_LIST = "ACTIVE_JOB_LIST";
const EXPIRED_JOB_LIST = "EXPIRED_JOB_LIST";
const EDIT_JOB = "EDIT_JOB";
const RESTORE_JOB = "RESTORE_JOB";
const DUPLICATE_JOB = "DUPLICATE_JOB";
const CREATE_JOB = "CREATE_JOB";
const EDIT_PROFILE = "EDIT_PROFILE";
const JOB_CANDIDATES = "JOB_CANDIDATES";
const ALL_CANDIDATES = "ALL_CANDIDATES";
const PAYMENTS = "PAYMENTS";
const JOB_PACKAGE = "JOB_PACKAGE";
const NEWS = "NEWS";
const BILLS = "BILLS";
const RATING = "RATING";

const editorOptions = () => ({
  ...defaultCRMScreenOptions,
  headerLeft: () => <BackButton fallBackRoute="CRM.JOB.ACTIVE_JOB_LIST" />,
});

const JobRouter: RouterConfig = {
  type: RouterType.stack,
  isShared: true,
  containerConfig: {
    headerMode: "screen",
    initialRouteName: ACTIVE_JOB_LIST,
    screenOptions: defaultCRMScreenOptions,
  },
  screens: {
    [EDIT_PROFILE]: {
      name: EDIT_PROFILE,
      component: withScreenWrapper(() => <ProfileEditScreen />),
    },
    [RATING]: {
      name: RATING,
      component: withScreenWrapper(() => <RatingScreen />),
    },
    [ACTIVE_JOB_LIST]: {
      name: ACTIVE_JOB_LIST,
      component: withScreenWrapper(() => <ActiveJobsScreen />),
    },
    [EXPIRED_JOB_LIST]: {
      name: EXPIRED_JOB_LIST,
      component: withScreenWrapper(() => <ExpiredJobsScreen />),
    },
    [EDIT_JOB]: {
      name: EDIT_JOB,
      component: withScreenWrapper(
        (props) => <EditJobScreen {...props} />,
        true
      ),
      options: editorOptions,
    },
    [DUPLICATE_JOB]: {
      name: DUPLICATE_JOB,
      component: withScreenWrapper(
        (props) => <DuplicateJobScreen {...props} />,
        true
      ),
      options: editorOptions,
    },
    [RESTORE_JOB]: {
      name: RESTORE_JOB,
      component: withScreenWrapper(
        (props) => <RestoreJobScreen {...props} />,
        true
      ),
      options: editorOptions,
    },
    [CREATE_JOB]: {
      name: CREATE_JOB,
      component: withScreenWrapper(() => <CreateJobScreen />, true),
      options: editorOptions,
    },
    [JOB_CANDIDATES]: {
      name: JOB_CANDIDATES,
      component: withScreenWrapper(
        (props) => <JobCandidatesScreen {...props} />,
        true
      ),
      options: editorOptions,
    },
    [ALL_CANDIDATES]: {
      name: ALL_CANDIDATES,
      component: withScreenWrapper(CandidatesScreen),
    },
    [JOB_PACKAGE]: {
      name: JOB_PACKAGE,
      component: withScreenWrapper(
        (props) => <JobPackageSelectScreen {...props} />,
        true
      ),
      options: editorOptions,
    },
    [PAYMENTS]: {
      name: PAYMENTS,
      component: withScreenWrapper(
        (props) => <PaymentsScreen {...props} />,
        true
      ),
      options: editorOptions,
    },
    [NEWS]: {
      name: NEWS,
      component: withScreenWrapper(NewsScreen, true),
      options: editorOptions,
    },
    [BILLS]: {
      name: BILLS,
      component: withScreenWrapper(
        (props) => <BillsScreen {...props} />,
        false
      ),
    },
    ...ChatRouterConfig.screens,
    ...EstateRouterConfig.screens,
  },
};

export type JobRouterParams = {
  [ACTIVE_JOB_LIST]: undefined;
  [EXPIRED_JOB_LIST]: undefined;
  [EDIT_PROFILE]: undefined;
  [EDIT_JOB]: {
    id: string;
  };
  [DUPLICATE_JOB]: {
    id: string;
  };
  [RESTORE_JOB]: {
    id: string;
  };
  [JOB_CANDIDATES]: {
    jobId: number;
  };
  [JOB_PACKAGE]: {
    jobId: number;
  };
  [PAYMENTS]: {
    paymentType: PaymentType;
    offerId: number;
    packName: string;
    packValue: number;
    callBackRoute: string;
  };
};

export default buildRouter(JobRouter);
