import React, { memo, useCallback } from "react";
import { useGetJob } from "../../../../services/hooks/jobHooks";
import { navigateTo } from "../../../navigationHelpers";
import JobEditor from "../components/JobEditor";

type EditJobScreenProps = {
  route: {
    params: {
      jobId: number;
    };
  };
};

function DuplicateJobScreen(props: EditJobScreenProps) {
  const job = useGetJob(props.route.params.jobId);
  const onSaved = useCallback((offerId: number) => {
    navigateTo(`CRM.JOB.JOB_PACKAGE?jobId=${offerId}`);
  }, []);

  if (!job || !job?.data) {
    return null;
  }

  return <JobEditor job={job.data} isRestore onSaved={onSaved} />;
}

export default memo(DuplicateJobScreen);
