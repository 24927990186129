import WPFirebase from "../firebase";
import "firebase/messaging";
import { logToConsole } from "../logger";
import { notificationVapidKey } from "../../utils/appConfig";

export const getToken = async () => {
  try {
    const messaging = WPFirebase.messaging();

    messaging.usePublicVapidKey(notificationVapidKey);

    const token = await messaging.getToken();
    return token;
  } catch (err) {
    logToConsole(err);
    return null;
  }
};
