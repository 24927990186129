import React, { useCallback, memo, useRef, useEffect, useState } from "react";
import theme, { isMobileView } from "../../../../../utils/theme";
import { ScrollView, StyleSheet, View } from "react-native";
import { Estate, RentType } from "../../../../../services/apis/estateApi";
import { Formik, FormikProps } from "formik";
import FormContainer from "../../../../../components/form/FormContainer";
import FormRow from "../../../../../components/form/FormRow";
import FormField, {
  formFieldStyle,
} from "../../../../../components/form/FormField";
import {
  useDeleteEstateImage,
  useSaveEstate,
} from "../../../../../services/hooks/estateHooks";
import { useTranslation } from "react-i18next";
import WPText from "../../../../../components/Text/WPText";
import Separator from "../../../../../components/misc/Separator";
import WPButton from "../../../../../components/Button/WPButton";
import { goBack } from "../../../../navigationHelpers";
import AuthNotification from "../../../../auth/components/AuthNotification";
import WPError from "../../../../../components/input/components/WPError";

const Spacer = memo(function () {
  return <View style={formFieldStyle} />;
});

export default function EstateEditor(props: { estate: Partial<Estate> }) {
  const onCancel = useCallback(() => {
    goBack("CRM.JOB.ESTATE_LIST");
  }, []);
  const [isLoading, errors, successMap, saveOffer] = useSaveEstate(
    !!props.estate.id
  );

  const { t } = useTranslation();

  const saveOfferByType = useCallback((estate: Partial<Estate>) => {
    switch (estate.rent_type) {
      case RentType.HOUSE:
        delete estate.property_type;
        delete estate.floor;
        delete estate.bed_type;
        break;
      case RentType.APARTMENT:
        delete estate.number_of_floors;
        delete estate.bed_type;
        break;
      case RentType.ROOM:
        delete estate.number_of_rooms;
        delete estate.number_of_floors;
        delete estate.lot_area;
        delete estate.floor;
        delete estate.bed_type;
        break;
      case RentType.BED:
        delete estate.number_of_rooms;
        delete estate.number_of_beds;
        delete estate.number_of_floors;
        delete estate.lot_area;
        delete estate.utilities;
        delete estate.deposit;
        break;
      default:
        break;
    }

    saveOffer(estate);
  }, []);

  const renderFirstRow = useCallback(
    (formikProps: FormikProps<Partial<Estate>>, resErrors, resSuccess) => {
      const rentType = (
        <FormField
          name="rent_type"
          type="dropdown"
          {...formikProps}
          errors={resErrors}
          successMap={resSuccess}
          required
        />
      );

      const propertyType = (
        <FormField
          name="property_type"
          type="dropdown"
          {...formikProps}
          errors={resErrors}
          successMap={resSuccess}
        />
      );

      const rooms = (
        <FormField
          name="number_of_rooms"
          type="dropdown"
          {...formikProps}
          errors={resErrors}
          successMap={resSuccess}
        />
      );

      const area = (
        <FormField
          name="total_area"
          {...formikProps}
          errors={resErrors}
          successMap={resSuccess}
          required
        />
      );

      switch (formikProps.values.rent_type) {
        case RentType.HOUSE:
          return (
            <FormRow key="1">
              {rentType}
              {rooms}
              {area}
            </FormRow>
          );
        case RentType.APARTMENT:
          return (
            <FormRow key="2">
              {rentType}
              {rooms}
              {area}
            </FormRow>
          );
        case RentType.ROOM:
        case RentType.BED:
          return (
            <FormRow key="3">
              {rentType}
              {propertyType}
              {area}
            </FormRow>
          );

        default:
          return isMobileView() ? (
            <FormRow key="4">{rentType}</FormRow>
          ) : (
            <FormRow key="4">
              {rentType}
              <Spacer /> <Spacer />
            </FormRow>
          );
      }
    },
    []
  );
  const renderSecondRow = useCallback(
    (formikProps: FormikProps<Partial<Estate>>, resErrors, resSuccess) => {
      const numberOfBeds = (
        <FormField
          name="number_of_beds"
          type="dropdown"
          {...formikProps}
          errors={resErrors}
          successMap={resSuccess}
          required
        />
      );
      const numberOfFloors = (
        <FormField
          name="number_of_floors"
          type="dropdown"
          {...formikProps}
          errors={resErrors}
          successMap={resSuccess}
        />
      );
      const renter = (
        <FormField
          name="renter"
          type="dropdown"
          {...formikProps}
          errors={resErrors}
          successMap={resSuccess}
        />
      );
      const adType = (
        <FormField
          name="advertiser_type"
          type="dropdown"
          {...formikProps}
          errors={resErrors}
          successMap={resSuccess}
          required
        />
      );

      const floorNumber = (
        <FormField
          name="floor"
          type="dropdown"
          {...formikProps}
          errors={resErrors}
          successMap={resSuccess}
          required
        />
      );
      const bedType = (
        <FormField
          name="bed_type"
          type="dropdown"
          {...formikProps}
          errors={resErrors}
          successMap={resSuccess}
          required
        />
      );

      const area = (
        <FormField
          name="lot_area"
          {...formikProps}
          errors={resErrors}
          successMap={resSuccess}
          required
        />
      );

      switch (formikProps.values.rent_type) {
        case RentType.HOUSE:
          return (
            <FormRow key="6">
              {numberOfBeds}
              {numberOfFloors}
              {area}
            </FormRow>
          );
        case RentType.APARTMENT:
          return (
            <FormRow key="7">
              {numberOfBeds}
              {floorNumber}
              {area}
            </FormRow>
          );
        case RentType.ROOM:
          return (
            <FormRow key="8">
              {renter}
              {numberOfBeds}
              {adType}
            </FormRow>
          );
        case RentType.BED:
          return (
            <FormRow key="9">
              {bedType}
              {renter}
              {adType}
            </FormRow>
          );

        default:
          return null;
      }
    },
    []
  );

  const renderThirdRow = useCallback(
    (formikProps: FormikProps<Partial<Estate>>, resErrors, resSuccess) => {
      const adType = (
        <FormField
          name="advertiser_type"
          type="dropdown"
          {...formikProps}
          errors={resErrors}
          successMap={resSuccess}
          required
        />
      );

      switch (formikProps.values.rent_type) {
        case RentType.HOUSE:
        case RentType.APARTMENT:
          return isMobileView() ? (
            <FormRow>{adType}</FormRow>
          ) : (
            <FormRow>
              {adType}
              <Spacer />
              <Spacer />
            </FormRow>
          );

        default:
          return null;
      }
    },
    []
  );

  const renderFirstRowUnselected = useCallback(
    (formikProps: FormikProps<Partial<Estate>>, resErrors, resSuccess) => {
      const rentType = (
        <FormField
          name="rent_type"
          type="dropdown"
          {...formikProps}
          errors={resErrors}
          successMap={resSuccess}
          required
        />
      );

      switch (true) {
        case !formikProps.values.rent_type:
          return isMobileView() ? (
            <FormRow>{rentType}</FormRow>
          ) : (
            <FormRow>
              {rentType}
              <Spacer />
              <Spacer />
            </FormRow>
          );

        default:
          return [
            renderFirstRow(formikProps, resErrors, resSuccess),
            renderSecondRow(formikProps, resErrors, resSuccess),
            renderThirdRow(formikProps, resErrors, resSuccess),
          ];
      }
    },
    []
  );

  const scrollView = useRef<ScrollView>(null);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    if (errors && typeof errors === "object" && Object.keys(errors).length)
      setIsVisible(true);
  }, [errors]);

  const closeNotification = useCallback(() => {
    scrollView.current?.scrollTo({ y: 0 });
    setIsVisible(false);
  }, [scrollView.current]);

  const deleteImage = useDeleteEstateImage(props.estate.id || 0);

  return (
    <ScrollView contentContainerStyle={styles.container} ref={scrollView}>
      <AuthNotification
        isVisible={isVisible}
        title={t("titles.errors")}
        iconName="attention"
        description={t("misc.errors")}
        onClose={closeNotification}
        buttonLabel={t("labels.got_it")}
      />
      <Formik initialValues={props.estate} onSubmit={saveOfferByType}>
        {(formikProps) => (
          <FormContainer error={errors["non_field_errors"]}>
            <FormRow>
              <FormField
                name="title"
                {...formikProps}
                errors={errors}
                successMap={successMap}
                required
              />
            </FormRow>
            {renderFirstRowUnselected(formikProps, errors, successMap)}
            <Separator />

            <WPText style={styles.sectionTitle}>
              {t("titles.rent_price")}
            </WPText>

            <FormRow>
              <FormField
                name="price"
                {...formikProps}
                errors={errors}
                successMap={successMap}
                required
              />
              <FormField
                name="billing_period"
                type="dropdown"
                {...formikProps}
                errors={errors}
                successMap={successMap}
                required
              />
              {formikProps.values.rent_type === RentType.BED ? (
                <FormField
                  name="utilities"
                  {...formikProps}
                  errors={errors}
                  successMap={successMap}
                  required
                />
              ) : isMobileView() ? null : (
                <Spacer />
              )}
            </FormRow>
            <FormRow>
              <FormField
                name="minimum_rent_term"
                type="dropdown"
                {...formikProps}
                errors={errors}
                successMap={successMap}
                required
              />
              <FormField
                name="start_date"
                {...formikProps}
                type="date"
                errors={errors}
                successMap={successMap}
                required
              />
              {formikProps.values.rent_type !== RentType.BED ? (
                <FormField
                  name="deposit"
                  {...formikProps}
                  errors={errors}
                  successMap={successMap}
                  required
                />
              ) : isMobileView() ? null : (
                <Spacer />
              )}
            </FormRow>
            <Separator />
            <WPText style={styles.sectionTitle}>
              {t("titles.general_info")}
            </WPText>
            <FormRow>
              <FormField
                name="description"
                {...formikProps}
                errors={errors}
                successMap={successMap}
                required
                numberOfLines={5}
              />
            </FormRow>
            <FormRow>
              <View style={{ width: "100%" }}>
                <ScrollView horizontal>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i, idx) => (
                    <FormField
                      key={idx.toString()}
                      name={`images_obj[${idx}]`}
                      type="image"
                      {...formikProps}
                      errors={errors}
                      successMap={successMap}
                      style={styles.image}
                      label={t("labels.add_photo")}
                      isMain={idx === 0}
                      onDelete={() => {
                        const imageData =
                          formikProps?.values?.images_obj &&
                          formikProps?.values?.images_obj[idx];

                        if (
                          typeof imageData === "object" &&
                          "id" in imageData
                        ) {
                          deleteImage(imageData.id);
                        }
                      }}
                    />
                  ))}
                </ScrollView>
                {errors["image"] ? <WPError error={errors["image"]} /> : null}
              </View>
            </FormRow>
            <Separator />
            <WPText style={styles.sectionTitle}>{t("labels.location")}</WPText>
            <FormRow>
              <FormField
                name="city"
                type="city"
                {...formikProps}
                errors={errors}
                successMap={successMap}
                required
              />
              <FormField
                name="district"
                {...formikProps}
                errors={errors}
                successMap={successMap}
              />
              <FormField
                name="street"
                {...formikProps}
                errors={errors}
                successMap={successMap}
              />
            </FormRow>

            <Separator />
            <WPText style={styles.sectionTitle}>{t("labels.equipment")}</WPText>
            <FormRow>
              <FormField
                name="equipment"
                type="select"
                multiple
                {...formikProps}
                errors={errors}
                successMap={successMap}
                label=""
              />
            </FormRow>
            <Separator />
            <WPText style={styles.sectionTitle}>
              {t("labels.facilities")}
            </WPText>
            <FormRow>
              <FormField
                name="facilities"
                type="select"
                multiple
                {...formikProps}
                errors={errors}
                successMap={successMap}
                label=""
              />
            </FormRow>
            <Separator />
            <WPText style={styles.sectionTitle}>
              {t("labels.restrictions")}
            </WPText>
            <FormRow>
              <FormField
                name="restrictions"
                type="select"
                multiple
                {...formikProps}
                errors={errors}
                successMap={successMap}
                label=""
              />
            </FormRow>
            <Separator />
            <WPText style={styles.sectionTitle}>
              {t("labels.contact_info")}
            </WPText>
            <FormRow>
              <FormField
                name="phone"
                required
                {...formikProps}
                errors={errors}
                successMap={successMap}
              />
              <FormField
                name="im_apps"
                type="select"
                multiple
                dense
                {...formikProps}
                errors={errors}
                successMap={successMap}
              />
            </FormRow>
            {errors["non_field_errors"] ? (
              <FormRow>
                <Separator />
                <WPError error={errors["non_field_errors"]} />
              </FormRow>
            ) : null}
            <Separator />
            <FormRow style={styles.actionButtons}>
              <WPButton appearance="outlined" onPress={onCancel}>
                {t("labels.cancel")}
              </WPButton>
              <WPButton
                onPress={formikProps.handleSubmit}
                isLoading={isLoading}
              >
                {t("labels.publish")}
              </WPButton>
            </FormRow>
          </FormContainer>
        )}
      </Formik>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: theme.input.paddingHorizontal,
  },
  image: {
    marginRight: 8,
  },
  sectionTitle: {
    fontFamily: "bold",
    fontSize: 20,
    // marginBottom: 20,
  },
  webSmallField: {
    flexGrow: 0,
    flexBasis: "auto",
    width: 100,
  },
  actionButtons: {
    // flexDirection: 'row',
    justifyContent: "flex-end",
  },
});
