import React from "react";
import WPBaseButton, { WPBaseButtonProps } from "./WPBaseButton";
import WPLinkButton, { WPLinkButtonProps } from "./WPLinkButton";

export type WPButtonProps = WPBaseButtonProps | WPLinkButtonProps;

function isPropsForLink(props: WPButtonProps): props is WPLinkButtonProps {
  return "pageLink" in props && !!props.pageLink;
}

const WPButton: React.FC<WPButtonProps> = (props) => {
  if (isPropsForLink(props)) {
    return <WPLinkButton {...props} />;
  }

  return <WPBaseButton {...props} />;
};

export default WPButton;
