import React from "react";
import { View, StyleSheet, Image, ImageStyle, StyleProp } from "react-native";
import WPButton from "../Button/WPButton";
import theme, {
  isMobileView,
  getThemeResponsiveValue,
} from "../../utils/theme";
import WPText from "../Text/WPText";
import DescList from "./DescList";
import { useDispatch } from "react-redux";
import { closeActionModal } from "../../services/store/actions/uiActions";
import ModalContentWithClose from "./ModalContentWithClose";

export default function ActionModalContent(props: {
  title: string;
  description: string;
  options?: string[];
  onClose: () => void;
  buttonLabel: string;
  image: string;
  customImageStyle?: StyleProp<ImageStyle>;
  buttonDescription?: string;
}) {
  const dispatch = useDispatch();

  return (
    <ModalContentWithClose onClose={() => dispatch(closeActionModal())}>
      <>
        {props.image && !isMobileView() ? (
          <Image
            resizeMode="cover"
            source={props.image as any}
            style={props.customImageStyle || styles.image}
          />
        ) : null}

        <View style={styles.descriptionContainer}>
          <View>
            <WPText style={styles.title}>{props.title}</WPText>
            <WPText style={styles.description}>{props.description}</WPText>
            <DescList list={props.options || []} />
            {props.buttonDescription ? (
              <WPText style={styles.buttonDescription}>
                {props.buttonDescription}
              </WPText>
            ) : null}
          </View>
          <WPButton
            size={isMobileView() ? "small" : "regular"}
            onPress={props.onClose}
            style={isMobileView() ? styles.button : undefined}
          >
            {props.buttonLabel}
          </WPButton>
        </View>
      </>
    </ModalContentWithClose>
  );
}

const addButtonSize = getThemeResponsiveValue(theme.button.size.regular);

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    position: "relative",
    width: "100%",
  },
  descriptionContainer: {
    flex: 1,
    justifyContent: "center",
    paddingRight: isMobileView() ? 0 : 68,
    marginHorizontal: 16,
    marginTop: 32,
    marginBottom: 16,
  },
  image: {
    width: 225,
    height: 385,
  },
  title: {
    fontSize: 16,
    fontFamily: "bold",
    marginBottom: 20,
  },
  description: {
    color: theme.colors.mains[3],
    marginBottom: 12,
  },
  button: { width: "100%" },
  close: {
    position: "absolute",
    right: addButtonSize / 2,
    top: -(addButtonSize / 2),
    backgroundColor: theme.colors.primary,
    height: addButtonSize,
    width: addButtonSize,
    borderRadius: addButtonSize / 2,
    alignItems: "center",
    justifyContent: "center",
    transform: [{ rotate: "45deg" }],
  },
  buttonDescription: {
    color: theme.colors.error,
    fontFamily: "bold",
    marginTop: 4,
    marginBottom: 8,
  },
});
