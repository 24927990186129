import React, { useState, memo, useCallback } from "react";
import {
  StyleProp,
  ViewStyle,
  View,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import WPIcon from "../Icon/WPIcon";
import WPText from "../Text/WPText";
import theme, { getThemeResponsiveValue } from "../../utils/theme";

type CollapsibleProps = {
  label: string;
  style?: StyleProp<ViewStyle>;
  children: React.ReactNode;
};

function Collapsible(props: CollapsibleProps) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggle = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={toggle} style={styles.indicatorContainer}>
        <WPIcon
          name="arrow"
          style={isCollapsed ? styles.iconIsCollapsed : styles.icon}
        />
        <WPText style={styles.title}>{props.label}</WPText>
      </TouchableOpacity>
      {!isCollapsed ? <View style={styles.info}>{props.children}</View> : null}
    </View>
  );
}

export default memo(Collapsible);

const styles = StyleSheet.create({
  container: { marginBottom: 24 },
  indicatorContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 12,
    height: 38,
    borderRadius: 8,
    backgroundColor: theme.colors.mains[6],
  },
  title: {
    fontFamily: "bold",
    textTransform: "uppercase",
    fontSize: getThemeResponsiveValue(theme.button.fontSize),
    color: theme.colors.mains[2],
  },
  info: {
    marginTop: 8,
    paddingHorizontal: 12,
  },
  icon: {
    marginRight: 8,
    transform: [{ rotate: "180deg" }],
  },
  iconIsCollapsed: {
    marginRight: 8,
    transform: [{ rotate: "0deg" }],
  },
});
