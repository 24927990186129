import React from "react";
import AuthForm from "../components/AuthForm";
import FormField from "../../../components/form/FormField";
import FormRow from "../../../components/form/FormRow";
import { ScrollView } from "react-native-gesture-handler";
import { StyleSheet, Platform } from "react-native";
import { useTranslation } from "react-i18next";
import WPButton from "../../../components/Button/WPButton";
import { Formik } from "formik";
import FormContainer from "../../../components/form/FormContainer";
import { useForgotPassRequest } from "./hooks/forgotPassHook";
import AuthNotification from "../components/AuthNotification";
import { navigateTo } from "../../navigationHelpers";
import FormError from "../../../components/form/FormError";
import AuthBackGround from "../components/AuthBackGround";

const initialValues = {
  email: "",
};

const ForgotPassScreen = () => {
  const { t } = useTranslation();

  const [isLoading, isSent, errors, send] = useForgotPassRequest();

  return (
    <AuthBackGround>
      <ScrollView contentContainerStyle={styles.container}>
        <AuthNotification
          isVisible={isSent}
          title={t("titles.reset_password")}
          iconName="send_email"
          description={t("misc.reset_password_text")}
          onClose={() => {
            navigateTo("AUTH.SIGN_IN");
          }}
          buttonLabel={Platform.OS === "web" ? undefined : t("labels.got_it")}
        />
        <AuthForm
          title={t("titles.reset_password")}
          subtitle={t("titles.reset_password_sub")}
          secondaryLinkLabel={t("labels.cancel")}
          link="AUTH.SIGN_IN"
        >
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => send(values.email)}
          >
            {(formikProps) => (
              <FormContainer>
                <FormRow>
                  <FormField name="email" {...formikProps} errors={errors}>
                    <FormError error={errors["non_field_errors"]} />
                  </FormField>
                </FormRow>

                <WPButton
                  onPress={formikProps.handleSubmit}
                  isLoading={isLoading}
                >
                  {t("labels.request_code")}
                </WPButton>
              </FormContainer>
            )}
          </Formik>
        </AuthForm>
      </ScrollView>
    </AuthBackGround>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
  forgotPass: {
    justifyContent: "flex-end",
    flexDirection: "row",
  },
});

export default ForgotPassScreen;
