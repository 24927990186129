import React from "react";
import Svg, { Path, G, Rect } from "../SVG";
import { IconProps } from "../WPIcon";

const CheckboxSelectedIcon: React.FC<IconProps> = (props) => {
  let height = 16;
  let width = 16;
  const ratio = width / height;
  const color = props.color || "#0076FF";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg
      {...props}
      width={height}
      height={height}
      style={[{ height, width }, props.style]}
      viewBox={`0 0 16 16`}
    >
      <G fill="none" fillRule="evenodd" stroke={color}>
        <Rect width={15} height={15} x={0.5} y={0.5} fill="#FFF" rx={4} />
        <Path strokeWidth={2} d="M4.902 8l2.482 2.227 4.455-4.454" />
      </G>
    </Svg>
  );
};

CheckboxSelectedIcon.displayName = "CheckboxSelectedIcon";

export default CheckboxSelectedIcon;
