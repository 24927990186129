import { createReducer } from "deox";
import { gotJob, gotJobs, getJob, jobDeleted } from "../../actions/jobActions";
import { JobOffer } from "../../../apis/jobApi";
import { signedOutAction } from "../../actions/authActions";

type authState = {
  jobs: {
    [key: number]: {
      data: JobOffer | undefined;
      isLoading: boolean;
      isLoaded: boolean;
      isDeleted: boolean;
    };
  };
  totalActive: number;
  totalExpired: number;
};

const defaultState: authState = {
  jobs: {},
  totalActive: 0,
  totalExpired: 0,
};

const jobReducer = createReducer(defaultState, (handleAction) => [
  handleAction(signedOutAction, (state, action) => defaultState),
  handleAction(getJob, (state, action) => ({
    ...state,
    jobs: {
      ...state.jobs,
      [action.payload]: {
        data: undefined,
        isLoading: true,
        isLoaded: false,
        isDeleted: false,
      },
    },
  })),
  handleAction(gotJob, (state, action) => ({
    ...state,
    jobs: {
      ...state.jobs,
      [action.payload.id]: {
        data: action.payload,
        isLoading: false,
        isLoaded: true,
        isDeleted: false,
      },
    },
  })),
  handleAction(jobDeleted, (state, action) => ({
    ...state,
    jobs: {
      ...state.jobs,
      [action.payload]: {
        data: undefined,
        isLoading: false,
        isLoaded: true,
        isDeleted: true,
      },
    },
  })),

  handleAction(gotJobs, (state, action) => {
    return {
      ...state,
      totalExpired: action.payload.isExpired
        ? action.payload.total
        : state.totalExpired,
      totalActive: !action.payload.isExpired
        ? action.payload.total
        : state.totalActive,
      jobs: action.payload.jobs.reduce(
        (jobs, newJob) => ({
          ...jobs,
          [newJob.id]: {
            data: newJob,
            isLoading: false,
            isLoaded: true,
            isDeleted: false,
          },
        }),
        state.jobs
      ),
    };
  }),
]);

export default jobReducer;
