import React from "react";
import { View, StyleSheet } from "react-native";
import { useTranslation } from "react-i18next";
import WPText from "../Text/WPText";
import { getNormalizedDate } from "../../utils/mixUtils";
import theme from "../../utils/theme";
import WPAvatar from "./WPAvatar";

export default function ReviewNode(props: {
  avatar?: string | null;
  name: string;
  date: string;
  text: string;
}) {
  const { i18n } = useTranslation();

  return (
    <View>
      <View style={styles.userContainer}>
        <WPAvatar source={props.avatar || undefined} size="l" />
        <View style={styles.userData}>
          <WPText style={styles.userName}>{props?.name}</WPText>
          <WPText style={styles.date}>
            {getNormalizedDate(props.date, i18n.language)}
          </WPText>
        </View>
      </View>
      <WPText style={styles.text}>{props.text}</WPText>
    </View>
  );
}

const styles = StyleSheet.create({
  spacer: {
    marginBottom: 12,
  },
  container: {
    flexDirection: "column-reverse",
  },
  userData: {
    marginLeft: 12,
  },
  userContainer: {
    marginBottom: 12,
    flexDirection: "row",
    alignItems: "center",
  },
  userName: {
    fontFamily: "bold",
  },
  date: {
    fontSize: 12,
    color: theme.colors.mains[3],
  },
  text: {
    fontSize: 14,
    lineHeight: 14 * 1.43,
  },
});
