import React, { memo } from "react";
import { View, StyleSheet } from "react-native";
import WPConversationInput from "./WPConversationInput";
import WPMessagesContainer from "./WPMessagesContainer";
import theme from "../../../../utils/theme";

function WPConversation() {
  return (
    <View style={styled.container}>
      <WPMessagesContainer />
      <WPConversationInput />
    </View>
  );
}

export default memo(WPConversation);

const styled = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.white,
  },
});
