import { Notifications } from "expo";
import { NOTIFICATIONS, getAsync, askAsync } from "expo-permissions";
import Constants from "expo-constants";
import { logToConsole } from "./logger";
import { Platform } from "react-native";

export const getNotificationPermission = async () => {
  if (Constants.isDevice) {
    const { status: existingStatus } = await getAsync(NOTIFICATIONS);

    let finalStatus = existingStatus;

    if (existingStatus !== "granted") {
      const { status } = await askAsync(NOTIFICATIONS);
      finalStatus = status;
    }

    if (finalStatus !== "granted") {
      return false;
    }

    if (Platform.OS === "android") {
      Notifications.createChannelAndroidAsync("default", {
        name: "default",
        sound: true,
        priority: "max",
        vibrate: [0, 250, 250, 250],
      });
    }

    return true;
  } else {
    logToConsole("Must use physical device for Push Notifications");
    return false;
  }
};

export type CRMPermissions = {
  isAuthenticated: boolean;
};

export const getCRMPermissions = async (): Promise<CRMPermissions> => {
  return {
    isAuthenticated: true,
  };
};
