import { Dimensions } from "react-native";
import { ApplicationStatus } from "../services/chatService";

type ResponsiveThemeValue<T> = {
  s: T;
  m?: T;
  l?: T;
};

const breakPoints: ResponsiveThemeValue<Number> = {
  s: 420,
  m: 1024,
  l: 1280,
};
const inputSizes = {
  big: {
    m: 60,
    s: 60,
  },
  regular: {
    m: 44,
    s: 52,
  },
  small: {
    m: 28,
    s: 32,
  },
};

const fontFamily = {
  black: "black",
  bold: "bold",
  normal: "normal",
  light: "light",
};

const inputFontSizes = {
  m: 14,
  s: 16,
};
const colors = {
  white: "#fff",
  main: "#454f5b",
  mains: [
    "#212b36",
    "#454f5b",
    "#637381",
    "#919eab",
    "#c4cdd5",
    "#dfe3e8",
    "#f4f6f8",
    "#f9fafb",
  ],
  primary: "#0076ff",
  primaries: ["#0064d9", "#0076ff", "#7fbaff", "#b2d5ff", "#e6f2ff", "#f5faff"],
  error: "#de3618",
  errors: ["#de3618", "#fead9a", "#fbeae5"],
  success: "#50b83c",
  successes: ["#50b83c", "#bbe5b3", "#e3f1df"],
  warning: "#f49342",
  warnings: ["#f49342", "#ffc58b", "#fcebdb"],
  misc: ["#ff4747", "#ffa400"],
  custom: ["#f2f2f2"],
  transparent: "transparent",
  statuses: {
    [ApplicationStatus.NEW]: {
      primary: "#02b862",
      secondary: "#e3f1df",
    },
    [ApplicationStatus.RFS]: {
      primary: "#ff4747",
      secondary: "#fbeae5",
    },
    default: {
      primary: "#ff8200",
      secondary: "#fcebdb",
    },
  },
};

const inputBorderRadius = 8;

const defaultInputStyle = {
  size: {
    ...inputSizes,
  },
  fontSize: {
    ...inputFontSizes,
  },
  borderRadius: inputBorderRadius,
  regularColor: colors.primary,
  criticalColor: colors.error,
  paddingHorizontal: 16,
};

const inputStyles = {
  ...defaultInputStyle,
  blurColor: colors.mains[3],
  focusColor: colors.primary,
  errorColor: colors.error,
  successColor: colors.success,
  labelFocusFontSize: {
    m: 12,
    s: 14,
  },
  labelBlurFontSize: {
    m: 14,
    s: 16,
  },
  labelActiveColor: colors.main,
  labelBlurColor: colors.mains[3],
  borderRadius: inputBorderRadius,
};

const theme = {
  colors,

  fontFamily,

  header: {
    height: {
      s: 60,
    },
  },

  input: {
    ...inputStyles,
  },

  button: {
    ...defaultInputStyle,
  },
  checkbox: {
    size: 16,
    defaultColor: inputStyles.blurColor,
    selectedColor: inputStyles.focusColor,
  },
  formRow: {
    flexDirection: {
      s: "column",
      m: "column",
      l: "row",
    },
    spacerForced: {
      width: 16,
      marginBottom: 0,
    },
    spacer: {
      width: {
        s: 0,
        m: 0,
        l: 16,
      },
      marginBottom: {
        s: 20,
        m: 20,
        l: 0,
      },
    },
  },
  avatar: {
    size: {
      s: 25,
      m: 32,
      l: 40,
      xl: 56,
    },
  },
  badge: {
    height: 20,
  },
};

export default theme;

export const getThemeResponsiveValue = <T>(
  range: ResponsiveThemeValue<T>
): T => {
  const { width } = Dimensions.get("window");

  if (range.l && breakPoints.l && width > breakPoints.l) {
    return range.l || range.m || range.s;
  }

  if (range.m && breakPoints.m && width > breakPoints.m) {
    return range.m || range.s;
  }

  return range.s;
};

export const isMobileView = () => {
  const { width } = Dimensions.get("window");

  return width <= (breakPoints.m || 600);
};

export const getHeaderHeight = () => {
  return Number(getThemeResponsiveValue(theme.header.height));
};
