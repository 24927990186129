import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const DownloadIcon: React.FC<IconProps> = (props) => {
  let height = 20;
  let width = 20;
  const ratio = width / height;
  const color = props.color || "#0076FF";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M16.222 15.544c.43 0 .778.326.778.728 0 .368-.293.673-.672.721l-.106.007H3.778c-.43 0-.778-.326-.778-.728 0-.369.293-.673.672-.722l.106-.006h12.444zM10 3c.394 0 .72.274.77.63l.008.098-.001 7.968 2.827-2.61a.816.816 0 011.1.007c.274.26.296.665.068.949l-.076.081-4.15 3.832-.022.02a.774.774 0 01-.068.052l.09-.072a.774.774 0 01-.292.17l-.035.01a.811.811 0 01-.342.021l-.027-.005a.741.741 0 01-.086-.02l-.033-.01a.724.724 0 01-.198-.102.77.77 0 01-.059-.045l-.028-.026-4.037-3.832a.696.696 0 01.008-1.03.818.818 0 011.013-.064l.086.071 2.706 2.569V3.728C9.222 3.326 9.57 3 10 3z"
      />
    </Svg>
  );
};

DownloadIcon.displayName = "DownloadIcon";

export default DownloadIcon;
