import React, { useState } from "react";
import WPIcon, { IconName } from "../Icon/WPIcon";
import theme from "../../utils/theme";
import DropDownWrapper from "./DropDownWrapper";
import { StyleSheet, View } from "react-native";
import PrependAppendDecorator from "./PrependAppendDecorator";
import WPText from "../Text/WPText";

type WPMoreButtonOption = {
  label: string;
  icon?: IconName;
  isDisabled?: boolean;
  onPress: () => void;
};

const getDropDownItems = (options: WPMoreButtonOption[]) =>
  options.map((option) => ({ label: option.label, value: option.label }));

const getOption = (
  options: WPMoreButtonOption[],
  label: string
): WPMoreButtonOption | undefined =>
  options.find((option) => option.label === label);

export default function WPMoreButton(props: { options: WPMoreButtonOption[] }) {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <DropDownWrapper
      isFocused={isFocused}
      setIsFocused={setIsFocused}
      items={getDropDownItems(props.options)}
      customRenderItem={(label) => (
        <PrependAppendDecorator
          prepend={
            getOption(props.options, label)?.icon ? (
              <WPIcon name={getOption(props.options, label)?.icon} />
            ) : undefined
          }
        >
          <WPText
            style={
              getOption(props.options, label)?.isDisabled
                ? styles.disabled
                : undefined
            }
          >
            {label}
          </WPText>
        </PrependAppendDecorator>
      )}
      onChange={(label) => {
        const option = getOption(props.options, label);

        if (option && !option?.isDisabled && option.onPress) {
          option.onPress();
        }
      }}
    >
      <View style={styles.icon}>
        <WPIcon name="more" color={theme.colors.primary} />
      </View>
    </DropDownWrapper>
  );
}

const styles = StyleSheet.create({
  icon: {
    paddingHorizontal: theme.button.paddingHorizontal,
  },
  disabled: {
    color: theme.colors.mains[4],
  },
});
