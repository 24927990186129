import { FBSDK } from "./fbsdk-types";

const FBSDK_MODULE: FBSDK = {
  logEvent: (eventName: string) => {
    const fbsdk = window["fbq"];

    if (fbsdk) {
      fbsdk("trackCustom", eventName);
    } else {
      console.warn("FBSDK is not initialized!");
    }
  },
  logPurchase: (amount) => {
    const fbsdk = window["fbq"];

    if (fbsdk) {
      fbsdk("track", "Purchase", { currency: "PLN", value: amount });
    } else {
      console.warn("FBSDK is not initialized!");
    }
  },
};

export default FBSDK_MODULE;
