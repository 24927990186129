import React, { useCallback } from "react";
import WPIcon from "../../../../components/Icon/WPIcon";
import { useGetActiveJobOffers } from "../../../../services/hooks/jobHooks";
import { JobOffer, defaultPagination } from "../../../../services/apis/jobApi";
import WPJobTile from "../../../../components/jobs/WPJobTile";
import WPSearchWrapper from "../../../../components/input/components/WPSearchWrapper";
import { useGetEstateList } from "../../estate/estateUtils";
import { getActiveConversationType } from "./conversationHooks";
import { ConversationType } from "../../../../services/chatService";
import EstateTile from "../../estate/screens/components/EstateTile";

export default function WPMessageAttachmentHandler(props: {
  onSelected: (data: { id: number; type: "job" | "estate" }) => void;
}) {
  const activeConversationType = getActiveConversationType();
  const getEstates = useGetEstateList();
  const getJobs = useGetActiveJobOffers();

  const listCall =
    activeConversationType === ConversationType.JOB ? getJobs : getEstates;

  const searchCall = useCallback(
    (searchText: string) => {
      return listCall({
        filter: { search: searchText },
        pagination: defaultPagination,
      }).then((res) => {
        if (activeConversationType === ConversationType.JOB) {
          return res.results.filter(
            (job: JobOffer) => job.status === "PUBLISHED"
          );
        }
        return res.results;
      });
    },
    [listCall]
  );

  const itemRenderer = useCallback(
    (item: { id: number }) =>
      activeConversationType === ConversationType.JOB ? (
        <WPJobTile jobId={item.id} />
      ) : (
        <EstateTile id={item.id} />
      ),
    [activeConversationType]
  );

  const onSelected = useCallback(
    (item: { id: number }) =>
      props.onSelected({ id: item.id, type: activeConversationType }),
    [props.onSelected, activeConversationType]
  );

  const keyExtractor = useCallback((job: JobOffer) => job.id.toString(), []);

  return (
    <WPSearchWrapper
      searchCall={searchCall}
      itemRenderer={itemRenderer}
      onSelected={onSelected}
      keyExtractor={keyExtractor}
      noSeparator
    >
      <WPIcon name="add" />
    </WPSearchWrapper>
  );
}
