import React, { memo, useCallback } from "react";
import { useGetJob } from "../../../../services/hooks/jobHooks";
import JobEditor from "../components/JobEditor";
import { useTranslation } from "react-i18next";
import { navigateTo } from "../../../navigationHelpers";

type EditJobScreenProps = {
  route: {
    params: {
      jobId: number;
    };
  };
};

function DuplicateJobScreen(props: EditJobScreenProps) {
  const { t } = useTranslation();
  const job = useGetJob(props.route.params.jobId);

  const onSaved = useCallback((offerId: number) => {
    navigateTo(`CRM.JOB.JOB_PACKAGE?jobId=${offerId}`);
  }, []);

  if (!job || !job?.data) {
    return null;
  }

  const duplicate = JSON.parse(JSON.stringify(job.data));

  delete duplicate.id;
  delete duplicate.images;

  return (
    <JobEditor
      job={duplicate}
      title={t("labels.duplicate")}
      onSaved={onSaved}
    />
  );
}

export default memo(DuplicateJobScreen);
