import React from "react";
import { navigateTo } from "../../navigation/navigationHelpers";
import WPBaseButton, { WPBaseButtonProps } from "./WPBaseButton";

export type WPLinkButtonProps = WPBaseButtonProps & {
  pageLink: string;
};

const WPLinkButton: React.FC<WPLinkButtonProps> = ({ pageLink, ...props }) => {
  return (
    <WPBaseButton
      {...props}
      onPress={() => {
        navigateTo(pageLink);
        if (props.onPress) {
          props.onPress();
        }
      }}
    />
  );
};

export default WPLinkButton;
