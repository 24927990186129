import wpRequest, { fileRequest } from "../../utils/wpRequest";
import { BillType } from "../../navigation/crm/job/screens/PaymentsScreen";
import { logError } from "../logger";
import { TransactionStatus } from "../hooks/paymentHooks";
// import Constants from "expo-constants";
import config from "../config";

const { API_V2 = "" } = config;

export enum AdPackType {
  "job_packet" = "job_packet",
  "job_action" = "job_action",
}

export type AdPackConfig = {
  id: number;
  name: string;
  actionsConfig: {
    [actionName: string]: number;
  };
  payConfig: {
    amount: number;
    price: number;
  }[];
  type: AdPackType;
};

export type AvailableAdPacks = {
  pack_id: number;
  number: number;
};

export type LinkedAdPack = {
  pack_id: number;
  offer_id: number;
  unsued_actions: {
    [key: string]: number;
  };
};

export type PaymentLinkConfig =
  | {
      payment_link: string;
      transaction_id: string;
    }
  | {
      transaction_status: TransactionStatus;
    };

export async function getAdPackConfig() {
  return wpRequest
    .get<AdPackConfig[]>(`${API_V2}packets/config`)
    .catch((err) => {
      logError(err);
      return [];
    });
}

export type Invoice = {
  paymentForm: BillType;
  country: undefined;
  city: undefined;
  index: undefined;
  street: undefined;
} & (
  | {
      first_name: undefined;
      last_name: undefined;
    }
  | { employer_company_name: undefined; nip: undefined }
);

export async function getPaymentConfig(paymentData: {
  packId: number;
  packOption: number;
  invoice: Omit<Invoice, "employer_company_name"> & {
    company_name: string;
  };
  link: { offers: number[] };
  promo_code: string;
}) {
  console.log(paymentData);
  return wpRequest.post<PaymentLinkConfig>(`${API_V2}packets/purchase`, {
    // amount: Number(paymentData.packOption),
    product_count: Number(paymentData.packOption),
    product_config_id: Number(paymentData.packId),
    invoice: paymentData.invoice,
    link: {
      offers: paymentData.link.offers.filter((item) => !!item),
    },
    promo_code: paymentData.promo_code,
  });
}

export async function checkTransactionStatus(transactionId: number) {
  return wpRequest.get<{ status: TransactionStatus }>(
    `${API_V2}packets/status/${transactionId}`
  );
}

export async function getAvailablePacks() {
  return wpRequest.get<AvailableAdPacks[]>(`${API_V2}packets/info`);
}

export async function getLinkedAdPacks() {
  return wpRequest.get<LinkedAdPack[]>(`${API_V2}packets/info/link`);
}

export type Transaction = {
  amount: number;
  date_create: string;
  hash: string;
  id: number;
  invoice: Invoice;
  link_offers: { offers: number[] };
  product_config_id: number;
  product_count: number;
  redirect_url: string;
  remote_id: string;
  status: TransactionStatus;
  user_id: number;
  user_type: "employer";
};

export async function getTransactionsCall() {
  return wpRequest.get<Transaction[]>(`${API_V2}packets/info/transactions`);
}

export async function getInvoiceCall(id: string | number) {
  return fileRequest(`${API_V2}packets/info/invoice`);
}

export async function connectPackToOfferApi(data: {
  offer_id: number;
  packet_config_id: number;
}) {
  return wpRequest.post(`${API_V2}packets/status`, data);
}

export type Promo = {
  code: string;
  id: number;
  maxCount: number;
  name: string;
  priceGain: number;
  priceOffset: number;
  usedCount: number;
  applicableTo: number[];
};

export async function getPromocodeDesc(
  promo: string,
  packId: number,
  count: number = 1
) {
  return wpRequest.post<Promo>(`${API_V2}packets/promo`, {
    promo,
    product_config_id: Number(packId),
    product_count: Number(count),
  });
}
