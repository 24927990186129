import { initGlobals } from "./src/utils/globals";
import React, { Suspense, Component, ReactNode } from "react";
import { AppLoading } from "expo";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
// @ts-ignore
console.reportErrorsAsExceptions = false;
initGlobals();

import i18n from "./src/utils/i18n";
import WPRouter from "./src/navigation/WPRouter";
import { getStore } from "./src/services/store/store";
import {
  useAppInitHook,
  useUserIntiHook,
  useIntroHook,
} from "./src/utils/useAppInitHook";
import {
  KeyboardAvoidingView,
  StyleSheet,
  Platform,
  ImageBackground,
} from "react-native";
import appCache from "./src/services/appCache";
import { logError } from "./src/services/logger";
import Welcome from "./src/components/misc/Welcome";

function UserWrapper(props: { children: ReactNode }) {
  useUserIntiHook();

  return props.children || null;
}

class AppWithErrorBoundaries extends Component<
  {
    storeData: any;
  },
  { isUpdatedCount: number }
> {
  state = {
    isUpdatedCount: 0,
  };

  componentDidCatch(err: any, errorInfo: any) {
    logError("GLOBAL ERROR");
    console.warn(err, errorInfo);
    appCache.clearCache();
  }

  render() {
    return (
      <Suspense fallback={<AppLoading />}>
        <I18nextProvider i18n={i18n}>
          <Provider store={getStore(this.props.storeData)}>
            <UserWrapper>
              <KeyboardAvoidingView
                enabled={Platform.OS !== "web"}
                style={style.main}
                behavior={Platform.OS === "ios" ? "padding" : undefined}
              >
                <Suspense fallback={<AppLoading />}>
                  <ImageBackground
                    source={require("./assets/auth_background.png")}
                    style={style.image}
                  >
                    <WPRouter />
                  </ImageBackground>
                </Suspense>
              </KeyboardAvoidingView>
            </UserWrapper>
          </Provider>
        </I18nextProvider>
      </Suspense>
    );
  }
}

export default function App() {
  const [isLoaded, storeData] = useAppInitHook();
  const [isIntroSeen, closeIntro] = useIntroHook();

  if (!isLoaded) {
    return <AppLoading />;
  }

  if (Platform.OS !== "web" && !isIntroSeen) {
    return (
      <I18nextProvider i18n={i18n}>
        <Welcome onEnd={closeIntro} />
      </I18nextProvider>
    );
  }
  return <AppWithErrorBoundaries storeData={storeData} />;
}

const style = StyleSheet.create({
  main: {
    flex: 1,
    justifyContent: "flex-start",
  },
  image: {
    flex: 1,
    // resizeMode: "cover",
    justifyContent: "center",
  },
});
