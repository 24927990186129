import React, { useCallback } from "react";
import { City, searchCitiesCall } from "../../../services/apis/jobApi";
import InputWrapper, { InputWrapperProps } from "./InputWrapper";
import ValueComponent from "./misc/ValueCustom";
import WPSearchWrapper from "./WPSearchWrapper";
import WPText from "../../Text/WPText";
import { TouchableOpacity } from "react-native-gesture-handler";
import WPIcon from "../../Icon/WPIcon";

export type WPCitySearchProps = Omit<
  Omit<InputWrapperProps<City>, "isFocused">,
  "value"
> & {
  value?: City;
  onChange: (value?: City) => void;
};

export default function WPCitySearch(props: WPCitySearchProps) {
  const searchCities = useCallback((searchTerm: string) => {
    return searchCitiesCall(searchTerm);
  }, []);

  const itemRenderer = useCallback((city: City | string) => {
    const value = typeof city === "string" ? city : city.name;
    return <WPText>{value}</WPText>;
  }, []);

  const keyExtractor = useCallback(
    (city: City) => city.geoname_id.toString(),
    []
  );

  const clearInput = useCallback(() => props.onChange(), [props.onChange]);

  return (
    <WPSearchWrapper
      searchCall={searchCities}
      itemRenderer={itemRenderer}
      keyExtractor={keyExtractor}
      onSelected={props.onChange}
    >
      <InputWrapper
        {...props}
        isFocused={false}
        append={
          props.value ? (
            <TouchableOpacity onPress={clearInput}>
              <WPIcon name="close" />
            </TouchableOpacity>
          ) : null
        }
      >
        <ValueComponent value={props.value} customRenderItem={itemRenderer} />
      </InputWrapper>
    </WPSearchWrapper>
  );
}
