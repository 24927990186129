import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const BackIcon: React.FC<IconProps> = (props) => {
  let height = 20;
  let width = 20;
  const ratio = width / height;
  const color = props.color || "#777";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M9.583 2.204a1.004 1.004 0 011.419-.017 1.003 1.003 0 01-.018 1.419L5.58 9.008h11.404c.521 0 .95.383 1.01.876L18 10c0 .547-.455.991-1.016.991H5.442l5.387 5.386a1 1 0 01.067 1.324l-.084.095a1.004 1.004 0 01-1.42.017l-7.008-7.008a1.004 1.004 0 01.017-1.42z"
      />
    </Svg>
  );
};

BackIcon.displayName = "BackIcon";

export default BackIcon;
