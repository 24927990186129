import { createStore, applyMiddleware, compose, Middleware } from "redux";
import createSagaMiddleware from "redux-saga";

import reducer, { RootState } from "./reducers/mainReducer";
import mySaga from "./sagas/mainSaga";
import appCache from "../appCache";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers =
  (process.env.NODE_ENV === "development" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const cacheMiddleware: Middleware = (store) => (next) => (action) => {
  setTimeout(() => {
    const state: RootState = store.getState();
    state.ui.actionModal = undefined;
    appCache.saveStoreValue(state);
  }, 100);

  next(action);
};

export const getStore = (initialState?: any) => {
  const enhancers = composeEnhancers(
    applyMiddleware(sagaMiddleware, cacheMiddleware)
  );

  const store = createStore(
    reducer,
    initialState ? initialState : enhancers,
    initialState ? enhancers : undefined
  );

  sagaMiddleware.run(mySaga);

  return store;
};
