import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const ChatIcon: React.FC<IconProps> = (props) => {
  let height = 19;
  let width = 20;
  const ratio = width / height;
  const color = props.color || "#B1B1B1";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 19 20" {...props}>
      <Path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.128 0H1.896A1.896 1.896 0 000 1.896v11.181c0 1.046.849 1.895 1.896 1.895h9.38l3.495 3.495v-3.495h2.357a1.896 1.896 0 001.896-1.895V1.896A1.896 1.896 0 0017.128 0z"
      />
    </Svg>
  );
};

ChatIcon.displayName = "ChatIcon";

export default ChatIcon;
