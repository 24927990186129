import React, { ReactNode } from "react";
import WPText from "../../../Text/WPText";
import { StyleSheet } from "react-native";
import theme, { getThemeResponsiveValue } from "../../../../utils/theme";

type ValueComponentProps<T> = {
  value?: T;
  customRenderItem?: (value: T) => ReactNode;
};

export default function ValueComponent<T>(props: ValueComponentProps<T>) {
  if (!props.value) {
    return null;
  }

  if (props.customRenderItem) {
    return props.customRenderItem(props.value) as React.ReactElement; //TODO: fix this type
  }

  return (
    <WPText style={styles.value} numberOfLines={1}>
      {props.value}
    </WPText>
  );
}

const styles = StyleSheet.create({
  value: {
    fontSize: getThemeResponsiveValue(theme.input.fontSize),
    alignSelf: "flex-start",
  },
});
