// TODO: make an abstraction
import React, { memo } from "react";
import { useGetExpiredJobOffers } from "../../../../services/hooks/jobHooks";
import { useTranslation } from "react-i18next";
import CRMDataScreen from "../../common/CRMDataScreen";
import { useRenderJobOfferTile } from "../jobUtils";
import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { RootState } from "../../../../services/store/reducers/mainReducer";
import { isMobileView } from "../../../../utils/theme";

const config = { refresh: true, delete: true };

function ExpiredJobsScreen() {
  const isUpdateScheduled = useSelector(
    (state: RootState) => state.ui.shouldUpdateList
  );
  const navigation = useNavigation();
  const getJobs = useGetExpiredJobOffers();
  const { i18n, t } = useTranslation();

  const renderItem = useRenderJobOfferTile({
    actionConfig: config,
    isCompactView: isMobileView(),
    skipTimeRemaining: true,
    hideStatistics: true,
  });

  return (
    <CRMDataScreen
      navigation={navigation}
      title={t("titles.expired_job_offers")}
      getData={getJobs}
      renderItem={renderItem}
      language={i18n.language}
      isUpdateScheduled={isUpdateScheduled}
    />
  );
}

export default memo(ExpiredJobsScreen);
