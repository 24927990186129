import React, { ReactNode, memo, useState, useEffect, useMemo } from "react";
import WPModal from "../WPModal";
import { StyleSheet, SafeAreaView, View } from "react-native";
import theme, { isMobileView } from "../../../utils/theme";

type WPNotificationModalProps = {
  isVisible: boolean;
  isFullscreen?: boolean;
  setIsVisible?: (isVisible: boolean) => void;
  isWide?: boolean;
  withoutOverflow?: boolean;
  children: ReactNode;
  onDismiss?: () => void;
};

function WPNotificationModal(props: WPNotificationModalProps) {
  const isMobile = isMobileView();

  const modalStyle = useMemo(
    () => [
      isMobile ? styles.modal : styles.modalLarge,
      props.isWide ? styles.modalExtraLarge : undefined,
      props.isFullscreen && styles.fullScreen,
      props.withoutOverflow
        ? undefined
        : {
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
            backgroundColor: "white",
            overflow: isMobile ? "hidden" : "visible",
          },
    ],
    [isMobile, props.isFullscreen, props.isWide, props.withoutOverflow]
  );

  return (
    <WPModal
      isVisible={!!props.isVisible}
      style={isMobile ? styles.modalContainer : styles.modalContainerLarge}
      backdropTransitionOutTiming={0}
      hideModalContentWhileAnimating={true}
      useNativeDriver
      animationIn="fadeInUp"
      animationOut="fadeOutDown"
      onModalHide={props.onDismiss}
    >
      <SafeAreaView style={modalStyle}>
        {props.withoutOverflow ? (
          <>
            <View
              style={{
                backgroundColor: "white",
                // backgroundColor: "transparent",
                marginTop: 30,
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
                zIndex: 2,
              }}
            >
              {props.children}
            </View>
            {/* <View
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                height: 100,
                // backgroundColor: "white",
                zIndex: 0,
              }}
            ></View> */}
          </>
        ) : (
          props.children
        )}
      </SafeAreaView>
    </WPModal>
  );
}

export default memo(WPNotificationModal);

const styles = StyleSheet.create({
  modalContainer: {
    margin: 0,
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: "transparent",
  },
  modalContainerLarge: {
    margin: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  modal: {
    // backgroundColor: theme.colors.white,
    width: "100%",
    // maxWidth: "auto" : 400,
    maxHeight: "93%",
  },
  modalExtraLarge: {
    maxWidth: 800,
  },
  modalLarge: {
    backgroundColor: theme.colors.white,
    borderRadius: 15,
    maxWidth: 400,
    minWidth: 300,
    maxHeight: "80%",
  },
  fullScreen: {
    flex: 1,
  },
});
