import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const HomeIcon: React.FC<IconProps> = (props) => {
  let height = 24;
  let width = 24;
  const ratio = width / height;
  const color = props.color || "#637381";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M19.49 20.4H4.51a.916.916 0 01-.91-.923V9.546c0-.307.15-.594.402-.766l7.35-5.023a.898.898 0 011.002-.008l7.631 5.022c.259.17.415.462.415.775v9.93c0 .51-.407.924-.91.924zM11.87 5.63l-6.45 4.407v8.517h3.532v-5.48c0-.467.342-.853.786-.914l.123-.009h4.28c.46 0 .84.348.901.798l.008.125v5.48h3.532v-8.507L11.869 5.63zm1.361 8.368h-2.46l-.001 4.555h2.461v-4.555z"
      />
    </Svg>
  );
};

HomeIcon.displayName = "HomeIcon";

export default HomeIcon;
