import React, { memo } from "react";
import { BaseChatData } from "../../../../services/store/reducers/chat/chatReducer";
import NotificationLabel from "../../../../components/misc/NotificationLabel";
import UserTile from "../../../../components/misc/UserTile";

function WPChatTile(props: BaseChatData) {
  return (
    <UserTile
      userId={props.userId}
      append={
        props.unreadCount ? (
          <NotificationLabel label={props.unreadCount.toString()} />
        ) : null
      }
    />
  );
}

export default memo(WPChatTile);
