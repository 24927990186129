import React from "react";
import theme, {
  getThemeResponsiveValue,
  isMobileView,
} from "../../utils/theme";
import { View, StyleSheet } from "react-native";
import WPText from "../Text/WPText";
import WPIcon from "../Icon/WPIcon";

type MobileListTileProps = {
  title: string;
  subtitle: string;
  location: string;
  firstDesc?: React.ReactNode | string;
  secondDesc?: React.ReactNode | string;
};

export default function MobileListTile(props: MobileListTileProps) {
  return (
    <View style={styles.container}>
      <WPText style={styles.title}>{props.title}</WPText>
      <WPText style={styles.subTitle}>{props.subtitle}</WPText>
      <View style={styles.locationContainer}>
        <WPIcon name="place" style={styles.locationIcon} />
        <WPText style={styles.locationName}>{props.location}</WPText>
      </View>
      <View style={styles.desc}>
        <View style={styles.firstDesc}>
          {typeof props.firstDesc === "string" ? (
            <WPText style={styles.descText}>{props.firstDesc}</WPText>
          ) : (
            props.firstDesc
          )}
        </View>
        {typeof props.secondDesc === "string" ? (
          <WPText style={styles.descText}>{props.secondDesc}</WPText>
        ) : (
          props.secondDesc
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {
    fontSize: getThemeResponsiveValue(theme.button.fontSize) + 4,
    fontFamily: "bold",
    marginBottom: 4,
  },
  subTitle: {
    fontSize: isMobileView() ? 18 : 14,
    marginBottom: 4,
  },
  locationContainer: {
    flexDirection: "row",
    marginBottom: 16,
    alignItems: "center",
  },
  locationIcon: { marginRight: 4 },
  locationName: {
    fontSize: getThemeResponsiveValue(theme.button.fontSize),
    color: theme.colors.mains[3],
  },
  desc: { flexDirection: "row", alignItems: "center" },
  firstDesc: {
    marginRight: 24,
  },
  descText: {
    fontSize: getThemeResponsiveValue(theme.button.fontSize) - 2,
  },
});
