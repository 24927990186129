import React, { useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getChatState,
  RootState,
} from "../../../../services/store/reducers/mainReducer";
import {
  getChatList,
  BaseChatData,
} from "../../../../services/store/reducers/chat/chatReducer";
import { FlatList, StyleSheet } from "react-native";
import WPChatTile from "./WPChatTile";
import WPButton from "../../../../components/Button/WPButton";
import { useOpenChat } from "./conversationHooks";
import WPInput from "../../../../components/input/WPInput";
import { useTranslation } from "react-i18next";
import { setChatListSearch } from "../../../../services/store/actions/chatActions";

function WPChatList() {
  const { t } = useTranslation();
  const chatList = getChatList(useSelector(getChatState));
  const searchTerm = useSelector((state: RootState) => state.chat.searchTerm);
  const dispatch = useDispatch();
  const openChat = useOpenChat();

  const setSearchTerm = useCallback(
    (value) => {
      dispatch(setChatListSearch(value));
    },
    [dispatch]
  );

  const renderItem = useCallback((data: { item: BaseChatData }) => {
    return (
      <WPButton
        appearance="link"
        onPress={() =>
          openChat(
            data.item.chats.job || data.item.chats.estate || "",
            data.item.userId
          )
        }
      >
        <WPChatTile {...data.item} />
      </WPButton>
    );
  }, []);

  const keyExtractor = useCallback(
    (item: { userId: number }) => item.userId.toString(),
    []
  );

  return (
    <FlatList
      ListHeaderComponent={
        <WPInput
          label={t("labels.search")}
          value={searchTerm}
          onChange={setSearchTerm}
        />
      }
      ListHeaderComponentStyle={styles.header}
      data={chatList}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      extraData={chatList.length}
    />
  );
}

const styles = StyleSheet.create({
  header: {
    margin: 12,
  },
});

export default memo(WPChatList);
