import { TFunction } from "i18next";
import {
  IncomeType,
  Accommodation,
  Meals,
  AgeRange,
  PreferredEmployees,
  IamApps,
} from "./apis/jobApi";
import {
  BillingPeriod,
  RentType,
  Renter,
  PropertyType,
  BedType,
  AdvertiserType,
  Equipment,
  Facilities,
  Restrictions,
} from "./apis/estateApi";
import { ApplicationStatus } from "./chatService";
import { BillType } from "../navigation/crm/job/screens/PaymentsScreen";

type Options = {
  [key: string]: any[] | object;
};

const defaultOptions: never[] = [];
const hours = Array.from(Array(24), (_, i) => i + 1);

const options: Options = {
  min_working_hours: hours,
  max_working_hours: hours,
  income_type: IncomeType,
  accommodation: Accommodation,
  meals: Meals,
  preferred_employees: PreferredEmployees,
  age_range: AgeRange,
  im_apps: IamApps,
  billing_period: BillingPeriod,
  rent_type: RentType,
  renter: Renter,
  property_type: PropertyType,
  number_of_rooms: hours,
  number_of_beds: hours,
  number_of_floors: hours,
  bed_type: BedType,
  advertiser_type: AdvertiserType,
  equipment: Equipment,
  facilities: Facilities,
  restrictions: Restrictions,
  minimum_rent_term: hours,
  floor: hours,
  job_application_status: ApplicationStatus,
  paymentForm: BillType,
};

export const getSelectOptions = (name: string, t: TFunction) => {
  if (name && name in options && options[name]) {
    const fieldOptions = options[name];

    if (Array.isArray(fieldOptions)) {
      return fieldOptions.map((option) => ({
        label: option.toString(),
        value: option,
      }));
    }

    return Object.keys(options[name]).map((type) => ({
      tooltip: t(`tooltip.select.${name}.${type}`, ""),
      label: t(`select.${name}.${type}`),
      value: type,
    }));
  }

  return defaultOptions;
};
