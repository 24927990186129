import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const UsersIcon: React.FC<IconProps> = (props) => {
  let height = 24;
  let width = 24;
  const ratio = width / height;
  const color = props.color || "#919EAB";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M10.158 11.071a4.009 4.009 0 014.01 4.01v5.59a.93.93 0 01-.93.93H3.33a.929.929 0 01-.929-.93v-5.59a4.009 4.009 0 014.01-4.01zm7.432 0a4.01 4.01 0 014.004 3.782l.006.228v5.59c0 .47-.35.86-.803.921l-.126.008h-3.716a.929.929 0 01-.126-1.85l.126-.008h2.787v-4.661c0-1.126-.864-2.05-1.966-2.144l-.186-.008h-1.874a.929.929 0 01-.126-1.85l.126-.008h1.874zm-7.432 1.858H6.41a2.15 2.15 0 00-2.152 2.152v4.661h8.052v-4.661c0-1.126-.864-2.05-1.966-2.144l-.186-.008zM8.654 2.4a3.036 3.036 0 013.036 3.037v1.339a3.037 3.037 0 01-3.036 3.036h-.74a3.037 3.037 0 01-3.037-3.036v-1.34A3.036 3.036 0 017.913 2.4zm7.432 0a3.036 3.036 0 013.036 3.037v1.339a3.037 3.037 0 01-3.036 3.036h-.74a3.037 3.037 0 01-3.037-3.036v-1.34A3.036 3.036 0 0115.346 2.4zM8.654 4.26h-.74c-.651 0-1.179.527-1.179 1.178v1.339c0 .65.528 1.178 1.178 1.178h.741c.65 0 1.178-.528 1.178-1.178v-1.34c0-.65-.527-1.177-1.178-1.177zm7.432 0h-.74c-.651 0-1.179.527-1.179 1.178v1.339c0 .65.528 1.178 1.179 1.178h.74c.65 0 1.178-.528 1.178-1.178v-1.34c0-.65-.527-1.177-1.178-1.177z"
      />
    </Svg>
  );
};

UsersIcon.displayName = "UsersIcon";

export default UsersIcon;
