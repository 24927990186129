import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";
import theme from "../../../utils/theme";

const CheckMarkIcon: React.FC<IconProps> = (props) => {
  let height = 12;
  let width = 12;
  const ratio = width / height;
  const color = props.color || theme.colors.success;

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 12 12" {...props}>
      <Path
        fill={color}
        d="M10.854 2.775a.493.493 0 00-.708 0l-5.99 6.117-2.302-2.351a.493.493 0 00-.708 0 .519.519 0 000 .722l2.657 2.713a.493.493 0 00.707 0l6.344-6.48a.517.517 0 000-.721z"
      />
    </Svg>
  );
};

CheckMarkIcon.displayName = "CheckMarkIcon";

export default CheckMarkIcon;
