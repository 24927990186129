import {
  JobOffer,
  getJobOfferCall,
  EntityListCallConfig,
  getJobListCall,
  getApplicationsCall,
  updateJobOfferCall,
  createJobOfferCall,
  deleteJobOfferCall,
  uploadOfferImagesCall,
  republishJobOfferCall,
  PaymentForm,
  deleteJobImage,
} from "../apis/jobApi";
import { useEffect, useCallback, useState } from "react";
import { RootState } from "../store/reducers/mainReducer";
import { useSelector, useDispatch } from "react-redux";
import {
  gotJob,
  gotJobs,
  gotApplications,
  getJob,
  jobDeleted,
} from "../store/actions/jobActions";
import { setJobPreview } from "../store/actions/uiActions";
import { wpDebounce } from "../../utils/mixUtils";
import { ErrorResponse } from "../../utils/wpRequest";
import { scheduleListUpdate } from "../store/actions/chatActions";
import analytics from "../analytics/analytics";

const defaultEntity = {
  isLoading: false,
  isLoaded: false,
  isDeleted: false,
};

export const useDeleteJobImage = (jobId: number) => {
  return (imageId: number) => deleteJobImage(jobId, imageId);
};

export const useGetJob = (jobId: number) => {
  const job = useSelector((state: RootState) => state.jobs.jobs[jobId]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!job || (job && !job["data"] && !job.isLoading && !job.isDeleted)) {
      dispatch(getJob(jobId));

      getJobOfferCall(jobId)
        .then((data) => {
          dispatch(
            gotJob({
              ...data,
              id: jobId,
            })
          );
        })
        .catch(() => {
          dispatch(jobDeleted(jobId));
        });
    }
  }, []);

  return job || defaultEntity;
};

export const useGetJobList = () => {
  const dispatch = useDispatch();

  return useCallback(
    (config: EntityListCallConfig) =>
      getJobListCall(config).then((res) => {
        dispatch(
          gotJobs({
            jobs: res.results,
            total: res.count,
            isExpired:
              "expired" in config.filter ? !!config.filter.expired : false,
          })
        );
        return res;
      }),
    [dispatch]
  );
};

export const useGetActiveJobOffers = () => {
  const getJobList = useGetJobList();

  return (config: EntityListCallConfig) => {
    const normalizedConfig: EntityListCallConfig = {
      ...config,
      filter: {
        ...config.filter,
        expired: false,
      },
    };

    return getJobList(normalizedConfig);
  };
};

export const useGetExpiredJobOffers = () => {
  const getJobList = useGetJobList();

  return (config: EntityListCallConfig) => {
    const normalizedConfig: EntityListCallConfig = {
      ...config,
      filter: {
        ...config.filter,
        expired: true,
      },
    };

    return getJobList(normalizedConfig);
  };
};

export const useGetApplicationsList = () => {
  const dispatch = useDispatch();

  return useCallback(
    (config: EntityListCallConfig) =>
      getApplicationsCall(config).then((res) => {
        dispatch(
          gotApplications({
            applications: res.results,
          })
        );
        return res;
      }),
    [dispatch]
  );
};

export const useGetJobApplicationsList = (jobId: number) => {
  const getApplications = useGetApplicationsList();
  return (config: EntityListCallConfig) => {
    const normalizedConfig: EntityListCallConfig = {
      ...config,
      filter: {
        ...config.filter,
        job_offer: jobId,
      },
    };

    return getApplications(normalizedConfig);
  };
};

export const useGetApplication = (applicationId: number) => {
  // const dispatch = useDispatch();
  const application = useSelector(
    (state: RootState) => state.application.applications[applicationId]
  );

  if (application) {
    return application;
  }

  return undefined;
};

export const useJobPreview = (): [
  () => void,
  (job: Partial<JobOffer>) => void,
  boolean
] => {
  const dispatch = useDispatch();
  const isPreviewOpened = useSelector(
    (state: RootState) => !!state.ui.jobPreviewData
  );

  const closePreview = useCallback(() => {
    dispatch(setJobPreview(undefined));
  }, [dispatch]);

  const showPreview = useCallback(
    wpDebounce((job: Partial<JobOffer>) => {
      dispatch(setJobPreview(job));
    }, 200),
    [dispatch]
  );

  return [closePreview, showPreview, isPreviewOpened];
};

export const useSaveJob = (
  isUpdate: boolean,
  isRestore?: boolean,
  onSaved?: (offerId: number) => void
): [
  boolean,
  ErrorResponse,
  { [key: string]: boolean },
  (job: Partial<JobOffer>) => Promise<void>
] => {
  const [successMap, setSuccessMap] = useState<{ [key: string]: boolean }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<ErrorResponse>({});
  const dispatch = useDispatch();
  const saveOfferCall = isUpdate ? updateJobOfferCall : createJobOfferCall;

  const updateErrorsAndSuccess = (
    job: Partial<JobOffer>,
    errors: ErrorResponse
  ) => {
    const newSuccessMap = Object.keys(job).reduce((acc, estateKey) => {
      if (!(estateKey in errors)) {
        acc[estateKey] = true;
      }

      return acc;
    }, {});

    setErrors(errors);
    setSuccessMap(newSuccessMap);
  };

  const saveOffer = useCallback(
    async (job: Partial<JobOffer>) => {
      const normalizedOffer = JSON.parse(JSON.stringify(job)) as JobOffer;

      delete normalizedOffer.images;
      delete normalizedOffer.list_img;

      if (normalizedOffer.contract_start_date) {
        normalizedOffer.contract_start_date = normalizedOffer.contract_start_date.replace(
          /T.+/,
          ""
        );
      }

      if (
        "city" in normalizedOffer &&
        typeof normalizedOffer.city === "object"
      ) {
        normalizedOffer["city"] = normalizedOffer.city.geoname_id;
      }

      if (typeof normalizedOffer.lma === "string") {
        normalizedOffer.lma = normalizedOffer.lma === "T";
      }

      if (typeof normalizedOffer.category === "object") {
        normalizedOffer.category = normalizedOffer.category.id;
      }

      if (normalizedOffer.min_rate && Number(normalizedOffer.min_rate) < 1000) {
        normalizedOffer.payment_form = PaymentForm.H;
      } else if (
        normalizedOffer.min_rate &&
        Number(normalizedOffer.min_rate) >= 1000
      ) {
        normalizedOffer.payment_form = PaymentForm.M;
      }

      let offer: JobOffer | undefined = undefined;

      try {
        setIsLoading(true);
        offer = await saveOfferCall(normalizedOffer);
      } catch (err) {
        setIsLoading(false);
        return updateErrorsAndSuccess(job, err);
      }

      try {
        if (offer && job.images) {
          await uploadOfferImagesCall(offer.id, job.images);
        }
      } catch (imageErr) {
        if (offer && !isUpdate) {
          await deleteJobOfferCall(offer.id);
        }
        setIsLoading(false);
        return updateErrorsAndSuccess(job, { images: imageErr });
      }

      try {
        if (offer && isRestore) {
          await republishJobOfferCall(offer.id);
        }
      } catch (republishError) {
        setIsLoading(false);
        return updateErrorsAndSuccess(job, republishError);
      }

      analytics.logCreateAd();

      setIsLoading(false);
      dispatch(setJobPreview(undefined));
      dispatch(scheduleListUpdate());

      // goBack("CRM.JOB.ACTIVE_JOB_LIST");

      if (onSaved) {
        onSaved(offer.id);
      }
    },
    [onSaved, dispatch, setIsLoading, updateErrorsAndSuccess, setErrors]
  );

  return [isLoading, errors, successMap, saveOffer];
};
