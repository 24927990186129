import React from "react";
import Svg, { Path } from "../SVG";
import { WPIconProps } from "../WPIcon";

const RadioButtonSelectedIcon: React.FC<WPIconProps> = (props) => {
  let height = 16;
  let width = 16;
  const ratio = width / height;
  const color = props.color || "#919EAB";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg
      {...props}
      width={height}
      height={height}
      style={[{ height, width }, props.style]}
      viewBox="0 0 16 16"
      fill="none"
    >
      <Path
        d="M15.5 8a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0z"
        fill="#fff"
        stroke="#919EAB"
      />
      <Path
        d="M15.5 8a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0z"
        fill="#fff"
        stroke="#0076FF"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 11.555a3.555 3.555 0 100-7.11 3.555 3.555 0 000 7.11z"
        fill="#0076FF"
      />
    </Svg>
  );
};

RadioButtonSelectedIcon.displayName = "RadioButtonSelectedIcon";

export default RadioButtonSelectedIcon;
