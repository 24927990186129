import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const CloseIcon: React.FC<IconProps> = (props) => {
  let height = 20;
  let width = 20;
  const ratio = width / height;
  const color = props.color || "#919EAB";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" {...props}>
      <Path
        fill={color}
        d="M3.813 3.813a.75.75 0 011.06 0L10 8.939l5.127-5.126a.75.75 0 01.976-.073l.084.073a.75.75 0 010 1.06L11.061 10l5.126 5.127a.75.75 0 01.073.976l-.073.084a.75.75 0 01-1.06 0L10 11.061l-5.127 5.126a.75.75 0 01-.976.073l-.084-.073a.75.75 0 010-1.06L8.939 10 3.813 4.873a.75.75 0 01-.073-.976z"
      />
    </Svg>
  );
};

CloseIcon.displayName = "CloseIcon";

export default CloseIcon;
