import { action, createReducer } from "deox";
import { AccountInfoCallData } from "../../../apis/authApi";
import {
  signedInAction,
  signedOutAction,
  profileInfoAction,
} from "../../actions/authActions";

type authState = {
  userId: undefined | number;
  userName: undefined | string;
  avatar: undefined | string;
  email: undefined | string;
  company: undefined | string;
} & Partial<AccountInfoCallData>;

const defaultState: authState = {
  userId: undefined,
  userName: undefined,
  avatar: undefined,
  email: undefined,
  company: undefined,
};

const authReducer = createReducer(defaultState, (handleAction) => [
  handleAction(signedInAction, (state, action) => ({
    ...state,
    ...action.payload,
  })),
  handleAction(signedOutAction, (state, action) => ({
    ...defaultState,
  })),

  handleAction(profileInfoAction, (state, action) => ({
    ...action.payload,
    ...state,
  })),
]);

export default authReducer;

export const getUserIdFromAuthState = (state: authState) => state.userId;
