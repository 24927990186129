import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/reducers/mainReducer";
import { useCallback, useEffect } from "react";
import {
  getAdPackConfig,
  AdPackType,
  getAvailablePacks,
  getLinkedAdPacks,
} from "../apis/adPackConfigApi";
import { getPacksSuccessAction } from "../store/actions/packActions";

const ONE_DAY_IN_MS = 60 * 1000;

export const useUpdatePacks = () => {
  const dispatch = useDispatch();
  const packsData = useSelector((state: RootState) => state.packs);
  const updatePacks = useCallback(() => {
    async function dataFetch() {
      const isDataExpired =
        !packsData.updatedAt ||
        Number(new Date()) - Number(new Date(packsData.updatedAt)) >
          ONE_DAY_IN_MS;

      let packs = packsData.packs;

      if (!packsData.packs.length || isDataExpired) {
        packs = await getAdPackConfig();
      }

      const availablePacks = await getAvailablePacks();
      const linkedPacks = await getLinkedAdPacks();

      dispatch(
        getPacksSuccessAction({
          packs,
          availablePacks: Array.isArray(availablePacks) ? availablePacks : [],
          linkedPacks: Array.isArray(linkedPacks) ? linkedPacks : [],
        })
      );
    }

    dataFetch();
  }, [dispatch, packsData]);

  return updatePacks;
};

export const useGetPacksByType = (type?: AdPackType) => {
  const packsData = useSelector((state: RootState) => state.packs);
  const updatePacks = useUpdatePacks();
  useEffect(() => {
    updatePacks();
  }, []);

  return packsData.packs.filter((pack) =>
    type ? pack.type === type && pack.name !== "demo" : pack.name !== "demo"
  );
};

export const useGetDemoPack = () => {
  const packsData = useSelector((state: RootState) => state.packs);
  const demoPack = packsData.packs.find((pack) => pack.name === "demo");

  const isDemoPackAvailable = packsData.availablePacks.find(
    (linkedPack) => linkedPack.pack_id === demoPack?.id && linkedPack.number > 0
  );

  return isDemoPackAvailable ? demoPack : undefined;
};

export const useGetAvailablePacks = (
  type?: AdPackType
): { name: string; amount: number }[] => {
  const packs = useGetPacksByType(type);
  const packsData = useSelector((state: RootState) => state.packs);

  return packsData.availablePacks
    ?.map((data) => ({
      name: packs.find((packData) => packData.id === data.pack_id)?.name || "",
      amount: data.number,
    }))
    .filter((pack) => !!pack.name);
};

export const useUpdateLinkedPacks = () => {
  const updatePacks = useUpdatePacks();

  return updatePacks;
};
