import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const PencilIcon: React.FC<IconProps> = (props) => {
  let height = 16;
  let width = 16;
  const ratio = width / height;
  const color = props.color || "#637381";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 16 16" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M13.255 2.23l.515.516c.84.84.84 2.202 0 3.04l-.735.734a.053.053 0 01-.008.01l-7.113 7.113a.602.602 0 01-.321.168l-3.286.58a.602.602 0 01-.698-.698l.58-3.286.004-.02a.602.602 0 01.014-.052l-.018.072a.602.602 0 01.168-.321l-.066.078a.602.602 0 01.066-.078l7.856-7.857a2.152 2.152 0 013.042 0zM3.174 11.755l-.228 1.3 1.298-.23-1.07-1.07zM9.9 4.246l-6.266 6.265 1.854 1.854 6.265-6.266L9.9 4.246zm1.165-1.165l-.313.313 1.853 1.853.313-.313a.945.945 0 000-1.336l-.516-.516a.947.947 0 00-1.337 0z"
      />
    </Svg>
  );
};

PencilIcon.displayName = "PencilIcon";

export default PencilIcon;
