import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const PlaceIcon: React.FC<IconProps> = (props) => {
  let height = 20;
  let width = 20;
  const ratio = width / height;
  const color = props.color || "#637381";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M10 2c.208 0 .417.009.63.027 2.517.213 4.426 1.778 5.04 3.66C17.605 11.6 10.413 18.006 10 18c-.412-.006-7.604-6.4-5.67-12.313.614-1.882 2.523-3.447 5.04-3.66C9.583 2.009 9.792 2 10 2zm0 1.5a6.04 6.04 0 00-.518.022c-2.1.178-3.434 1.463-3.824 2.659C4.395 10.042 8.3 14.641 10 16.179c1.699-1.538 5.606-6.137 4.342-9.998-.39-1.196-1.725-2.481-3.824-2.659A6.04 6.04 0 0010 3.5zM9.962 6a2.25 2.25 0 110 4.5 2.25 2.25 0 010-4.5zm0 1.383a.867.867 0 10-.002 1.734.867.867 0 00.002-1.734z"
      />
    </Svg>
  );
};

PlaceIcon.displayName = "PlaceIcon";

export default PlaceIcon;
