import React, { useState, useCallback, useEffect, useRef } from "react";
import { JobOffer } from "../../../../services/apis/jobApi";
import { Formik } from "formik";
import FormContainer from "../../../../components/form/FormContainer";
import FormRow from "../../../../components/form/FormRow";
import FormField from "../../../../components/form/FormField";
import WPButton from "../../../../components/Button/WPButton";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, View } from "react-native";
import Separator from "../../../../components/misc/Separator";
import WPText from "../../../../components/Text/WPText";
import theme, { isMobileView } from "../../../../utils/theme";
import {
  useDeleteJobImage,
  useJobPreview,
  useSaveJob,
} from "../../../../services/hooks/jobHooks";
import { RootState } from "../../../../services/store/reducers/mainReducer";
import { useSelector } from "react-redux";
import AuthNotification from "../../../auth/components/AuthNotification";
import WPToggle from "../../../../components/input/components/WPToggle";
import WPError from "../../../../components/input/components/WPError";

export default function JobEditor(props: {
  job: Partial<JobOffer>;
  title?: string;
  isRestore?: boolean;
  onSaved: (offerId: number) => void;
}) {
  const { t } = useTranslation();
  const isJobPreviewOpened = useSelector(
    (state: RootState) => !!state.ui.jobPreviewData
  );
  const company = useSelector((state: RootState) => state.auth.company);
  const [_closePreview, setPreviewData, isPreviewOpened] = useJobPreview();
  const [isVisible, setIsVisible] = useState(false);

  const showPreview = useCallback(
    (job: Partial<JobOffer>) => {
      if (isPreviewOpened) {
        _closePreview();
      } else {
        setPreviewData(job);
      }
    },
    [isPreviewOpened]
  );

  useEffect(() => {
    // if (!isMobileView()) {
    //   showPreview(props.job || {});
    // }

    return () => _closePreview();
  }, []);

  const [isLoading, errors, successMap, saveJobOffer] = useSaveJob(
    !!props.job.id,
    props.isRestore,
    props.onSaved
  );

  const scrollView = useRef<ScrollView>(null);

  useEffect(() => {
    if (errors && typeof errors === "object" && Object.keys(errors).length)
      setIsVisible(true);
  }, [errors]);

  const closeNotification = useCallback(() => {
    scrollView.current?.scrollTo({ y: 0 });
    setIsVisible(false);
  }, [scrollView.current]);

  const [isTooltips, setIsTooltips] = useState(false);

  const deleteImage = useDeleteJobImage(props.job.id || 0);

  return (
    <ScrollView contentContainerStyle={styles.container} ref={scrollView}>
      <AuthNotification
        isVisible={isVisible}
        title={t("titles.payment_error")}
        iconName="attention"
        description={t("misc.errors")}
        onClose={closeNotification}
        buttonLabel={t("labels.got_it")}
      />
      <Formik
        initialValues={props.job}
        onSubmit={(jobOfferEditorData) => {
          saveJobOffer(jobOfferEditorData);
        }}
        validate={(jobData) => {
          if (isJobPreviewOpened) {
            setPreviewData(jobData);
          }
        }}
      >
        {(formikProps) => (
          <FormContainer error={errors["non_field_errors"]}>
            <FormRow>
              <WPText style={styles.title}>{props.title}</WPText>
            </FormRow>
            <FormRow>
              <WPToggle
                label={t("labels.tooltips")}
                value={isTooltips}
                onChange={setIsTooltips}
              />
            </FormRow>
            <FormRow>
              <FormField
                name="title"
                {...formikProps}
                errors={errors}
                successMap={successMap}
                disableTooltip={isTooltips}
                required
              />
            </FormRow>
            <FormRow>
              <FormField
                name="company_name"
                {...formikProps}
                errors={errors}
                successMap={successMap}
                disableTooltip={isTooltips}
                required
              />
              <FormField
                name="recruiter_company_name"
                {...formikProps}
                values={{ recruiter_company_name: company }}
                errors={errors}
                successMap={successMap}
                disableTooltip={isTooltips}
                disabled
              />
            </FormRow>
            <FormRow>
              <FormField
                name="category"
                type="jobCategory"
                {...formikProps}
                errors={errors}
                successMap={successMap}
                disableTooltip={isTooltips}
                required
              />
              <FormField
                name="city"
                type="city"
                {...formikProps}
                errors={errors}
                successMap={successMap}
                disableTooltip={isTooltips}
                required
              />
            </FormRow>
            <FormRow>
              <FormField
                name="position_count"
                {...formikProps}
                errors={errors}
                successMap={successMap}
                disableTooltip={isTooltips}
                required
              />
              <FormField
                name="contract_start_date"
                {...formikProps}
                type="date"
                errors={errors}
                successMap={successMap}
                disableTooltip={isTooltips}
                required
              />
            </FormRow>
            <FormRow>
              <View style={{ width: "100%" }}>
                <ScrollView horizontal>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i, idx) => (
                    <FormField
                      key={idx.toString()}
                      name={`images[${idx}]`}
                      type="image"
                      {...formikProps}
                      errors={errors}
                      successMap={successMap}
                      style={styles.image}
                      label={t("labels.add_photo")}
                      disableTooltip={isTooltips}
                      isMain={idx === 0}
                      onDelete={() => {
                        const imageData =
                          formikProps?.values?.images &&
                          formikProps?.values?.images[idx];

                        if (
                          typeof imageData === "object" &&
                          "id" in imageData
                        ) {
                          deleteImage(imageData.id);
                        }
                      }}
                    />
                  ))}
                </ScrollView>
                {errors["image"] ? <WPError error={errors["image"]} /> : null}
              </View>
            </FormRow>
            <Separator />
            <WPText style={styles.sectionTitle}>
              {t("titles.job_description")}
            </WPText>
            <FormRow>
              <FormField
                name="responsibilities"
                {...formikProps}
                errors={errors}
                successMap={successMap}
                disableTooltip={isTooltips}
                required
                numberOfLines={4}
              />
            </FormRow>
            <FormRow>
              <FormField
                name="conditions"
                {...formikProps}
                errors={errors}
                successMap={successMap}
                required
                disableTooltip={isTooltips}
                numberOfLines={4}
              />
            </FormRow>
            <WPText style={styles.sectionTitle}>{t("titles.job_hours")}</WPText>
            <FormRow>
              <FormField
                {...formikProps}
                name="min_working_hours"
                type="dropdown"
                required
                disableTooltip={isTooltips}
                errors={errors}
                successMap={successMap}
                style={isMobileView() ? undefined : styles.webSmallField}
              />
              <FormField
                name="max_working_hours"
                type="dropdown"
                {...formikProps}
                errors={errors}
                disableTooltip={isTooltips}
                successMap={successMap}
                style={isMobileView() ? undefined : styles.webSmallField}
              />
            </FormRow>
            <Separator />
            <WPText style={styles.sectionTitle}>{t("titles.salary")}</WPText>
            <FormRow>
              <FormField
                {...formikProps}
                errors={errors}
                successMap={successMap}
                name="min_rate"
                disableTooltip={isTooltips}
                required
                style={isMobileView() ? undefined : styles.webSmallField}
                append={
                  <WPText style={styles.currency} numberOfLines={1}>
                    zł
                  </WPText>
                }
              />
              <FormField
                {...formikProps}
                errors={errors}
                successMap={successMap}
                name="max_rate"
                disableTooltip={isTooltips}
                style={isMobileView() ? undefined : styles.webSmallField}
                append={
                  <WPText style={styles.currency} numberOfLines={1}>
                    zł
                  </WPText>
                }
              />
            </FormRow>
            <FormRow>
              <FormField
                name="income_type"
                type="select"
                required
                disableTooltip={isTooltips}
                dense
                {...formikProps}
                errors={errors}
                successMap={successMap}
              />
            </FormRow>
            <Separator />
            <WPText style={styles.sectionTitle}>
              {t("titles.extra_options")}
            </WPText>
            <FormRow>
              <FormField
                name="accommodation"
                type="select"
                required
                disableTooltip={isTooltips}
                dense
                {...formikProps}
                errors={errors}
                successMap={successMap}
              />
              <FormField
                name="meals"
                type="select"
                required
                disableTooltip={isTooltips}
                dense
                {...formikProps}
                errors={errors}
                successMap={successMap}
              />
            </FormRow>
            <Separator />
            <FormRow>
              <FormField
                name="preferred_employees"
                type="select"
                disableTooltip={isTooltips}
                multiple
                dense
                {...formikProps}
                errors={errors}
                successMap={successMap}
              />
              <FormField
                name="age_range"
                type="select"
                disableTooltip={isTooltips}
                multiple
                dense
                {...formikProps}
                errors={errors}
                successMap={successMap}
              />
            </FormRow>
            <Separator />
            <FormRow>
              <FormField
                name="phone"
                required
                disableTooltip={isTooltips}
                {...formikProps}
                errors={errors}
                successMap={successMap}
              />
              <FormField
                name="im_apps"
                type="select"
                multiple
                disableTooltip={isTooltips}
                dense
                {...formikProps}
                errors={errors}
                successMap={successMap}
              />
            </FormRow>
            {errors["non_field_errors"] ? (
              <FormRow>
                <Separator />
                <WPError error={errors["non_field_errors"]} />
              </FormRow>
            ) : null}
            <Separator />
            <FormRow style={styles.actionButtons}>
              <WPButton
                onPress={() => showPreview(formikProps.values)}
                appearance="outlined"
              >
                {t("labels.preview")}
              </WPButton>
              <WPButton
                onPress={formikProps.handleSubmit}
                isLoading={isLoading}
              >
                {t("labels.publish")}
              </WPButton>
            </FormRow>
          </FormContainer>
        )}
      </Formik>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: theme.input.paddingHorizontal,
  },
  image: {
    marginRight: 8,
  },
  sectionTitle: {
    fontFamily: "bold",
    fontSize: 20,
    marginBottom: 20,
  },
  webSmallField: {
    flexGrow: 0,
    flexBasis: "auto",
    width: 140,
  },
  actionButtons: {
    // flexDirection: 'row',
    justifyContent: "flex-end",
  },
  currency: {
    width: 14,
  },
  title: {
    fontFamily: "bold",
    fontSize: 28,
  },
});
