import { createReducer } from "deox";
import { gotApplications } from "../../actions/jobActions";
import { Application } from "../../../apis/jobApi";

type authState = {
  applications: { [key: number]: Application };
  totalActive: number;
  totalExpired: number;
};

const defaultState: authState = {
  applications: {},
  totalActive: 0,
  totalExpired: 0,
};

const applicationReducer = createReducer(defaultState, (handleAction) => [
  handleAction(gotApplications, (state, action) => {
    return {
      ...state,

      applications: action.payload.applications.reduce(
        (application, newApplication) => ({
          ...application,
          [newApplication.id]: newApplication,
        }),
        state.applications
      ),
    };
  }),
]);

export default applicationReducer;
