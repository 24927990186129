import React, { memo } from "react";
import { useGetEstate } from "../../../../../services/hooks/estateHooks";
import EntityPreview from "../EntityPreview";
import { getEstatePaymentInfo } from "../../../../../utils/mixUtils";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

function WPEstateMessage(props: { estateId: number }) {
  const estateData = useGetEstate(props.estateId);
  const { t } = useTranslation();

  let image = undefined;
  let title = undefined;
  let subTitle = undefined;

  if ("data" in estateData && estateData.data) {
    title = estateData.data.title;
    subTitle = getEstatePaymentInfo(estateData.data, t);
    if (estateData.data.images.length) {
      const firstImage = estateData.data.images[0];
      image = typeof firstImage === "object" ? firstImage.image : firstImage;
    }
  }

  return (
    <View style={styles.container}>
      <EntityPreview
        isDeleted={estateData.isDeleted}
        isLoading={estateData.isLoading}
        image={image}
        title={title}
        subtitle={subTitle}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: 280,
  },
});

export default memo(WPEstateMessage);
