import React, { memo } from "react";
import EstateEditor from "./components/EstateEditor";
import { Renter } from "../../../../services/apis/estateApi";

const estate = {
  start_date: new Date().toISOString().replace(/T.+/, ""),
  equipment: [],
  facilities: [],
  im_apps: [],
  restrictions: [],
  renter: Renter.ANY,
};

function EstateCreateScreen() {
  return <EstateEditor estate={estate} />;
}

export default memo(EstateCreateScreen);
