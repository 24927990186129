import { useState } from "react";
import { signInCall, accountInfoCall } from "../../../../services/apis/authApi";
import { ErrorResponse } from "../../../../utils/wpRequest";
import appCache from "../../../../services/appCache";
import { useRedirect } from "../../../navigationHelpers";
import {
  useGetActiveJobOffers,
  useGetExpiredJobOffers,
} from "../../../../services/hooks/jobHooks";
import { useGetEstateList } from "../../../crm/estate/estateUtils";
import { defaultPagination } from "../../../../services/apis/jobApi";
import { useGetEmployeeApplications } from "../../../../services/hooks/employeeHooks";
import config from "../../../../services/config";

// const { TOKEN_KEY = "", REFRESH_TOKEN_KEY = "" } = Constants.manifest.extra;
const { TOKEN_KEY = "", REFRESH_TOKEN_KEY = "" } = config;

type signInData = {
  username: string;
  password: string;
};

export const useSignIn = (
  onSignedIn: (authValues: {
    userId: number;
    userName: string;
    avatar: string;
    email: string;
    company: string;
  }) => void
): [boolean, ErrorResponse, (data: signInData) => Promise<void>] => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({} as ErrorResponse);
  const getActiveJobs = useGetActiveJobOffers();
  const getExpiredJobs = useGetExpiredJobOffers();
  const getEstates = useGetEstateList();
  const getAllCandidates = useGetEmployeeApplications();

  const listCallConfig = {
    filter: {},
    pagination: {
      ...defaultPagination,
      page_size: 1,
    },
  };

  const signIn = async (data: signInData) => {
    setIsLoading(true);

    await appCache.setValue(TOKEN_KEY, "");
    await appCache.setValue(REFRESH_TOKEN_KEY, "");

    try {
      const res = await signInCall(data);

      await appCache.setValue(TOKEN_KEY, res.access_token);
      await appCache.setValue(REFRESH_TOKEN_KEY, res.refresh_token);

      let avatarUri = "";
      let company = "";

      setErrors({});

      try {
        const infoRes = await accountInfoCall();
        avatarUri = infoRes.logo;
        company = infoRes.company_name;
      } catch (err) {}

      Promise.all([
        getActiveJobs(listCallConfig),
        getExpiredJobs(listCallConfig),
        getEstates(listCallConfig),
        getAllCandidates(listCallConfig),
      ]);

      onSignedIn({
        userId: res.id,
        userName: `${res.first_name} ${res.last_name}`,
        avatar: avatarUri,
        email: res.username,
        company,
      });

      useRedirect();
    } catch (err) {
      setErrors(err);
    }
    setIsLoading(false);
  };
  return [isLoading, errors, signIn];
};
