import React from "react";
import Svg, { Path, G, Defs, ClipPath } from "../SVG";
import { IconProps } from "../WPIcon";

const DetailsIcon: React.FC<IconProps> = (props) => {
  let height = 12;
  let width = 12;
  const ratio = width / height;
  const color = props.color || "#0076FF";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg
      {...props}
      width={width}
      height={height}
      style={[{ height, width }, props.style]}
      viewBox={`0 0 12 12`}
    >
      <G fill="#919EAB" clipPath="url(#prefix__clip0)">
        <Path d="M0 8.64a.354.354 0 00.241.32l2.105.694.695 2.105a.351.351 0 00.657.028l2.25-5.273a.352.352 0 00-.462-.461L.214 8.303A.35.35 0 000 8.641zm1.346-.056l3.612-1.542-1.541 3.612-.458-1.389a.353.353 0 00-.224-.223l-1.389-.458z" />
        <Path d="M6 9.024c1.667 0 3.023-1.356 3.023-3.023S7.667 2.977 6 2.977 2.976 4.334 2.976 6.001a.35.35 0 11-.703 0A3.731 3.731 0 016 2.274a3.731 3.731 0 013.726 3.727A3.73 3.73 0 016 9.727a.352.352 0 110-.703z" />
        <Path d="M6 11.296A5.302 5.302 0 0011.296 6 5.302 5.302 0 005.999.704 5.303 5.303 0 00.703 6 .352.352 0 110 6c0-1.596.626-3.1 1.762-4.237a5.973 5.973 0 018.475 0 5.973 5.973 0 010 8.474A5.952 5.952 0 015.999 12a.35.35 0 110-.703z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h12v12H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

DetailsIcon.displayName = "DetailsIcon";

export default DetailsIcon;
