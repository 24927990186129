import React, { useCallback, useState } from "react";
import { TFunction } from "i18next";
import {
  JobOffer,
  deleteJobOfferCall,
  Application,
  updateApplicationStatusCall,
  JobOfferStatus,
} from "../../../services/apis/jobApi";
import theme, { isMobileView } from "../../../utils/theme";
import { getNormalizedDate } from "../../../utils/mixUtils";
import WPIcon, { IconName } from "../../../components/Icon/WPIcon";
import { navigateTo } from "../../navigationHelpers";
import { getEmployeeDisplayName } from "../../../services/store/reducers/employee/employeeReducer";
import DataListItem from "../../../components/misc/DataListItem";
import { useTranslation } from "react-i18next";
import JobStatistics from "../../../components/misc/JobStatistics";
import {
  ConversationType,
  ApplicationStatus,
} from "../../../services/chatService";
import {
  useOpenChatByChatType,
  useSendMessageByTypeByUser,
} from "../chat/components/conversationHooks";
import { TouchableOpacity, StyleSheet, View } from "react-native";
import WPText from "../../../components/Text/WPText";
import { getSelectOptions } from "../../../services/selectOptions";
import DropDownWrapper from "../../../components/misc/DropDownWrapper";
import { logError } from "../../../services/logger";
import PrependAppendDecorator from "../../../components/misc/PrependAppendDecorator";
import Badge from "../../../components/misc/Badge";
import { Dispatch } from "redux";
import { openActionModal } from "../../../services/store/actions/uiActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../services/store/reducers/mainReducer";
import { LinkedAdPack } from "../../../services/apis/adPackConfigApi";
import { useBumpJobOffer } from "../../../services/hooks/paymentHooks";

type DropDownConfig = {
  delete?: boolean;
  edit?: boolean;
  duplicate?: boolean;
  bump?: boolean;
  refresh?: boolean;
};

const deletejoboffer = (
  job: JobOffer,
  functions: {
    refresh: () => void;
    dispatch: Dispatch;
    bump: (jobId: number, onDone: () => void) => void;
  },
  t: TFunction,
  linkedPack: LinkedAdPack | undefined
) => () => {
  functions.dispatch(
    openActionModal({
      title: t("misc.delete_title"),
      description: t("misc.delete"),
      customImageStyle: {
        height: 142,
        width: 145,
        marginLeft: 16,
        alignSelf: "center",
      },
      onClose: () => {
        deleteJobOfferCall(job.id)
          .then(functions.refresh)
          .catch(functions.refresh);
      },
      image: require("./assets/warning.png"),
      buttonLabel: t("labels.delete"),
    })
  );
};

const bump = (
  job: JobOffer,
  functions: {
    refresh: () => void;
    dispatch: Dispatch;
    bump: (jobId: number, onDone: () => void) => void;
  },
  t: TFunction,
  linkedPack: LinkedAdPack | undefined
) => () => {
  functions.dispatch(
    openActionModal({
      title: t("labels.bump_title"),
      description: t("misc.bump"),
      options: t("misc.bump_descOptions").split("."),

      onClose: () => {
        functions.bump(job.id, () => {
          setTimeout(() => {
            functions.dispatch(
              openActionModal({
                title: t("titles.payment_success_up_action"),
                description: t("misc.payment_success_up_action"),
                onClose: functions.refresh,
                buttonLabel: t("labels.got_it"),
                image: require("./assets/bump.png"),
              })
            );
          }, 300);
        });
      },
      image: require("./assets/bump.png"),
      buttonDescription: `${t("misc.available")}: ${
        linkedPack?.unsued_actions?.up || 0
      }`,
      buttonLabel: !linkedPack?.unsued_actions?.up
        ? t("labels.buy")
        : t("labels.bump"),
    })
  );
};

export const getJobMoreOptions = (
  config: DropDownConfig,
  t: TFunction,
  job: JobOffer,
  functions: {
    refresh: () => void;
    dispatch: Dispatch;
    bump: (jobId: number, onDone: () => void) => void;
  },
  linkedPack: LinkedAdPack | undefined,
  isCompactView?: boolean
) =>
  [
    getJobStatus(job, linkedPack) === "PUBLISHED" &&
      !isCompactView && {
        text: t("labels.bump"),
        icon: "bump",
        isButton: true,
        label: linkedPack?.unsued_actions.up,
        onClick: bump(job, functions, t, linkedPack),
      },
    (getJobStatus(job, linkedPack) === "PUBLISHED" ||
      getJobStatus(job, linkedPack) === "NOT_PAYED") && {
      text: t("labels.edit"),
      icon: "pencil",
      onClick: () => {
        navigateTo(`CRM.JOB.EDIT_JOB?jobId=${job.id}`);
      },
    },
    getJobStatus(job, linkedPack) === "PUBLISHED" && {
      text: t("labels.duplicate"),
      icon: "duplicate",
      onClick: () => {
        navigateTo(`CRM.JOB.DUPLICATE_JOB?jobId=${job.id}`);
      },
    },

    getJobStatus(job, linkedPack) !== "ON_MODERATION" && {
      text: t("labels.delete"),
      icon: "trash",
      onClick: deletejoboffer(job, functions, t, linkedPack),
    },
  ].filter((item) => !!item) as {
    text: string;
    icon: IconName;
    onClick: () => void;
  }[];

const showJobCandidates = (jobId: number) =>
  navigateTo(`CRM.JOB.JOB_CANDIDATES?jobId=${jobId}`);

function isExpired(job: JobOffer, linkedPack: LinkedAdPack | undefined) {
  return (
    job.expire_date !== null &&
    Number(new Date(job.expire_date)) < Number(new Date())
  );
}

function isOnModeration(job: JobOffer, linkedPack: LinkedAdPack | undefined) {
  return job.status === JobOfferStatus.DRAFT;
}

function isNotPayed(job: JobOffer, linkedPack: LinkedAdPack | undefined) {
  return !isExpired(job, linkedPack) && !linkedPack;
}

function getJobStatus(job: JobOffer, linkedPack: LinkedAdPack | undefined) {
  switch (true) {
    case isExpired(job, linkedPack):
      return "EXPIRED";
    case isNotPayed(job, linkedPack):
      return "NOT_PAYED";
    case isOnModeration(job, linkedPack):
      return "ON_MODERATION";

    default:
      return "PUBLISHED";
  }
}

export const useRenderJobOfferTile = (config: {
  actionConfig?: { [key: string]: boolean };
  isCompactView?: boolean;
  hideStatistics?: boolean;
  skipTimeRemaining?: boolean;
  onTileClick?: (item: any) => void;
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const packs = useSelector((state: RootState) => state.packs);
  const bumpJobOffer = useBumpJobOffer();

  function getJobLinkedPack(jobOffer: JobOffer) {
    const linkedPack = packs.linkedPacks?.find(
      (pack) => pack.offer_id === jobOffer.id
    );

    if (linkedPack) {
      const packName = packs.packs.find(
        (pack) => pack.id === linkedPack.pack_id
      )?.name;

      return {
        ...linkedPack,
        name: packName || "",
      };
    }

    return undefined;
  }

  function getExpireColor(daysToEnd: number) {
    if (daysToEnd < 3) {
      return theme.colors.misc[0];
    }
    if (daysToEnd < 7) {
      return theme.colors.misc[1];
    }

    return theme.colors.mains[3];
  }

  const renderItem = useCallback(
    (item: JobOffer, refresh: () => void) => {
      let image: string | undefined = undefined;

      if (item.images && item.images.length) {
        if (typeof item.images[0] === "object") {
          image = item.images[0].image;
        } else {
          image = item.images[0];
        }
      }

      const linkedPack = getJobLinkedPack(item);

      const daysToEnd = item.expire_date
        ? Math.ceil(
            (Number(new Date(item.expire_date)) - Number(new Date())) /
              (24 * 60 * 60 * 1000)
          )
        : undefined;

      const fromDate =
        item.published_at && getJobStatus(item, linkedPack) === "PUBLISHED"
          ? `${t("labels.to")}: ${getNormalizedDate(
              item.published_at,
              i18n.language,
              true,
              true
            )}`
          : "";

      const timeRemaining =
        daysToEnd && !config.skipTimeRemaining
          ? `${daysToEnd} ${t("misc.days_left")}`
          : undefined;

      return (
        <TouchableOpacity
          style={styles.jobContainer}
          disabled={!config.onTileClick}
          onPress={() => config.onTileClick && config.onTileClick(item)}
        >
          <DataListItem
            isCompactView={config.isCompactView}
            image={{
              uri: image,
              size: config.isCompactView ? "m" : "l",
              label: linkedPack ? linkedPack.name?.toUpperCase() : undefined,
            }}
            title={item.title}
            onTitleClick={() => {
              !config.onTileClick && showJobCandidates(item.id);
            }}
            subtitle1={{
              text: fromDate,
            }}
            subtitle2={undefined}
            subtitle3={
              daysToEnd && !config.skipTimeRemaining && timeRemaining
                ? {
                    text: timeRemaining,
                    isAdditional: true,
                    icon: "clock",
                    color: getExpireColor(daysToEnd),
                  }
                : undefined
            }
            actions={
              config.actionConfig
                ? getJobMoreOptions(
                    config.actionConfig,
                    t,
                    item,
                    {
                      refresh,
                      dispatch,
                      bump: bumpJobOffer,
                    },
                    linkedPack,
                    config.isCompactView
                  )
                : undefined
            }
            additionalNode={
              config.hideStatistics ? undefined : (
                <JobStatistics
                  offerId={item.id}
                  isCompactView={config.isCompactView}
                />
              )
            }
            additionalAction={
              getJobStatus(item, linkedPack) === "PUBLISHED" &&
              config.isCompactView
                ? {
                    text: t("labels.bump"),
                    label: linkedPack?.unsued_actions.up.toString() || "0",
                    onClick: bump(
                      item,
                      {
                        refresh,
                        dispatch,
                        bump: bumpJobOffer,
                      },
                      t,
                      linkedPack
                    ),
                  }
                : getJobStatus(item, linkedPack) === "EXPIRED"
                ? {
                    text: t("labels.refresh"),
                    onClick: () => {
                      navigateTo(`CRM.JOB.RESTORE_JOB?jobId=${item.id}`);
                    },
                  }
                : getJobStatus(item, linkedPack) === "NOT_PAYED"
                ? {
                    text: t("labels.use_pack"),
                    onClick: () => {
                      navigateTo(`CRM.JOB.JOB_PACKAGE?jobId=${item.id}`);
                    },
                  }
                : undefined
            }
            status={
              item.status === "DRAFT" || !linkedPack
                ? {
                    text: linkedPack
                      ? t("misc.on_moderation")
                      : t("misc.not_payed"),
                    icon: linkedPack ? "clock" : "attention",
                    color: linkedPack
                      ? theme.colors.warning
                      : theme.colors.error,
                    size: config.isCompactView ? undefined : 20,
                  }
                : undefined
            }
          />
        </TouchableOpacity>
      );
    },
    [dispatch, packs, packs.linkedPacks, bumpJobOffer]
  );
  return renderItem;
};

function ApplicationStatusNode(props: {
  status: ApplicationStatus;
  isShortened?: boolean;
}) {
  const { t } = useTranslation();
  let color = theme.colors.warning;

  switch (props.status) {
    case ApplicationStatus.NEW:
      color = theme.colors.statuses[ApplicationStatus.NEW].primary;
      break;

    case ApplicationStatus.RFS:
      color = theme.colors.error;

      break;

    default:
      color = theme.colors.warning;

      break;
  }

  return (
    <PrependAppendDecorator
      prepend={
        <Badge isRound background={color} text="" textColor="white" size="s" />
      }
    >
      <WPText
        style={[styles.status, props.isShortened ? { maxWidth: 100 } : {}]}
        numberOfLines={props.isShortened ? 1 : undefined}
      >
        {t(`select.job_application_status.${props.status}`).toUpperCase()}
      </WPText>
    </PrependAppendDecorator>
  );
}

function ApplicationStatuses(props: Application) {
  const [selectedStatus, setSelectedStatus] = useState(props.status);
  const sendNewStatusMessage = useSendMessageByTypeByUser(
    props.employee.id,
    ConversationType.JOB
  );
  const [isFocused, setIsFocused] = useState(false);
  const { t } = useTranslation();
  const items = getSelectOptions("job_application_status", t);

  const changeStatus = useCallback(
    (status: ApplicationStatus) => {
      updateApplicationStatusCall(props.id, status)
        .then(() => {
          setSelectedStatus(status);
        })
        .then(() => {
          return sendNewStatusMessage(
            props.job_offer.id,
            ConversationType.JOB,
            {
              status,
            }
          );
        })
        .catch(logError);
    },
    [setSelectedStatus]
  );

  return (
    <DropDownWrapper
      onChange={changeStatus}
      items={items}
      isFocused={isFocused}
      setIsFocused={setIsFocused}
      customRenderItem={(item) => <ApplicationStatusNode status={item} />}
    >
      <View style={styles.statusContainer}>
        <PrependAppendDecorator
          append={
            <View style={styles.chevron}>
              <WPIcon
                name="chevron"
                style={isFocused ? styles.itemSelected : undefined}
              />
            </View>
          }
        >
          <ApplicationStatusNode status={selectedStatus} isShortened />
        </PrependAppendDecorator>
      </View>
    </DropDownWrapper>
  );
}

export const useRenderJobCandidateTile = () => {
  const openChat = useOpenChatByChatType(ConversationType.JOB);
  const render = useCallback((item: Application) => {
    return (
      <DataListItem
        isCompactView={isMobileView()}
        image={{
          uri: item.employee.photo,
          size: "s",
        }}
        title={getEmployeeDisplayName(item.employee)}
        onTitleClick={() => {
          openChat(item.employee.id);
        }}
        sideNode={<ApplicationStatuses {...item} />}
      />
    );
  }, []);

  return render;
};

const styles = StyleSheet.create({
  jobContainer: {
    position: "relative",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.white,
    opacity: 0.5,
  },
  moderationContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.input.borderRadius,
  },
  moderationText: {
    color: theme.colors.error,
    fontFamily: "bold",
  },
  status: {
    fontSize: 10,
    fontFamily: "bold",
  },
  statusContainer: {
    paddingHorizontal: 12,
    paddingVertical: 4,
    backgroundColor: theme.colors.successes[2],
    borderBottomLeftRadius: theme.input.borderRadius,
  },
  chevron: {
    marginLeft: 16,
  },
  itemSelected: {
    transform: [{ rotate: "180deg" }],
  },
});
