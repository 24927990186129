import { action, createReducer } from "deox";
import {
  Conversation,
  ConversationMessage,
  ConversationType,
} from "../../../chatService";
import {
  receivedConversations,
  openConversation,
  gotMessage,
  closeConversation,
  setChatListSearch,
} from "../../actions/chatActions";
import { signedOutAction } from "../../actions/authActions";

export type BaseChatData = {
  chats: Partial<{
    [key in ConversationType]: string;
  }>;
  userId: number;
  updatedAt: Date;
  unreadCount: number;
};

type chatState = {
  activeConversationId: string | undefined;
  messages: {
    [key: string]: ConversationMessage;
  };
  chats: {
    [key: string]: Conversation;
  };

  searchTerm: string;
  chatOrder: BaseChatData[];
};

const defaultState: chatState = {
  activeConversationId: undefined,
  messages: {},
  chats: {},
  chatOrder: [],
  searchTerm: "",
};

const cachedDefaultState = JSON.parse(JSON.stringify(defaultState));

const chatReducer = createReducer(defaultState, (handleAction) => [
  handleAction(signedOutAction, () => {
    return JSON.parse(JSON.stringify(cachedDefaultState));
  }),
  handleAction(setChatListSearch, (state, action) => ({
    ...state,
    searchTerm: action.payload,
  })),
  handleAction(openConversation, (state, action) => ({
    ...state,
    activeConversationId: action.payload,
  })),

  handleAction(closeConversation, (state) => ({
    ...state,
    activeConversationId: undefined,
  })),

  handleAction(gotMessage, (state, action) => ({
    ...state,
    messages: {
      ...state.messages,
      [action.payload.id]: action.payload,
    },
  })),
  handleAction(receivedConversations, (state, action) => {
    const stateWithAdded = handleAdded(state, action.payload.added);
    const stateWithModified = handleModified(
      stateWithAdded,
      action.payload.modified
    );

    const stateWithRemoved = handleRemoved(
      stateWithModified,
      action.payload.removed
    );

    const { withUnread, withoutUnread } = stateWithRemoved.chatOrder.reduce(
      (acc, chatConfig) => {
        if (chatConfig.unreadCount) {
          acc.withUnread.push(chatConfig);
        } else {
          acc.withoutUnread.push(chatConfig);
        }
        return acc;
      },
      { withUnread: [], withoutUnread: [] } as {
        withUnread: BaseChatData[];
        withoutUnread: BaseChatData[];
      }
    );

    stateWithRemoved.chatOrder = [
      ...withUnread.sort((a, b) => Number(b.updatedAt) - Number(a.updatedAt)),
      ...withoutUnread.sort(
        (a, b) => Number(b.updatedAt) - Number(a.updatedAt)
      ),
    ];

    return stateWithRemoved;
  }),
]);

export default chatReducer;

function getChatUnreadCount(state: chatState, chatData: BaseChatData) {
  let unreadCount = 0;

  for (let key in chatData.chats) {
    const conversation = state.chats[chatData.chats[key]];

    if (conversation) {
      unreadCount += getUnreadCount(conversation);
    }
  }

  return unreadCount;
}

function handleAdded(state: chatState, changes: Conversation[]) {
  const nextState = { ...state };

  changes.forEach((conversation) => {
    nextState.chats[conversation.id] = conversation;

    const employeeId = getEmployeeIdFromConversation(conversation);

    const idx = state.chatOrder.findIndex((item) => {
      return item.userId === employeeId;
    });

    const unreadCount = getUnreadCount(conversation);
    const modifiedDate = new Date(
      "seconds" in conversation.last_modified_at
        ? conversation.last_modified_at.seconds * 1000
        : ""
    );

    if (idx >= 0) {
      const chatConfig = nextState.chatOrder[idx];
      chatConfig.chats[conversation.group] = conversation.id;
      if (chatConfig.updatedAt < modifiedDate) {
        chatConfig.updatedAt = modifiedDate;
      }
      chatConfig.unreadCount = getChatUnreadCount(state, chatConfig);
    } else {
      nextState.chatOrder.push({
        chats: {
          [conversation.group]: conversation.id,
        },
        userId: employeeId,
        unreadCount,
        updatedAt: modifiedDate,
      });
    }
  });

  return nextState;
}
function handleModified(state: chatState, changes: Conversation[]) {
  const nextState = { ...state };

  changes.forEach((conversation) => {
    nextState.chats[conversation.id] = conversation;

    const employeeId = getEmployeeIdFromConversation(conversation);

    const idx = state.chatOrder.findIndex((item) => {
      return item.userId === employeeId;
    });

    const unreadCount = getUnreadCount(conversation);
    const modifiedDate = new Date(
      "seconds" in conversation.last_modified_at
        ? conversation.last_modified_at.seconds * 1000
        : ""
    );

    if (idx >= 0) {
      const chatConfig = nextState.chatOrder[idx];

      chatConfig.chats[conversation.group] = conversation.id;
      chatConfig.updatedAt = modifiedDate;
      chatConfig.unreadCount = getChatUnreadCount(state, chatConfig);
    } else {
      nextState.chatOrder.push({
        chats: {
          [conversation.group]: conversation.id,
        },
        userId: employeeId,
        unreadCount,
        updatedAt: modifiedDate,
      });
    }
  });

  return nextState;
}
function handleRemoved(state: chatState, changes: Conversation[]) {
  const nextState = { ...state };
  return nextState;
}

export const getChatList = (state: chatState) => state.chatOrder;
export const getChatById = (id: string) => (state: chatState) =>
  state.chats[id];

export const getEmployeeIdFromConversation = (conversation: Conversation) =>
  Number(conversation.employees[0]);
export const getEmployerIdFromConversation = (conversation: Conversation) =>
  conversation.employers[0];
export const getUnreadCount = (conversation: Conversation) => {
  const lastSeenMessage =
    conversation.messages_seen_map &&
    conversation.messages_seen_map[getEmployerIdFromConversation(conversation)];

  if (lastSeenMessage === undefined) {
    return conversation.messages?.length || 0;
  }

  if (conversation.messages.indexOf(lastSeenMessage) === -1) {
    return 0;
  }

  return (
    conversation.messages.length -
    1 -
    conversation.messages.indexOf(lastSeenMessage)
  );
};
