import { createActionCreator } from "deox";
import { ImageStyle, StyleProp } from "react-native";
import { JobOffer } from "../../apis/jobApi";

export const setJobPreview = createActionCreator(
  "SET_JOB_PREVIEW",
  (resolve) => (job: Partial<JobOffer> | undefined) => resolve(job)
);

export const setUserPreviewId = createActionCreator(
  "SET_USER_PREVIEW_ID",
  (resolve) => (userId?: number) => resolve(userId)
);

export const openActionModal = createActionCreator(
  "OPEN_ACTION_MODAL",
  (resolve) => (config: {
    title: string;
    description: string;
    options?: string[];
    onClose: () => void;
    buttonLabel: string;
    image: string;
    buttonDescription?: string;
    customImageStyle?: StyleProp<ImageStyle>;
  }) => resolve(config)
);

export const closeActionModal = createActionCreator("CLOSE_ACTION_MODAL");
