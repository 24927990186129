import { createActionCreator } from "deox";
import { resolve } from "react-native-svg/src/lib/resolve";
import { ConversationChanges, ConversationMessage } from "../../chatService";

export const receivedConversations = createActionCreator(
  "RECEIVED_CONVERSATIONS",
  (resolve) => (changes: ConversationChanges) => resolve(changes)
);

export const openConversation = createActionCreator(
  "OPEN_CONVERSATION",
  (resolve) => (id: string) => resolve(id)
);

export const closeConversation = createActionCreator("CLOSE_CONVERSATION");

export const gotMessage = createActionCreator(
  "GOT_MESSAGE",
  (resolve) => (message: ConversationMessage) => resolve(message)
);

export const scheduleListUpdate = createActionCreator("SCHEDULE_LIST_UPDATE");
export const dismissListUpdate = createActionCreator("DISMISS_LIST_UPDATE");
export const setChatListSearch = createActionCreator(
  "SET_CHAT_LIST_SEARCH_TERM",
  (resolve) => (searchTerm: string) => resolve(searchTerm)
);
