import { createActionCreator } from "deox";
import {
  AdPackConfig,
  AvailableAdPacks,
  LinkedAdPack,
} from "../../apis/adPackConfigApi";

export const getPacksSuccessAction = createActionCreator(
  "GET_PACKS_SUCCESS",
  (resolve) => (payload: {
    packs: AdPackConfig[];
    availablePacks: AvailableAdPacks[];
    linkedPacks: LinkedAdPack[];
  }) => resolve(payload)
);
