import React, { FC, useMemo, memo, ReactNode } from "react";
import {
  Text,
  TextProps,
  StyleSheet,
  StyleProp,
  TextStyle,
} from "react-native";
import theme, { getThemeResponsiveValue } from "../../utils/theme";

function WPText({ children, ...props }: TextProps & { children?: ReactNode }) {
  const style: StyleProp<TextStyle> = useMemo(() => {
    return props.style ? [styles.text, props.style] : styles.text;
  }, [props.style]);

  return (
    <Text {...props} style={style}>
      {children}
    </Text>
  );
}

const styles = StyleSheet.create({
  text: {
    fontFamily: "normal",
    color: theme.colors.main,
    fontSize: getThemeResponsiveValue(theme.button.fontSize),
  },
});

export default memo(WPText);
