import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const TrashIcon: React.FC<IconProps> = (props) => {
  let height = 20;
  let width = 20;
  const ratio = width / height;
  const color = props.color || "#DE3618";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M12.06 2c.335 0 .631.212.738.527l.871 2.569h2.553c.394 0 .72.292.77.67L17 5.87v2.064a.776.776 0 01-.778.775l-.341-.001-.857 7.684a1.811 1.811 0 01-1.651 1.6L13.22 18H6.78a1.812 1.812 0 01-1.804-1.607l-.858-7.684h-.34a.777.777 0 01-.77-.668L3 7.935V5.871c0-.428.348-.774.778-.774l2.552-.001.872-2.57A.777.777 0 017.94 2h4.122zm2.256 6.71H5.684l.838 7.512a.26.26 0 00.258.23h6.44a.259.259 0 00.258-.23l.838-7.512zm1.128-2.065H4.556v.516h10.888v-.516zm-3.941-3.097H8.497l-.525 1.549h4.056l-.525-1.549z"
      />
    </Svg>
  );
};

TrashIcon.displayName = "TrashIcon";

export default TrashIcon;
