import React, { memo } from "react";
import theme from "../../utils/theme";
import { View, StyleProp, ViewStyle, Image, StyleSheet } from "react-native";
import { useTranslation } from "react-i18next";
import WPButton from "../Button/WPButton";
import { ScrollView } from "react-native-gesture-handler";
import WPText from "../Text/WPText";
import { getNormalizedDate, getPaymentInfo } from "../../utils/mixUtils";
import WPSeparator from "./WPSeparator";
import InfoRow from "./InfoRow";
import WPAvatar from "./WPAvatar";
import { RootState } from "../../services/store/reducers/mainReducer";
import { useSelector } from "react-redux";
import WPIcon from "../Icon/WPIcon";
import { JobOffer } from "../../services/apis/jobApi";

const WPTextPlaceHolder: React.FC<{
  height: number;
  width: number;
  style?: StyleProp<ViewStyle>;
}> = (props) => (
  <View
    style={[
      {
        height: props.height,
        width: props.width + "%",
        backgroundColor: theme.colors.mains[6],
      },
      props.style,
    ]}
  />
);

function JobPreview(props: { job: Partial<JobOffer>; onClose: () => void }) {
  const { t, i18n } = useTranslation();
  const user = useSelector((state: RootState) => state.auth);

  const { job } = props;
  const { images } = job;

  const firstImage = images && images[images.length - 1];

  const image =
    (typeof firstImage === "object" && firstImage.image) ||
    (typeof firstImage === "string" && firstImage) ||
    "";

  const source = {
    uri: image,
  };

  const placeholder = {
    height: 10,
    width: 100,
    style: styles.placeholder,
  };

  return (
    <ScrollView style={styles.container} removeClippedSubviews={true}>
      {job ? (
        <>
          {firstImage ? (
            <Image source={source} style={styles.image} />
          ) : (
            <View style={styles.image} />
          )}
          <View style={styles.desc}>
            <View style={styles.mainInfo}>
              {job.min_rate || job.max_rate ? (
                <WPText style={styles.payment}>
                  {`${getPaymentInfo(job, t)}`}
                </WPText>
              ) : (
                <WPTextPlaceHolder height={20} width={60} />
              )}
            </View>
            <WPText style={styles.date}>{`${t(
              "labels.added_on"
            )}: ${getNormalizedDate(
              job?.created || new Date().toISOString()
            )}`}</WPText>
            <WPSeparator />

            <View style={styles.title}>
              {job?.title ? (
                <WPText style={styles.titleText}>{job.title}</WPText>
              ) : (
                <WPTextPlaceHolder height={20} width={80} />
              )}
            </View>

            {job.company_name ? (
              <WPText style={styles.company}>{job.company_name}</WPText>
            ) : (
              <WPTextPlaceHolder height={15} width={40} />
            )}
            <WPSeparator />
            <View>
              <InfoRow
                label={t("labels.location")}
                value={job.city?.name || ""}
                isSingleLine={true}
                style={styles.infoRow}
              />
              <InfoRow
                label={t("titles.income_type")}
                value={t(`select.income_type.${job?.income_type}`, "")}
                isSingleLine={true}
                style={styles.infoRow}
              />
              <InfoRow
                label={t("labels.preferred_employees")}
                value={
                  job.preferred_employees
                    ?.map((val) => t(`select.preferred_employees.${val}`, ""))
                    .join(", ") || ""
                }
                isSingleLine={true}
                style={styles.infoRow}
              />
              <InfoRow
                label={t("labels.age_range")}
                value={
                  job?.age_range
                    ?.map((val) => t(`select.age_range.${val}`, ""))
                    .join(", ") || ""
                }
                isSingleLine={true}
                style={styles.infoRow}
              />
              <InfoRow
                label={t("labels.contract_start_date", "")}
                value={
                  (job.contract_start_date &&
                    getNormalizedDate(
                      job.contract_start_date,
                      i18n.language
                    )) ||
                  ""
                }
                isSingleLine={true}
                style={styles.infoRow}
              />
              <InfoRow
                label={t("titles.number_of_working_hours", "")}
                value={
                  job?.min_working_hours || job?.max_working_hours
                    ? `${job?.min_working_hours}${
                        job?.max_working_hours
                          ? ` - ${job?.max_working_hours}`
                          : ""
                      }`
                    : ""
                }
                style={styles.infoRow}
              />
              <InfoRow
                label={t("labels.accommodation")}
                value={t(`select.accommodation.${job?.accommodation}`, "")}
                style={styles.infoRow}
              />
              <InfoRow
                label={t("labels.meals")}
                value={t(`select.meals.${job?.meals}`, "")}
                style={styles.infoRow}
              />
            </View>

            <WPSeparator />

            <View style={styles.location}>
              <WPIcon name="place" style={styles.locationIcon} />
              <WPText style={styles.sectionTitle}>
                {t("labels.location")}
              </WPText>
            </View>
            <View style={styles.city}>
              {job.city ? (
                <WPText>{job.city?.name}</WPText>
              ) : (
                <WPTextPlaceHolder height={15} width={50} />
              )}
            </View>
            <View style={styles.employerContainer}>
              <WPAvatar
                source={user.avatar}
                size="m"
                style={styles.employerAvatar}
              />
              <View>
                <WPText style={styles.employerNameTitle}>
                  {t("titles.offer_from")}
                </WPText>
                <WPText>{user.userName}</WPText>
              </View>
            </View>
            <View style={styles.responsibilities}>
              <WPIcon name="file" style={styles.locationIcon} />
              <WPText style={styles.sectionTitle}>
                {t("labels.responsibilities")}
              </WPText>
            </View>
            <View>
              {job?.responsibilities ? (
                <WPText>{job?.responsibilities}</WPText>
              ) : (
                <>
                  <WPTextPlaceHolder {...placeholder} />
                  <WPTextPlaceHolder {...placeholder} />
                  <WPTextPlaceHolder {...placeholder} />
                  <WPTextPlaceHolder height={10} width={80} />
                </>
              )}
            </View>

            <WPSeparator />

            <View style={styles.responsibilities}>
              <WPText style={styles.sectionTitle}>
                {t("labels.conditions")}
              </WPText>
            </View>
            <View>
              {job?.responsibilities ? (
                <WPText>{job?.conditions}</WPText>
              ) : (
                <>
                  <WPTextPlaceHolder {...placeholder} />
                  <WPTextPlaceHolder {...placeholder} />
                  <WPTextPlaceHolder {...placeholder} />
                  <WPTextPlaceHolder height={10} width={80} />
                </>
              )}
            </View>
          </View>
        </>
      ) : null}
      <View style={styles.close}>
        <WPButton
          type="icon"
          name="close"
          appearance="link"
          color={theme.colors.main}
          onPress={props.onClose}
        />
      </View>
    </ScrollView>
  );
}

JobPreview.displayName = "JobPreview";
export default memo(JobPreview);

const styles = StyleSheet.create({
  container: { flex: 1, position: "relative" },
  close: { position: "absolute", right: 0, zIndex: 9999999 },
  preview: { flex: 1 },
  image: {
    height: 230,
    marginBottom: 16,
    backgroundColor: theme.colors.mains[6],
  },
  desc: { marginHorizontal: 20, marginBottom: 20 },
  mainInfo: {
    flexDirection: "row",
    alignItems: "baseline",
    marginBottom: 4,
  },
  payment: { fontSize: 20, marginRight: 8, fontFamily: "bold" },
  date: {
    color: theme.colors.mains[3],
    fontSize: 12,
    fontFamily: "bold",
  },
  title: { marginBottom: 4 },
  titleText: { fontSize: 18, fontFamily: "bold" },
  company: {
    marginRight: 8,
    color: theme.colors.mains[2],
  },
  infoRow: { marginBottom: 10 },
  location: { flexDirection: "row", marginBottom: 12 },
  locationIcon: { marginRight: 8 },
  sectionTitle: { fontSize: 16, fontFamily: "bold" },
  city: { marginBottom: 16 },
  employerContainer: {
    borderRadius: 10,
    backgroundColor: theme.colors.mains[6],
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 20,
    paddingVertical: 12,
    marginBottom: 20,
  },
  employerAvatar: {
    marginRight: 16,
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.3,
    shadowRadius: 3,
  },
  employerNameTitle: { fontSize: 12, color: theme.colors.mains[2] },
  responsibilities: {
    flexDirection: "row",
    marginBottom: 12,
    alignItems: "center",
  },
  placeholder: {
    marginBottom: 8,
  },
});
