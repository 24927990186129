import { RootState } from "../services/store/reducers/mainReducer";
import { useState, useEffect, useCallback } from "react";
import appCache from "../services/appCache";
import { loadAsync } from "expo-font";
import { hideAsync, preventAutoHideAsync } from "expo-splash-screen";
import { useDispatch, useSelector } from "react-redux";
import { AppState, Platform } from "react-native";
import { useTranslation } from "react-i18next";
import { getToken } from "../services/notifications/notifications";
import { getNotificationPermission } from "../services/permissions";
import Constants from "expo-constants";
import {
  accountInfoCall,
  saveNotificationToken,
} from "../services/apis/authApi";
import { logError } from "../services/logger";
import { ConversationListener } from "../services/chatService";
import { receivedConversations } from "../services/store/actions/chatActions";
import { getStateFromPath } from "@react-navigation/native";
import { saveNavState } from "../navigation/WPRouter";
import config from "../services/config";
import { refreshTokenRequest } from "./wpRequest";
import {
  profileInfoAction,
  signedInAction,
} from "../services/store/actions/authActions";

const { REFRESH_TOKEN_KEY, TOKEN_KEY } = config;

export const useIntroHook = (): [boolean, () => void] => {
  const [isSeen, setIsSeen] = useState(true);

  useEffect(() => {
    appCache
      .getIsSeenIntro()
      .then((val) => {
        if (val) {
          setIsSeen(true);
        } else {
          setIsSeen(false);
        }
      })
      .catch(() => setIsSeen(false));
  }, [setIsSeen]);

  const setIsSeenDecorated = useCallback(() => {
    appCache
      .setIsSeenIntro("true")
      .then(() => setIsSeen(true))
      .catch(() => setIsSeen(true));
  }, [setIsSeen]);

  return [isSeen, setIsSeenDecorated];
};

const storeNotificationToken = async (language: string) => {
  const isNotificationEnabled = await getNotificationPermission();
  const token = await getToken();
  const cachedToken = await appCache.getCachedToken();
  try {
    if (!isNotificationEnabled || token === cachedToken || !token) {
      return;
    }

    await saveNotificationToken({
      registration_id: token,
      device_id: Constants.installationId,
      device_type: Platform.OS,
      language: language,
    });

    await appCache.saveCachedToken(token);
  } catch (err) {
    logError(err);
  }
};

export const useAppInitHook = (): [boolean, undefined | RootState] => {
  const unAuthorizedRouts = ["/AUTH/NEW_PASS_SCREEN", "SIGN_UP"];
  preventAutoHideAsync();
  const [isLoading, setIsLoading] = useState(true);
  const [storeData, setStoreData] = useState<RootState | undefined>(undefined);

  useEffect(() => {
    async function init() {
      try {
        const savedStoreData = await appCache.getStoreValue();
        setStoreData(savedStoreData);
      } catch (err) {
        logError(err);
      }

      let redirectUrl = await appCache.getRedirectValue();

      if (Platform.OS === "web") {
        if (window.location.search.includes("?token=")) {
          const refreshToken = window.location.search.replace("?token=", "");

          setStoreData(undefined);

          history.pushState("", "CRM", window.location.pathname);

          await appCache.setValue(TOKEN_KEY, "");
          await appCache.setValue(REFRESH_TOKEN_KEY, refreshToken);
          await appCache.setRedirectValue(window.location.pathname);
          await appCache.saveStoreValue({});

          await refreshTokenRequest();
        }

        if (window.location.pathname === "/auth/registration/success") {
          await appCache.setValue(TOKEN_KEY, "");
          await appCache.setValue(REFRESH_TOKEN_KEY, "");
          await appCache.saveStoreValue({});

          const navState = getStateFromPath("/AUTH/SIGN_IN");
          await saveNavState(navState as any);
        }
      }

      const apiToken =
        (await appCache.getApiToken()) || (await appCache.getApiRefreshToken());

      if (apiToken) {
        if (Platform.OS === "web") {
          const navState = getStateFromPath(
            window.location.pathname + window.location.search
          );

          await saveNavState(navState as any);
        }
      } else {
        await appCache.clearCache();
        await appCache.setRedirectValue(
          redirectUrl || Platform.OS === "web"
            ? window.location.pathname + window.location.search
            : ""
        );

        if (Platform.OS === "web") {
          if (
            unAuthorizedRouts.some((routePath) =>
              window.location.pathname.includes(routePath)
            )
          ) {
            const navState = getStateFromPath(
              window.location.pathname + window.location.search
            );

            await saveNavState(navState as any);
          } else {
            const navState = getStateFromPath("/AUTH/SIGN_IN");
            await saveNavState(navState as any);
          }
        }
      }

      try {
        await loadAsync({
          black: require("../../assets/fonts/Lato-Black.ttf"),
          bold: require("../../assets/fonts/Lato-Bold.ttf"),
          light: require("../../assets/fonts/Lato-Light.ttf"),
          normal: require("../../assets/fonts/Lato-Regular.ttf"),
        });
      } catch (err) {
        logError(err);
      }

      setIsLoading(false);
    }

    init().catch(logError);
  }, [setIsLoading, setStoreData]);

  useEffect(() => {
    if (!isLoading) {
      hideAsync();
    }
  }, [isLoading]);

  return [!isLoading, storeData];
};

export const useUserIntiHook = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.auth?.userId);
  const [conversationListener, setConversationListener] = useState<
    ConversationListener | undefined
  >(undefined);

  const initNewListener = useCallback(() => {
    let listener = conversationListener;

    if (listener) {
      listener.remove();
    }

    if (userId) {
      storeNotificationToken(i18n.language);
      accountInfoCall()
        .then((res) => {
          dispatch(profileInfoAction(res));
        })
        .catch(console.warn);

      listener = new ConversationListener(
        (changes) => dispatch(receivedConversations(changes)),
        userId
      );

      setConversationListener(listener);
    } else {
      refreshTokenRequest()
        .then(
          (
            authData:
              | undefined
              | {
                  username: string;
                  access_token: string;
                  refresh_token: string;
                  id: number;
                  first_name: string;
                  last_name: string;
                }
          ) => {
            if (authData) {
              dispatch(
                signedInAction({
                  userId: authData.id,
                  email: authData.username,
                  avatar: "",
                  userName: authData.first_name + " " + authData.last_name,
                  company: "",
                })
              );
            }
          }
        )
        .catch(console.warn);
    }

    return listener ? listener.remove : () => {};
  }, [userId, dispatch]);

  useEffect(initNewListener, [initNewListener, userId]);

  useEffect(() => {
    return AppState.addEventListener("change", (state) => {
      if (state === "active") {
        initNewListener();
      }
    });
  }, []);
};
