import React, { ReactNode } from "react";
import { View, StyleSheet } from "react-native";
import WPText from "../../../components/Text/WPText";
import theme, { isMobileView } from "../../../utils/theme";
import WPButton from "../../../components/Button/WPButton";

export type AuthFormProps = {
  title?: string | ReactNode;
  subtitle?: string;
  secondaryTitle?: string;
  secondaryLinkLabel?: string;
  link?: string;
  children?: React.ReactNode;
  descriptionTitle?: string;
  descriptionOptions?: { label: string; desc: string }[];
  descriptionFooterText?: string;
  descriptionFooterLinkText?: string;
  descriptionFooterLinkOnPress?: () => void;
};

function AuthForm(props: AuthFormProps) {
  return (
    <View style={styles.mainContainer}>
      <View style={styles.container}>
        {props.title ? (
          typeof props.title === "string" ? (
            <WPText
              style={props.subtitle ? titleWithSubTitleStyle : styles.title}
            >
              {props.title}
            </WPText>
          ) : (
            props.title
          )
        ) : null}

        {props.subtitle && (
          <WPText style={styles.subTitle}>{props.subtitle}</WPText>
        )}

        <View style={styles.form}>
          {props.children}
          {props.secondaryTitle && (
            <WPText style={styles.secondaryTitle}>
              {props.secondaryTitle}
            </WPText>
          )}

          {props.secondaryLinkLabel && (
            <View
              style={
                props.secondaryTitle ? undefined : styles.secondaryLinkLabel
              }
            >
              <WPButton pageLink={props.link} size="small" appearance="link">
                {props.secondaryLinkLabel}
              </WPButton>
            </View>
          )}
        </View>
      </View>
      {!isMobileView() && props.descriptionTitle ? (
        <>
          <View style={styles.spacer} />
          <View style={styles.container}>
            <WPText style={styles.descriptionTitle}>
              {props.descriptionTitle}
            </WPText>
            {props.descriptionOptions?.map((option) => (
              <WPText style={styles.descriptionText}>
                <WPText style={styles.descriptionLabel}>{option.label}</WPText>
                {option.label ? " - " : ""}
                {option.desc}
              </WPText>
            ))}
            {props.descriptionFooterText ? (
              <WPText style={styles.descriptionFooterText}>
                {props.descriptionFooterText}{" "}
                <WPText
                  style={styles.descriptionLinkText}
                  onPress={props.descriptionFooterLinkOnPress}
                >
                  {props.descriptionFooterLinkText}
                </WPText>
              </WPText>
            ) : null}
          </View>
        </>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: "row",
  },
  container: {
    flex: 1,
    paddingVertical: 16,
    maxWidth: isMobileView() ? 320 : 416,
    minWidth: isMobileView() ? 300 : 416,
    // justifyContent: "flex-end",
  },
  title: {
    fontFamily: "black",
    fontSize: 24,
    textAlign: "center",
    marginBottom: 28,
  },
  titleWithSubTitle: {
    marginBottom: 12,
  },
  subTitle: { fontSize: 14, textAlign: "center", marginBottom: 24 },
  form: {
    flex: 1,
    paddingHorizontal: isMobileView() ? 16 : 52,
    paddingVertical: isMobileView() ? 16 : 28,
    backgroundColor: theme.colors.white,
    borderRadius: 10,
    borderColor: "transparent",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.1,
    shadowRadius: 14,

    elevation: 8,
  },
  secondaryTitle: {
    marginTop: 20,
    fontFamily: "bold",
    fontSize: 12,
    textAlign: "center",
    color: theme.colors.mains[3],
    marginBottom: 4,
  },
  secondaryLinkLabel: {
    marginTop: 12,
    alignItems: "center",
  },
  spacer: {
    width: 80,
  },
  descriptionTitle: {
    marginTop: 64,
    fontSize: 24,
    fontFamily: "bold",
    marginBottom: 30,
    color: theme.colors.mains[0],
  },
  descriptionText: {
    color: theme.colors.mains[2],
    marginBottom: 16,
    letterSpacing: 0.25,
  },
  descriptionLabel: {
    fontFamily: "bold",
    color: theme.colors.mains[0],
  },
  descriptionFooterText: {
    color: theme.colors.mains[3],
  },
  descriptionLinkText: {
    color: theme.colors.primary,
  },
});

const titleWithSubTitleStyle = [styles.title, styles.titleWithSubTitle];

export default AuthForm;
