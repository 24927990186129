import React, { useState, useEffect } from "react";
import AuthForm from "../components/AuthForm";
import FormField from "../../../components/form/FormField";
import FormRow from "../../../components/form/FormRow";
import { ScrollView } from "react-native-gesture-handler";
import { View, StyleSheet, ImageBackground, Platform } from "react-native";
import { useTranslation } from "react-i18next";
import WPButton from "../../../components/Button/WPButton";
import { Formik } from "formik";
import FormContainer from "../../../components/form/FormContainer";
import { useSignIn } from "./hooks/signInHook";
import { useDispatch } from "react-redux";
import { signedInAction } from "../../../services/store/actions/authActions";
import FormError from "../../../components/form/FormError";
import AuthBackGround from "../components/AuthBackGround";
import WPText from "../../../components/Text/WPText";
import theme, { isMobileView } from "../../../utils/theme";
import WPSwitcher, { SwitcherItem } from "../../../components/misc/WPSwitcher";
import { TFunction } from "i18next";
import { useSignUp } from "./hooks/signUpHook";
import WPWebLink from "../../../components/misc/WPWebLink";
import AuthNotification from "../components/AuthNotification";

const initialValues = {
  username: "",
  password: "",
};

const initialValuesSignUp = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  phone: "",
  employer_company_name: "",
  logo: "",
  terms: false,
  general_terms: false,
};

export const TERMS_URL = "https://workport.pl/mobile/terms-general";
export const TERMS_EMPLOYER_URL = "https://workport.pl/mobile/terms-employer";

function getTabs({
  activeLabel,
  t,
  onSelected,
}: {
  activeLabel: string;
  t: TFunction;
  onSelected: (tabLabel: string) => void;
}): SwitcherItem[] {
  return ["titles.login", "titles.create_account"].map((label) => ({
    label: t(label),
    onPress: () => onSelected(label),
    isActive: activeLabel === label,
  }));
}

const SignInScreen = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [isLoadingSignUp, errorsSignUp, signUp, isSuccessful] = useSignUp();

  const [isLoading, errors, signIn] = useSignIn((values) =>
    dispatch(signedInAction(values))
  );

  const formError = errors["non_field_errors"];

  const [activeLabel, setActiveLabel] = useState("titles.create_account");

  const [tabs, setTabs] = useState<SwitcherItem[]>(
    getTabs({ activeLabel: activeLabel, t, onSelected: setActiveLabel })
  );

  useEffect(() => {
    setTabs(
      getTabs({ activeLabel: activeLabel, t, onSelected: setActiveLabel })
    );
  }, [i18n.language, activeLabel]);

  return (
    <AuthBackGround>
      <ScrollView contentContainerStyle={styles.container}>
        {activeLabel === "titles.create_account" ? (
          <AuthNotification
            isVisible={isSuccessful}
            title={t("titles.confirm_email")}
            iconName="send_email"
            description={t("misc.confirm_email_text")}
            onClose={() => {
              setActiveLabel("titles.login");
            }}
            buttonLabel={
              Platform.OS === "web" && !isMobileView()
                ? undefined
                : t("labels.got_it")
            }
          />
        ) : null}
        <AuthForm
          title={
            <View style={styles.titleContainer}>
              <WPSwitcher items={tabs} appearance="link" />
            </View>
          }
          secondaryLinkLabel={
            activeLabel === "titles.login"
              ? t("labels.forgot_password")
              : undefined
          }
          link="AUTH.FORGOT_PASS_START"
          descriptionTitle={
            activeLabel === "titles.login"
              ? t("titles.sign_in")
              : t("titles.tooltips")
          }
          descriptionOptions={
            activeLabel === "titles.login"
              ? [
                  {
                    label: "",
                    desc: t("misc.after_sign_in"),
                  },
                ]
              : [
                  {
                    label: t("labels.logo"),
                    desc: t("tooltip.logo"),
                  },
                  {
                    label: t("labels.employer_company_name"),
                    desc: t("tooltip.employer_company_name"),
                  },
                  {
                    label: t("labels.email"),
                    desc: t("tooltip.email"),
                  },
                  {
                    label: t("labels.password"),
                    desc: t("tooltip.password"),
                  },
                ]
          }
          descriptionFooterText={
            activeLabel === "titles.login"
              ? t("misc.if_dont_have_account")
              : t("misc.if_have_account")
          }
          descriptionFooterLinkText={
            activeLabel === "titles.login"
              ? t("titles.create_account")
              : t("titles.login")
          }
          descriptionFooterLinkOnPress={() =>
            setActiveLabel(
              activeLabel === "titles.login"
                ? "titles.create_account"
                : "titles.login"
            )
          }
        >
          {activeLabel === "titles.login" ? (
            <Formik
              initialValues={initialValues}
              onSubmit={signIn}
              enableReinitialize
            >
              {(formikProps) => (
                <FormContainer>
                  {/* <FormRow>
                    <View style={styles.subTitle}>
                      <WPText style={styles.title}>
                        {t("titles.missing_account")}
                      </WPText>
                      <WPButton
                        size="small"
                        appearance="link"
                        onPress={() => setActiveLabel("titles.create_account")}
                      >
                        {t("titles.create_account")}
                      </WPButton>
                    </View>
                  </FormRow> */}
                  <FormRow>
                    <FormField
                      key="signInEmail"
                      name="username"
                      {...formikProps}
                      errors={errors}
                    />
                  </FormRow>
                  <FormRow>
                    <FormField
                      key="signInPass"
                      name="password"
                      {...formikProps}
                      errors={errors}
                      tooltip=""
                      type="password"
                    >
                      <FormError error={formError} />
                    </FormField>
                  </FormRow>
                  <WPButton
                    onPress={formikProps.handleSubmit}
                    isLoading={isLoading}
                  >
                    {t("labels.login")}
                  </WPButton>
                </FormContainer>
              )}
            </Formik>
          ) : (
            <Formik
              initialValues={initialValuesSignUp}
              onSubmit={signUp}
              enableReinitialize
            >
              {(formikProps) => (
                <FormContainer>
                  <FormRow>
                    <View style={styles.subTitle}>
                      <WPText style={styles.title}>
                        {t("titles.sign_up1")}
                        <WPText style={styles.titleBold}>
                          {t("titles.sign_up2")}
                        </WPText>
                        {t("titles.sign_up3")}
                      </WPText>
                    </View>
                  </FormRow>
                  <FormRow key="logo">
                    <FormField
                      key="signInFirstName"
                      {...formikProps}
                      style={styles.container}
                      name="logo"
                      type="image"
                      isRounded
                      errors={errorsSignUp}
                      tooltip={isMobileView() ? undefined : ""}
                    />
                  </FormRow>
                  <FormRow>
                    <FormField
                      name="first_name"
                      {...formikProps}
                      errors={errorsSignUp}
                    />
                  </FormRow>
                  <FormRow>
                    <FormField
                      name="last_name"
                      {...formikProps}
                      errors={errorsSignUp}
                    />
                  </FormRow>
                  <FormRow>
                    <FormField
                      name="employer_company_name"
                      {...formikProps}
                      errors={errorsSignUp}
                      tooltip={isMobileView() ? undefined : ""}
                    />
                  </FormRow>
                  <FormRow>
                    <FormField
                      name="phone"
                      {...formikProps}
                      errors={errorsSignUp}
                      disableTooltip
                    />
                  </FormRow>
                  <FormRow>
                    <FormField
                      key="signUpEmail"
                      name="email"
                      {...formikProps}
                      errors={errorsSignUp}
                      tooltip={isMobileView() ? undefined : ""}
                    />
                  </FormRow>
                  <FormRow>
                    <FormField
                      key="signUpPass"
                      name="password"
                      {...formikProps}
                      errors={errorsSignUp}
                      tooltip={isMobileView() ? undefined : ""}
                      type="password"
                    >
                      <FormError error={errors["non_field_errors"]} />
                    </FormField>
                  </FormRow>
                  <FormRow>
                    <FormField
                      {...formikProps}
                      name="general_terms"
                      type="checkbox"
                      style={styles.checkboxContainer}
                      label={t("labels.terms")}
                      errors={errorsSignUp}
                    >
                      <WPWebLink
                        link={TERMS_URL + "/" + i18n.language}
                        text={" " + t("labels.general_terms")}
                      />
                    </FormField>
                  </FormRow>
                  <FormRow>
                    <FormField
                      {...formikProps}
                      name="terms"
                      type="checkbox"
                      style={styles.checkboxContainer}
                      errors={errorsSignUp}
                    >
                      <WPWebLink
                        link={TERMS_EMPLOYER_URL + "/" + i18n.language}
                        text={" " + t("labels.terms_link")}
                      />
                    </FormField>
                  </FormRow>
                  <WPButton
                    onPress={formikProps.handleSubmit}
                    disabled={
                      !formikProps.values.terms ||
                      !formikProps.values.general_terms
                    }
                    isLoading={isLoadingSignUp}
                  >
                    {t("titles.create_account")}
                  </WPButton>
                </FormContainer>
              )}
            </Formik>
          )}
        </AuthForm>
      </ScrollView>
    </AuthBackGround>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
  forgotPass: {
    justifyContent: "flex-end",
    flexDirection: "row",
  },
  titleContainer: {
    paddingHorizontal: isMobileView() ? 8 : theme.button.paddingHorizontal * 2,
  },
  subTitle: {
    flex: 1,
    alignItems: "center",
  },
  title: {
    flex: 1,
    color: theme.colors.mains[2],
    textAlign: "center",
  },
  titleBold: {
    color: theme.colors.mains[1],
    fontFamily: "bold",
  },
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  link: {
    fontSize: 12,
    textDecorationLine: "underline",
  },
});

export default SignInScreen;
