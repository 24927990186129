import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const CameraIcon: React.FC<IconProps> = (props) => {
  let height = 21;
  let width = 20;
  const ratio = width / height;
  const color = props.color || "#637381";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg
      {...props}
      width={width}
      height={height}
      style={[{ height, width }, props.style]}
      viewBox={`0 0 20 21`}
    >
      <Path
        fill={color}
        fillRule="evenodd"
        d="M7.654 3l4.736.001a2.146 2.146 0 011.759 1.126l.076.15.487 1.433h1.284c1.056 0 1.925.813 1.999 1.839l.005.14v7.343c0 1.04-.816 1.891-1.85 1.963l-.142.005H4.004a1.999 1.999 0 01-1.999-1.839L2 15.021V7.677c0-1.04.816-1.891 1.85-1.963l.142-.005H5.29l.515-1.504c.308-.652.935-1.093 1.687-1.19L7.654 3zm4.692 1.355l-4.648-.002a.812.812 0 00-.583.29l-.04.065-.643 1.895a.684.684 0 01-.554.455l-.097.007H3.992a.619.619 0 00-.614.522l-.007.09v7.343c0 .314.241.578.548.62l.085.005h12.004c.311 0 .57-.227.614-.523l.007-.09V7.69a.632.632 0 00-.548-.62l-.085-.005h-1.777a.687.687 0 01-.613-.374l-.038-.088-.614-1.824a.762.762 0 00-.517-.411l-.09-.013-4.649-.002 4.648.002zM10 7.332c1.997 0 3.615 1.598 3.615 3.571 0 1.972-1.618 3.572-3.615 3.572s-3.615-1.6-3.615-3.572c0-1.973 1.618-3.571 3.615-3.571zm0 1.355c-1.24 0-2.244.992-2.244 2.216 0 1.224 1.005 2.217 2.244 2.217 1.24 0 2.244-.993 2.244-2.217 0-1.224-1.004-2.216-2.244-2.216z"
      />
    </Svg>
  );
};

CameraIcon.displayName = "CameraIcon";

export default CameraIcon;
