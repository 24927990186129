import { takeEvery, select, put, call } from "redux-saga/effects";
import { ActionType, getType } from "deox";
import { receivedConversations } from "../actions/chatActions";
import { RootState, getEmployeeState } from "../reducers/mainReducer";
import { Conversation } from "../../chatService";
import { getEmployeeIdFromConversation } from "../reducers/chat/chatReducer";
import { getBulkEmployeeInfoCall } from "../../apis/employeeApi";
import { receivedEmployees } from "../actions/employeeActions";

const REFRESH_GAP = 2 * 60 * 60 * 1000; // 2 hours;

function* onNewConversationSaga(
  action: ActionType<typeof receivedConversations>
) {
  const state: RootState = yield select();
  const employeeState = getEmployeeState(state);

  const idsToGet: number[] = [];

  function checkToRefresh(item: Conversation) {
    const id = getEmployeeIdFromConversation(item);
    const savedEmployeeData = employeeState[id];

    if (!savedEmployeeData) {
      idsToGet.push(id);
      return;
    }

    const refreshDelta =
      Number(new Date()) - Number(savedEmployeeData.lastRefreshed);

    if (refreshDelta > REFRESH_GAP) {
      idsToGet.push(id);
    }
  }

  action.payload.added.forEach(checkToRefresh);
  action.payload.modified.forEach(checkToRefresh);

  const uniqIds = Array.from(new Set(idsToGet));

  // const data = yield all(uniqIds.map(getEmployeeInfoCall));
  if (uniqIds.length) {
    var chunks = [];

    while (uniqIds.length) {
      chunks.push(uniqIds.splice(0, 20));
    }

    const calls = chunks.map(getBulkEmployeeInfoCall);

    const rawData = yield Promise.all(calls);

    const data = [].concat(...rawData);

    yield put(receivedEmployees(data.filter((item: any) => !!item)));
  }
}

function* employeeSaga() {
  yield takeEvery(getType(receivedConversations), onNewConversationSaga);
}

export default employeeSaga;
