import React, { useCallback, useState, useEffect, memo } from "react";
import WPDropDownSelect, { WPDropDownSelectProps } from "./WPDropDownSelect";
import { Category, getCategoriesCall } from "../../../services/apis/jobApi";
import WPText from "../../Text/WPText";
import { ListItem } from "../../list/WPList";

function WPJobCategorySelect(
  props: Omit<WPDropDownSelectProps<Category>, "items"> & {
    value: number | Category;
  }
) {
  const [categories, setCategories] = useState<ListItem<Category>[]>([]);
  useEffect(() => {
    getCategoriesCall().then((res) =>
      setCategories(
        res.map((cat) => ({
          label: cat.name,
          value: cat,
        }))
      )
    );
  }, []);

  // const itemRenderer = useCallback((item: Category) => {
  //   if (typeof item === "string") {
  //     return <WPText>{item}</WPText>;
  //   }

  //   return <WPText>{item.name}</WPText>;
  // }, []);

  let normalizedValue: Category | undefined = undefined;

  if (typeof props.value === "object") {
    normalizedValue = props.value;
  } else {
    const categoryListItem = categories.find(
      (cat) => cat.value.id === props.value
    );

    normalizedValue = categoryListItem?.value;
  }

  return (
    <WPDropDownSelect
      {...props}
      // customRenderItem={itemRenderer}
      items={categories}
      value={normalizedValue}
    />
  );
}

export default memo(WPJobCategorySelect);
