import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const PlusIcon: React.FC<IconProps> = (props) => {
  let height = 24;
  let width = 24;
  const ratio = width / height;
  const color = props.color || "#FFF";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M8.099 3.974c.956-1.347 3.026-1.297 3.9.15l5.666 9.378c.931 1.54-.188 3.498-1.998 3.498H4.334c-1.81 0-2.93-1.958-1.999-3.497L8 4.123zm2.583.932a.798.798 0 00-1.364 0l-5.666 9.38a.787.787 0 00.682 1.191h11.333a.787.787 0 00.68-1.192zM10 12.68c.424 0 .768.341.768.762v.02c0 .42-.344.761-.768.761a.765.765 0 01-.768-.761l.007-.124A.766.766 0 0110 12.68zm0-5.67c.424 0 .768.34.768.761v3.29c0 .42-.344.761-.768.761a.765.765 0 01-.768-.761v-3.29c0-.42.344-.761.768-.761z"
      />
    </Svg>
  );
};

PlusIcon.displayName = "PlusIcon";

export default PlusIcon;
