import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { View, Image, StyleSheet } from "react-native";
import WPText from "../../../Text/WPText";
import DropDownWrapper from "../../../misc/DropDownWrapper";
import { ListItem } from "../../../list/WPList";
import theme from "../../../../utils/theme";

type LanguageConfig<T> = ListItem<T> & {
  image: any;
};

const englishConfig = {
  label: "ENG",
  value: "en",
  image: require("./assets/eng.png"),
};

const languages: LanguageConfig<string>[] = [
  { label: "UA ", value: "uk", image: require("./assets/ua.png") },
  { label: "RU ", value: "ru", image: require("./assets/ru.png") },
  englishConfig,
  { label: "PL ", value: "pl", image: require("./assets/pl.png") },
  // { label: "DE ", value: "de", image: require("./assets/de.png") },
];

export const supportedLanguagesFlagMap = languages;
const getLanguageConfig = (language: string) =>
  languages.find((lngCfg) => lngCfg.value === language) || englishConfig;

const LanguageItem = (props: {
  language: string;
  labelColor?: string;
  isShort: boolean;
}) => {
  const languageConfig = getLanguageConfig(props.language);

  const textStyle = props.labelColor
    ? { color: props.labelColor }
    : styles.text;

  return (
    <View style={styles.container}>
      <Image source={languageConfig.image} style={styles.flag} />
      {props.isShort ? null : (
        <WPText style={textStyle}>{languageConfig.label}</WPText>
      )}
    </View>
  );
};

export default function WPLanguageSelect(props: {
  labelColor?: string;
  isShort?: boolean;
}) {
  const { i18n } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  return (
    <DropDownWrapper
      style={styles.selectedContainer}
      isFocused={isFocused}
      setIsFocused={setIsFocused}
      onChange={(lang) => i18n.changeLanguage(lang)}
      items={languages}
      customRenderItem={(value) => (
        <LanguageItem language={value} isShort={!!props.isShort} />
      )}
    >
      <LanguageItem
        language={i18n.language}
        labelColor={props.labelColor}
        isShort={!!props.isShort}
      />
    </DropDownWrapper>
  );
}

const styles = StyleSheet.create({
  container: { flexDirection: "row", alignItems: "center" },
  flag: { height: 21, width: 21, marginRight: 8 },
  selectedContainer: {
    paddingHorizontal: theme.button.paddingHorizontal,
  },
  text: {},
});
