import { createActionCreator } from "deox";
import { Estate } from "../../apis/estateApi";

export const gotEstates = createActionCreator(
  "GOT_ESTATES",
  (resolve) => (data: { estates: Estate[]; total: number }) => resolve(data)
);
export const gotEstate = createActionCreator(
  "GOT_ESTATE",
  (resolve) => (estate: Estate) => resolve(estate)
);
export const getEstate = createActionCreator(
  "GET_ESTATE",
  (resolve) => (id: number) => resolve(id)
);

export const deletedEstate = createActionCreator(
  "DELETED_ESTATE",
  (resolve) => (id: number) => resolve(id)
);
