import fb from "../fbsdk/fbsdk";
import firebaseAnalytics from "../firebaseAnalytics/firebaseAnalytics";

const logEvent = (eventName: string) => {
  fb.logEvent("BIZ_" + eventName);
  firebaseAnalytics.logEvent("BIZ_" + eventName);
};

export default {
  logScreenChange: (screenName: string) => {
    firebaseAnalytics.setScreen(screenName);
  },
  logPurchase: (amount: number) => {
    fb.logPurchase(amount);
    firebaseAnalytics.logPurchase(amount);
    logEvent("All_completed_conversions");
  },

  logRestoreAdd: () => logEvent("restore_ad"),

  logFirstReplyInChat: () => logEvent("first_reply_chat"),

  logBump: () => logEvent("ad_bump"),

  logRegistration: () => {
    logEvent("registration");
    logEvent("All_completed_conversions");
  },

  logCreateAd: () => {
    logEvent("ad_created");
    logEvent("All_completed_conversions");
  },

  logDemoPeriod: () => {
    logEvent("demo_period");
    logEvent("All_completed_conversions");
  },

  logEvent,
};
