import React from "react";
import PrependAppendDecorator from "./PrependAppendDecorator";
import WPAvatar from "./WPAvatar";
import { getEmployeeDisplayName } from "../../services/store/reducers/employee/employeeReducer";
import WPText from "../Text/WPText";
import { StyleSheet, View } from "react-native";
import { useBaseEmployeeInfo } from "../../services/hooks/employeeHooks";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store/reducers/mainReducer";

type UserTileProps = {
  append?: React.ReactNode;
  userId: number;
};

export default function UserTile(
  props: {
    size?: "xl" | "l" | "m" | "s";
  } & UserTileProps
) {
  const searchTerm = useSelector((state: RootState) => state.chat.searchTerm);
  const { t } = useTranslation();
  const baseData = useBaseEmployeeInfo(props.userId);
  const avatar = baseData ? baseData.photo : undefined;
  const userName = baseData
    ? getEmployeeDisplayName(baseData)
    : t("missing_name");

  if (searchTerm && !userName.toLowerCase().includes(searchTerm)) {
    return null;
  }

  return (
    <View>
      <PrependAppendDecorator
        prepend={<WPAvatar size={props.size || "l"} source={avatar} />}
        append={props.append}
      >
        <WPText style={styles.userName}>{userName}</WPText>
      </PrependAppendDecorator>
    </View>
  );
}

const styles = StyleSheet.create({
  userName: {
    fontFamily: "bold",
    fontSize: 16,
  },
});
