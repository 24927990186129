import React from "react";
import WPButton, { WPButtonProps } from "../../components/Button/WPButton";
import { StyleSheet } from "react-native";
import { getHeaderHeight } from "../../utils/theme";

const HeaderButton: React.FC<WPButtonProps> = ({ style, ...props }) => (
  <WPButton
    {...props}
    appearance="link"
    style={style ? [wpButtonStyles.button, style] : wpButtonStyles.button}
  />
);

const wpButtonStyles = StyleSheet.create({
  button: {
    height: getHeaderHeight(),
    minWidth: getHeaderHeight(),
    alignItems: "center",
    justifyContent: "center",
  },
});

export default React.memo(HeaderButton);
