import React, { memo } from "react";
import { EmployeeApplications } from "../../../../services/apis/employeeApi";
import { StyleSheet, View } from "react-native";
import WPAvatar from "../../../../components/misc/WPAvatar";
import { getEmployeeDisplayName } from "../../../../services/store/reducers/employee/employeeReducer";
import WPText from "../../../../components/Text/WPText";
import CandidateStatus from "./CandidateStatus";
import CandidatesJobsTableCell from "./CandidatesJobsTableCell";
import theme, { getThemeResponsiveValue } from "../../../../utils/theme";

function CandidateTile(props: EmployeeApplications) {
  const status =
    props.applications.length > 1 ? undefined : props.applications[0].status;
  return (
    <View style={styles.container}>
      <WPAvatar source={props.photo} size="l" />
      <View style={styles.descContainer}>
        <View style={styles.spacer}>
          <WPText style={styles.mainText}>
            {getEmployeeDisplayName(props)}
          </WPText>
        </View>
        <View style={styles.spacer}>
          <CandidateStatus status={status} total={props.applications.length} />
        </View>
        <CandidatesJobsTableCell {...props} />
        <View style={styles.spacer}>
          <WPText>
            {props.applications
              .map((application) => application.job_company_name)
              .join(", ")}
          </WPText>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: { flexDirection: "row" },
  descContainer: {
    marginLeft: theme.button.paddingHorizontal,
    flex: 1,
  },
  mainText: {
    fontSize: getThemeResponsiveValue(theme.button.fontSize) + 4,
    fontFamily: "bold",
    marginBottom: 4,
  },
  spacer: {
    marginBottom: 8,
  },
});

export default memo(CandidateTile);
