import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const LogoutIcon: React.FC<IconProps> = (props) => {
  let height = 20;
  let width = 20;
  const ratio = width / height;
  const color = props.color || "#FFF";

  if (props.size) {
    height = props.size - 4;
    width = ratio * props.size - 4;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M1.791 1l10.42.012a.78.78 0 01.789.773c0 .39-.298.713-.684.763l-.107.007-9.63-.012v14.913l9.63.001c.4 0 .731.29.784.667l.007.105c0 .39-.297.713-.683.764L12.21 19H1.79c-.4 0-.73-.29-.783-.667L1 18.23V1.77c0-.39.298-.713.684-.764L1.79 1zm11.576 4.107l.095.066 5.225 4.21.069.064.04.043a1.11 1.11 0 01.064.087 1.146 1.146 0 01.047.087l.022.053.016.045c.006.018.01.037.015.056l.01.053a.781.781 0 01.011.113v.03l-.001.038v-.038l-.003.06-.006.055a.811.811 0 01-.112.294l-.034.05a1.242 1.242 0 01-.054.066l-.008.008a.79.79 0 01-.06.056l-.016.013-5.225 4.211a.78.78 0 01-1.101-.123.794.794 0 01.037-1.03l.085-.08 3.48-2.805H7.764A.787.787 0 016.98 10c0-.4.295-.73.678-.782l.106-.007 8.198-.001-3.48-2.804a.794.794 0 01-.188-1.013l.066-.097a.78.78 0 011.006-.19z"
        opacity={0.5}
      />
    </Svg>
  );
};

LogoutIcon.displayName = "LogoutIcon";

export default LogoutIcon;
