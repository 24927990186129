import React from "react";
import Svg, { Path, G, Circle } from "../SVG";
import { IconProps } from "../WPIcon";

const ArrowIcon: React.FC<IconProps> = (props) => {
  let height = 15;
  let width = 15;
  const ratio = width / height;
  const color = props.color || "#919EAB";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 15 15" {...props}>
      <G fill="none" fillRule="evenodd">
        <Circle cx={7.5} cy={7.5} r={7.5} fill={color} />
        <Path
          fill="#FFF"
          d="M5.03 5.86a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 00-1.06-1.06L7.5 8.33 5.03 5.86z"
        />
      </G>
    </Svg>
  );
};

ArrowIcon.displayName = "ArrowIcon";

export default ArrowIcon;
