import { createReducer } from "deox";
import {
  AdPackConfig,
  AvailableAdPacks,
  LinkedAdPack,
} from "../../../apis/adPackConfigApi";
import { getPacksSuccessAction } from "../../actions/packActions";
import { signedOutAction } from "../../actions/authActions";

type State = {
  packs: AdPackConfig[];
  availablePacks: AvailableAdPacks[];
  linkedPacks: LinkedAdPack[];
  updatedAt: string | undefined;
};

const defaultState: State = {
  packs: [],
  availablePacks: [],
  linkedPacks: [],
  updatedAt: undefined,
};

const packsReducer = createReducer(defaultState, (handleAction) => [
  handleAction(getPacksSuccessAction, (state, action) => {
    return {
      ...action.payload,
      updatedAt: new Date().toDateString(),
    };
  }),
  handleAction(signedOutAction, (state) => defaultState),
]);

export default packsReducer;
