import React, { memo, useMemo } from "react";
import { View, ViewStyle, StyleProp, StyleSheet } from "react-native";
import WPText from "../Text/WPText";
import theme from "../../utils/theme";

const Badge: React.FC<{
  text: string;
  background: string;
  textColor: string;
  style?: StyleProp<ViewStyle>;
  isRound?: boolean;
  size?: "s" | "m";
}> = (props) => {
  const style = useMemo(() => {
    const computedStyle: StyleProp<ViewStyle> = [
      styles.container,
      {
        backgroundColor: props.background
          ? props.background
          : theme.colors.main,
      },
      props.size
        ? {
            height: theme.badge.height - 8,
            borderRadius: (theme.badge.height - 8) / 2,
          }
        : {},
    ];

    if (props.isRound) {
      computedStyle.push(styles.rounded);
      if (props.size) {
        computedStyle.push({
          width: theme.badge.height - 8,
          minWidth: theme.badge.height - 8,
        });
      }
    }

    return computedStyle;
  }, [props.isRound, props.background]);

  const textStyle = useMemo(() => {
    return [
      styles.text,
      {
        color: props.textColor ? props.textColor : theme.colors.white,
      },
      props.size
        ? {
            fontSize: 8,
          }
        : {},
    ];
  }, [props.textColor]);

  return (
    <View style={style}>
      <WPText style={textStyle}>{props.text}</WPText>
    </View>
  );
};

Badge.displayName = "Badge";

const styles = StyleSheet.create({
  container: {
    borderRadius: theme.badge.height / 2,
    height: theme.badge.height,
    minWidth: 30,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 8,
  },
  rounded: {
    width: theme.badge.height,
    paddingHorizontal: 0,
    minWidth: theme.badge.height,
  },
  text: {
    fontFamily: "bold",
    color: theme.colors.white,
    fontSize: 12,
  },
});

export default memo(Badge);
