import React, { useState, useCallback, memo } from "react";
import { TouchableOpacity, View, FlatList, StyleSheet } from "react-native";
import { InputWrapperProps } from "./InputWrapper";
import WPNotificationModal from "../../modal/modalVariants/WPNotificationModal";
import { useTranslation } from "react-i18next";
import WPButton from "../../Button/WPButton";
import theme from "../../../utils/theme";
import WPSeparator from "../../misc/WPSeparator";
import WPInput from "../WPInput";
import { hitSlop } from "../../../utils/constants";

type WPSearchWrapperProps<T> = Omit<InputWrapperProps<T>, "isFocused"> & {
  onSelected: (item: T) => void;
  searchCall: (searchTerm: string) => Promise<T[]>;
  itemRenderer: (item: T) => React.ReactElement | null;
  keyExtractor: (item: T) => string;
  noSeparator?: boolean;
};

function WPSearchWrapper<T>(props: WPSearchWrapperProps<T>) {
  const { t } = useTranslation();

  const [items, setItems] = useState<T[]>([]);

  const [isVisible, setIsVisible] = useState(false);

  const toggleModal = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);

  return (
    <>
      <TouchableOpacity hitSlop={hitSlop} onPress={toggleModal}>
        {props.children}
      </TouchableOpacity>

      <WPNotificationModal isVisible={isVisible} isFullscreen>
        <View style={styles.container}>
          <WPInput
            searchCall={props.searchCall}
            onSearchResults={setItems}
            label={t("labels.search")}
            type="search"
          />
          <FlatList
            style={styles.itemsContainer}
            renderItem={({ item }) => (
              <TouchableOpacity
                onPress={() => {
                  setIsVisible(false);
                  props.onSelected(item);
                }}
                style={props.noSeparator ? styles.itemWrapper : undefined}
              >
                <View style={{ pointerEvents: "none" }}>
                  {props.itemRenderer(item)}
                </View>
              </TouchableOpacity>
            )}
            data={items}
            keyExtractor={props.keyExtractor}
            ItemSeparatorComponent={props.noSeparator ? undefined : WPSeparator}
          />
          <WPButton onPress={toggleModal}>{t("labels.close")}</WPButton>
        </View>
      </WPNotificationModal>
    </>
  );
}

export default WPSearchWrapper;

const styles = StyleSheet.create({
  container: {
    padding: theme.input.paddingHorizontal,
    flex: 1,
  },
  itemsContainer: {
    flex: 1,
    paddingVertical: theme.input.paddingHorizontal,
  },
  itemWrapper: {
    marginBottom: 12,
  },
});
