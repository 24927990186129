import { useState } from "react";
import analytics from "../../../../services/analytics/analytics";
import {
  CreateAccountData,
  signUpCall,
} from "../../../../services/apis/authApi";
import { ErrorResponse } from "../../../../utils/wpRequest";

export const useSignUp = (): [
  boolean,
  ErrorResponse,
  (data: Omit<CreateAccountData, "id">) => Promise<void>,
  boolean
] => {
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({} as ErrorResponse);

  const signIn = async (
    accountData: Omit<Omit<CreateAccountData, "id">, "company_name"> & {
      employer_company_name: string;
    }
  ) => {
    setIsLoading(true);

    try {
      await signUpCall({
        ...accountData,
        // phone: accountData.phone || "",
        company_name: accountData.employer_company_name,
      });
      setErrors({});
      setIsSuccessful(true);

      analytics.logRegistration();
    } catch (err) {
      setErrors({ ...err, employer_company_name: err.company_name });
    }

    setIsLoading(false);
  };

  return [isLoading, errors, signIn, isSuccessful];
};
