// TODO: make an abstraction
import React, { useCallback } from "react";
import { useGetActiveJobOffers } from "../../../../services/hooks/jobHooks";
import { useTranslation } from "react-i18next";
import CRMDataScreen from "../../common/CRMDataScreen";
import { useRenderJobOfferTile } from "../jobUtils";
import { navigateTo } from "../../../navigationHelpers";
import { useNavigation } from "@react-navigation/native";
import { isMobileView } from "../../../../utils/theme";

const config = { edit: true, duplicate: true, bump: true, delete: true };

export default function ActiveJobsScreen() {
  const navigation = useNavigation();
  const getJobs = useGetActiveJobOffers();
  const { i18n, t } = useTranslation();

  const navigateToAddJob = useCallback(() => {
    navigateTo("CRM.JOB.CREATE_JOB");
  }, []);

  const renderItem = useRenderJobOfferTile({
    actionConfig: config,
    isCompactView: isMobileView(),
    hideStatistics: true,
  });

  return (
    <CRMDataScreen
      navigation={navigation}
      title={t("titles.active_job_offers")}
      getData={getJobs}
      renderItem={renderItem}
      addLabel={t("labels.add_job_offer")}
      onAdd={navigateToAddJob}
      language={i18n.language}
      showPackages
    />
  );
}
