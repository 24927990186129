import React, { useCallback } from "react";
import WPText from "../../../components/Text/WPText";
import { TFunction } from "i18next";
import theme, { isMobileView } from "../../../utils/theme";
import { StyleSheet } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { getEmployeeDisplayName } from "../../../services/store/reducers/employee/employeeReducer";
import CandidateStatus from "./components/CandidateStatus";
import { EmployeeApplications } from "../../../services/apis/employeeApi";
import CandidatesJobsTableCell from "./components/CandidatesJobsTableCell";
import CandidateTile from "./components/CandidateTile";
import DataListItem from "../../../components/misc/DataListItem";
import Badge from "../../../components/misc/Badge";
import { useTranslation } from "react-i18next";

export const useGetDesktopCandidatesItems = (
  onSelected: (employeeId: number) => void
) => (items: EmployeeApplications[]): React.ReactNode[][] => {
  return items.map((item) => [
    <TouchableOpacity onPress={() => onSelected(item.id)}>
      <WPText style={styles.title}>{getEmployeeDisplayName(item)}</WPText>
    </TouchableOpacity>,
    <CandidatesJobsTableCell {...item} />,
    item.applications
      .map((application) => application.job_company_name)
      .join(", "),
    <CandidateStatus
      status={
        item.applications.length > 1 ? undefined : item.applications[0].status
      }
      total={item.applications.length}
    />,
  ]);
};

export const useRenderMobileCandidateAppliactionsTile = (
  onSelected: (employeeId: number) => void
) => (item: EmployeeApplications) => (
  <TouchableOpacity style={styles.tile} onPress={() => onSelected(item.id)}>
    <CandidateTile {...item} />
  </TouchableOpacity>
);

export const getCandidateApplicationsHeader = (t: TFunction) => [
  t("labels.full_name"),
  t("labels.title"),
  t("labels.company_name"),
  t("labels.status"),
];

const styles = StyleSheet.create({
  title: {
    fontFamily: "bold",
    color: theme.colors.primary,
  },
  tile: {
    paddingHorizontal: theme.button.paddingHorizontal,
  },
});

export const useRenderEmployeeApplicationsTile = (
  toggleUserInfo: (userId: number) => void
) => {
  const { t } = useTranslation();
  const render = useCallback((item: EmployeeApplications) => {
    const latestApplication = item.applications[item.applications.length - 1];
    const totalApplications = item.applications.length;
    const text = `+${totalApplications - 1} ${t("labels.offer", {
      count: totalApplications - 1,
    })}`;

    return (
      <DataListItem
        isCompactView={isMobileView()}
        image={{
          uri: item.photo,
          size: "s",
        }}
        title={getEmployeeDisplayName(item)}
        onTitleClick={() => {
          toggleUserInfo(item.id);
        }}
        subtitle1={{
          text: latestApplication.job_title,
          additionalNode:
            totalApplications > 1 ? (
              <Badge
                size="s"
                text={text}
                textColor={theme.colors.white}
                background={theme.colors.mains[3]}
              />
            ) : undefined,
        }}
      />
    );
  }, []);

  return render;
};
