import React, { useState, useEffect, memo } from "react";
import {
  NavigationContainer,
  NavigationState,
  getStateFromPath,
  getPathFromState,
} from "@react-navigation/native";
import {
  navigationRef,
  saveNavigationState,
  getNavigationState,
} from "./navigationHelpers";
import RootRouter from "./RootRouter";
import { AppLoading } from "expo";
import { Platform } from "react-native";
import { logError } from "../services/logger";
import appCache from "../services/appCache";
import config from "../services/config";
import analytics from "../services/analytics/analytics";

const { TOKEN_KEY = "", REFRESH_TOKEN_KEY = "" } = config;

export const saveNavState = (state: NavigationState | undefined) => {
  if (Platform.OS === "web" && state) {
    window.history.pushState("", "", getPathFromState(state));
  }
  const screenName = navigationRef.current?.getCurrentRoute()?.name;

  if (screenName) {
    analytics.logScreenChange(screenName);
  }

  return state && saveNavigationState(state);
};
// TODO: push this to env variable;
const webOrigin =
  Platform.OS === "web" ? window.location.origin : "https://office.workport.pl";

const linking = {
  prefixes: [webOrigin, "workportbiz://"],
  enabled: true,
};

const WPRouter = () => {
  const [isReady, setIsReady] = useState(false);

  const [initialState, setInitialState] = useState<NavigationState | undefined>(
    undefined
  );

  useEffect(() => {
    async function init() {
      if (!isReady) {
        let savedState = await getNavigationState();

        if (Platform.OS === "web") {
          if (window.location.search.includes("?token=")) {
            const refreshToken = window.location.search.replace("?token=", "");

            await appCache.setValue(TOKEN_KEY, "");
            await appCache.setValue(REFRESH_TOKEN_KEY, refreshToken);
          }

          try {
            const lastUrl = savedState ? getPathFromState(savedState) : "";
            const newUrl = window.location.href.replace(
              window.location.origin,
              ""
            );

            if (lastUrl.indexOf(newUrl) === -1) {
              const stateFromUrl = getStateFromPath(newUrl);

              if (stateFromUrl) {
                //@ts-ignore
                savedState = stateFromUrl;
              }
            }
          } catch (err) {
            logError(
              `Failed to parse state from path: ${window.location.pathname}`
            );
          }
        }

        setInitialState(savedState);
        setIsReady(true);
      }
    }

    init();
  }, [isReady]);

  if (!isReady) {
    return <AppLoading />;
  }

  return (
    <NavigationContainer
      ref={navigationRef}
      onStateChange={saveNavState}
      initialState={initialState}
      linking={linking}
    >
      <RootRouter />
    </NavigationContainer>
  );
};

export default memo(WPRouter);
