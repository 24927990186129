import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const CalendarIcon: React.FC<IconProps> = (props) => {
  let height = 20;
  let width = 20;
  const ratio = width / height;
  const color = props.color || "#637381";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M14.808 2c.377 0 .688.283.738.65l.006.102v.203h.22c1.23 0 2.228 1.008 2.228 2.251V15.75c0 1.243-.998 2.25-2.228 2.25H4.238a2.239 2.239 0 0 1-2.228-2.25v-8.86a.765.765 0 0 1 0-.235V5.206c0-1.243.997-2.25 2.228-2.25l.603-.001v-.203c0-.38.28-.696.644-.745l.1-.007c.377 0 .689.283.738.65l.007.102v.203h7.733l.001-.203c0-.38.28-.696.643-.745L14.808 2zm1.703 5.523H3.498v8.226c0 .413.331.747.74.747h11.534c.409 0 .74-.335.74-.747V7.523zM6.003 13.32a.778.778 0 1 1 0 1.557.778.778 0 0 1 0-1.557zm4.076 0a.778.778 0 1 1 0 1.557.778.778 0 0 1 0-1.557zM6.003 9.424a.778.778 0 1 1 0 1.556.778.778 0 0 1 0-1.556zm4.076 0a.778.778 0 1 1 0 1.556.778.778 0 0 1 0-1.556zm4.075 0a.778.778 0 1 1 0 1.556.778.778 0 0 1 0-1.556zM4.84 4.459h-.603a.743.743 0 0 0-.74.747v.813h13.013v-.813a.743.743 0 0 0-.739-.747h-.22v.54a.75.75 0 0 1-.643.746l-.1.007a.747.747 0 0 1-.738-.65L14.064 5v-.541H6.328v.54a.75.75 0 0 1-.642.746l-.101.007a.747.747 0 0 1-.738-.65L4.841 5v-.541z"
      />
    </Svg>
  );
};

CalendarIcon.displayName = "CalendarIcon";

export default CalendarIcon;
