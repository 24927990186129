import { createReducer } from "deox";
import { gotJobCandidatesApplications } from "../../actions/jobActions";

type jobCandidatesState = {
  total: number;
};

const defaultState: jobCandidatesState = {
  total: 0,
};

const jobCandidatesReducer = createReducer(defaultState, (handleAction) => [
  handleAction(gotJobCandidatesApplications, (state, action) => {
    return {
      ...state,
      total: action.payload.total,
    };
  }),
]);

export default jobCandidatesReducer;
