import React from "react";
import { IconProps } from "../WPIcon";
import { ActivityIndicator } from "react-native";

const LoadingIcon: React.FC<IconProps> = (props) => {
  // let height = 30;
  // let width = 120;
  // const ratio = width / height;
  // const color = props.color || "#777";

  // if (props.size) {
  //   height = props.size;
  //   width = ratio * props.size;
  // }

  return <ActivityIndicator color={props.color} />;
};

LoadingIcon.displayName = "LoadingIcon";

export default LoadingIcon;
