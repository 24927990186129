import React, { useState, useRef, useCallback } from "react";
import {
  TextInput,
  StyleSheet,
  TouchableOpacity,
  StyleProp,
  Platform,
  TextStyle,
  View,
} from "react-native";
import InputWrapper, { InputWrapperProps } from "./InputWrapper";
import theme, { getThemeResponsiveValue } from "../../../utils/theme";
import { WPBaseInputProps } from "../WPInput";

const autocompleteType = [
  "username",
  "password",
  "email",
  "name",
  "tel",
  "street-address",
  "postal-code",
  "off",
];

export type WPTextInputProps = Omit<InputWrapperProps<string>, "isFocused"> &
  WPBaseInputProps<string> & {
    numberOfLines?: number;
    autocompleteType?:
      | "username"
      | "password"
      | "email"
      | "name"
      | "tel"
      | "street-address"
      | "postal-code"
      | "off";

    onChange?: (value: string) => void;
  };

function getTextStyle(props: WPTextInputProps): StyleProp<TextStyle> {
  if (Platform.OS !== "web" && props.numberOfLines) {
    const fontSize = getThemeResponsiveValue(theme.input.fontSize);
    const height = props.numberOfLines * fontSize * 1.2;
    const heightStyle = { height };
    return [styles.input, heightStyle];
  }

  return [styles.input];
}

const WPTextInput: React.FC<WPTextInputProps> = (props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [textStyle, setTextStyle] = useState<StyleProp<TextStyle>>([
    props.numberOfLines
      ? [getTextStyle(props), { marginVertical: 8 }]
      : getTextStyle(props),
  ]);

  const inputRef = useRef<TextInput>(null);

  const value =
    props.value !== undefined && props.value !== null
      ? props.value.toString()
      : props.value;

  const normalizedAutocompleteType =
    props.autocompleteType &&
    autocompleteType.indexOf(props.autocompleteType) !== -1
      ? props.autocompleteType
      : "off" || "off";

  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, [setIsFocused]);
  const onFocus = useCallback(() => {
    setIsFocused(true);
  }, [setIsFocused]);

  const onFocusWithRef = useCallback(() => {
    onFocus();
    inputRef.current?.focus();
  }, [inputRef.current, onFocus]);

  return (
    <View>
      <InputWrapper {...props} isFocused={isFocused}>
        <TouchableOpacity
          disabled={props.disabled}
          onFocus={onFocusWithRef}
          onPress={onFocusWithRef}
          onBlur={onBlur}
        >
          <TextInput
            editable={!props.disabled}
            value={value}
            style={textStyle}
            ref={inputRef}
            autoCompleteType={normalizedAutocompleteType}
            secureTextEntry={props.autocompleteType === "password"}
            onChangeText={props.onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            numberOfLines={props.numberOfLines}
            multiline={props.numberOfLines !== undefined}
          />
        </TouchableOpacity>
      </InputWrapper>
    </View>
  );
};

const styles = StyleSheet.create({
  input: {
    fontSize: getThemeResponsiveValue(theme.input.fontSize),
    color: theme.colors.main,
    paddingVertical: 4,
  },
});

export default WPTextInput;
