// import * as FirebaseCore from "expo-firebase-core";

export const firebaseConfig = {
  apiKey: "AIzaSyCSnXHJqIPov8aDWnhmrCQEob2gsSbcgcU",
  authDomain: "workport-simple-chat.firebaseapp.com",
  databaseURL: "https://workport-simple-chat.firebaseio.com",
  projectId: "workport-simple-chat",
  storageBucket: "workport-simple-chat.appspot.com",
  messagingSenderId: "619715385388",
  appId: "1:619715385388:web:986032c730f8ae53",
  measurementId: "G-VV52VZ6SF5",
};
// export const firebaseConfig = FirebaseCore.DEFAULT_WEB_APP_OPTIONS;

export const notificationVapidKey =
  "BDfldJ794KkyRWZ4OR_pff23UB41M314RGwZZrH8aPGXP26ofvOlrbIcB9-6Aa1kWuY5PFSYUb4Rp6UMWI2BjLQ";
