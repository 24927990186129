import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const CheckboxIcon: React.FC<IconProps> = (props) => {
  let height = 16;
  let width = 16;
  const ratio = width / height;
  const color = props.color || "#919EAB";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg
      {...props}
      width={height}
      height={height}
      style={[{ height, width }, props.style]}
      viewBox={`0 0 16 16`}
    >
      <Path
        fill={color}
        d="M12 0a4 4 0 014 4v8a4 4 0 01-4 4H4a4 4 0 01-4-4V4a4 4 0 014-4h8zm0 1H4a3 3 0 00-2.995 2.824L1 4v8a3 3 0 002.824 2.995L4 15h8a3 3 0 002.995-2.824L15 12V4a3 3 0 00-2.824-2.995L12 1z"
      />
    </Svg>
  );
};

CheckboxIcon.displayName = "CheckboxIcon";

export default CheckboxIcon;
