import {
  Invoice,
  getPaymentConfig,
  PaymentLinkConfig,
  checkTransactionStatus,
  Transaction,
  getTransactionsCall,
  connectPackToOfferApi,
  getLinkedAdPacks,
} from "../apis/adPackConfigApi";
import React, { useState, useCallback, useMemo } from "react";
import { openAuthSessionAsync } from "expo-web-browser";
import { View, StyleSheet } from "react-native";
import theme from "../../utils/theme";
import { logError } from "../logger";
import WPText from "../../components/Text/WPText";
import WPButton from "../../components/Button/WPButton";
import { useTranslation } from "react-i18next";
import { useGetPacksByType, useUpdateLinkedPacks } from "./packsHooks";
import { saveToLibrary } from "../../utils/fileUtils";
import { getNormalizedDate } from "../../utils/mixUtils";
import {
  BillType,
  PaymentType,
} from "../../navigation/crm/job/screens/PaymentsScreen";
import { navigateTo } from "../../navigation/navigationHelpers";
import { useDispatch, useSelector } from "react-redux";
import { scheduleListUpdate } from "../store/actions/chatActions";
import { RootState } from "../store/reducers/mainReducer";
import { getPacksSuccessAction } from "../store/actions/packActions";
import { bumpJobOffer } from "../apis/jobApi";
// import Constants from "expo-constants";
import config from "../config";
import analytics from "../analytics/analytics";

// const { API_V2 = "" } = Constants.manifest.extra;
const { API_V2 = "" } = config;

export enum TransactionStatus {
  Pending = "PENDING",
  Declined = "DECLINED",
  Successful = "SUCCESS",
  Error = "ERROR",
}

export const useBumpJobOffer = () => {
  const packs = useSelector((state: RootState) => state.packs);
  const dispatch = useDispatch();

  const bumpJobOfferFunc = useCallback(
    async (jobId: number, onDone: () => void) => {
      const upPack = packs.packs.find((pack) => pack.name === "up_1");
      const linkedPack = packs.linkedPacks.find(
        (pack) => pack.offer_id === jobId
      );

      if (linkedPack?.unsued_actions?.up) {
        await bumpJobOffer(jobId).then(onDone).catch(onDone);

        const linkedPacks = await getLinkedAdPacks();

        analytics.logBump();

        dispatch(scheduleListUpdate());
        dispatch(
          getPacksSuccessAction({
            ...packs,
            linkedPacks,
          })
        );
      } else {
        navigateTo(
          `CRM.JOB.PAYMENTS?packId=${upPack?.id}&packValue=1&paymentType=${PaymentType.job}&offerId=${jobId}`
        );
      }
    },
    [dispatch, packs]
  );

  return bumpJobOfferFunc;
};

export const useConnectPackToOffer = () => {
  const packs = useSelector((state: RootState) => state.packs);
  const dispatch = useDispatch();

  const connectPackToOffer = useCallback(
    async (data: { offer_id: number; packet_config_id: number }) => {
      await connectPackToOfferApi({
        packet_config_id: Number(data.packet_config_id),
        offer_id: Number(data.offer_id),
      });

      const linkedPacks = await getLinkedAdPacks();

      dispatch(scheduleListUpdate());
      dispatch(
        getPacksSuccessAction({
          ...packs,
          linkedPacks,
        })
      );

      navigateTo("CRM.JOB.ACTIVE_JOB_LIST");
    },
    [dispatch, packs]
  );

  return connectPackToOffer;
};

export const usePayment = (): [
  boolean,
  (paymentData: {
    packId: number;
    packOption: number;
    invoice: Omit<Invoice, "employer_company_name"> & {
      company_name: string;
    };
    link: { offers: number[] };
    promo_code?: string;
  }) => Promise<TransactionStatus>
] => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentConfig, setPaymentConfig] = useState<PaymentLinkConfig>({
    payment_link: "",
    transaction_id: "",
  });
  const updateLinkedPacks = useUpdateLinkedPacks();

  const getPaymentConfigDecorated = useCallback(
    async (paymentData: {
      packId: number;
      packOption: number;
      invoice: Omit<Invoice, "employer_company_name"> & {
        company_name: string;
      };
      link: { offers: number[] };
      promo_code?: string;
    }) => {
      setIsLoading(true);
      const paymentConfigData = await getPaymentConfig(paymentData);

      if ("transaction_status" in paymentConfig) {
        setIsLoading(false);
        return TransactionStatus.Successful;
      }

      setPaymentConfig(paymentConfigData);

      await openAuthSessionAsync(paymentConfigData.payment_link, "");

      const transactionStatus = await checkTransactionStatus(
        Number(paymentConfigData.transaction_id)
      );

      function getEndStatus(): Promise<TransactionStatus> {
        return new Promise<TransactionStatus>((resolve, reject) => {
          setTimeout(() => {
            checkTransactionStatus(Number(paymentConfigData.transaction_id))
              .then((res) => resolve(res.status))
              .catch(reject);
          }, 2000);
        }).then((res) => {
          if (transactionStatus.status === TransactionStatus.Pending) {
            return getEndStatus();
          }

          analytics.logPurchase(1);

          return res;
        });
      }

      dispatch(scheduleListUpdate());

      if (transactionStatus.status === TransactionStatus.Pending) {
        const updatedTransactionStatus = await getEndStatus();

        setIsLoading(false);

        return updatedTransactionStatus as TransactionStatus;
      }

      setIsLoading(false);
      updateLinkedPacks();

      return transactionStatus.status;
    },
    [setIsLoading, dispatch, updateLinkedPacks]
  );

  return [isLoading, getPaymentConfigDecorated];
};

export const useGetTransactions = () => {
  return () =>
    getTransactionsCall()
      .then((res) => ({
        results: res,
        count: res.length,
        total: res.length,
        page_count: res.length,
        page_num: res.length,
        page_size: res.length,
      }))
      .catch((err) => {
        logError(err);
        return {
          results: [],
          count: 0,
          total: 0,
          page_count: 0,
          page_num: 0,
          page_size: 0,
        };
      });
};

export const useSaveInvoice = () => {
  return useCallback((invoiceId: string | number) => {
    return saveToLibrary(
      `${API_V2}packets/info/invoice/${invoiceId}`,
      `invoice_${invoiceId}.pdf`
    );
  }, []);
};

export const useRenderPaymentCard = (isCompact: boolean) => {
  const availablePackages = useGetPacksByType();
  const { t, i18n } = useTranslation();
  const containerStyle = useMemo(
    () =>
      isCompact
        ? [styles.container, styles.containerCompact]
        : styles.container,
    [isCompact]
  );

  const saveInvoice = useSaveInvoice();

  const renderPaymentCard = useCallback(
    (transaction: Transaction) => {
      const targetPack = availablePackages.find(
        (pack) => pack.id === transaction.product_config_id
      );

      const title = `${targetPack?.name} x${transaction.product_count}`;
      const price = `${transaction.amount.toFixed(2)} zł`;
      const date = getNormalizedDate(
        transaction.date_create.replace(/\s.+/, ""),
        i18n.language
      );

      if (isCompact) {
        return (
          <View style={containerStyle}>
            <View style={styles.containerFirstRow}>
              <WPText style={styles.bold}>{title}</WPText>
              <WPText style={styles.bold}>{price}</WPText>
            </View>
            <WPText style={styles.desc}>
              {`${date}, Transaction ID: ${transaction.id}`}
            </WPText>
            {transaction.invoice.paymentForm === BillType.firm ? (
              <WPButton
                size="small"
                onPress={() => saveInvoice(transaction.id)}
              >
                {t("labels.get_pay_slip")}
              </WPButton>
            ) : null}
          </View>
        );
      }

      return (
        <View style={containerStyle}>
          <WPText style={{ flex: 1 }}>{date}</WPText>
          <WPText style={{ flex: 1 }}>{transaction.id}</WPText>
          <WPText style={[styles.bold, { flex: 1 }]}>{title}</WPText>
          <WPText style={[styles.bold, { flex: 1 }]}>{price}</WPText>

          <View style={{ flex: 1 }}>
            {transaction.invoice.paymentForm === BillType.firm ? (
              <WPButton
                size="small"
                onPress={() => saveInvoice(transaction.id)}
              >
                {t("labels.get_pay_slip")}
              </WPButton>
            ) : null}
          </View>
        </View>
      );
    },
    [t, availablePackages, i18n]
  );

  return renderPaymentCard;
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    flexDirection: "row",
    borderRadius: theme.button.borderRadius,
    backgroundColor: theme.colors.mains[6],
    padding: 24,
    justifyContent: "space-between",
  },
  containerCompact: {
    alignItems: "stretch",
    flexDirection: "column",
    padding: 20,
  },
  containerFirstRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 18,
  },
  desc: {
    marginBottom: 24,
  },
  bold: {
    fontFamily: "bold",
  },
});
