import React from "react";
import { RouterType } from "../navigationConstants";
import CRMDrawerContent from "./common/CRMDrawerContent";
import { withAuth, RouterConfig, buildRouter } from "../navigationHelpers";
import JobRouter from "./job/JobRouter";

const JOB = "JOB";

const CrmRouterConfig: RouterConfig = {
  type: RouterType.drawer,
  containerConfig: {
    initialRouteName: JOB,
    drawerType: "front",
    hideStatusBar: false,
    drawerContent: () => <CRMDrawerContent isVisible />,
  },
  screens: {
    [JOB]: {
      name: JOB,
      component: JobRouter,
    },
  },
};

export default withAuth(buildRouter(CrmRouterConfig));
