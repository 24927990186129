import { createActionCreator } from "deox";
import { Employee, Document } from "../reducers/employee/employeeReducer";

export const receivedEmployees = createActionCreator(
  "RECEIVED_EMPLOYEES",
  (resolve) => (employees: Employee[]) => resolve(employees)
);

export const receivedDocuments = createActionCreator(
  "RECEIVED_DOCUMENTS",
  (resolve) => (data: { employeeId: number; documents: Document[] }) =>
    resolve(data)
);
