import React, { useState } from "react";
import InputWrapper, { InputWrapperProps } from "./InputWrapper";
import DropDownWrapper from "../../misc/DropDownWrapper";
import { WPListProps } from "../../list/WPList";
import ValueComponent from "./misc/ValueCustom";
import WPIcon from "../../Icon/WPIcon";
import { StyleSheet } from "react-native";

export type WPDropDownSelectProps<T> = Omit<InputWrapperProps<T>, "isFocused"> &
  WPListProps<T> & {};

function WPDropDownSelect<T>(props: WPDropDownSelectProps<T>) {
  const [isFocused, setIsFocused] = useState(false);

  const selectedItem = props.items.find((item) => item.value === props.value);
  const value =
    selectedItem && !props.customRenderItem ? selectedItem.label : props.value;

  return (
    <DropDownWrapper
      disabled={props.disabled}
      isFocused={isFocused}
      setIsFocused={setIsFocused}
      items={props.items}
      onChange={props.onChange}
      customRenderItem={props.customRenderItem}
    >
      <InputWrapper
        {...props}
        isFocused={isFocused}
        append={
          <WPIcon
            name="chevron"
            style={isFocused ? styles.itemSelected : undefined}
          />
        }
      >
        <ValueComponent
          value={value as T}
          customRenderItem={props.customRenderItem}
        />
      </InputWrapper>
    </DropDownWrapper>
  );
}

export default WPDropDownSelect;

const styles = StyleSheet.create({
  itemSelected: {
    transform: [{ rotate: "180deg" }],
  },
});
