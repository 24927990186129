import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { View, StyleSheet } from "react-native";
import WPText from "../../../../components/Text/WPText";
import theme from "../../../../utils/theme";
import Badge from "../../../../components/misc/Badge";
import { EmployeeApplications } from "../../../../services/apis/employeeApi";

function CandidatesJobsTableCell(props: EmployeeApplications) {
  const { t } = useTranslation();
  const latestApplication = props.applications[props.applications.length - 1];
  const totalApplications = props.applications.length;
  const text = `+${totalApplications - 1} ${t("labels.offer", {
    count: totalApplications - 1,
  })}`;

  return (
    <View style={styles.container}>
      <WPText numberOfLines={1}>{latestApplication.job_title}</WPText>
      {totalApplications > 1 ? (
        <Badge
          text={text}
          textColor={theme.colors.mains[1]}
          background={theme.colors.warnings[1]}
        />
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  container: { flexDirection: "row", justifyContent: "space-between" },
});

export default memo(CandidatesJobsTableCell);
