import React from "react";
import Svg, { Path } from "../SVG";
import { IconProps } from "../WPIcon";

const ChevronIcon: React.FC<IconProps> = (props) => {
  let height = 20;
  let width = 20;
  const ratio = width / height;
  const color = props.color || "#637381";

  if (props.size) {
    height = props.size;
    width = ratio * props.size;
  }

  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" {...props}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M6.707 8.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L10 11.586 6.707 8.293z"
      />
    </Svg>
  );
};

ChevronIcon.displayName = "ChevronIcon";

export default ChevronIcon;
