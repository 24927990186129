import * as React from "react";
import { defaultCRMScreenOptions, RouterType } from "../../navigationConstants";
import {
  RouterConfig,
  buildRouter,
  withScreenWrapper,
} from "../../navigationHelpers";
import BackButton from "../../common/BackButton";
import EstateListScreen from "./screens/EstateListScreen";
import EstateEditScreen from "./screens/EstateEditScreen";
import EstateCreateScreen from "./screens/EstateCreateScreen";
import EstateDuplicateScreen from "./screens/EstateDuplicateScreen";

const ESTATE_LIST = "ESTATE_LIST";
const ESTATE_EDIT = "ESTATE_EDIT";
const ESTATE_DUPLICATE = "ESTATE_DUPLICATE";
const CREATE_ESTATE = "CREATE_ESTATE";

const editorOptions = () => ({
  ...defaultCRMScreenOptions,
  headerLeft: () => <BackButton fallBackRoute={ESTATE_LIST} />,
});

export const EstateRouterConfig: RouterConfig = {
  type: RouterType.stack,
  containerConfig: {
    headerMode: "float",
    initialRouteName: ESTATE_LIST,
    screenOptions: defaultCRMScreenOptions,
  },
  screens: {
    [ESTATE_LIST]: {
      name: ESTATE_LIST,
      component: withScreenWrapper(() => <EstateListScreen />),
    },

    [ESTATE_EDIT]: {
      name: ESTATE_EDIT,
      component: withScreenWrapper(
        (props) => <EstateEditScreen {...props} />,
        true
      ),
      options: editorOptions,
    },
    [ESTATE_DUPLICATE]: {
      name: ESTATE_DUPLICATE,
      component: withScreenWrapper(
        (props) => <EstateDuplicateScreen {...props} />,
        true
      ),
      options: editorOptions,
    },
    [CREATE_ESTATE]: {
      name: CREATE_ESTATE,
      component: withScreenWrapper(EstateCreateScreen, true),
      options: editorOptions,
    },
  },
};

export type EstateRouterParams = {
  [ESTATE_LIST]: undefined;
  [ESTATE_EDIT]: {
    estateId: string;
  };
  [ESTATE_DUPLICATE]: {
    estateId: string;
  };
  [CREATE_ESTATE]: undefined;
};

export default buildRouter(EstateRouterConfig);
