import React, { useCallback } from "react";
import {
  useEmployeesDocument,
  useBaseEmployeeInfo,
} from "../../services/hooks/employeeHooks";
import { View, StyleSheet } from "react-native";
import WPIcon from "../Icon/WPIcon";
import WPButton from "../Button/WPButton";
import WPText from "../Text/WPText";
import theme from "../../utils/theme";
import { useTranslation } from "react-i18next";
import { getEmployeeAllDocumentsLink } from "../../services/apis/employeeApi";
import { saveToLibrary } from "../../utils/fileUtils";
import { getEmployeeDisplayName } from "../../services/store/reducers/employee/employeeReducer";
import DocumentTile from "./DocumentTile";
import WPImagePreviewWrapper from "./WPImagePreviewWrapper";
import NoDataState from "./NoDataState";

export default function DocumentsUserInfoPreview(props: { userId: number }) {
  const [isLoading, documents] = useEmployeesDocument(props.userId);
  const allDocumentLink = getEmployeeAllDocumentsLink(props.userId);
  const userInfo = useBaseEmployeeInfo(props.userId);
  const { t } = useTranslation();

  let userName = userInfo ? getEmployeeDisplayName(userInfo) : "?";

  const downloadAll = useCallback(() => {
    saveToLibrary(allDocumentLink, userName + ".zip");
  }, [userName, allDocumentLink]);

  return (
    <View>
      {documents.length === 0 ? (
        <NoDataState isLoading={isLoading} />
      ) : (
        <>
          <View style={style.downloadAllContainer}>
            <WPButton
              appearance="link"
              prepend={<WPIcon name="download" color={theme.colors.primary} />}
              onPress={downloadAll}
            >
              <WPText style={style.downloadAll}>
                {t("labels.download_all")}
              </WPText>
            </WPButton>
          </View>
          {documents.map((doc) => (
            <WPImagePreviewWrapper
              key={doc.file}
              imageUri={doc.file}
              imageName={userName}
              style={style.imageWrapper}
            >
              <DocumentTile {...doc} />
            </WPImagePreviewWrapper>
          ))}
        </>
      )}
    </View>
  );
}

const style = StyleSheet.create({
  downloadAllContainer: { alignItems: "flex-end" },
  downloadAll: {
    color: theme.colors.primary,
    fontFamily: "bold",
  },
  imageWrapper: {
    marginBottom: theme.input.paddingHorizontal,
  },
});
