import React from "react";
import { TouchableOpacity, StyleSheet } from "react-native";
import { openBrowserAsync } from "expo-web-browser";
import WPText from "../Text/WPText";

type WPWebLinkProps = {
  link: string;
  text: string;
};

export default function WPWebLink(props: WPWebLinkProps) {
  return (
    <TouchableOpacity
      onPress={() => {
        openBrowserAsync(props.link);
      }}
    >
      <WPText style={styles.link}>{props.text}</WPText>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  link: {
    fontSize: 12,
    textDecorationLine: "underline",
  },
});
