import React, { ReactNode, Component, useCallback } from "react";
import {
  FlatList,
  View,
  StyleSheet,
  TouchableOpacity,
  Alert,
} from "react-native";
import {
  ListCall,
  defaultPagination,
  PaginationConfig,
} from "../../../services/apis/jobApi";
import { WithTranslation, useTranslation } from "react-i18next";
import theme, {
  isMobileView,
  getThemeResponsiveValue,
} from "../../../utils/theme";
import { getUniqEntities } from "../../../utils/mixUtils";
import WPText from "../../../components/Text/WPText";
import WPButton from "../../../components/Button/WPButton";
import WPIcon from "../../../components/Icon/WPIcon";
import { logError } from "../../../services/logger";
import NoDataState from "../../../components/misc/NoDataState";
import { NavigationProp } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import { dismissListUpdate } from "../../../services/store/actions/chatActions";
import WPSeparator from "../../../components/misc/WPSeparator";
import { useGetAvailablePacks } from "../../../services/hooks/packsHooks";
import { hitSlop } from "../../../utils/constants";
import { navigateTo } from "../../navigationHelpers";
import { RootState } from "../../../services/store/reducers/mainReducer";
import { AdPackType } from "../../../services/apis/adPackConfigApi";

function isAdd(
  props: CRMDataGeneralProps<any> | CRMDataPropsWithAdd<any>
): props is CRMDataPropsWithAdd<any> {
  return "addLabel" in props;
}

type CRMDataGeneralProps<T> = {
  isUpdateScheduled: boolean;
  language: string;
  isFocused: boolean;
  title: string | ReactNode;
  subTitle?: ReactNode;
  getData: ListCall<T>;
  headerItems: string[];
  initialFilter?: object;
  navigation: NavigationProp<any>;
  renderItem: (item: T, refresh: () => void) => React.ReactElement | null;
  showPackages?: boolean;
};

type CRMDataPropsWithAdd<T extends { id: number }> = CRMDataGeneralProps<T> & {
  addLabel: string;
  onAdd: () => void;
};

type State<T extends { id: number }> = {
  isLoading: boolean;
  items: Array<T>;
  pagination: PaginationConfig;
};

type CombinedCRMProps<T> = CRMDataPropsWithAdd<T> &
  WithTranslation & {
    dismissListUpdate: () => void;
    availablePacks: { name: string; amount: number }[];
  };

class CRMDataScreen<T extends { id: number }> extends Component<
  CombinedCRMProps<T>,
  State<T>
> {
  state = {
    isLoading: false,
    pagination: {
      ...defaultPagination,
      page_size: 6,
    },
    items: [] as Array<T>,
  };

  componentDidMount() {
    this.updateData(this.state.pagination);
  }

  componentDidUpdate(prevProps: CRMDataPropsWithAdd<T>) {
    if (
      prevProps.isUpdateScheduled !== this.props.isUpdateScheduled &&
      this.props.isUpdateScheduled
    ) {
      this.refresh();
    }
  }

  shouldComponentUpdate(nextProps: CombinedCRMProps<T>, nextState: State<T>) {
    if (
      this.state.items !== nextState.items ||
      this.props.language !== nextProps.language ||
      this.state.isLoading !== nextState.isLoading ||
      this.props.isUpdateScheduled !== nextProps.isUpdateScheduled ||
      this.props.availablePacks !== nextProps.availablePacks
    ) {
      return true;
    }

    return false;
  }

  updateData = (paginationConfig: PaginationConfig, isRefresh?: boolean) => {
    this.setState({ ...this.state, isLoading: true });

    return this.props
      .getData({
        filter: { ...(this.props.initialFilter || {}) },
        pagination: paginationConfig,
      })
      .then((res) => {
        const newItems = res.results;
        const nextItems = isRefresh
          ? [...newItems]
          : ([...this.state.items, ...newItems] as T[]);
        const uniqItems = getUniqEntities(nextItems);

        this.props.dismissListUpdate();

        this.setState({
          ...this.state,
          items: uniqItems,
          isLoading: false,
          pagination: isRefresh
            ? this.state.pagination
            : {
                ...paginationConfig,
                total: res.total,
                page_count: res.page_count,
              },
        });
      })
      .catch((err) => {
        logError(err);
        this.setState({ ...this.state, isLoading: false });
      });
  };

  refresh = () => {
    this.updateData(
      {
        ...this.state.pagination,
        page_num: 1,
        page_size:
          this.state.pagination.page_num * this.state.pagination.page_size,
      },
      true
    );
  };

  getNextPageData = () => {
    const nextPageNum = this.state.pagination.page_num + 1;
    if (this.state.pagination.page_count >= nextPageNum) {
      this.updateData({
        ...this.state.pagination,
        page_num: nextPageNum,
      });
    }
  };

  getTitle = () => (
    <View style={styles.titleContainer}>
      <View>
        {typeof this.props.title === "string" ? (
          <WPText style={styles.title}>{this.props.title}</WPText>
        ) : (
          this.props.title
        )}
        {!this.props.subTitle ? null : (
          <View style={styles.subTitle}>
            {typeof this.props.subTitle === "string" ? (
              <WPText>{this.props.subTitle}</WPText>
            ) : (
              this.props.subTitle
            )}
          </View>
        )}
        {isAdd(this.props) && !isMobileView() ? (
          <View style={styles.button}>
            <WPButton
              onPress={this.props.onAdd}
              size={isMobileView() ? "small" : "regular"}
            >
              {this.props.addLabel}
            </WPButton>
          </View>
        ) : null}
      </View>

      {this.props.showPackages ? (
        <>
          {isMobileView() ? <WPSeparator /> : null}
          <View style={styles.packContainer}>
            <View>
              <WPText style={styles.packsTitle}>
                {this.props.t("titles.available_packages")}
              </WPText>

              <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                {this.props.availablePacks &&
                  this.props.availablePacks.map((packData) => (
                    <View style={{ flexDirection: "row", flexWrap: "nowrap" }}>
                      <WPText key={packData.name}>
                        {`${packData.name.toLowerCase()}: `}
                        <WPText
                          style={styles.packValue}
                        >{`${packData.amount}    `}</WPText>
                      </WPText>
                    </View>
                  ))}
              </View>
            </View>
            <TouchableOpacity
              hitSlop={hitSlop}
              onPress={() => navigateTo(`CRM.JOB.JOB_PACKAGE?`)}
            >
              <WPText style={styles.packBuyButton}>
                {this.props.t("labels.buy")}
              </WPText>
            </TouchableOpacity>
          </View>
        </>
      ) : null}
    </View>
  );

  keyExtractor = (item: any) => item.id.toString();

  renderItem = (rowData: { item: T }) => (
    <View style={styles.item}>
      {this.props.renderItem(rowData.item, this.refresh)}
    </View>
  );

  render() {
    return (
      <View style={styles.container}>
        <FlatList
          onRefresh={this.refresh}
          keyExtractor={this.keyExtractor}
          data={this.state.items}
          refreshing={this.state.isLoading}
          ListHeaderComponent={this.getTitle()}
          onEndReached={this.getNextPageData}
          renderItem={this.renderItem}
          ListEmptyComponent={<NoDataState isLoading={false} />}
          ItemSeparatorComponent={() => <View style={styles.separator} />}
          contentContainerStyle={styles.content}
        />

        {isAdd(this.props) && isMobileView() ? (
          <TouchableOpacity onPress={this.props.onAdd} style={styles.addButton}>
            <WPIcon name="plus" color={theme.colors.white} />
          </TouchableOpacity>
        ) : null}
      </View>
    );
  }
}

export default function CRMDataScreenWithPacks<T>(
  props: CRMDataGeneralProps<T>
) {
  const isUpdateScheduled = useSelector(
    (state: RootState) => state.ui.shouldUpdateList
  );
  const translationProps = useTranslation();
  const dispatch = useDispatch();
  const dismissListUpdateCall = useCallback(
    () => dispatch(dismissListUpdate()),
    [dispatch]
  );
  const availablePacks = useGetAvailablePacks(AdPackType.job_packet);

  return (
    <CRMDataScreen
      {...props}
      {...translationProps}
      dismissListUpdate={dismissListUpdateCall}
      availablePacks={availablePacks}
      isUpdateScheduled={isUpdateScheduled}
    />
  );
}

const addButtonSize = getThemeResponsiveValue(theme.button.size.big);

const styles = StyleSheet.create({
  separator: { height: 16 },
  container: { flex: 1, overflow: "visible" },
  content: {},
  titleContainer: {
    flex: 1,
    justifyContent: "space-between",
    backgroundColor: theme.colors.white,
    marginTop: isMobileView() ? 0 : 12,
    marginBottom: 32,
    paddingBottom: 20,
    paddingTop: 12,
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 10,
    paddingHorizontal: 16,
    flexDirection: isMobileView() ? "column" : "row",
  },
  title: {
    fontFamily: "bold",
    fontSize: 28,
  },
  subTitle: {
    marginTop: 8,
  },
  button: {
    marginTop: 16,
    alignItems: "flex-start",
  },
  item: {
    marginHorizontal: 16,
  },
  addButton: {
    position: "absolute",
    right: 20,
    bottom: 20,
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 4,
    backgroundColor: theme.colors.primary,
    height: addButtonSize,
    width: addButtonSize,
    borderRadius: addButtonSize / 2,
    alignItems: "center",
    justifyContent: "center",
  },
  packContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  packsTitle: {
    fontFamily: "bold",
    marginBottom: 12,
  },
  packValue: {
    fontFamily: "bold",
  },
  packBuyButton: {
    marginTop: 20,
    color: theme.colors.primary,
  },
});
