import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/reducers/mainReducer";
import { useEffect, useState, useCallback } from "react";
import {
  getEmployeeDocumentsCall,
  getEmployeeNotesCall,
  addEmployeeNoteCall,
  getEmployeeApplications,
  getEmployeeInfoCall,
} from "../apis/employeeApi";
import {
  receivedDocuments,
  receivedEmployees,
} from "../store/actions/employeeActions";
import { Document, Note } from "../store/reducers/employee/employeeReducer";
import { EntityListCallConfig } from "../apis/jobApi";
import { gotJobCandidatesApplications } from "../store/actions/jobActions";

// const REFRESH_GAP = 2 * 60 * 60 * 1000; // 2 hours;
const REFRESH_GAP = 60 * 1000; // 1 minute;

export const useBaseEmployeeInfo = (
  employeeId: number,
  withUpdate?: boolean
) => {
  const dispatch = useDispatch();

  const userInfo = useSelector(
    (state: RootState) => state.employee[employeeId]
  );

  if (!userInfo) {
    return undefined;
  }

  const refreshDelta =
    Number(new Date()) - Number(new Date(userInfo.lastRefreshed));

  if (refreshDelta > REFRESH_GAP && !userInfo.isLoading && withUpdate) {
    getEmployeeInfoCall(employeeId)
      .then((res) => {
        if (res) dispatch(receivedEmployees([res]));
      })
      .catch(console.warn);
  }

  return userInfo.baseData;
};

export const useGetUserNotes = (
  userId: number
): [boolean, Note[], (note: string) => void] => {
  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState<Note[]>([]);

  function addNote(note: string) {
    addEmployeeNoteCall(userId, note).then(getNotes, getNotes);
  }

  function getNotes() {
    setIsLoading(true);
    getEmployeeNotesCall(userId)
      .then(setNotes)
      .catch((err) => {})
      .finally(() => setIsLoading(false));
  }

  useEffect(getNotes, []);

  return [isLoading, notes, addNote];
};

export const useEmployeesDocument = (
  employeeId: number
): [boolean, Document[]] => {
  const documents = useSelector(
    (state: RootState) => state.employee[employeeId]?.documents
  );
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    getEmployeeDocumentsCall(employeeId)
      .then((documents) => {
        dispatch(receivedDocuments({ employeeId, documents }));
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [employeeId]);

  return [isLoading, documents || []];
};

export const useGetEmployeeApplications = () => {
  const dispatch = useDispatch();

  return useCallback(
    (config: EntityListCallConfig) =>
      getEmployeeApplications(config).then((res) => {
        dispatch(gotJobCandidatesApplications(res));
        return res;
      }),
    [dispatch]
  );
};
