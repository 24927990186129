import React, { useState } from "react";
import { useGetUserNotes } from "../../services/hooks/employeeHooks";
import { View, StyleSheet } from "react-native";
import WPTextInput from "../input/components/WPTextInput";
import WPButton from "../Button/WPButton";
import { useTranslation } from "react-i18next";
import WPText from "../Text/WPText";
import { getNormalizedDate } from "../../utils/mixUtils";
import { useSelector } from "react-redux";
import { RootState } from "../../services/store/reducers/mainReducer";
import theme from "../../utils/theme";

export default function NotesUserInfoPreview(props: { userId: number }) {
  const { t, i18n } = useTranslation();
  const [text, setText] = useState("");
  const [isLoading, notes, addNote] = useGetUserNotes(props.userId);
  const user = useSelector((state: RootState) => state.auth);

  return (
    <View>
      <View style={styles.spacer}>
        <WPTextInput value={text} onChange={setText} numberOfLines={4} />
      </View>
      <View style={styles.spacer}>
        <WPButton
          size="small"
          onPress={() => {
            addNote(text);
            setText("");
          }}
          isLoading={isLoading}
          disabled={!text.trim()}
        >
          {t("labels.submit")}
        </WPButton>
      </View>

      <View style={styles.container}>
        {notes.map((note) => (
          <View key={note.id} style={styles.note}>
            <View style={styles.userContainer}>
              <WPText style={styles.userName}>{user?.userName}</WPText>
              <WPText style={styles.date}>
                {getNormalizedDate(note.date_created, i18n.language)}
              </WPText>
            </View>
            <WPText style={styles.text}>{note.body}</WPText>
          </View>
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  spacer: {
    marginBottom: 12,
  },
  container: {
    flexDirection: "column-reverse",
  },
  note: {
    marginBottom: 24,
  },
  userContainer: {
    marginBottom: 12,
  },
  userName: {
    fontFamily: "bold",
  },
  date: {
    fontSize: 12,
    color: theme.colors.mains[3],
  },
  text: {
    fontSize: 14,
    lineHeight: 14 * 1.43,
  },
});
