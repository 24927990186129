import { createActionCreator } from "deox";
import { JobOffer, Application } from "../../apis/jobApi";

export const getJob = createActionCreator(
  "GET_JOB",
  (resolve) => (jobId: number) => resolve(jobId)
);

export const gotJob = createActionCreator(
  "GOT_JOB",
  (resolve) => (job: JobOffer) => resolve(job)
);

export const jobDeleted = createActionCreator(
  "GOT_JOB_DELETED",
  (resolve) => (id: number) => resolve(id)
);

export const gotJobs = createActionCreator(
  "GOT_JOBS",
  (resolve) => (data: {
    jobs: JobOffer[];
    total: number;
    isExpired: boolean;
  }) => resolve(data)
);

export const gotApplications = createActionCreator(
  "GOT_APPLICATIONS",
  (resolve) => (data: { applications: Application[] }) => resolve(data)
);

export const gotJobCandidatesApplications = createActionCreator(
  "GOT_JOB_CANDIDATES_APPLICAtiONS",
  (resolve) => (data: { total: number }) => resolve(data)
);
